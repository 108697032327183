import React, { useRef, useLayoutEffect } from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import HabitoSVG from "../svg/HabitoSVG";
import { CabecalhoATStyle } from "./CabecalhoATStyle";
import CircleCheck from "../svg/circleCheck";
import WeexModal from "../weexModais/WeexModal";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import { smoothScroll } from "./smoothScroll";

function CabecalhoAT() {
  const {
    voltar,
    erro,
    mudarComponentePagina,
    componentePagina,
    TipoComponenteEnum,
    acerteTempo,
    indiceAcerteTempo,
    obtemPontuacaoQuestaoHC,
    mostrarAlternativas,
  } = useAcerteTempo();
  const titleOrName = acerteTempo?.title || acerteTempo?.name;

  const i18n = useStore().getState().i18n;

  const scrollRef = useRef(null);

  const cabecalhoVoltar = () => {
    if (componentePagina === TipoComponenteEnum.modoRevisao) {
      mudarComponentePagina(TipoComponenteEnum.placar);
      return;
    }
    voltar();
  };

  useLayoutEffect(() => {
    if (obtemPontuacaoQuestaoHC != null) {
      smoothScroll("proximo-7b82dc88", "down");
    } else if (mostrarAlternativas) {
      smoothScroll("alternativaContainer-221d0", "down");
    } else {
      smoothScroll("volta-8993e98b", "up");
    }
  }, [
    mudarComponentePagina,
    componentePagina,
    TipoComponenteEnum,
    acerteTempo,
    indiceAcerteTempo,
    obtemPontuacaoQuestaoHC,
    mostrarAlternativas,
  ]);

  const botaoVoltar = () => {
    return (
      <div id="volta-8993e98b" className="weex-l-cabecalho-voltarAltura">
        {componentePagina !== TipoComponenteEnum?.atividadeIniciada ||
        componentePagina === TipoComponenteEnum?.placar ? (
          <button
            onClick={cabecalhoVoltar}
            className="weex__buttonLink weex-l-cabecalho-btn-voltar"
            aria-label={i18n.message(
              "aria.label.geral.mensagem.voltar.atividades",
              "Volta para a página de atividades.",
            )}
          >
            <span className="custom-color-icon icon weex-l-cabecalho-custom-color-icon">
              <span className="custom-color-icon fa-stack fa-sm">
                <i className="custom-color-icon far fa-circle fa-stack-2x "></i>
                <i className="custom-color-icon fas fa-chevron-left fa-stack-1x "></i>
              </span>
            </span>
            <span className="weex-l-cabecalho-btn-text">
              {componentePagina === TipoComponenteEnum?.modoRevisao
                ? i18n.message("dinamica.acerteTempo.placar", "Placar")
                : i18n.message("dinamica.acerteTempo.inicio", "Inicio")}
            </span>
          </button>
        ) : (
          ""
        )}
      </div>
    );
  };

  const icones = () => {
    if (componentePagina === TipoComponenteEnum?.placar) {
      return (
        <span className="icon is-large">
          <CircleCheck />
        </span>
      );
    } else {
      return (
        <span className="icon is-large">
          <HabitoSVG />
        </span>
      );
    }
  };

  const quantidadeQuestoesDesktop = () => {
    if (
      componentePagina === TipoComponenteEnum?.atividadeIniciada ||
      componentePagina === TipoComponenteEnum?.modoRevisao
    ) {
      return (
        <h2 className="weex-l-cabecalho-quantidadeQuestoes">
          {indiceAcerteTempo + 1}/{acerteTempo?.questions?.length}
        </h2>
      );
    }
  };

  const quantidadeQuestoesMobile = () => {
    if (
      componentePagina === TipoComponenteEnum?.atividadeIniciada ||
      componentePagina === TipoComponenteEnum?.modoRevisao
    ) {
      return (
        <>
          {indiceAcerteTempo + 1}/{acerteTempo?.questions?.length}
        </>
      );
    }
  };

  return (
    <>
      {erro != null && (
        <WeexModal
          fecharModalCallback={voltar}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      <CabecalhoATStyle>
        <div ref={scrollRef}></div>
        {botaoVoltar()}
        <WeexDesktopMode>{quantidadeQuestoesDesktop()}</WeexDesktopMode>
        <div className="weex-l-cabecalho">
          <div className="weex-l-cabecalho--filhos">{icones()}</div>
          <WeexDesktopMode className="weex-l-cabecalho--filhos">
            <h1 className="weex-l-cabecalho-titulo">
              {componentePagina === TipoComponenteEnum?.comoFunciona ? (
                i18n.message("geral.menu.comoFunciona", "Como funciona")
              ) : (
                <>
                  {componentePagina === TipoComponenteEnum?.atividadeIniciada ||
                  componentePagina === TipoComponenteEnum?.modoRevisao
                    ? acerteTempo?.questions[indiceAcerteTempo]?.title
                    : titleOrName}
                </>
              )}
            </h1>
          </WeexDesktopMode>
          <WeexMobileMode style={{ width: "100%" }}>
            <h1 className="weex-l-cabecalho-titulo weex-l-cabecalho-titulo__composto">
              <span></span>
              {componentePagina === TipoComponenteEnum?.comoFunciona
                ? i18n.message("geral.menu.comoFunciona", "Como funciona")
                : componentePagina === TipoComponenteEnum?.atividadeIniciada ||
                    componentePagina === TipoComponenteEnum?.modoRevisao
                  ? acerteTempo?.questions[indiceAcerteTempo]?.title
                  : titleOrName}
              <span className="acerteTempo__cabecalho--qtItens">
                {quantidadeQuestoesMobile()}
              </span>
            </h1>
          </WeexMobileMode>
        </div>
      </CabecalhoATStyle>
    </>
  );
}

export default CabecalhoAT;

import React from "react";
import styled from "styled-components";
import BurguerMobile from "../../svg/botoes/BurguerMobile";

export const BurguerStyle = styled.button`
  width: 2.5rem; //40px;
  height: 2.375rem; // 38px;
  border-radius: 100%;

  padding: 10px;

  border: 0.063rem solid
    ${(props) => {
      return "#201d1d" || props.theme.headerText;
    }};
  background-color: ${(props) => {
    return "#201d1d" || props.theme.headerText;
  }};

  svg {
    width: 1.25rem; // 20px;
    height: 1.125rem; //18px;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.$toggleMenu ? "rotate(45deg)" : "none")};
  }
`;

function Burguer({ toggle, toggleMenu, altOpen, altClose }) {
  return (
    <>
      <BurguerStyle $toggleMenu={toggleMenu} type="button" onClick={toggle}>
        <BurguerMobile alt={toggleMenu ? altOpen : altClose} />
      </BurguerStyle>
    </>
  );
}

export default Burguer;

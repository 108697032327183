import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginStyle } from "../../../pages/authInscricao/style";
import CampanhaService from "../../../services/CampanhaService";
import InputsCustomizadosService from "../../../services/InputsCustomizadosService";
import TemaService from "../../../services/TemaService";
import UsuarioService from "../../../services/UsuarioService";
import SecundarioBtn from "../../buttonPWA2.0/SecundarioBtn";
import WeexFactory from "../../WeexInputs/WeexFactory";
import WeexModal from "../../weexModais/WeexModal";
import CustomWeexInput from "../input/CustomWeexInput";
import GerarSelectUnidadesTree from "../input/GerarSelectUnidadesTree";

const usuarioService = new UsuarioService();

const BIRTHDATE = "BIRTHDATE";
const UNIT = "UNIT";
const INVITE = "INVITE";
const ACCESS_DENIED_ERROR_CODE = 419;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Login padrão da Weex (sem auth 2F)
 */
function LoginCampaign({ modoRegister }) {
  const campanhaService = new CampanhaService();
  const inputsCustomizadosService = new InputsCustomizadosService();
  const eventoCorrente = campanhaService?.campanhaCorrente();

  const [credenciais, setCredenciais] = useState({
    username: null,
    password: null,
    campaign: eventoCorrente?.code,
    extraInfo: [],
  });
  const [processando, setProcessando] = useState(false);
  const [erro, setErro] = useState(null);

  const [unidadeMae, setUnidadeMae] = useState(null);
  const [subareas, setSubareas] = useState(null);
  const [subSubUnit, setSubSubUnit] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [loginComSenha, setLoginComSenha] = useState(
    inputsCustomizadosService.possuiCampo("SENHA"),
  );
  const navigate = useNavigate();

  if (!eventoCorrente || !eventoCorrente.tipoInscricao) {
    campanhaService.clearAll();
    if (!window.location.href.endsWith("campaign")) {
      window.location = "/campaign";
    }
  }
  const tipoInscricao = eventoCorrente?.tipoInscricao;
  const elegibilidade = eventoCorrente?.restrictEligibility;
  const nomeMatricula = eventoCorrente?.nomeMatricula;
  const obrigatoriedadeDoTermoCampanha = eventoCorrente?.legalTerm;
  const campaignType = eventoCorrente?.campaignType;
  const isCampaignTypeEquipe = campaignType === "EQUIPE";
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();

  const expoeBotoes =
    (elegibilidade && tipoInscricao !== "ELEGIBILIDADE") ||
    !(tipoInscricao === "ELEGIBILIDADE" && elegibilidade);

  const query = useQuery();
  const dashboardStyles = query.get("dashboardStyles");

  useEffect(() => {
    campanhaService.definirCampanha(
      eventoCorrente?.code,
      { dashboardStyles },
      (erro, sucesso) => {
        if (erro) {
          setErro(erro);
          return;
        }
        if (sucesso) {
          dispatch({ type: "campanha/selecionar", payload: sucesso.messages });
          dispatch({ type: "campanha", payload: sucesso });
          dispatch({ type: "template", payload: sucesso.template });
          dispatch({ type: "icone", payload: sucesso.icons });
          dispatch({ type: "usuario", payload: false });
          navigate("/");
        }
      },
    );
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventoCorrente.code]);

  function atribuirConvite(service) {
    const convite = service.getConvite();
    if (convite) {
      credenciais.extraInfo.push({ code: INVITE, value: convite });
    }
  }

  const submit = (event) => {
    event.preventDefault();
    if (processando) {
      return;
    }
    setProcessando(true);
    const usuarioService = new UsuarioService();
    atribuirAuthExtraInfoUnit();
    atribuirAuthExtraInfoBirthday();
    atribuirConvite(usuarioService);
    usuarioService.auth(credenciais, (erro, sucesso) => {
      credenciais.extraInfo = [];
      if (erro) {
        verificaErrosSubmit(erro);
        return;
      }
      if (sucesso) {
        // Remove o identificador do convite, se tiver um
        usuarioService.removeConvite();
        removerTema();
        setProcessando(false);
        dispatch({ type: "usuario", payload: true });
        dispatch({ type: "usuariologado", payload: sucesso });
        dispatch({ type: "userTeamUuid", payload: sucesso.team });
        dispatch({
          type: "usuarioAvatar",
          payload: sucesso?.avatarDTO ? true : false,
        });
        dispatch({ type: "avatar", payload: sucesso?.avatarDTO });
        if (obrigatoriedadeDoTermoCampanha && !sucesso.legalTerm) {
          navigate("/accept");
          return;
        }
        contadorCampanha();
      }
    });
  };

  const removerTema = () => {
    const temaService = new TemaService();
    temaService.removerTema();
  };

  const contadorCampanha = () => {
    campanhaService.contadorCampanha((erro, sucesso) => {
      if (erro) {
        setErro({
          titulo: i18n.message(
            "atividades.erro.dias.habilitados",
            "Erro obter lista de dias habilitados",
          ),
        });
        return;
      }
      if (sucesso) {
        if (sucesso !== null) {
          if (sucesso.length > 0) {
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
          } else {
            dispatch({ type: "campanhaComecou", payload: false });
            const adminOrGestor =
              usuarioService.isAdmin() || usuarioService.isGestor();
            if (
              elegibilidade &&
              tipoInscricao === "ELEGIBILIDADE" &&
              isCampaignTypeEquipe &&
              !usuarioService.usuarioPossuiEquipe() &&
              !adminOrGestor
            ) {
              navigate("/selectTeam");
              return;
            }
            navigate("/home");
          }
        }
      }
    });
  };

  const trataErroDeRegraDeAcesso = (erro) => {
    let errorMessageCode = "login.erro.access.denied.mensagem";
    let errorMessage =
      "Sua tentativa de acessar esta campanha não está de acordo com as regras estabelecidas";

    if (erro?.response?.data?.code === "IP_FAILED_VALIDATION") {
      errorMessageCode = "login.erro.access.denied.ip.mensagem";
      errorMessage = "Seu IP não está autorizado a acessar esta campanha";
    }

    setErro({
      titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
      mensagem: i18n.message(errorMessageCode, errorMessage),
    });
  };

  const verificaErrosSubmit = (erro) => {
    if (erro?.response?.data?.message) {
      if (erro?.response?.status === ACCESS_DENIED_ERROR_CODE) {
        trataErroDeRegraDeAcesso(erro);
      } else {
        setErro({
          titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
          mensagem: i18n.message(
            "login.erro.denied.mensagem",
            "Participante, informe todas as credenciais, elas são obrigatórias.",
          ),
        });
      }
      setProcessando(false);
      return;
    }

    let mensagemInscricao = "";
    let temSenha = true;
    if (eventoCorrente.tipoInscricao !== "CUSTOMIZADO") {
      mensagemInscricao = nomeMatricula;
    } else {
      if (inputsCustomizadosService.possuiCampo("MATRICULA")) {
        mensagemInscricao =
          inputsCustomizadosService.rotuloDoCampo("MATRICULA");
      } else if (inputsCustomizadosService.possuiCampo("EMAIL")) {
        mensagemInscricao = inputsCustomizadosService.rotuloDoCampo("EMAIL");
      } else {
        mensagemInscricao = "login";
      }
      if (!inputsCustomizadosService.possuiCampo("SENHA")) {
        temSenha = false;
        setLoginComSenha(false);
      }
    }
    let mensagemPadrao =
      "Verifique seu " + mensagemInscricao + " e senha e tente novamente.";
    if (elegibilidade || !temSenha) {
      mensagemPadrao =
        "Verifique seu " + mensagemInscricao + " e tente novamente.";
    }

    setErro({
      titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
      mensagem: i18n.message("login.erro.denied.mensagem", mensagemPadrao),
    });
    setProcessando(false);
  };

  const atribuirAuthExtraInfoUnit = () => {
    if (
      eventoCorrente?.extraAuthInfo?.includes(UNIT) &&
      (unidadeMae !== null || subareas !== null || subSubUnit !== null)
    ) {
      switch (eventoCorrente.levelUnit) {
        case 1:
          credenciais.extraInfo.push({ code: UNIT, value: unidadeMae });
          break;
        case 2:
          credenciais.extraInfo.push({ code: UNIT, value: subareas });
          break;
        case 3:
          credenciais.extraInfo.push({ code: UNIT, value: subSubUnit });
          break;
        default:
          break;
      }
    }
  };

  const changeDataFormat = (data) => {
    if (!data) return null;

    // Converte de DD/MM/YYYY para MM/DD/YYYY para compatibilidade com o construtor de Date
    const parts = data.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const formattedDate = `${month}/${day}/${year}`;

      // Cria um objeto Date e verifica se é válido
      const dateObject = new Date(formattedDate);
      if (!isNaN(dateObject.getTime())) {
        return `${day}/${month}/${year}`;
      }
    }
    return null;
  };

  const atribuirAuthExtraInfoBirthday = () => {
    if (
      eventoCorrente?.extraAuthInfo?.includes(BIRTHDATE) &&
      birthday !== null
    ) {
      credenciais.extraInfo.push({
        code: BIRTHDATE,
        value: changeDataFormat(birthday),
      });
    }
  };

  const onChange = (event) => {
    let cred = credenciais;
    cred[event.target.name] = event.target.value;
    setCredenciais(cred);
  };

  const onChangeBirthday = (value) => {
    setBirthday(value);
  };

  const callbackWarning = () => {
    setErro(null);
  };

  const inputBirthday = () => {
    if (eventoCorrente.ageType === "DATE" && elegibilidade) {
      return (
        <div>
          <WeexFactory
            id="birthday"
            type="date"
            name="birthday"
            placeholder=""
            onChange={(e) => {
              onChangeBirthday(e);
            }}
            legenda=""
            ariaLabel={i18n.message(
              "aria.label.campo.bithday",
              "Insira sua data de nascimento",
            )}
            label={i18n.message(
              "aria.label.campo.bithday",
              "Insira sua data de nascimento",
            )}
          />
        </div>
      );
    }
  };

  const extraInfoNascimento = () => {
    if (eventoCorrente?.extraAuthInfo?.includes(BIRTHDATE)) {
      return inputBirthday();
    }
  };

  const extraInfoUnidades = () => {
    if (eventoCorrente?.extraAuthInfo?.includes(UNIT)) {
      return (
        <GerarSelectUnidadesTree
          campanha={eventoCorrente}
          i18n={i18n}
          onChange={atribuirUnidade}
        ></GerarSelectUnidadesTree>
      );
    }
  };

  const atribuirUnidade = (event) => {
    if (event != null) {
      const value = event.target.value;
      if (event.target.name === "unit") {
        setUnidadeMae(value);
      } else if (event.target.name === "subUnit") {
        setSubareas(value);
      } else if (event.target.name === "level3") {
        setSubSubUnit(value);
      }
    }
  };

  const botaoInscrevase = () => {
    if (expoeBotoes) {
      return (
        <SecundarioBtn
          id="inscrevaSe"
          disabled={false}
          nome={i18n.message("registro.inscrevase", "Inscreva-se")}
          funcao={modoRegister}
        />
      );
    }
  };

  const esqueciSenha = () => {
    if (loginComSenha) {
      return (
        <Link
          to="/resetPassword"
          callbackLogin={() => {
            dispatch({ type: "usuariologado", payload: {} });
          }}
        >
          {i18n.message("registro.resetsenha", "Esqueci minha senha")}
        </Link>
      );
    }
  };

  return (
    <LoginStyle $loginComSenha={loginComSenha}>
      {erro != null ? (
        <WeexModal
          fecharModalCallback={callbackWarning}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}
      <form className="loginStyle__form" onSubmit={submit}>
        <CustomWeexInput
          tipoInscricao={tipoInscricao}
          elegibilidade={elegibilidade}
          nomeMatricula={nomeMatricula}
          onChange={onChange}
        />

        {extraInfoUnidades()}
        {extraInfoNascimento()}

        <fieldset className="loginStyle__enterButton">
          <SecundarioBtn
            id="login-campanha"
            disabledOnClick={false}
            disabled={false}
            type="submit"
            nome={
              processando
                ? i18n.message("geral.aguarde", "AGUARDE...")
                : i18n.message("login.entrar", "Entrar")
            }
          />
        </fieldset>

        {expoeBotoes ? (
          <fieldset className="loginStyle__register">
            {botaoInscrevase()}
            {esqueciSenha()}
          </fieldset>
        ) : (
          ""
        )}
      </form>
    </LoginStyle>
  );
}

export default LoginCampaign;

import styled from "styled-components";

export const InteresseDesktopStyle = styled.div`
  .interesse-content {
    h3 {
      color: #201d1d;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    h4 {
      color: #7c7c7c;
    }
  }
  @media screen and (min-width: 700px) {
    width: 100%;
    .interesse-content-desktop {
      margin: 3vw 3vw;
      margin-left: 5vw;
      .pageHeader__title {
        font-weight: bold;
        font-size: 2rem !important;
        line-height: 2rem !important;
      }
      .pageHeader__subtitle {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin: 1em 0;
        font-weight: bold;
      }
    }

    .cabecalho-interesse {
      display: flex;
      align-items: flex-start;
    }
    .cabecalho-interesse-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 50%;
      max-width: 50%;
    }

    .container-interesse {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5em;
    }

    .interesse-content-escolha {
      display: flex;
    }
    .interesse-escolha {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 50%;
      max-width: 50%;
      min-height: 22vw;
      margin-right: 3em;
    }
    .interesses {
      display: flex;
      justify-content: center;
    }
    .interesse-item {
      margin: 0.5em;
      .baseButton__text {
        text-align: center;
      }
    }

    .button-custom-interesse {
      background-color: white;
      border: 1px solid transparent;
      border-color: #dbdbdb;
      border-width: 1px;
      color: #363636;
      cursor: pointer;
      justify-content: center;
      padding-bottom: calc(1em - 1px);
      padding-left: 1em;
      padding-right: 1em;
      padding-top: calc(1em - 1px);
      text-align: center;
      white-space: normal;

      border-radius: 290486px;
      padding-left: calc(1em + 0.25em);
      padding-right: calc(1em + 0.25em);
    }

    .button-custom-interesse-contente {
      margin-top: 3em;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .interesses-escolhidos {
      display: flex;
      flex-direction: column;
    }

    .interesse-btn-container {
      width: 50%;
      display: flex;
      justify-content: center;
    }

    .interesse-title-escolha {
      display: flex;
    }

    .interesse-tilte-block {
      justify-content: space-between;
      min-width: 50%;
      max-width: 50%;
    }

    .interesse-title-content {
      margin-left: 3.5em;
    }

    .interesse-content__title {
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin: 1em 0;
      font-weight: bold;
    }
  }
`;

import React from "react";
import { DiaAgendaStyle } from "./style";
import { useStore } from "react-redux";
import CampanhaService from "../../../services/CampanhaService";
import AgendaProlongada from "../../svg/agenda/AgendaProlongada";

/**
 * Componente que exibe a data da agenda.
 * @param {Object} props As propriedades do componente.
 * @param {boolean} props.habilitado Indica se a data está habilitada.
 * @param {boolean} props.dayCompleted Indica se a data foi completada.
 * @param {string} props.diaTextual O dia textual.
 * @param {string} props.mesTextualVisivel O mês textual visível.
 * @param {string} props.mesTextualLeitorTela O mês textual para leitor de tela.
 * @param {boolean} props.isAgendaProlongada Indica se a agenda é prolongada.
 */

function DataAgenda({
  habilitado,
  diaTextual,
  mesTextualVisivel,
  mesTextualLeitorTela,
  isAgendaProlongada,
}) {
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const shouldShowScheduleDate =
    campanha?.personalizationEvent?.SHOW_DATE_SCHEDULE === "true";
  const i18n = useStore().getState().i18n;
  return (
    <>
      {!shouldShowScheduleDate && !isAgendaProlongada ? (
        ""
      ) : (
        <>
          <DiaAgendaStyle $habilitado={habilitado.toString()}>
            {isAgendaProlongada ? (
              <div className="diaAgenda__container">
                <p aria-hidden="true" className="diaAgenda__month">
                  {i18n.message("dia.agenda.prolongada.nome", "Livre")}
                </p>{" "}
                <div className="diaAgenda__itemPadding">
                  <AgendaProlongada />
                </div>
                <p className="weex__a11y--visuallyHidden">{`${diaTextual} ${mesTextualLeitorTela}`}</p>
              </div>
            ) : (
              <div className="diaAgenda__container">
                <p aria-hidden="true" className="diaAgenda__month">
                  {mesTextualVisivel}
                </p>{" "}
                <p
                  aria-hidden="true"
                  className="diaAgenda__itemPadding diaAgenda__day"
                >
                  {diaTextual}
                </p>
                <p className="weex__a11y--visuallyHidden">{`${diaTextual} ${mesTextualLeitorTela}`}</p>
              </div>
            )}
          </DiaAgendaStyle>
        </>
      )}
    </>
  );
}

export default DataAgenda;

import React, { useState, useEffect } from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import { ContadorRegressivoStyle } from "./contadorRegressivoStyle";
import AcerteTempoService from "../../services/AcerteTempoService";

function ContadorRegressivo() {
  const {
    allowsAnswerAfterTime,
    isContando,
    apagarContagem,
    acerteTempo,
    indiceAcerteTempo,
    componentePagina,
    TipoComponenteEnum,
    mostrarAlternativas,
    impedirResposta,
  } = useAcerteTempo();
  const [tempoInicial, setTempoInicial] = useState(null);
  const [contador, setContador] = useState(tempoInicial);
  const [variaveisPreenchidas, setVariaveisPreenchidas] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const i18n = useStore().getState().i18n;
  const acerteTempoService = new AcerteTempoService();

  /**
   * Seta as variavéis para a contagem regressiva
   */
  useEffect(() => {
    if (
      acerteTempo !== null &&
      acerteTempo !== undefined &&
      indiceAcerteTempo !== null &&
      indiceAcerteTempo !== undefined
    ) {
      localStorage.setItem(
        "acerteTempo__questionTime",
        acerteTempo?.questions[indiceAcerteTempo]?.time * 1000,
      );
      setTempoInicial(acerteTempo?.questions[indiceAcerteTempo]?.time);
      setContador(acerteTempo?.questions[indiceAcerteTempo]?.time);
      setVariaveisPreenchidas(true);
    }
  }, [acerteTempo, indiceAcerteTempo]);

  /**
   * Realiza a contagem regressiva
   */
  useEffect(() => {
    const origem = acerteTempoService.getOrigemLocalStorage(acerteTempo.uuid);
    if (origem !== "QUESTAO_ABERTA") {
      //limpa a o  acerteTempo__timestamp__enterPage__ da questão
      localStorage.removeItem(
        "acerteTempo__timestamp__enterPage__" +
          acerteTempo?.questions[indiceAcerteTempo].uuid,
      );
    }

    if (variaveisPreenchidas) {
      const enterPage = localStorage.getItem(
        "acerteTempo__timestamp__enterPage__" +
          acerteTempo?.questions[indiceAcerteTempo].uuid,
      );
      if (enterPage === null || enterPage === undefined) {
        localStorage.setItem(
          "acerteTempo__timestamp__enterPage__" +
            acerteTempo?.questions[indiceAcerteTempo].uuid,
          Date.now(),
        );
      }
      let interval;
      const time = verificarTempo();
      if (isContando) {
        interval = setInterval(() => {
          setContador((prevContador) =>
            prevContador === 0 ? 0 : prevContador - 1,
          );
        }, 1000);

        setIntervalId(interval);
      } else if (time !== null && time > 0) {
        setContador(contador - time);
        interval = setInterval(() => {
          setContador((prevContador) =>
            prevContador === 0 ? 0 : prevContador - 1,
          );
        }, 1000);
        // setIntervalId(interval);
      } else if (time !== null && time === 0) {
        setContador(0);
        setTempoInicial(0);
      }

      if (apagarContagem) {
        setContador(tempoInicial);
        clearInterval(intervalId);
      }

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variaveisPreenchidas, tempoInicial, isContando, apagarContagem]);

  /*
    Responsável por parar o contador quando
    o participante seleciona uma das alternativas.
  */
  useEffect(() => {
    if (!isContando && variaveisPreenchidas) {
      clearInterval(intervalId);
    }
  }, [intervalId, isContando, variaveisPreenchidas]);

  /*
    Responsável por setar a mensagem de que não será mais possível responder
    a questão depois que o contador zerou caso a opção allowsAnswerAfterTime estiver
    marcada como false
  */
  useEffect(() => {
    if (!allowsAnswerAfterTime && contador === 0 && isContando === true) {
      impedirResposta();
    }
  }, [allowsAnswerAfterTime, contador, isContando, impedirResposta]);

  /**
  Para saber se o tempo que o participante demorou para voltar para o acerte em tempo
  é maior que o tempo da questão.

  Se for maior, os segundos devem estar zerados.
  Se for menor, os segundos devem continuar de onde parou ao sair da página.
  */
  const verificarTempo = () => {
    const enterPage = localStorage.getItem(
      "acerteTempo__timestamp__enterPage__" +
        acerteTempo?.questions[indiceAcerteTempo].uuid,
    );
    const now = Date.now();
    const questionTime = localStorage.getItem("acerteTempo__questionTime");

    if (enterPage !== null && questionTime !== null) {
      const enterPageTime = Number(enterPage);
      const questionTimeNumber = Number(questionTime);
      const timeSpent = (enterPageTime - now) * -1;
      if (timeSpent > questionTimeNumber) {
        return 0;
      } else {
        const timeLeft = Math.round(timeSpent / 1000);
        if (timeLeft !== null && timeLeft !== undefined && timeLeft > 0) {
          return timeLeft;
        }
      }
    }
  };

  const barraEstilo = {
    width: `${(contador / tempoInicial) * 75}%`,
  };

  if (
    componentePagina === TipoComponenteEnum.atividadeIniciada &&
    acerteTempo?.questions[indiceAcerteTempo]?.alternatives?.length > 0 &&
    mostrarAlternativas
  ) {
    return (
      <>
        <span className="weex__a11y--visuallyHidden">
          {`${contador} ${i18n.message(
            "alt.dinamica.acerteTempo.contadorSegundos",
            "segundos",
          )}`}
        </span>
        <ContadorRegressivoStyle>
          <div className="weex-l-barra-regresso-container">
            <div className="weex-l-barra-regresso-circle weex-l-barra-regresso-circle--segundos">
              <p className="weex-l-barra-regresso-circle--contagem">
                {contador}
              </p>
              <p className="weex-l-barra-regresso-circle--textoSegundos">
                {i18n.message("dinamica.acerteTempo.contadorSegundos", "seg")}
              </p>
            </div>

            <div className="weex-l-barra-regresso" style={barraEstilo}></div>

            <div className="weex-l-barra-regresso-circle">
              <img
                src="/cronometro.svg"
                alt={i18n.message(
                  "alt.dinamica.acerteTempo.cronometro",
                  "Ícone de um cronometro",
                )}
                className="weex-l-barra-regresso-circle--img"
              />
            </div>
          </div>
        </ContadorRegressivoStyle>
      </>
    );
  } else if (componentePagina === TipoComponenteEnum.modoRevisao) {
    return (
      <>
        <span className="weex__a11y--visuallyHidden">
          {`${contador} ${i18n.message(
            "alt.dinamica.acerteTempo.contadorSegundos",
            "segundos",
          )}`}
        </span>
        <ContadorRegressivoStyle>
          <div className="weex-l-barra-regresso-container">
            <div className="weex-l-barra-regresso-circle weex-l-barra-regresso-circle--segundos">
              <p className="weex-l-barra-regresso-circle--contagem">
                {contador}
              </p>
              <p className="weex-l-barra-regresso-circle--textoSegundos">
                {i18n.message("dinamica.acerteTempo.contadorSegundos", "seg")}
              </p>
            </div>

            <div className="weex-l-barra-regresso" style={barraEstilo}></div>

            <div className="weex-l-barra-regresso-circle">
              <img
                src="/cronometro.svg"
                alt={i18n.message(
                  "alt.dinamica.acerteTempo.cronometro",
                  "Ícone de um cronometro",
                )}
                className="weex-l-barra-regresso-circle--img"
              />
            </div>
          </div>
        </ContadorRegressivoStyle>
      </>
    );
  } else {
    return <></>;
  }
}

export default ContadorRegressivo;

import styled from "styled-components";

export const AgendaHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;

  .agendaHeader__alignedSubelements {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    h5 {
      font-size: inherit;
    }
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }

  /* #endregion DESKTOP */
`;

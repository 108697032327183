import { configureStore } from "@reduxjs/toolkit";
import CampanhaService from "./services/CampanhaService";
import IconeService from "./services/IconeService";
import InternacionalizacaoService from "./services/Internacionalizacao";
import TemplateService from "./services/TemplateService";
import UsuarioService from "./services/UsuarioService";

const campanhaService = new CampanhaService();
const usuarioService = new UsuarioService();

const initialState = {
  mainBackgroundColor: "#ffffff",
  campanha: campanhaService.campanhaCorrente() || {},
  i18n: new InternacionalizacaoService(),
  template: new TemplateService(),
  icone: new IconeService(),
  usuario: false || usuarioService.usuarioCorrente() ? true : false,
  usuariologado: usuarioService.usuarioCorrente(),
  usuarioAvatar: false || usuarioService.getUsuarioAvatar() ? true : false,
  userTeamUuid: usuarioService.getUserTeamUuid(),
  avatar: usuarioService.getUsuarioAvatar(),
  backgroundispresent: "true",
  isDesktopMode: campanhaService.isDesktopModel(),
  isAdmin: usuarioService.isAdmin(),
  isGestor: usuarioService.isGestor(),
  gameUuid: "",
  modo: "",
  campanhaComecou: false,
  scoreParticipant: {},
  rankingEvent: [],
  selectListCampanha: [
    // por enquanto so tem uma tipo de buscar na campanha futuramente será adicionado mais
    {
      uuid: "Geral",
      name: "Geral",
    },
    {
      uuid: "Unidade",
      name: "Unidade",
    },
  ],
  modals: {
    // Adicione outros modais conforme necessário
    avatar: false,
    welcomeVideo: false,
    error: false,
    entrarEquipe: false,
  },
  error: {
    title: null,
    message: null,
  },
  entrarEquipe: {
    teamUuid: null,
    teamName: null,
    title: null,
    message: null,
    callConfirmation: null,
    btnCancelMessage: null,
    btnConfirmMessage: null,
  },
  messageVideo: [],
};

if (campanhaService.campanhaCorrente()) {
  initialState.i18n.load();
  initialState.template.load();
  initialState.icone.load();
}

function weexReducer(state = initialState, action) {
  switch (action.type) {
    case "campanha/selecionar":
      let internacional = new InternacionalizacaoService();
      internacional.load();
      return {
        ...state,
        i18n: internacional,
      };
    case "campanha":
      return {
        ...state,
        campanha: action.payload.campanha,
      };
    case "template":
      let t = new TemplateService();
      t.load();
      return {
        ...state,
        template: t,
      };
    case "icone":
      let i = new IconeService();
      i.load();
      return {
        ...state,
        icone: i,
      };
    case "usuario":
      return {
        ...state,
        usuario: action.payload,
      };
    case "usuariologado":
      return {
        ...state,
        usuariologado: action.payload,
      };
    case "userTeamUuid":
      return {
        ...state,
        userTeamUuid: action.payload,
      };
    case "usuarioAvatar":
      return {
        ...state,
        usuarioAvatar: action.payload,
      };
    case "avatar":
      return {
        ...state,
        avatar: action.payload,
      };
    case "backgroundispresent":
      return {
        ...state,
        backgroundispresent: action.payload,
      };
    case "isDesktopMode":
      return {
        ...state,
        isDesktopMode: action.payload,
      };
    case "isAdmin":
      return {
        ...state,
        isAdmin: action.payload,
      };
    case "isGestor":
      return {
        ...state,
        isGestor: action.payload,
      };
    case "game":
      return {
        ...state,
        gameUuid: action.payload,
      };
    case "modo":
      return {
        ...state,
        modo: action.payload,
      };
    case "scoreParticipant":
      return {
        ...state,
        scoreParticipant: action.payload,
      };
    case "rankingEvent":
      return {
        ...state,
        rankingEvent: action.payload,
      };
    case "campanhaComecou":
      return {
        ...state,
        campanhaComecou: action.payload,
      };
    case "SHOW_MODAL":
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload]: true,
        },
      };
    case "HIDE_MODAL":
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload]: false,
        },
      };
    case "error":
      return {
        ...state,
        error: action.payload,
      };
    case "entrarEquipe":
      return {
        ...state,
        entrarEquipe: action.payload,
      };
    case "messageVideo":
      return {
        ...state,
        messageVideo: action.payload,
      };
    case "mainBackgroundColor":
      return {
        ...state,
        mainBackgroundColor: action.payload,
      };
    default:
      return state;
  }
}

export default configureStore({
  reducer: weexReducer,
});

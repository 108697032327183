/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { CertificadoContainerStyle } from "./styled";
import UsuarioService from "../../services/UsuarioService";
import CampanhaService from "../../services/CampanhaService";
import { useStore } from "react-redux";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import WeexDesktopMode from "../../comps/WeexMode/WeexDesktopMode";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import { useNavigate } from "react-router-dom";
import BotaoAsALink from "../../comps/botaoAsALink/BotaoAsALink";
import WeexMobileMode from "../../comps/WeexMode/WeexMobileMode";

export default function Certificado() {
  const isDesktopMode = useStore().getState().isDesktopMode;
  const i18n = useStore().getState().i18n;
  const iframeRef = useRef(null);
  const [iframeUrl, setIframeUrl] = useState("");
  const [height, setHeight] = useState("400px");
  const [width, setWidth] = useState("100%");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const usuarioService = new UsuarioService();
    const campanhaService = new CampanhaService();

    const usuario = usuarioService?.usuarioCorrente();
    const campanha = campanhaService?.campanhaCorrente();

    if (usuario && campanha) {
      const url = `${process.env.REACT_APP_HOST_CERTIFICADO}/event/${campanha.uuid}/participant/${usuario.participantCode}`;
      setIframeUrl(url);
      fetch(url, { mode: "no-cors" })
        .then((response) => {
          console.info("Certificado carregado com sucesso: ", response);
        })
        .catch((error) => {
          console.error("Erro ao carregar o certificado: ", error);
          setHasError(true);
        });
    }
  }, []);

  const handleIframeLoad = () => {
    // Enviar token após o iframe ter carregado
    const usuarioService = new UsuarioService();
    const usuario = usuarioService?.usuarioCorrente();
    if (usuario && iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        { type: "TOKEN", token: usuario.token },
        process.env.REACT_APP_HOST_CERTIFICADO,
      );
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleIframeError = () => {
    console.error("Erro ao carregar o iframe");
    setIsLoading(false);
    setHasError(true);
  };

  const baixarArquivo = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        { type: "PDF", pdf: true },
        process.env.REACT_APP_HOST_CERTIFICADO,
      );
    }
  };

  useEffect(() => {
    if (isDesktopMode) {
      setHeight("600px");
      setWidth("50%");
    } else {
      setHeight("350px");
      setWidth("100%");
    }
  }, [isDesktopMode]);

  return (
    <CertificadoContainerStyle>
      <WeexDesktopMode asHtmlTag="div">
        <BotaoAsALinkIconeEsquerda
          icon="fas fa-arrow-left"
          classeComplementar="header-como-funciona-desktop-botao"
          nome={i18n.message("geral.voltar", "Voltar")}
          ariaLabelMsg={i18n.message(
            "aria.label.geral.mensagem.voltar.atividades",
            "Volta para a página de atividades.",
          )}
          funcao={() => {
            navigate("/home");
          }}
        />
      </WeexDesktopMode>

      <div className="certificado-container">
        {isLoading && <div>{i18n.message("geral.aguarde", "Aguarde")}</div>}

        {hasError ? (
          <div>
            {i18n.message("erro.iframe", "Erro ao carregar o certificado.")}
          </div>
        ) : (
          iframeUrl && (
            <iframe
              ref={iframeRef}
              src={iframeUrl}
              title="Certificado"
              width={width}
              height={height}
              onLoad={handleIframeLoad}
              onError={handleIframeError}
              frameBorder="0"
            />
          )
        )}

        <div className="btn-pdf">
          <PrimarioBtn
            nome={i18n.message("certificado.download", "Baixar certificado")}
            funcao={baixarArquivo}
            disabled={hasError || isLoading}
          />
        </div>

        <WeexMobileMode>
          <BotaoAsALink
            nome={i18n.message("geral.voltar", "Voltar")}
            funcao={() => {
              navigate("/home");
            }}
          />
        </WeexMobileMode>
      </div>
    </CertificadoContainerStyle>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { LeadershipStyle } from "./LeadershipStyle";
import Medalha from "../svg/Medalha";
/**
 * Pódio do ranking da campanha, usado tanto no ranking do game como no da competição individual.
 * @param {Object} props
 * @param {Object} props.topRanking
 * @returns
 */
function LeadershipRanking({ topRanking }) {
  const i18n = useSelector((state) => state.i18n);

  const gePodiumColor = (index) => {
    if (index === 0) {
      return "#F3CF17";
    } else if (index === 1) {
      return "#7C7C7C";
    } else if (index === 2) {
      return "#C76D2B";
    }
  };

  const topNaoDefinidas = () => {
    const topRankingNaoDefinidos = [];
    if (topRanking.length < 3) {
      for (let i = topRanking.length; i < 3; i++) {
        let cssNotFirstPlace = i !== 0 ? "podium__score--notFirstPlace" : "";
        topRankingNaoDefinidos.push(
          <div className={`podium podium__place--${i}`} key={i}>
            <Medalha color={gePodiumColor(i)} />
            <div>
              <p className="podium__position">
                {i + 1}º {i18n.message("rankings.leadership.place", " lugar")}
              </p>
              <h4 className="podium__position" aria-hidden="true">
                {i18n.message("rankings.leadership.undefined", "Não definido")}
              </h4>
            </div>
            <div className={`podium__score podium__score--${i}`}>
              <span className={`podium__score--text ${cssNotFirstPlace}`}>
                0{" "}
                <small aria-hidden="true">
                  {" "}
                  {i18n.message("geral.pts", "pts")}{" "}
                </small>
              </span>
            </div>
          </div>,
        );
      }
      return topRankingNaoDefinidos;
    }
  };

  const ranking = () => {
    if (topRanking) {
      const topRankingNaoDefinidos = topNaoDefinidas();
      const ranking = topRanking.map((ranking, index) => {
        let cssNotFirstPlace =
          index !== 0 ? "podium__score--notFirstPlace" : "";
        return (
          <div className={`podium podium__place--${index}`} key={index}>
            <Medalha color={gePodiumColor(index)} />
            <div>
              <p className="podium__position" aria-hidden="true">
                {ranking.position || `0${index + 1}`}º
                {i18n.message("rankings.leadership.place", " lugar")}
              </p>
              <span className="weex__a11y--visuallyHidden">{`${
                index + 1
              }º ${i18n.message("rankings.aria.label.lugar", "lugar")}, ${
                ranking?.name
              }`}</span>
              <h4 className="podium__position" aria-hidden="true">
                {ranking.name}
              </h4>
            </div>
            <div className={`podium__score podium__score--${index}`}>
              <span className={`podium__score--text ${cssNotFirstPlace}`}>
                {ranking.rating.toLocaleString("pt-BR")}
                <small aria-hidden="true">
                  {" "}
                  {i18n.message("geral.pts", "pts")}
                </small>
              </span>
            </div>
            <span className="weex__a11y--visuallyHidden">{`${
              ranking.rating
            }${i18n.message("rankings.aria.label.pontos", "pontos")}`}</span>
          </div>
        );
      });
      if (topRankingNaoDefinidos && topRankingNaoDefinidos.length > 0) {
        topRankingNaoDefinidos.forEach((element) => {
          ranking.push(element);
        });
      }
      return ranking;
    }
  };

  return <LeadershipStyle>{ranking()}</LeadershipStyle>;
}

export default LeadershipRanking;

import styled from "styled-components";

export const CampanhaRankingsStyled = styled.div`
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */

  .buttom-page {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  .buttom-page__buttom {
    width: 45%;
  }

  span {
    color: inherit;
  }
  @media (min-width: 75rem) {
    margin-left: ${(props) => {
      if (props.$isRenderedInSchedulePage === true) {
        return "0px";
      } else {
        return "20rem";
      }
    }};
    margin-right: ${(props) => {
      if (props.$isRenderedInSchedulePage === true) {
        return "0px";
      } else {
        return "20rem";
      }
    }};
    padding: 1.5rem; //24px;
    background-color: #f8f8f8;
    border-radius: 1.25rem; // 20px;
    border: 0.063rem solid #f3f3f3; // 1px;
  }
  /* #endregion DESKTOP */

  .botoes_select {
    display: flex;
    gap: 1rem; //24px;
    margin-top: 1.5rem; //24px;
    margin-bottom: 1.5rem; //24px;
  }
`;

import React from "react";
import { BotaoAsALinkStyle } from "./style";

/**
 * @param {Object} props
 * @param {string} props.classeComplementar
 * @param {string} props.ariaLabelMsg
 * @param {boolean} props.disabled
 * @param {string} props.nome
 * @param {string} props.icon icon className
 * @param {string} props.iconRight icon right className
 * @param {function(e): void} props.funcao onClick event
 */
function BotaoAsALinkIconeEsquerda(props) {
  return (
    <BotaoAsALinkStyle
      className={`${props.classeComplementar} ${props.disabled && "disabled"}`}
      onClick={props.funcao}
      aria-label={props.ariaLabelMsg}
    >
      <span className="botaoDesktop">
        {props.icon && (
          <span className="icon icon-custom">
            <i className={props.icon}></i>
          </span>
        )}
        <span>{props.nome}</span>
        {props.iconRight && (
          <span className="icon icon-custom-right">
            <i className={props.iconRight}></i>
          </span>
        )}
      </span>
      <span className="weex__a11y--visuallyHidden" role="status">
        {props.ariaAtivarMensagemDeClique ? props.ariaStatusMessage : ""}
      </span>
    </BotaoAsALinkStyle>
  );
}

export default BotaoAsALinkIconeEsquerda;

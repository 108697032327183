import InfiniteScroll from "react-infinite-scroller";
import { RankingsListStyled } from "./styled";
import { useSelector } from "react-redux";
import PontuacaoEquipe from "./PontuacaoEquipe";
import { useLayoutEffect, useRef } from "react";

function RankingListaEquipes({ rankings, paginacao }) {
  const i18n = useSelector((state) => state.i18n);
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const scrollRef = useRef(null);

  useLayoutEffect(() => {
    // Rolagem suave para o elemento específico na página principal
    if (scrollRef.current && paginacao.page > 0 && isDesktopMode) {
      // Rola o scroll da página principal até este elemento
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      // Ajusta o scroll da div interna para o topo
      scrollRef.current.scrollTop = 0;
    }
  }, [rankings, isDesktopMode, paginacao.page]);

  const gerarPaginas = () => {
    if (rankings <= 0) {
      return (
        <>
          <p>{i18n.message("ranking.campanha.no.equipes", "Não há equipes")}</p>
        </>
      );
    }
    // se hover filtro, exibe as equipes filtradas
    return rankings.map((ranking, index) => {
      return <PontuacaoEquipe key={index} {...ranking} />;
    });
  };
  return (
    <RankingsListStyled>
      <div ref={scrollRef} className="ranking__scrollbar">
        <InfiniteScroll
          pageStart={0}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          {gerarPaginas()}
        </InfiniteScroll>
      </div>
    </RankingsListStyled>
  );
}

export default RankingListaEquipes;

import React, { useEffect, useRef, useState } from "react";
import { ButtonTeamRankingStyle } from "../button/style";
import { useSelector } from "react-redux";

function BotaoOpcoesRanking({
  funcao,
  controlValue,
  tag,
  disabled = false,
  disabledOnClick = true,
  size = "normal",
  type = "button",
  ariaLabelMsg,
  tabIndex = "0",
  ariaHidden = "false",
  iconposition,
  iconLeft,
  iconRight,
}) {
  const i18n = useSelector((state) => state.i18n);
  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] =
    useState(false);
  const buttonRef = useRef(null);
  const [newFilterName, setNewFilterName] = useState(false);

  useEffect(() => {
    if (disabled) {
      buttonRef.current.disabled = true;
      return;
    }
    buttonRef.current.disabled = false;
  }, [disabled]);

  useEffect(() => {
    setNewFilterName(controlValue === tag);
  }, [controlValue, tag]);

  function onClick(event) {
    if (disabled) return;
    if ((funcao !== null || funcao !== undefined) && type !== "submit") {
      if (disabledOnClick) {
        buttonRef.current.disabled = true;
      }
      setAriaAtivarMensagemDeClique(true);
      funcao && funcao(event);
    }
  }

  function camelCaseToNormal(str) {
    // Adiciona um espaço antes de cada letra maiúscula
    let result = str.replace(/([A-Z])/g, " $1");

    // Converte a primeira letra da string resultante para maiúscula
    result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();

    return result;
  }

  const nome = i18n.message(
    "ranking.campanha.equipes.botao.options." + tag,
    camelCaseToNormal(tag),
  );

  const a11yStatus = () => {
    const ariaStatusMessage = i18n.message(
      "alt.ranking.campanha.equipes.botao.options." + tag,
      "Botão " + camelCaseToNormal(tag) + " clicado",
    );
    if (ariaAtivarMensagemDeClique) {
      return (
        <span className="weex__a11y--visuallyHidden" role="status">
          {ariaStatusMessage}
        </span>
      );
    }
  };

  const left = () => {
    if (iconLeft && (iconposition === "left" || iconposition === "both")) {
      return <div className="baseButton__iconContainer">{iconLeft}</div>;
    }
  };

  const right = () => {
    if (iconRight && (iconposition === "right" || iconposition === "both")) {
      return <div className="baseButton__iconContainer">{iconRight}</div>;
    }
  };

  return (
    <ButtonTeamRankingStyle
      $filterName={newFilterName}
      $iconposition={iconposition}
      $size={size}
      $hasname={nome ? "true" : "false"}
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      aria-hidden={ariaHidden}
      tabIndex={tabIndex}
      aria-label={ariaLabelMsg ? ariaLabelMsg : nome}
      aria-disabled={disabled ? "true" : "false"}
      type={type}
    >
      <span className="baseButton__content">
        {left()}
        {nome && <span className="baseButton__text">{nome}</span>}
        {right()}
      </span>
      {a11yStatus()}
    </ButtonTeamRankingStyle>
  );
}

export default BotaoOpcoesRanking;

import React from "react";

const ArrowEnter = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.41663 5.8335L12.3333 8.75016L9.41663 11.6668"
        stroke="#201D1D"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99994 2.3335V6.41683C2.99994 7.03567 3.24577 7.62916 3.68336 8.06675C4.12094 8.50433 4.71443 8.75016 5.33327 8.75016H12.3333"
        stroke="#201D1D"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowEnter;

import styled, { keyframes } from "styled-components";

export const MenuStyle = styled.span`
  display: flex;
  align-items: center;

  .menuStyle__group {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
  }

  .menuStyle__group--link {
    padding-top: 0.5rem;
    gap: 3rem;
    margin-right: 3rem;
  }

  .menuStyle__group--btn {
    gap: 0.5rem;
  }

  .menuStyle__link {
    color: ${(props) => {
      return props.theme.headerText;
    }};
    text-decoration: none;
  }

  .menuStyle__link--dotContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menuStyle__link--dot {
    width: 0.375rem;
    height: 0.375rem;
    background-color: ${(props) => {
      return props.theme.headerBg;
    }};
    border-radius: 50%;
  }

  .menuStyle__link--dotActived {
    background-color: ${(props) => {
      return props.theme.headerText;
    }};
  }

  .submenu__group--mobile {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .menuStyle__link:hover,
    menuStyle__link:active {
      filter: contrast(0.6);
    }
  }
  /* #endregion DESKTOP */
`;

const dropdownOpenAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-0.625rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const dropdownCloseAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-0.625rem);
  }
`;

export const DropdownMenuStyle = styled.div`
  border-radius: 0px 0px 1.5rem 1.5rem;
  background-color: ${(props) => {
    return props.theme.headerBg;
  }};
  color: ${(props) => {
    return props.theme.headerText;
  }};
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;

  animation: ${({ $toggleMenu }) =>
      $toggleMenu ? dropdownOpenAnimation : dropdownCloseAnimation}
    0.3s ease forwards;

  .dropdownMenuStyle__list {
    font-size: 1.1rem;
    display: ${(props) => {
      if (props.$toggleMenu) {
        return "flex";
      }
      return "none";
    }};
    flex-direction: column;
    gap: 1rem;
  }

  .dropdownMenuStyle__logout {
    margin-top: 1rem;
  }

  .dropdownMenuStyle__link {
    color: ${(props) => {
      return props.theme.headerText;
    }};
    text-decoration: none;
  }
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .dropdownMenuStyle__link:hover,
    dropdownMenuStyle__link:active {
      filter: contrast(0.8);
    }
  }
`;

import styled from "styled-components";

/**
 * Estilo da tag com a pontuação obtida
 * em cada questão respondida
 */
export const TagRespostaStyle = styled.div`
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
  padding-left: 1rem;
  padding-right: 1rem;

  .weex-l-acerteTempo-background {
    background-color: ${(props) => {
      if (props.$correto) {
        return "#00E291";
      } else {
        return "#FE001A";
      }
    }};

    border-radius: 2rem;
  }

  .weex-l-acerteTempo-resposta-tag--bulmaColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.4rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .acerteTempo__tagResposta--ghostDiv {
    width: 2.063rem; //33px;
  }

  .weex-l-acerteTempo-resposta-tag--img {
    max-width: 2.5rem;
    min-width: 2.5rem;
  }

  .weex-l-acerteTempo-resposta-tag--bonus {
    font-weight: 800;
    font-size: 0.875rem;
  }

  .weex-l-acerteTempo-resposta-tag--textos {
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 700;
    color: ${(props) => {
      if (props.$correto) {
        return "#4a4a4a";
      } else {
        return "#FFFFFF";
      }
    }};
  }

  .weex-l-acerteTempo-resposta-tag--pontos-uppercase {
    text-transform: uppercase;
  }

  .weex-l-acerteTempo-icone {
    font-size: 2rem;
    color: #ffffff;
  }
`;

/**
 * Estilo da tag na página do placar
 */
export const TagPlacarStyle = styled.div`
  border: 1px solid
    ${(props) => {
      if (props.$correto) {
        return "#00E291";
      } else {
        return "#FE001A";
      }
    }};
  border-radius: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;

  .justify-center {
    justify-content: center;
  }

  .weex-l-acerteTempo-placar-desktopIconeSize {
    padding: 0.75rem;
  }

  .weex-l-acerteTempo-resposta-tag--bulmaColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .acerteTempo__tagResposta--ghostDiv {
    width: 2.063rem; // 33px;
  }

  .weex-l-acerteTempo-resposta-tag--img {
    max-width: 2rem;
    min-width: 2rem;
  }

  .weex-l-acerteTempo-resposta-tag--bonus {
    font-weight: 800;
    font-size: 0.875rem;
  }

  .weex-l-acerteTempo-resposta-tag--textos {
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 800;
    color: #4a4a4a;
  }

  .weex-l-acerteTempo-icone {
    color: ${(props) => {
      if (props.$correto) {
        return "#00E291";
      } else {
        return "#FE001A";
      }
    }};
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-acerteTempo-placar-desktopIconeSize {
      font-size: 2rem;
    }
  }
  /* #endregion DESKTOP */
`;

/**
 * Estilo da tag com o total do pontos obtidas
 * na página do placar
 */
export const TagTotalStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  background-color: #00e291;
  border-radius: 2rem;
  margin-top: 1.6rem;
  padding-left: 1rem;
  padding-right: 1rem;

  .weex-l-acerteTempo-resposta-tag--textos {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #4a4a4a;
    font-weight: 700;
  }

  .weex-l-acerteTempo-resposta-tag--pontos-uppercase {
    text-transform: uppercase;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin-left: 20%;
    margin-right: 20%;
  }
  /* #endregion DESKTOP */
`;

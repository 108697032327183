import styled from "styled-components";

export const VideoStyled = styled.div`
  .dinamica-container {
    overflow: hidden;
  }
  .text-area-feedback-video {
    max-height: 3rem;
    margin-top: 1rem;
  }

  .botao-video-desabilitado {
    background-color: #dfdde3;
    color: #ffffff;
    cursor: not-allowed;
  }

  .text-area-feedback-video&::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
    color: #847d92;
  }
  .video-pergunta {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 1.5rem;
  }

  .video-container {
    width: 100%;
    margin: 0px;
    height: ${(props) => {
      return ((props.$sizeScreen * 0.8) / 16) * 9;
    }}px;
  }

  .video-with-desktop {
    text-align: center;
  }

  .mobile-video {
    text-align: center;
  }
  .video-quiz-feedback-markup {
    p {
      text-align: center;
      color: #201d1d;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  @media screen and (min-width: 700px) {
    /** @deprecated - será removido */
    .dinamica-content-desktop {
      margin: 1vw 5vw;
    }
    width: 100%;
    .desktop-video-voltar {
      display: block;
    }

    .dinamica-container {
      display: flex;
      justify-content: space-between;
    }

    .video-desktop-content-left {
      margin-top: 2vw;
      min-width: 25%;
      margin-right: 10rem;
    }
    .video-with-desktop {
      width: 100%;
      margin-left: 2em;
    }
    .video-container {
      width: 100%;
      margin: 0px;
      height: 60vh;
    }

    .dinamica-container {
      overflow: visible;
    }

    .is-desktop_video {
      margin-top: 4em;
      width: 100%;
    }
    .video-botao-entendi-desktop {
      display: block;
      margin-top: 2em;
    }

    .video-feedback-desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
      margin-left: 1em;
    }

    .video-text-area-desktop {
      width: 20vw;
    }

    .feedback-video {
      width: 100%;
    }

    .video-text-area-desktop-text {
      border-left: 5px solid #ffffff;
      font-weight: 300;
      font-size: 14px;
      line-height: 12px;
      background: #fff;
    }
  }
`;

import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Face({ changeAvatar }) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.rosto", "Rosto")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          type="faceColor"
          color="#F9C9B6"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor1",
            "Cor de pele rosa suave",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor1",
            "Cor de pele rosa suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#AC6651"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor2",
            "Cor de pele marrom suave",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor2",
            "Cor de pele marrom suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#77311D"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor3",
            "Cor de pele marrom",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor3",
            "Cor de pele marrom selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#9287FF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor4", "Lilás")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor4",
            "Lilás selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#6BD9E9"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor5", "Azul aqua")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor5",
            "Azul aqua selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#FC909F"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor6", "Rosa")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor6",
            "Rosa selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#F4D150"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor7", "Amarelo")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor7",
            "Amarelo selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#E0DDFF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor8", "Lilás suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor8",
            "Lilás suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#D2EFF3"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor9", "Azul aqua suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor9",
            "Azul aqua suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#FFEDEF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor10", "Rosa suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor10",
            "Rosa suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="faceColor"
          color="#FFEBA4"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor11", "Amarelo suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor11",
            "Amarelo suave selecionado",
          )}
        />
      </AvatarPartsContainerStyle>
    </>
  );
}

import React from "react";

const Exit = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.125 2.5H10C10.3315 2.5 10.6495 2.6317 10.8839 2.86612C11.1183 3.10054 11.25 3.41848 11.25 3.75V12.5M1.25 12.5H3.125M3.125 12.5L7.34875 13.5556C7.44086 13.5786 7.537 13.5803 7.62987 13.5606C7.72273 13.5408 7.80988 13.5002 7.8847 13.4418C7.95952 13.3833 8.02005 13.3086 8.06168 13.2233C8.10332 13.138 8.12497 13.0443 8.125 12.9494V2.85125C8.12488 2.66136 8.08149 2.47338 7.99814 2.30276C7.91479 2.13214 7.79367 1.98276 7.64396 1.86595C7.49426 1.74913 7.31991 1.66796 7.13414 1.6286C6.94838 1.58923 6.75609 1.5927 6.57188 1.63875L4.07188 2.26375C3.80145 2.33135 3.56138 2.48739 3.38982 2.70708C3.21825 2.92677 3.12504 3.19751 3.125 3.47625L3.125 12.5ZM8.125 12.5H13.75M6.25 7.5V7.50708"
        stroke="#201D1D"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Exit;

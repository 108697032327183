import React, { useState } from "react";
import { useStore } from "react-redux";
import Menu from "../menu/Menu";
import DropdownMenu from "../menu/DropdownMenu";
import Burguer from "./Burguer";
import WeexMobileMode from "../../WeexMode/WeexMobileMode";
import WeexDesktopMode from "../../WeexMode/WeexDesktopMode";
import LogoFactory from "../LogoFactory";
import Participant from "../participant/Participant";
import CampaignInformations from "../../campanha/CampaignInformations";
import SubmenuMobile from "../menu/SubmenuMobile";

function Navbar({ now, sair }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const i18n = useStore().getState().i18n;

  let toggle = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <>
      <WeexMobileMode asHtmlTag="nav" className="header__nav">
        <section className="header__topBar">
          <LogoFactory type="mobile" />
          <Burguer
            toggle={toggle}
            toggleMenu={toggleMenu}
            altOpen={i18n.message(
              "alt.menu.burguer.mobile.open",
              "Menu suspenso, aberto.",
            )}
            altClose={i18n.message(
              "alt.menu.burguer.mobile.close",
              "Menu suspenso, fechado.",
            )}
          />
        </section>
        <section>
          <DropdownMenu
            now={now}
            toggle={toggle}
            toggleMenu={toggleMenu}
            sair={sair}
          />
          <Participant />
        </section>
        <section className="header__bottomBar">
          <SubmenuMobile />
        </section>
      </WeexMobileMode>

      <WeexDesktopMode asHtmlTag="nav">
        <section className="header__topBar">
          <LogoFactory type="desktop" />
          <Menu now={now} sair={sair} />
        </section>
        <section className="header__bottomBar">
          <Participant />
          <CampaignInformations />
        </section>
      </WeexDesktopMode>
    </>
  );
}

export default Navbar;

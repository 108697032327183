import React, { useEffect } from "react";
import { useStore } from "react-redux";
import CampanhaService from "../../../services/CampanhaService";
import TemaService from "../../../services/TemaService";
import InteresseDesktop from "./InteresseDesktop";
import { InteresseDesktopStyle } from "./InteresseDesktopStyle";
import PagesHeader from "../../pagesHeader/PagesHeader";

export default function InteressesDesktop(props) {
  const i18n = useStore().getState().i18n;

  useEffect(() => {
    let temaService = new TemaService(new CampanhaService().campanhaCorrente());
    temaService.aplicarTema("HOME");
  }, []);
  return (
    <>
      <InteresseDesktopStyle>
        <div className="interesse-content">
          <div className="interesse-content-desktop">
            <PagesHeader
              title={props.titleMessage}
              subtitle={props.selectMessage}
            />
            <div style={{ marginTop: "3rem" }}>
              <div className="interesse-title-escolha">
                <div className="interesse-tilte-block"></div>
                <div className="interesse-title-content">
                  <p className="interesse-content__title">
                    {i18n.message("interesse.escolha", "Você escolheu:")}{" "}
                  </p>
                </div>
              </div>
              <InteresseDesktop
                campanha={props.campanha.interests}
                beginDate={props.beginDate}
                now={props.now}
                daysOfEvent={props.daysOfEvent}
                canSelectInterets={props.canSelectInterets}
                requiredInterests={props.requiredInterests}
              />
            </div>
          </div>
        </div>
      </InteresseDesktopStyle>
    </>
  );
}

import styled from "styled-components";

export const Style = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  padding-bottom: 0.8rem;

  overflow-x: auto;
  width: 100%;
  button {
    white-space: nowrap;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    white-space: normal;
  }
  /* #endregion DESKTOP */
`;

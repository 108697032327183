import React from "react";
import { useSelector } from "react-redux";
import { PontuacaoUsuarioCorrenteStyle } from "../../comps/rankings/styled";
import Trofeu from "../svg/Trofeu";

function PontuacaoUsuarioCorrente({ pontuacaoUsuario, foraDoRanking }) {
  const i18n = useSelector((state) => state.i18n);

  let name = "";
  let unit = "";
  if (pontuacaoUsuario?.name) {
    name = pontuacaoUsuario?.name;
  }
  if (pontuacaoUsuario?.unit) {
    unit = " | " + pontuacaoUsuario?.unit;
  }

  const userInfos = `${name} ${unit}`;

  return (
    <PontuacaoUsuarioCorrenteStyle>
      <Trofeu />
      <div>
        {foraDoRanking === false ? (
          <h4>
            {i18n.message("info.pontuacao", "Sua posição: fora do top 50 ")}
          </h4>
        ) : (
          <h4>
            {i18n.message(
              "ranking.pontuacao.usuarioCorrente.titulo",
              "Sua pontuação",
            )}
          </h4>
        )}
        <p>{userInfos}</p>
      </div>
      <div className="participantCurrent__score">
        <h4>{pontuacaoUsuario?.rating || 0}</h4>
        <span> {i18n.message("geral.pts", "pts")}</span>
      </div>
    </PontuacaoUsuarioCorrenteStyle>
  );
}

export default PontuacaoUsuarioCorrente;

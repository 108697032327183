import styled from "styled-components";

export const WeexHeaderStyle = styled.header`
  border-radius: 0px 0px 1.5rem 1.5rem;
  background-color: ${(props) => {
    return props.theme.headerBg;
  }};
  color: ${(props) => {
    return props.theme.headerText;
  }};

  .header__topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem; // 32px;
  }

  .header__nav .baseButton__iconContainer {
    background-color: ${(props) => {
      return props.theme.headerTextSecudaryButton;
    }};
  }

  .header__nav .baseButton__text {
    color: ${(props) => {
      return props.theme.headerTextSecudaryButton;
    }};
  }

  .header__bottomBar {
    margin-top: 2rem; // 32px;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .header__bottomBar {
      display: flex;
      justify-content: space-between;
    }
  }
  /* #endregion DESKTOP */
`;

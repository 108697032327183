import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useStore } from "react-redux";
import MuralService from "../../services/MuralService";
import VideoIframe from "../VideoIframe/VideoIframe";
import BotaoIcone from "../botaoIcone/BotaoIcone";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import BotaoVideoMural from "../botaoVideoMural/BotaoVideoMural";
import Spinner from "../WeexSpinner/Spinner";
import WeexModalAnexarVideo from "./WeexModalAnexarVideo";
import { WeexModalDialog } from "./WeexModalDialog";
import WeexTextarea from "../WeexInputs/WeexTextarea";
import WeexFile from "../WeexInputs/WeexFile";

/**
 * @param {Object} props
 * @param {string} props.ariaTextArea
 * @param {boolean} props.isPublicacao
 * @param {function(): void} props.fecharModalCallback
 * @param {function({ postagem: string, imagemCode: any }): void} props.create // TODO: Encontrar a  tipagem de imagemCode
 */
function EscreverPublicacao({
  ariaTextArea,
  fecharModalCallback,
  isPublicacao,
  create,
  allowVideoLink,
  muralCampanha,
  allowUploadVideo,
}) {
  const [request, setRequest] = useState({
    postagem: "",
    imagemCode: null,
    urlVideo: null,
    videosMuralDTO: null,
  });

  const i18n = useStore().getState().i18n;
  const [tamanhoTexto, setTamanhoTexto] = useState(0);
  const [botaoIsDesabled, setBotaoIsDesabled] = useState(null);
  const [isSpinnerAtivo, setisSpinnerAtivo] = useState(false);
  const [processandoImagem, setProcessandoImagem] = useState(false);
  const [imagemProcessada, setImagemProcessada] = useState(false);
  const [imagem, setImagem] = useState("");
  const [arquivo, setArquivo] = useState();
  const [arquivoVideo, setArquivoVideo] = useState();
  const [videoPreviewURL, setVideoPreviewURL] = useState(null);
  const [mostrarDialogModal, setMostrarDialogModal] = useState(true);
  const [isAnexarVideoModal, setisModalAnexarVideoModal] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const dialogRef = useRef(null);
  const refForm = useRef(null);
  const scrollRef = useRef(null);

  function submit(event) {
    event.preventDefault();

    if (!request?.postagem?.trim()) {
      alert(
        i18n.message(
          "dinamica.mural.publicar.alert.texto",
          "Texto não pode estar vazio!",
        ),
      );
      return;
    }

    setisSpinnerAtivo(true);
    if (isPublicacao) {
      if (!request.imagemCode) {
        delete request.imagemCode;
      }
      create(request, arquivoVideo);
    } else {
      delete request.imagemCode;
      create(request, arquivoVideo);
    }
  }

  useEffect(() => {
    if (!arquivo) {
      setImagem(undefined);
      return;
    }

    if (!arquivoVideo) {
      setVideoPreviewURL(undefined);
      return;
    }

    const preview = URL.createObjectURL(arquivo);
    setImagem(preview);

    setVideoPreviewURL(URL.createObjectURL(arquivoVideo));

    return () => URL.revokeObjectURL(preview);
  }, [arquivo, arquivoVideo]);

  useEffect(() => {
    if (request) {
      const isValid = refForm.current.checkValidity();

      if (!isValid) {
        setBotaoIsDesabled(false);
      } else {
        setBotaoIsDesabled(null);
      }
    }
  }, [request]);

  function ativaModalAnexarVideo() {
    setisModalAnexarVideoModal(true);
  }

  function enviarImagem(e) {
    setProcessandoImagem(true);
    const tamanhoArquivo = 1024 * 1024 * 30;
    const file = e.target.files[0];
    if (file.size > tamanhoArquivo) {
      setProcessandoImagem(false);
      alert(
        i18n.message(
          "dinamica.mural.publicar.alert.imagem",
          "Imagem maior que o tamanho permitido de 30 MB!",
        ),
      );
    } else {
      const service = new MuralService();
      service.enviarImagem(file, (erro, sucess) => {
        if (sucess) {
          setRequest({ ...request, imagemCode: sucess.code });
          setProcessandoImagem(false);
          setImagemProcessada(true);
          setArquivo(file);
          setImagem(URL.createObjectURL(file));
        }
        if (erro) {
          setRequest({ ...request, imagemCode: null });
          alert(JSON.stringify(erro));
          setProcessandoImagem(false);
          setImagemProcessada(false);
          setImagem("");
        }
      });
    }
  }

  function receberVideo(e) {
    const tamanhoArquivo = 1024 * 1024 * 300;

    const file = e.target.files[0];

    if (file && file.size > tamanhoArquivo) {
      alert(
        i18n.message(
          "dinamica.mural.publicar.alert.videoUpload",
          "video maior que o tamanho permitido de 50 MB!",
        ),
      );
      return;
    }

    setArquivoVideo(file);
    setVideoPreviewURL(URL.createObjectURL(file));
  }

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      if (videoPreviewURL) {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      }
    }, 500); // Delay of 500ms

    // Cleanup timeout if the component is unmounted before timeout triggers
    return () => clearTimeout(timeoutId);
  }, [videoPreviewURL]);
  function previewVideoUpload() {
    if (videoPreviewURL) {
      return (
        <video width="400" controls>
          <source src={videoPreviewURL} type="video/mp4" />
          <track
            default
            kind="captions"
            srcLang="en"
            src=""
            label="English captions"
          />
          Your browser does not support the video tag.
        </video>
      );
    }
    return null;
  }

  function anexarVideo(url) {
    setUrlVideo(url);
    setRequest({ ...request, urlVideo: url });
    desativaModalAnexarVideo();
  }

  function desativaModalAnexarVideo() {
    setisModalAnexarVideoModal(false);
  }

  function hasVideo() {
    return urlVideo.length > 0;
  }

  function hasVideoUpload() {
    if (!videoPreviewURL) return false;
    return videoPreviewURL.length > 0;
  }

  function renderImagem() {
    if (imagemProcessada) {
      return (
        <div className="video-publicacao">
          <img
            src={imagem}
            className="preview-imagem"
            alt={i18n.message(
              "alt.mural.publicar.imagem.erro",
              "Formato não suportado",
            )}
          ></img>
        </div>
      );
    }
  }

  function renderVideo() {
    if (hasVideo()) {
      return (
        <div className="video-publicacao">
          <VideoIframe
            urlVideo={urlVideo}
            controls={false}
            showTitle={false}
            title={i18n.message(
              "arial.mural.publicar.video.titleAnexar",
              "Video Anexado",
            )}
          ></VideoIframe>
        </div>
      );
    }
  }

  function removerVideo() {
    setUrlVideo("");
  }

  function removerImagem() {
    setImagemProcessada(false);
    setImagem("");
    setRequest({ ...request, imagemCode: null });
  }

  function handlerRemover() {
    if (hasVideo()) {
      removerVideo();
    } else if (imagemProcessada) {
      removerImagem();
    }
  }

  const renderContent = () => {
    if (hasVideoUpload()) {
      const preview = previewVideoUpload();
      return preview;
    } else if (hasVideo()) {
      return renderVideo();
    } else {
      return renderImagem();
    }
  };

  const shouldShowRemoveButton = hasVideo() || imagemProcessada;

  function preViewRender() {
    return (
      <div className="video-publicacao-container">
        {renderContent()}
        {shouldShowRemoveButton && (
          <BotaoIcone
            icon="fas fa-trash"
            type="button"
            funcao={handlerRemover}
            label={i18n.message(
              "label.mural.publicar.remover",
              "Remover imagem ou video",
            )}
          />
        )}
      </div>
    );
  }

  function fecharDialogModal() {
    fecharModalCallback();
    setMostrarDialogModal(false);
    dialogRef.current.close();
  }

  const buttonClick = () => {
    setBotaoIsDesabled(true);
    //se o form for valido ele vai chamar o submit mesmo que o botao esteja desabilitado
    if (refForm.current.checkValidity()) {
      refForm.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    } else {
      //se o form for invalido ele vai chamar o reportValidity para mostrar os erros
      refForm.current.reportValidity();
    }
  };

  const getMenssagemBotao = () => {
    if (!botaoIsDesabled) {
      return i18n.message("dinamica.mural.publicar", "Publicar ");
    } else {
      return i18n.message("dinamica.mural.publicando", "Publicando ");
    }
  };

  return mostrarDialogModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={fecharDialogModal}>
      {isAnexarVideoModal && (
        <WeexModalAnexarVideo
          visivel={isAnexarVideoModal}
          confirma={anexarVideo}
          cancela={desativaModalAnexarVideo}
        ></WeexModalAnexarVideo>
      )}
      <div className={"modal is-active"}>
        <div className="modal-background"></div>
        <div className=" modal-card-publicacao ">
          <form ref={refForm} onSubmit={submit}>
            <header className="weex-modal-card-header-publicacao escreverPublicacao__header">
              <div />
              <div className="titulo-icone escreverPublicacao__title">
                <div className="icon-mural-modal">
                  <span className="icon color-fundo-secundaria">
                    <i className="fa fa-comment-dots"></i>
                  </span>
                </div>
                {isPublicacao ? (
                  <h3 className="titulo-modal">
                    {i18n.message(
                      "dinamica.mural.publicar.titulo",
                      "ESCREVER PUBLICAÇÂO",
                    )}
                  </h3>
                ) : (
                  <h3 className="titulo-modal">
                    {i18n.message("dinamica.mural.comentar.titulo", "COMENTAR")}
                  </h3>
                )}
              </div>
              <button
                onClick={fecharDialogModal}
                className="close-btn"
                aria-label={i18n.message(
                  "aria.label.mural.fechar.modal",
                  "Fecha a janela e volta para a página mural social.",
                )}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message(
                    "alt.button.imagem.fechar",
                    "ícone em formato de um X indicando o encerramento de uma ação.",
                  )}
                />
              </button>
            </header>
            <section className="modal-card-body has-text-centered">
              <div style={{ marginBottom: "10px" }}>
                <div>
                  <div>
                    <WeexTextarea
                      ariaLabel={ariaTextArea}
                      id="escreverPublicacao"
                      rows={6}
                      required
                      maxLength="280"
                      minLength="1"
                      value={request?.postagem}
                      onChange={(e) => {
                        setRequest({ ...request, postagem: e.target.value });
                        setTamanhoTexto(e.target.value.length);
                      }}
                      className="textarea text-area-feedback-video textarea-mural"
                      placeholder={
                        isPublicacao
                          ? i18n.message(
                              "dinamica.mural.publica.placeholder",
                              "Preencha sua publicação",
                            )
                          : i18n.message(
                              "dinamica.mural.comentar.placeholder",
                              "Preencha seu comentário",
                            )
                      }
                      textSize={tamanhoTexto}
                    />
                  </div>
                  {isPublicacao ? (
                    <>
                      <div className="imagem-publicacao">
                        <WeexFile
                          id="input-file"
                          name="upload"
                          onChange={enviarImagem}
                          accept="image/*"
                          label={i18n.message(
                            "dinamica.mural.publica.upload",
                            "Selecione uma imagem",
                          )}
                          required={false}
                          icon={"fa fa-images"}
                        />
                      </div>
                      {allowUploadVideo && (
                        <div className="imagem-publicacao">
                          <WeexFile
                            id="input-video"
                            name="upload"
                            onChange={receberVideo}
                            accept="video/*"
                            label={i18n.message(
                              "dinamica.mural.publica.video.upload",
                              "Selecione um video",
                            )}
                            required={false}
                            icon={"fa fa-video"}
                            disabled={imagemProcessada}
                          />
                        </div>
                      )}
                      {muralCampanha === true ? (
                        <>
                          {allowVideoLink ? (
                            <div className="imagem-publicacao">
                              <BotaoVideoMural
                                funcao={ativaModalAnexarVideo}
                                disabled={imagemProcessada}
                              ></BotaoVideoMural>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <div className="imagem-publicacao">
                          <BotaoVideoMural
                            funcao={ativaModalAnexarVideo}
                            disabled={imagemProcessada}
                          ></BotaoVideoMural>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <Spinner isVisivel={processandoImagem}></Spinner>
                  {preViewRender()}
                </div>
              </div>
            </section>
            <Spinner isVisivel={isSpinnerAtivo}></Spinner>

            <footer
              className="modal-card-foot weex-modal-card-footer"
              style={{ textAlign: "center" }}
            >
              <PrimarioBtn
                classeComplementar={
                  botaoIsDesabled ? "weex-mural-button-disabled" : ""
                }
                type="submit"
                nome={getMenssagemBotao()}
                disabled={botaoIsDesabled}
                funcao={buttonClick}
                ariaStatusMessage={i18n.message(
                  "aria.status.escreverpublicacao.publicar",
                  "Botão publicar clicado.",
                )}
              />
            </footer>
            <div className="mt-1" ref={scrollRef}></div>
          </form>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default EscreverPublicacao;

import React from "react";
import { useStore } from "react-redux";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";

/**
 * @param {Object} props
 * @param {boolean} props.ultimo card deve aparecer então o botão de finalizar atividade
 * @param {string} props.cardPictureSrc src da imagem do card
 * @param {string} props.cardDescription html da descrição do card
 * @param {function(): void} props.proximoHabitoCallback função que leva para o próximo card
 * @param {function(): void} props.finalizarCallback função que finaliza a atividade
 */
function CardNovosHabitos({
  ultimo,
  cardPictureSrc,
  cardDescription,
  proximoHabitoCallback,
  finalizarCallback,
  bloquearBotao,
}) {
  const i18n = useStore().getState().i18n;
  const createMarkup = () => {
    if (cardDescription) {
      return { __html: cardDescription };
    }
  };
  return (
    <div>
      <div className="card weex-card-interno">
        <div>
          {cardPictureSrc && (
            <div>
              <div className="animations-HideUntilLoaded">
                <figure className="weex-card-container-imagem">
                  <img
                    src={cardPictureSrc}
                    alt={i18n.message(
                      "alt.novoHabito.card",
                      "Imagem card novo hábito",
                    )}
                    className="weex__image is-2by1"
                  />
                </figure>
              </div>
            </div>
          )}
          <div className="novos-habitos card-content">
            <div className="content weex-card-interno__content weex-card-interno__content__fato-fake__novos-habitos">
              <div
                className="markup-novos-habitos"
                dangerouslySetInnerHTML={createMarkup()}
              ></div>
            </div>
            <div className="botao-card-novos-habitos">
              {cardDescription ? (
                ultimo ? (
                  <PrimarioBtn
                    nome={i18n.message(
                      "dinamica.novoshabitos.farei",
                      "FAREI ISSO!",
                    )}
                    funcao={finalizarCallback}
                    disabled={bloquearBotao}
                    classeComplementar={
                      bloquearBotao ? "botao-desabilitado" : ""
                    }
                    ariaStatusMessage={i18n.message(
                      "aria.status.cardnovoshabitos.finalizando",
                      "Botão farei isso clicado",
                    )}
                  />
                ) : (
                  <SecundarioBtn
                    nome={i18n.message(
                      "dinamica.novoshabitos.proximo",
                      "PRÓXIMO HÁBITO",
                    )}
                    funcao={(e) => {
                      e.target.blur();
                      proximoHabitoCallback();
                    }}
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardNovosHabitos;

import React from "react";
import WeexSimplesInput from "./WeexSimplesInput";
import WeexPassword from "./WeexPassword";
import WeexBirthdateInput from "./WeexBirthdateInput";
import WeexNumbersSelect from "./WeexNumbersSelect";

/**
 * Factory to create Weex input components for forms.
 * @param {object} props - properties of the input component
 * @param {string} props.type - type of the input component
 * @param {string} props.name - name of the input component
 * @param {string} props.id - id of the input component
 * @param {string} props.placeholder - placeholder of the input component
 * @param {string} props.label - label of the input component
 * @param {boolean} [props.required=true] - whether the input component is required or not
 * @param {boolean} [props.disabled=false] - whether the input component is disabled or not
 * @param {function} props.onChange - function to be called when the input component value changes
 * @param {string} [props.icon] - icon to be displayed before the input component
 * @param {string} [props.iconposition="right"] - position of the icon, can be "left" or "right"
 * @param {boolean} [props.strong=true] - whether the input component is displayed in strong style or not
 * @param {string} [props.value] - value of the input component
 * @param {string} [props.ariaLabel] - accessibility label of the input component
 * @param {string} [props.pattern] - regular expression pattern for validating the input component value
 * @param {function} [props.onInvalid] - function to be called when the input component value is invalid
 * @param {function} [props.onKeyPress] - function to be called when the input component is focused and a key is pressed
 * @returns {JSX.Element} - the input component
 */
function WeexFactory({
  key,
  type,
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  icon,
  iconposition = "right",
  strong = true,
  value = "",
  ariaLabel,
  pattern,
  onInvalid,
  onKeyPress,
}) {
  switch (type.toLocaleLowerCase()) {
    case "text":
      return (
        <WeexSimplesInput
          key={key}
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          label={label}
          required={required}
          disabled={disabled}
          onChange={onChange}
          icon={icon}
          iconposition={iconposition}
          strong={strong}
          value={value}
          ariaLabel={ariaLabel}
          pattern={pattern}
          onInvalid={onInvalid}
          onKeyPress={onKeyPress}
        />
      );
    case "strongPassword":
      return (
        <WeexPassword
          key={key}
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          label={label}
          required={required}
          disabled={disabled}
          onChange={onChange}
          icon={icon}
          iconposition={iconposition}
          strong={strong}
          value={value}
          ariaLabel={ariaLabel}
        />
      );
    case "password":
      return (
        <WeexPassword
          key={key}
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          label={label}
          required={required}
          disabled={disabled}
          onChange={onChange}
          icon={icon}
          iconposition={iconposition}
          strong={strong}
          value={value}
          ariaLabel={ariaLabel}
        />
      );
    case "date":
      return (
        <WeexBirthdateInput
          key={key}
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          label={label}
          required={required}
          disabled={disabled}
          onChange={onChange}
          icon={icon}
          iconposition={iconposition}
          strong={strong}
          value={value}
          ariaLabel={ariaLabel}
          pattern={pattern}
          onInvalid={onInvalid}
        />
      );
    case "email":
      return (
        <WeexSimplesInput
          key={key}
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          label={label}
          required={required}
          disabled={disabled}
          onChange={onChange}
          icon={icon}
          iconposition={iconposition}
          strong={strong}
          value={value}
          ariaLabel={ariaLabel}
          pattern={pattern}
          onInvalid={onInvalid}
        />
      );
    case "numbersList":
      return (
        <WeexNumbersSelect
          key={key}
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          label={label}
          required={required}
          disabled={disabled}
          onChange={onChange}
          icon={icon}
          iconposition={iconposition}
          strong={strong}
          value={value}
          ariaLabel={ariaLabel}
        />
      );
    default:
      console.error(`Tipo de input desconhecido: ${type}`);
      throw new Error(`Tipo de input desconhecido: ${type}`);
  }
}

export default WeexFactory;

import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import TeamService from "../../services/TeamService";

const teamService = new TeamService();

function TeamLogoListagem({ uuid }) {
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;
  const [logoSrc, setLogoSrc] = useState(null);

  const getLogo = () => {
    if (!uuid) {
      return;
    }
    teamService
      .getTeamLogo(uuid)
      .then((response) => {
        if (response.data.size > 0) {
          const url = URL.createObjectURL(response.data);
          setLogoSrc(url);
        }
      })
      .catch((error) => {
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "selectTeam.logo.erro.title",
              "Erro ao retornar logo da equipe",
            ),
            message: i18n.message(
              "selectTeam.logo.erro.message",
              "Erro ao retornar logo da equipe. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            ),
          },
        });
      });
  };

  useEffect(() => {
    getLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (logoSrc !== null) {
    return (
      <img
        className="cardTeam__imageContainer--logo"
        alt={i18n.message("aria.cardTeam.icone.equipe", "ícone da equipe")}
        src={logoSrc}
      />
    );
  } else {
    return (
      <img
        className="cardTeam__imageContainer--logoDefault"
        alt={i18n.message(
          "aria.cardTeam.icone.equipe.padrao",
          "ícone com três usuario simbolizando uma equipe",
        )}
        src="/team.svg"
      />
    );
  }
}

export default TeamLogoListagem;

import styled, { css } from "styled-components";

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  @media (min-width: 75rem) {
    &:hover div {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const TooltipText = styled.div`
  border-radius: 0.5rem; //8px;
  visibility: hidden;
  width: 7.5rem; //120px;
  background-color: #7c7c7c;
  padding: 0.313rem; //0.313rem;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;

  font-size: 0.688rem; //11px;
  font-style: italic;
  font-weight: 400;
  line-height: 0.825rem; //.2px;
  text-align: right;
  color: #ffffff;

  &:after {
    content: "";
    position: absolute;
    border-style: solid;
  }

  ${({ $position }) => {
    switch ($position) {
      case "top":
        return css`
          bottom: calc(100% + 0.313rem);
          left: -80%;
          margin-left: -0.313rem;
          &:after {
            top: 100%;
            left: 50%;
            border-width: 0.313rem;
            border-color: #7c7c7c transparent transparent transparent;
          }
        `;
      case "right":
        return css`
          top: 33%;
          left: calc(100% + 0.313rem);
          margin-top: -0.313rem;
          &:after {
            top: 33%;
            left: -0.625rem; //-10px;
            border-width: 0.313rem;
            border-color: transparent #7c7c7c transparent transparent;
          }
        `;
      case "bottom":
        return css`
          top: calc(100% + 0.313rem);
          left: -80%;
          margin-left: -0.313rem;
          &:after {
            bottom: 100%;
            left: 50%;
            border-width: 0.313rem;
            border-color: transparent transparent #7c7c7c transparent;
          }
        `;
      case "left":
        return css`
          top: 33%;
          right: calc(100% + 0.313rem);
          margin-top: -0.313rem;
          &:after {
            top: 33%;
            right: -0.625rem; //-10px;
            border-width: 0.313rem;
            border-color: transparent transparent transparent #7c7c7c;
          }
        `;
      default:
        return null;
    }
  }}
`;

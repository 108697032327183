import React, { useState } from "react";
import { InformacaoSuaEquipeStyle } from "./styled";
import RankingListaParticipantes from "../../comps/rankings/RankingListaParticipantes";
import TeamLogo from "../team/TeamLogo";
import UsuarioService from "../../services/UsuarioService";
import { useStore } from "react-redux";
import RankingEquipesService from "../../services/RankingEquipesService";

const RankingDaEquipe = ({
  uuidEquipe,
  nomeEquipe,
  pontuacaoEquipe,
  refreshEquipeInfo,
  members,
  loading,
  search,
}) => {
  const userServices = new UsuarioService();
  const rankingEquipesService = new RankingEquipesService();
  const [isEditing, setIsEditing] = useState(false);
  const [nameField, setNameField] = useState(nomeEquipe);
  const i18n = useStore().getState().i18n;

  const editName = () => {
    rankingEquipesService.editTeamName(nameField).then(() => {
      refreshEquipeInfo();
      // setSearchValue(nomeEquipe);
    });

    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setNameField(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      editName();
    }
  };

  const rankingFormat = members
    ?.sort((a, b) => b.score - a.score)
    .map((participant, index) => {
      return {
        rating: participant.score,
        name: participant.name,
        uuidParticipante: participant.participantUuid,
        position: index + 1,
        isCompEquip: true,
      };
    });

  const isLeader =
    members?.find((member) => member.role === "LEADER")?.participantUuid ===
    userServices?.getCodigoParticipante();

  return (
    <>
      <InformacaoSuaEquipeStyle>
        {uuidEquipe && (
          <TeamLogo uuid={uuidEquipe} name={nameField} isEditable={isLeader} />
        )}
        {isEditing ? (
          <input
            type="text"
            value={nameField}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={() => editName()}
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4>{nameField} </h4>
              {isLeader && (
                <button
                  aria-label={i18n.message(
                    "aria.label.editar.nome.equipe",
                    "Editar nome da equipe",
                  )}
                  className="icon pointer"
                  onClick={handleEditClick}
                >
                  <i className="material-icons" style={{ color: "#005925" }}>
                    create
                  </i>
                </button>
              )}
            </div>
            <div className="participantCurrent__score">
              <h4>{pontuacaoEquipe || 0}</h4>
              <span> {i18n.message("geral.pts", "pts")}</span>
            </div>
          </div>
        )}
      </InformacaoSuaEquipeStyle>
      <RankingListaParticipantes
        rankingFormat={rankingFormat}
        loading={loading}
        search={search}
      />
    </>
  );
};

export default RankingDaEquipe;

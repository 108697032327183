import React from "react";

export default function AgendaProlongada() {
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 4.00001C6.66671 2.22001 5.33337 1.33334 4.00004 1.33334C3.2928 1.33334 2.61452 1.61429 2.11442 2.11439C1.61433 2.61449 1.33337 3.29277 1.33337 4.00001C1.33337 4.70725 1.61433 5.38553 2.11442 5.88563C2.61452 6.38573 3.2928 6.66668 4.00004 6.66668C5.33337 6.66668 6.66671 5.78001 8.00004 4.00001ZM8.00004 4.00001C9.33337 5.78001 10.6667 6.66668 12 6.66668C12.7073 6.66668 13.3856 6.38573 13.8857 5.88563C14.3858 5.38553 14.6667 4.70725 14.6667 4.00001C14.6667 3.29277 14.3858 2.61449 13.8857 2.11439C13.3856 1.61429 12.7073 1.33334 12 1.33334C10.6667 1.33334 9.33337 2.22001 8.00004 4.00001Z"
        stroke="#201D1D"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { PollVotesStyle } from "./style";

export default function PollVotes({ alternative }) {
  const i18n = useStore().getState().i18n;
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  const wordVote =
    alternative?.votes === 1
      ? i18n.message("enqueteCampanha.votos.singular", "voto")
      : i18n.message("enqueteCampanha.votos.plural", "votos");
  return (
    <>
      {alternative?.percent !== null && alternative?.votes !== null ? (
        <PollVotesStyle
          style={{
            opacity: opacity,
            transition: "opacity 2s ease-in-out",
          }}
        >
          <span className="pollVotes__percentage">
            {alternative?.percent.toLocaleString("pt-BR", {
              maximumFractionDigits: 1,
            })}
            <small>%</small>
          </span>
          <span className="pollVotes__count">
            {alternative?.votes}
            <small>&#x2007;{wordVote}</small>
          </span>
        </PollVotesStyle>
      ) : (
        ""
      )}
    </>
  );
}

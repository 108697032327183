import styled from "styled-components";

export const DinamicasHeaderStyle = styled.header`
  margin-top: 3em;

  h3 {
    color: ${(props) => {
      return props.theme.cardLogadoTextoTerceiro;
    }};
  }

  h3 {
    color: ${(props) => {
      return props.theme.cardLogadoTextoTerceiro;
    }};
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .color-fundo-secundaria {
    color: ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};
  }

  .input-mural {
    border: none;
    padding: 0.8em;
    width: -webkit-fill-available;
  }

  .display-none {
    display: none;
  }

  .span-icon {
    position: absolute;
    display: inline !important;
    margin-left: 0.5em;
    margin-top: 0.3em;
  }

  .input-wrap {
    position: relative;
  }

  .input-mural&::placeholder {
    text-align: center;

    background-image: "lupa-busca.jpg";
  }

  .dinamica-nome {
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #201d1d;

    span {
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .dinamica-img {
    max-height: 23px;
    max-width: 29px;
  }

  @media screen and (min-width: 700px) {
    .dinamica-img {
      max-height: 10vh;
      max-width: 16vw;
    }

    .dinamica-nome {
      justify-content: center;
      margin-left: 1em;
    }

    .dinamica-title-desktop {
      font-size: 1.3em;
      line-height: 20px;
      text-align: center;
    }
  }
`;

/**
 * Nova versão do cabeçalho a ser usado em páginas e dinâmicas.
 * Ele faz parte do novo design que está sendo apresentado em novas telas
 * feitas pelo Yan da Weex.
 */
export const CabecalhoPaginasStyle = styled.div`
  .weex-l-cabecalho {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .weex-l-cabecalho-voltarAltura {
    height: 2.5rem;
  }

  .weex-l-cabecalho-btn-voltar {
    text-decoration: none;
  }

  .weex-l-cabecalho-custom-color-icon {
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .weex-l-cabecalho-btn-text {
    margin-left: 0.8rem;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.75rem;
  }

  .weex-l-cabecalho-titulo {
    text-transform: none;
    font-size: 0.9rem;
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .weex-l-cabecalho-quantidadeQuestoes {
    font-weight: 800;
    text-align: right;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-cabecalho {
      /* flex-direction: row; */
      margin-top: 5rem;
      margin-left: 5rem;
      margin-right: 5rem;
    }

    .weex-l-cabecalho--filhos {
      flex: 1;
      text-align: center;
    }

    .weex-l-cabecalho-titulo {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
  /* #endregion DESKTOP */
`;

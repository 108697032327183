import React from "react";
import { ButtonPrimarioStyle } from "../button/style";

function BotaoInteressesEscolha({ funcao, interesse, nome }) {
  const svgClose = () => {
    return (
      <div className="baseButton__iconContainer">
        <svg
          width="8"
          height="8"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1L1 13M1 1L13 13"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };
  return (
    <ButtonPrimarioStyle
      $iconposition={"right"}
      $size={"normal"}
      $hasname={"true"}
      onClick={() => funcao(interesse, "remove")}
    >
      <span className="baseButton__content">
        <span className="baseButton__text">{nome}</span>
        {svgClose()}
      </span>
    </ButtonPrimarioStyle>
  );
}

export default BotaoInteressesEscolha;

import React from "react";
import { ButtonSecundarioStyle } from "../button/style";

function BotaoInteressesEscolher({ funcao, interesse, nome }) {
  return (
    <ButtonSecundarioStyle
      $iconposition={"none"}
      $size={"normal"}
      $hasname={"true"}
      onClick={() => funcao(interesse, "add")}
    >
      <span className="baseButton__content">
        <span className="baseButton__text">{nome}</span>
      </span>
    </ButtonSecundarioStyle>
  );
}

export default BotaoInteressesEscolher;

import styled from "styled-components";

export const InteracoesStyle = styled.div`
  padding: 1rem; // 16px;
  margin-top: 1.75rem; // 28px;
  margin-bottom: 1.5rem; // 24px;
  border-radius: 0.75rem; // 12px;
  background-color: ${(props) => {
    return props.theme.headerBg;
  }};

  .interacoesBar__textContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${(props) => {
      if (!props.$hasvotes) {
        return "0px";
      } else {
        return "1rem"; // 16px
      }
    }};
    margin-bottom: ${(props) => {
      if (!props.$hasvotes) {
        return "0px";
      } else {
        return "1rem"; // 16px
      }
    }};
  }

  .interacoesBar__text {
    font-size: 1rem; // 16px
    font-weight: 400;
    line-height: 1.225rem; // 19.6px;
    text-align: left;
    /* color: #7c7c7c; */
    color: ${(props) => {
      return props.theme.headerText;
    }};
    opacity: 0.9;
  }

  .interacoesBar__text--votes {
    color: ${(props) => {
      return props.theme.headerText;
    }};
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1.379rem; //22.07px;
    letter-spacing: -0.04em;
    text-align: center;
    small {
      font-size: 0.75rem; // 12px;
      font-weight: 900;
      line-height: 0.648rem; // 10.37px;
      letter-spacing: -0.04em;
      text-align: center;
    }
  }

  .interacoesBar__bordaCadeado {
    padding: 0px;
    margin: 0px;
    width: 3.125rem; //50px;
    height: 3.125rem; //50px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    border: ${(props) => {
      return "0.125rem solid " + props.theme.headerSubBgText;
    }};
    background-color: ${(props) => {
      return props.theme.headerSubBg;
    }};
    /* filter: contrast(0.92); */

    color: ${(props) => {
      return props.theme.headerSubBgText;
    }};

    path {
      fill: ${(props) => {
        return props.theme.headerSubBgText || `rgb(239, 231, 102)`;
      }};
    }
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    width: 25%;
    .interacoesBar__text--votes {
      font-size: 1.75rem; // 28px
      line-height: 2.069rem; //33.170px;
      small {
        font-size: 0.875rem; // 14px;
        line-height: 0.972rem; // 15.55px;
      }
    }
  }
  /* #endregion DESKTOP */
`;

import AgendaService from "./AgendaService";
import instance from "./Api";
import UsuarioService from "./UsuarioService";

export default class RankingGameService {
  buscarRanking(atividade, scoreOrder, page, participantName) {
    let usuarioService = new UsuarioService();
    let uuidParticipante = usuarioService?.getCodigoParticipante();
    if (!uuidParticipante) {
      console.error(
        "Codigo do participante não encontrado em RankingGameService",
      );
    }
    let params = new URLSearchParams();
    params.append("scoreOrder", scoreOrder);
    params.append("participant", uuidParticipante);
    params.append("pageSize", 50);
    params.append("participantName", participantName ? participantName : "");
    params.append("page", page);
    return instance.get(
      this.host() + "/api/v1/atividade/" + atividade + "/ranking",
      { params },
    );
  }

  getPontuacaoAtividadeUsuario(atividade, scoreOrder) {
    let usuarioService = new UsuarioService();
    let uuidParticipante = usuarioService?.getCodigoParticipante();
    if (!uuidParticipante) {
      console.error(
        "Codigo do participante não encontrado em RankingGameService",
      );
    }
    let params = new URLSearchParams();
    params.append("scoreOrder", scoreOrder);
    return instance.get(
      this.host() +
        "/api/v1/atividade/" +
        atividade +
        "/participant/" +
        uuidParticipante +
        "/ranking",
      { params },
    );
  }

  getGamesAvailable() {
    const agendaService = new AgendaService();
    let games = [];
    agendaService.agendaCorrente((erro, agenda) => {
      if (agenda) {
        let dias = [];
        dias = [...agenda.schedule];
        dias.forEach((dia) => {
          if (this.IsAvailableDay(dia)) {
            let activities = [];
            activities = [...dia.activities];
            activities = activities.filter(
              (activity) => activity.type === "GAME",
            );
            if (activities.length > 0) {
              games.push({
                day: dia.day,
                games: [...activities],
              });
            }
          }
        });
      }
    });
    const gamesFormat = [];
    games.forEach((g) => {
      g.games.forEach((game) => {
        game.day = g.day;
        gamesFormat.push(game);
      });
    });
    return gamesFormat;
  }

  IsAvailableDay(dia) {
    return Date.parse(dia.day) <= new Date();
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}

import React from "react";
import { WeexInput, Label, Textarea } from "./style";

/**
 * A textarea component for Weex.
 * @function
 * @param {string} name - The name of the input element.
 * @param {string} id - The id of the input element.
 * @param {string} placeholder - The placeholder text for the input element.
 * @param {string} label - The label text for the input element.
 * @param {boolean} [required=true] - Whether the input element is required.
 * @param {boolean} [disabled=false] - Whether the input element is disabled.
 * @param {function} onChange - A function that is called when the input element value changes.
 * @param {string} value - The value of the input element.
 * @param {string} ariaLabel - The aria-label attribute for the input element.
 * @param {string} textSize - The current size of the text in the input element.
 * @param {number} [rows=2] - The number of rows in the textarea element.
 * @param {number} [maxLength=250] - The maximum number of characters allowed in the textarea element.
 * @returns {JSX.Element} A textarea component for Weex.
 */
function WeexTextarea({
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  value,
  ariaLabel,
  textSize,
  rows = 2,
  maxLength = 250,
}) {
  return (
    <WeexInput>
      <Label htmlFor={id} aria-label={ariaLabel}>
        {label}
        <Textarea
          id={id}
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
          value={value}
          rows={rows}
          maxLength={maxLength}
        />
        {maxLength && (
          <div className="weexInput__maxText">
            {textSize}/{maxLength}
          </div>
        )}
      </Label>
    </WeexInput>
  );
}

export default WeexTextarea;

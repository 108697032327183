import React from "react";

const GameSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3389_1400)">
        <path
          d="M4.37161 9.11869H7.8969M6.13425 7.35604V10.8813M12.3035 10H12.3123M14.9475 8.23737H14.9563M14.3482 3.83075H4.97091C4.09864 3.83095 3.25743 4.15453 2.60987 4.73892C1.96232 5.32332 1.55441 6.12703 1.465 6.9947C1.45971 7.04053 1.45619 7.08371 1.45002 7.12866C1.37863 7.72267 0.846313 12.1645 0.846313 13.5253C0.846313 14.2265 1.12487 14.899 1.62071 15.3949C2.11655 15.8907 2.78906 16.1693 3.49028 16.1693C4.37161 16.1693 4.81227 15.7286 5.25293 15.288L6.49912 14.0418C6.8296 13.7112 7.27787 13.5254 7.74531 13.5253H11.5738C12.0412 13.5254 12.4895 13.7112 12.82 14.0418L14.0662 15.288C14.5068 15.7286 14.9475 16.1693 15.8288 16.1693C16.53 16.1693 17.2025 15.8907 17.6984 15.3949C18.1942 14.899 18.4728 14.2265 18.4728 13.5253C18.4728 12.1637 17.9405 7.72267 17.8691 7.12866C17.8629 7.08459 17.8594 7.04053 17.8541 6.99558C17.7649 6.12775 17.3571 5.32382 16.7095 4.73925C16.0619 4.15467 15.2206 3.83098 14.3482 3.83075Z"
          stroke="#201D1D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3389_1400">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GameSVG;

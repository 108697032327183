import styled from "styled-components";

export const StyledTreeDropdow = styled.div`
  width: 100%;
  .tag-list .tag-item:not(:last-child) {
    display: none;
  }

  .material-icons {
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
  }

  /*
    weex-l-dropdown-tree-select-custom is a custom classname to increases
    the specificity of our styles.It can be anything.
    The idea is that it is easy to extend/override builtin
    styles with very little effort.
*/

  .weex-l-dropdown-tree-select-custom .dropdown-trigger {
    height: 3.238rem !important;
  }

  .weex-l-dropdown-tree-select-custom .tag-list {
    width: 100%;
  }
  .weex-l-dropdown-tree-select-custom .tag-item {
    width: 100%;
  }
  .weex-l-dropdown-tree-select-custom .search {
    width: 100%;
  }

  .weex-l-dropdown-tree-select-custom .dropdown-trigger > span&:after {
    font-size: 0.625rem;
    color: #555;
  }

  .weex-l-dropdown-tree-select-custom .toggle {
    font: normal normal normal 1.125rem/1;
    color: #555;
    white-space: pre;
    margin-right: 0.25rem;
  }

  .weex-l-dropdown-tree-select-custom .toggle.collapsed&::after {
    font-style: normal;
    font-family: "Material Icons Outlined";
    cursor: pointer;
    content: "expand_more";
    vertical-align: middle;
  }

  .weex-l-dropdown-tree-select-custom .toggle.expanded&::after {
    font-style: normal;
    font-family: "Material Icons Outlined";
    cursor: pointer;
    content: "expand_less";
    vertical-align: middle;
  }

  /* checkbox styles */
  .weex-l-dropdown-tree-select-custom .checkbox-item {
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: middle;
  }

  .weex-l-dropdown-tree-select-custom .checkbox-item&:before {
    font-style: normal;
    font-size: 1.2rem;
    font-family: "Material Icons Outlined";
    cursor: pointer;
    content: "check_box_outline_blank";
    vertical-align: middle;
  }

  .weex-l-dropdown-tree-select-custom .checkbox-item&:checked&::before {
    font-style: normal;
    font-size: 1.2rem;
    font-family: "Material Icons Outlined";
    cursor: pointer;
    content: "check_box";
    vertical-align: middle;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    overflow: auto;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    max-height: 12.5rem;
    height: 3.4rem;
    width: 90%;

    background-color: #f3f3f3;
    border-radius: 0.75rem;
    border: 0.125rem solid #f3f3f3;
    padding: 0px;
    padding-left: 1rem;
    padding-right: 1rem;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.225rem;
    text-align: left;
    color: #7c7c7c;

    &:disabled {
      background-color: #f8f8f8;
      cursor: not-allowed;
    }

    &:disabled::placeholder {
      color: #efefef;
    }
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top&:after {
    font-family: "Material Icons Outlined";
    cursor: pointer;
    content: "expand_less";
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 0.625rem;
    background: #ffffff;
    margin-right: 0rem;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom&:after {
    font-family: "Material Icons Outlined";
    cursor: pointer;
    content: "expand_more";
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 0.625rem;
    background: #ffffff;
    margin-right: 0rem;
  }

  .search {
    background-color: #f3f3f3;
    border-bottom: none;
    height: 3.2em;
  }

  .tag-item {
    margin: 0rem;
    font-weight: 400;
  }

  .dropdown {
    margin-top: 0.1rem;
    width: 100%;
  }

  .infinite-scroll-component {
    height: auto;
    overflow: auto;
  }

  .node-label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.225rem;
    text-align: left;
    color: #7c7c7c;
  }

  .suspended .node-label {
    text-decoration: line-through;
  }

  .dropdown > .dropdown-content {
    height: 40vh;
    overflow-y: auto;
    font-size: 1.2rem;
    min-width: 100%;
    box-shadow: none;
  }

  .node .fa {
    font-size: 12px;
    margin-left: 4px;
    cursor: pointer;
  }

  .node .fa-ban {
    color: darkorange;
  }

  .weex-l-dropdown-tree-confirmar {
    background-color: #ffffff;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .weex-l-dropdown-tree-confirmar__button {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    width: 50%;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .react-dropdown-tree-select .dropdown .dropdown-trigger {
      width: 96%;
    }
    .dropdown > .dropdown-content {
      height: 33vh;
      width: 34.2%;
      box-shadow: none;
    }

    .weex-l-dropdown-tree-confirmar {
      background-color: #ffffff;
      text-align: center;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }

    .dropdown > .dropdown-content::-webkit-scrollbar {
      width: 8px; /* Largura da barra de rolagem */
    }

    /* Cor de fundo da track (parte não preenchida) */
    .dropdown > .dropdown-content::-webkit-scrollbar-track {
      background-color: #ffffff;
    }

    .dropdown > .dropdown-content::-webkit-scrollbar-thumb {
      background-color: hsl(0, 0%, 86%); /* Cor do thumb (parte preenchida) */
    }

    .dropdown > .dropdown-content::-webkit-scrollbar-thumb&:hover {
      background-color: #ffffff; /* Cor do thumb ao passar o mouse */
    }

    .weex-l-dropdown-tree-node {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .weex-l-dropdown-tree-confirmar__button {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      width: 50%;
    }
    &:focus,
    &:hover,
    &:active {
      border: 0.125rem solid
        ${(props) => {
          if (props.backgroundispresent) {
            return props.theme.fundoTres;
          } else {
            return props.theme.fundoSecundario || `#533888`;
          }
        }};
    }
  }
  /* #endregion DESKTOP */
`;

import React from "react";
import { useStore } from "react-redux";

/**
 * @param {Object} props
 * @param {String} props.url
 * @param {Boolean} props.achieved
 */
function TrofeuDoDia({ url, achieved }) {
  const i18n = useStore().getState().i18n;

  let altImageTrofeuDia = achieved
    ? i18n.message(
        "alt.trofeu.day.able",
        "Imagem do troféu diário da campanha concluído",
      )
    : i18n.message(
        "alt.trofeu.day.disable",
        "Imagem do troféu diário da campanha não concluído",
      );

  if (url === null) {
    return <div></div>;
  }

  return (
    <>
      <div>
        <img
          style={{ maxWidth: "40px", maxHeight: "40px" }}
          src={url}
          alt={altImageTrofeuDia}
        />
      </div>
    </>
  );
}

export default TrofeuDoDia;

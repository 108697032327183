import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Eyes({ changeAvatar }) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.olhos", "Olhos")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.olhos1",
            "Olhos redondos selecionado",
          )}
          type="eyeStyle"
          value="circle"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.olhos1", "Olhos redondos")}
          avatarPart={"/svgs-3.0/avatar-parts/eyes_circle.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.olhos2",
            "Olhos ovais selecionado",
          )}
          type="eyeStyle"
          value="oval"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.olhos2", "Olhos ovais")}
          avatarPart={"/svgs-3.0/avatar-parts/eyes_oval.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.olhos3",
            "Olhos sorrindo selecionado",
          )}
          type="eyeStyle"
          value="smile"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.olhos3", "Olhos sorrindo")}
          avatarPart={"/svgs-3.0/avatar-parts/eyes_smile.svg"}
        />
      </AvatarPartsContainerStyle>
    </>
  );
}

import styled from "styled-components";

export const DetalhesDinamicaPresencialStyle = styled.div`
  .presencial__header {
    display: flex;
    justify-content: space-between;
  }

  .presencial__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .presencial__titulo {
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    line-height: 18px;
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .presencial__paragrafos {
    margin-bottom: 2rem;
    p {
      color: #4a4a4a;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }

  .presencial__informacoes {
    color: #4a4a4a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .icon-center {
    height: 2rem;
    width: 2rem;
  }

  .custom-color-icon {
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .presencial__paragrafos,
    .presencial__informacoes {
      margin-left: 25%;
      margin-right: 25%;
    }
  }
  /* #endregion DESKTOP */
`;

import styled from "styled-components";

export const PontuacaoUsuarioStyle = styled.div`
  border-bottom: 0.063rem solid #efefef;
  display: flex;
  align-items: center;
  gap: 1.5rem; // 24px;

  margin-top: 0.75rem; // 12px;
  margin-right: 0.375rem; //6px;
  padding-bottom: 0.75rem; //12px;

  .participantScore {
    display: flex;
    align-items: center;
    gap: 0.25rem; //4px;
  }

  .participantScore__position {
    font-size: 0.688rem; //11px;
    font-weight: 600;
    line-height: 1.125rem; //18px;
    text-align: left;
    color: #7c7c7c;
  }

  .participantScore__currentUser--container {
    padding: 0.188rem; // 3px;
    border-radius: 0.25rem; //4px;
    background-color: #d2f4e0;
  }

  .participantScore__currentUser {
    font-size: 0.688rem; //11px;
    font-weight: 700;
    line-height: 0.895rem; // 14.32px;
    text-align: center;
    color: #005925;
  }

  .participantScore__score {
    font-size: 1rem; // 16px;
    margin-left: auto;
    color: #005925;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    &:hover {
      border: 0.063rem solid
        ${(props) => {
          return props.theme.fundoSecundario || `#533888`;
        }};
      border-radius: 0.625rem; //10px;
    }
  }
`;

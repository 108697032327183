import styled from "styled-components";
export const MuralStyled = styled.div`
  .escreverPublicacao__header {
    display: flex;
    align-items: center;
  }
  .escreverPublicacao__title {
    width: 100%;
    font-size: 1.6em;
    font-weight: bold;
  }
  .comentarios__title--color {
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }
  .mural__buttonLink {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;

    i {
      margin: 0px;
      padding: 0px;
    }
  }
  .icon-mural-modal {
    margin-right: 0.4em;
    font-weight: 500;

    span {
      i {
        font-size: 1.2rem;
      }
    }
  }
  .has-text-info {
    color: #3e8ed0;
  }
  .has-background-success {
    background-color: #48c78e;
  }
  .has-text-white {
    color: #ffffff;
  }
  .has-text-grey-light {
    color: #b5b5b5;
  }
  .has-text-danger {
    color: #f14668;
  }
  .img-close {
    position: absolute;
    right: 1em;
  }
  .botao-voltar-desktop {
    display: none;
  }
  .botao-publicacao-desktop {
    display: none;
  }
  .botao-publicacao-mobile {
    display: block;
    width: 90%;
    position: fixed;
    /* Ajuste o valor conforme necessário para controlar a
    distância do botão em relação à parte inferior da tela */
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .spinner {
    margin: auto;
  }
  .buttom-page {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    align-items: center;
    margin-bottom: 7rem;
    width: 95%;
  }
  .indicate-pagination {
    display: flex;
    justify-content: center;
  }
  .publicacoes-content {
    margin-bottom: 1rem;
  }

  .buttom-page__buttom {
    width: 35%;
  }
  .buttom-page__buttom > p {
    text-align: center;
  }

  .to-the-top {
    display: none;
  }
  .div-close {
    align-items: center;
    display: flex;
    padding: 2em;
    position: relative;
  }

  .publicacoes-conteudo {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #f8f8f8;
  }
  @media screen and (min-width: 700px) {
    /** @deprecated - será removido */
    .dinamica-content-desktop {
      margin: 1vw 5vw;
    }
    .div-close {
      display: none;
    }
    .full-width-desktop {
      width: 100%;
    }
    .botao-voltar-desktop {
      display: block;
      min-width: 80vw;
    }
    .desktop-content {
      display: flex;
    }
    .desktop-content-left {
      margin-right: 10vw;
      margin-top: 15vh;
      max-width: 25%;
      min-width: 25%;
    }
    .icon-mural {
      font-size: 1.5em;
    }
    .text-mural {
      margin-left: 1em;
      padding-top: 0.3em;
    }
    .h3-mural {
      display: flex;
    }
    .input-wrap {
      margin-top: 5vh;
    }
    .botao-publicacao-desktop {
      margin-top: 5vh;
      display: flex;
      justify-content: center;
    }
    .botao-publicacao-mobile {
      display: none;
    }
    .desktop-content-right {
      overflow: visible;
    }
    .coluna-1-publicacao {
      margin-left: 1em;
      width: 95%;
    }

    .coluna-2-publicacao {
      width: 95%;
    }
    .publicacao-mural-content-page {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
    }
    .publicacoes-content {
      max-height: 61vh;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.fundoSecundario};
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb&:hover {
        background: ${({ theme }) => theme.fundoSecundarioHover || `#533888`};
      }
    }
    .list {
      width: 100%;
    }
    .publicacoes-conteudo {
      display: flex;
      justify-content: flex-start;
      width: 97%;
      background-color: #f8f8f8;
      border-radius: 1.25rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .botao-voltar {
      width: 10%;
    }
    .to-the-top {
      display: flex;
      justify-content: center;
    }
    .buttom-page {
      display: flex;
      justify-content: space-between;
      margin: auto;
      align-items: center;
      margin-top: 2em;
      margin-bottom: 1.5em;
    }
    .indicate-pagination {
      display: flex;
      justify-content: center;
    }
  }
`;

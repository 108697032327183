/* eslint-disable max-len */
import React from "react";
import { useSelector } from "react-redux";
import UsuarioService from "../../services/UsuarioService";
import { PontuacaoUsuarioStyle } from "./PontuacaoUsuarioStyle";

function PontuacaoUsuario({ pontuacaoUsuario, foraDoRanking, isParticipante }) {
  const i18n = useSelector((state) => state.i18n);
  const usuarioService = new UsuarioService();
  const mensagemCompInd = i18n.message(
    "ranking.naoParticipouCompInd",
    "Você não participou de nenhuma atividade ",
  );
  const mensagemCompIndDois = i18n.message(
    "ranking.naoParticipouCompIndDois",
    "Faça atividades para ganhar pontos e melhorar sua posição no ranking",
  );
  const mensagemgameranking = i18n.message(
    "ranking.game.naoParticipou",
    "Você ainda não pontuou no game !",
  );
  const mensagemgamerankingDois = i18n.message(
    "ranking.game.naoParticipouDois",
    "Clique em concluir ao finalizar um game para registrar sua pontuação",
  );

  const isUsuarioCorrente = () => {
    let uuidParticipante = usuarioService?.getCodigoParticipante();
    if (!uuidParticipante) {
      console.error(
        "Codigo do participante não encontrado em PontuacaoUsuario",
      );
    }
    return pontuacaoUsuario?.uuidParticipante === uuidParticipante;
  };

  const participantOutOfRanking = () => {
    if (foraDoRanking) {
      return (
        <>
          <span className="weex__a11y--visuallyHidden">
            {`${i18n.message(
              "out.of.rankings.aria.label.lugar",
              "fora do top 50",
            )}`}
          </span>
          <p aria-hidden="true">
            {i18n.message("rankings.aria.label.lugar", "maior que 50")}
          </p>
        </>
      );
    }
  };

  const formatNumber = (value) => {
    if (!value) return 0;
    return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const userWithScore = () => {
    return (
      <>
        <div>
          {pontuacaoUsuario?.position ? (
            <>
              <span className="weex__a11y--visuallyHidden">
                {`${pontuacaoUsuario?.position}º ${i18n.message(
                  "rankings.aria.label.lugar",
                  "lugar",
                )}`}
              </span>
              <p className="participantScore__position" aria-hidden="true">
                {pontuacaoUsuario?.position + "º"}
              </p>
            </>
          ) : (
            <>{participantOutOfRanking()}</>
          )}
        </div>
        <div>
          <div className="participantScore">
            <span>{pontuacaoUsuario?.name}</span>
            {isUsuarioCorrente() ? (
              <div className="participantScore__currentUser--container">
                <span className="participantScore__currentUser">
                  {i18n.message("ranking.you", "Você")}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <p>{pontuacaoUsuario?.unit}</p>
          </div>
        </div>

        <div className="participantScore__score">
          <span className="weex__a11y--visuallyHidden">
            {`${pontuacaoUsuario?.rating || 0}${i18n.message(
              "rankings.aria.label.pontos",
              "pontos",
            )}`}
          </span>
          <h6 className="participantScore__score" aria-hidden="true">
            {`${formatNumber(pontuacaoUsuario?.rating || 0)} ${i18n.message("geral.pts", "PTS")}`}
          </h6>
        </div>
      </>
    );
  };

  const userWithoutScore = () => {
    return (
      <>
        <div>
          <p>
            {pontuacaoUsuario?.isCompind
              ? mensagemCompInd
              : mensagemgameranking}
          </p>
        </div>
        <div>
          <p>
            {pontuacaoUsuario?.isCompind
              ? mensagemCompIndDois
              : mensagemgamerankingDois}
          </p>
        </div>
      </>
    );
  };

  return (
    <PontuacaoUsuarioStyle>
      {pontuacaoUsuario?.rating > 0 || pontuacaoUsuario.isCompEquip ? (
        <>{userWithScore()}</>
      ) : (
        <>{userWithoutScore()}</>
      )}
    </PontuacaoUsuarioStyle>
  );
}

export default PontuacaoUsuario;

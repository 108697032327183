import React, { useEffect, useRef, useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import Contador from "../../comps/contador/Contador";
import DetalhesDinamicaPresencial from "../../comps/presencial/DetalhesDinamicaPresencial";
import WeexModal from "../../comps/weexModais/WeexModal";
import AgendaService from "../../services/AgendaService";
import AtividadesService from "../../services/AtividadesService";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import { PresencialStyled } from "./style";

export default function Presencial() {
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  let { codigo, codigoAtividade } = useParams();
  const atividadesService = new AtividadesService();
  const agendaService = new AgendaService();
  const [atividade, setAtividade] = useState();
  const campanhaService = new CampanhaService();
  const [erro, setErro] = useState(null);
  const [dinamicaPresencial, setDinamicaPresencial] = useState();
  const [inicioDinamica, setInicioDinamica] = useState(new Date().getTime());
  const effectRan = useRef(false);

  useEffect(() => {
    inicializar();
    let service = new TemaService(campanhaService.campanhaCorrente());
    service.aplicarTema("HOME");
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callbackModal = () => {
    setErro(null);
    navigate("/atividades");
  };

  const inicializar = () => {
    //TODO - No modo de desenvolvimento do React 18, os componentes podem ser renderizados duas vezes para detectar efeitos colaterais inseguros.
    if (effectRan.current === false) {
      effectRan.current = true;
      dispatch({ type: "mainBackgroundColor", payload: "rgb(243, 243, 248)" });
      atividadesService.iniciarAtividade(
        codigo,
        codigoAtividade,
        (erro, atividade) => {
          if (atividade) {
            if (atividade.dateFinished !== null) {
              agendaService.atualizarAgenda(
                codigo,
                codigoAtividade,
                "PRESENCIAL",
                null,
                null,
                (erro, sucesso) => {
                  if (sucesso) {
                  } else if (erro) {
                    setErro({
                      titulo: i18n.message(
                        "dinamica.erro.jaexecutada.titulo",
                        "Erro ao exibir atividade já executada",
                      ),
                      mensagem: i18n.message(
                        "dinamica.erro.jaexecutada.mensagem",
                        "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
                      ),
                      voltarAgenda: true,
                    });
                  }
                },
              );
            }
            setAtividade(atividade);
            setDinamicaPresencial(atividade.dynamicPresencial);

            const data = atividade.dynamicPresencial.day.split("/");
            const horas = atividade.dynamicPresencial.startTime.split(":");

            const inicioDinamicaContador = new Date(
              parseInt(data[2]),
              parseInt(data[1]) - 1,
              parseInt(data[0]),
              parseInt(horas[0]),
              parseInt(horas[1]),
              parseInt(horas[2]),
            ).getTime();
            setInicioDinamica(inicioDinamicaContador);
          }
          if (erro) {
            if (
              erro.response &&
              erro.response.data &&
              erro.response.data.message
            ) {
              setErro({
                titulo: i18n.message(
                  "dinamica.erro.iniciar.titulo",
                  "Erro ao iniciar atividade",
                ),
                mensagem: erro.response.data.message,
                voltarAgenda: true,
              });
            } else {
              setErro({
                titulo: i18n.message(
                  "dinamica.erro.iniciar.titulo",
                  "Erro ao iniciar atividade",
                ),
                mensagem: i18n.message(
                  "dinamica.erro.iniciar.mensagem",
                  "Entre em contato com o suporte ou tente novamente mais tarde.",
                ),
                voltarAgenda: true,
              });
            }
          }
        },
      );
    }
  };

  const voltar = () => {
    navigate("/home");
  };

  const loading = () => {
    return (
      <WeexSpinner
        aguarde={i18n.message("geral.carregando", "Carregando ...")}
      />
    );
  };

  return (
    <PresencialStyled>
      {erro != null && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      {atividade == null ? (
        loading()
      ) : (
        <>
          <DetalhesDinamicaPresencial
            voltar={voltar}
            page="presencial"
            titulo={dinamicaPresencial?.title}
            dinamicaPresencial={dinamicaPresencial}
          ></DetalhesDinamicaPresencial>

          {inicioDinamica > new Date().getTime() ? (
            <div className="container-contador">
              <Contador inicioCampanha={inicioDinamica}></Contador>
            </div>
          ) : (
            ""
          )}

          <div className="container-footer">
            <p className="presencial__aviso">
              <b>
                {i18n.message(
                  "dinamica.presencial.checkin.mensagemApoio",
                  "Realize o check-in pelo QR CODE na ação.",
                )}
              </b>
            </p>
          </div>
        </>
      )}
    </PresencialStyled>
  );
}

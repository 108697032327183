import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { WeexInput, Label, RankingInputSearchStyle } from "./style";

/**
 * @function RankingInputSearch
 * @param {function} onChange - function that is called when the input value changes
 * @param {string} placeholder - placeholder text for the input field
 * @param {string} ariaLabel - accessibility label for the input field
 * @returns {JSX.Element} - a Weex component that represents an input field with a search icon and a clear icon
 */
function RankingInputSearch({ onChange, placeholder, ariaLabel }) {
  const [searchTerm, setSearchTerm] = useState("");
  const i18n = useStore().getState().i18n;
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // função setSearch que será executada após um certo tempo
      if (searchTerm.length > 2 || searchTerm.length === 0) {
        onChange(searchTerm);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <WeexInput>
      <Label htmlFor="ranking-search">
        <RankingInputSearchStyle
          id="ranking-search"
          onChange={handleSearch}
          value={searchTerm}
          placeholder={placeholder}
          aria-label={ariaLabel}
          type="text"
          autoComplete="off"
        />
        <div className="weexInput__icon">
          <i className="fas fa-search"></i>
        </div>
        <button
          type="reset"
          aria-label={i18n.message(
            "aria.search.reset",
            "botão para limpar o campo de busca",
          )}
          className="weex__buttonLink weexInput__icon weexInput__icon--right"
          onClick={handleClear}
        >
          <i className="fas fa-times"></i>
        </button>
      </Label>
    </WeexInput>
  );
}

export default RankingInputSearch;

import instance from "./Api";
import CampanhaService from "./CampanhaService";

export default class MuralService {
  curtirOuDescurtirPublicacao(code, request, callback) {
    instance
      .post(this.host() + "/api/v1/campaign/mural/" + code + "/like", request)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  criarApenasPublicacao(codeAgenda, codeAtividade, request, callback) {
    instance
      .post(
        this.host() +
          "/api/v1/campaign/schedule/" +
          codeAgenda +
          "/activity/" +
          codeAtividade +
          "/mural",
        request,
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  criarPublicacao(codeAgenda, codeAtividade, request, arquivoVideo, callback) {
    if (arquivoVideo) {
      const campanhaService = new CampanhaService();
      const campanhaCorrente = campanhaService.campanhaCorrente();
      const videoPayload = {
        mural: codeAtividade,
        campaign: campanhaCorrente.code,
      };
      const form_data = new FormData();
      form_data.append("payload", JSON.stringify(videoPayload));
      form_data.append("video", arquivoVideo);

      const maxRetries = 3;
      let retryCount = 0;
      const uploadVideo = () => {
        instance
          .post(this.videoHost() + "/upload-mural", form_data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 30000, // 30 seconds timeout
          })
          .then((response) => {
            const video =
              Array.isArray(response.data.videos) &&
              response.data.videos.length > 0
                ? response.data.videos[0]
                : null;
            request.videosMuralDTO = [
              {
                uuid: response.data.uuid,
                bucket: response.data.bucket,
                cdn: response.data.cdn,
                video: video,
                thumbnail: response.data.thumbnail,
                originalName: response.data.arquivo_original,
              },
            ];
            this.criarApenasPublicacao(
              codeAgenda,
              codeAtividade,
              request,
              callback,
            );
          })
          .catch((erro) => {
            if (erro.code === "ECONNABORTED" && retryCount < maxRetries) {
              retryCount++;
              uploadVideo();
            } else {
              console.error("Upload failed:", erro);
              // Handle other errors or max retries reached
              if (callback && typeof callback === "function") {
                callback(erro);
              }
            }
          });
      };
      uploadVideo(); // Start the upload process
      return;
    }
    this.criarApenasPublicacao(codeAgenda, codeAtividade, request, callback);
  }

  fixarPublicacao(codePublicacao, callback) {
    instance
      .put(`${this.host()}/api/v1/campaign/mural/${codePublicacao}/fix`)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  buscarPublicacoes(codeAgenda, codeAtividade, request, callback) {
    let params = "";

    if (request.pageSize) {
      params = params + `pageSize=${request.pageSize}&`;
    }
    if (request.page || typeof request.page === "number") {
      params = params + `page=${request.page}&`;
    }
    if (request.name) {
      params = params + `name=${request.name}&`;
    }
    instance
      .get(
        this.host() +
          "/api/v1/campaign/schedule/" +
          codeAgenda +
          "/activity/" +
          codeAtividade +
          "/mural?" +
          params,
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  criarComentario(codePublicacao, request, callback) {
    instance
      .post(
        this.host() + "/api/v1/campaign/mural/" + codePublicacao + "/comment",
        request,
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  buscarComentarios(codePublicacao, request, callback) {
    let params = "";
    for (const key in request) {
      const value = request[key];
      if (!value) {
        continue;
      }
      params = params + `${key}=${value}&`;
    }
    instance
      .get(
        this.host() +
          "/api/v1/campaign/mural/" +
          codePublicacao +
          "/comment?" +
          params,
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  deleteComentario(codePublicacao, codeComentario, callback) {
    instance
      .delete(
        this.host() +
          "/api/v1/campaign/mural/" +
          codePublicacao +
          "/comment/" +
          codeComentario,
      )
      .then((response) => {
        callback(null, response);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  deletePublicacao(codePublicacao, callback) {
    instance
      .delete(this.host() + "/api/v1/campaign/mural/" + codePublicacao)
      .then((response) => {
        callback(null, response);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  enviarImagem(file, callback) {
    const arquivo = new FormData();
    arquivo.append("file", file);
    instance
      .post(this.host() + "/api/v1/campaign/mural/image", arquivo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
  videoHost() {
    return process.env.REACT_APP_HOST_VIDEO;
  }
}

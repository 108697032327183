import styled from "styled-components";

export const MidiaStyle = styled.div`
  .midia__alternatives {
    width: 6.25rem; //100px;
    height: 4.688rem; // 75px;
    object-fit: cover;
    border-radius: 0.25rem; // 4px;
    margin-right: 0.313rem; //5px;
  }

  .midia__maxWidth {
    max-width: 100%;
    border-radius: 0.25rem; // 4px;
  }

  .midia__thumbnail--container {
    position: relative;
  }

  .midia__thumbnail {
    cursor: pointer;
    position: relative;
    z-index: 2;
  }

  .midia__thumbnail--video {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .midia__thumbnail--btn {
    position: relative;
    padding: 0px;
    margin: 0px;
    border: none;
    background: transparent;
  }

  .midia__thumbnail--icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    i {
      font-size: 2rem;
      color: ${(props) => {
        return props.theme.fundoSecundario || `#533888`;
      }};
    }
  }

  .midia__thumbnail--icon:hover {
    filter: opacity(0.6);
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    display: flex;
    align-items: center;
    justify-content: center;

    .midia__maxWidth {
      max-width: 60%;
      border-radius: 0.5rem; //8px;
    }

    .midia__alternatives {
      border-radius: 0.375rem; //6px;
    }
  }
  /* #endregion DESKTOP */
`;

import styled from "styled-components";

export const GameStyled = styled.div`
  .card,
  .card-content {
    background: transparent;
    box-shadow: none;
  }
  .info {
    width: 100%;
    text-align: center;
  }
  .info-game {
    text-align: center;
    margin-bottom: 2em;
  }

  div,
  p,
  button {
    text-align: center;
  }
`;

/* eslint-disable max-len */
import styled from "styled-components";

export const Weex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${(props) => {
    if (
      (props.$usuariologado !== null && props.$usuario === "true") ||
      (props.$usuariologado !== undefined && props.$usuario === "true")
    ) {
      return props.theme.mainBackgroundColor;
    } else {
      return props.theme.fundoPrimario || `#F3F5F9`;
    }
  }};

  h1 {
    font-size: 1.625rem; //26px default. Mudar através de classes.
    font-weight: 700; // default. Mudar através de classes.
    line-height: 1.625rem; //26px default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
    color: #201d1d; // default. Mudar através de classes.
  }

  h2 {
    font-size: 1.5rem; // 24px; default. Mudar através de classes.
    font-weight: 600; // default. Mudar através de classes.
    line-height: 1.953rem; //31.25px; default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
    color: #201d1d; // default. Mudar através de classes.
  }

  h3 {
    font-size: 1.375rem; //22px; default. Mudar através de classes.
    font-weight: 700; // default. Mudar através de classes.
    line-height: 1.375rem; //22px; default. Mudar através de classes.
    text-align: center; // default. Mudar através de classes.
    color: #201d1d; // default. Mudar através de classes.
  }

  h4 {
    font-size: 1.125rem; // 18px; default. Mudar através de classes.
    font-weight: 700; // default. Mudar através de classes.
    line-height: 1.125rem; //18px; default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
    color: #201d1d; // default. Mudar através de classes.
  }

  h5 {
    font-size: 0.938rem; // 15px; default. Mudar através de classes.
    font-weight: 600; // default. Mudar através de classes.
    line-height: 1.221rem; //19.53px; default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
    color: #201d1d; // default. Mudar através de classes.
  }

  h6 {
    font-size: 0.813rem; //13px; default. Mudar através de classes.
    font-weight: 600; // default. Mudar através de classes.
    line-height: 1.125rem; //18px; default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
    color: #201d1d; // default. Mudar através de classes.
  }

  p {
    font-size: 0.938rem; // 15px; default. Mudar através de classes.
    font-weight: 400; // default. Mudar através de classes.
    line-height: 1.221rem; //19.53px; default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
    color: #7c7c7c; // default. Mudar através de classes.
  }

  span {
    font-size: 0.938rem; // 15px; default. Mudar através de classes.
    font-weight: 400; // default. Mudar através de classes.
    line-height: 1.221rem; //19.53px; default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
    color: #201d1d; // default. Mudar através de classes.
  }

  button {
    font-size: 0.938rem; // 15px; default. Mudar através de classes.
    font-weight: 600; // default. Mudar através de classes.
    line-height: 1.221rem; //19.53px; default. Mudar através de classes.
    text-align: left; // default. Mudar através de classes.
  }

  body {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  /** Provisório -- retrocompatibilidade com o weex 2.0.
  login/register. Ainda não tem mockup para o 3.0 */
  a,
  label,
  h1,
  h2,
  span,
  path {
    color: ${(props) => {
      if (
        (props.$usuariologado !== null && props.$usuario === "true") ||
        (props.$usuariologado !== undefined && props.$usuario === "true")
      ) {
        return "#201d1d";
      } else {
        return props.theme.textosCorFundoPrimario || `#201d1d`;
      }
    }};
  }

  .weex__container {
    padding: 1.5rem;
  }

  .flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .align-content-center {
    align-content: center;
  }

  .gap-1 {
    gap: 1rem;
  }

  /**
    Acessibilidade: esconde visualmente,
    mas ainda mantém disponível para leitores de tela
   */
  .weex__a11y--visuallyHidden {
    clip: rect(0.063rem 0.063rem 0.063rem 0.063rem);
    padding: 0 !important;
    border: 0 !important;
    height: 0.063rem !important;
    width: 0.063rem !important;
    overflow: hidden;
    display: block;
  }

  .weex__scrollbar {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; /* Otimiza a rolagem no iOS */

    /* Customização da barra de rolagem para navegadores baseados em WebKit (não visível no iOS) */
    &::-webkit-scrollbar {
      width: 0.313rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.313rem transparent;
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.fundoSecundario};
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.fundoSecundario || `#533888`};
    }
  }

  /**@deprecated - será removido */
  .close-btn {
    border: none;
    background: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;

    :active {
      filter: brightness(0.5);
    }
  }

  /** Botões que sejam apenas textos */
  .weex__buttonLink {
    margin-top: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  .weex__buttonLink--color {
    color: ${(props) => {
      if (
        (props.$usuariologado !== null && props.$usuario === true) ||
        (props.$usuariologado !== undefined && props.$usuario === true)
      ) {
        return "#201d1d";
      } else {
        return props.theme.textosCorFundoPrimario || `#201d1d`;
      }
    }};
  }

  .weex__footer {
    margin: 0 1rem;
  }

  .animations-popIn {
    -webkit-animation: popIn 0.3s ease forwards;
    animation: popIn 0.3s ease forwards;
    will-change: transform, opacity;
  }

  .animations-HideUntilLoaded {
    -webkit-animation: HideUntilLoaded 1s ease forwards;
    animation: HideUntilLoaded 1s ease forwards;
    will-change: opacity;
  }

  .ml-3 {
    margin-left: 0.75rem;
  }
  .mr-3 {
    margin-right: 0.75rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }

  .weex__image {
    height: auto;
    max-width: 100%;
  }

  .fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex__container {
      padding: 1.5rem 2rem 1.5rem 2rem;
    }

    .weex__desktopMargins {
      margin: 1vw 5vw;
    }

    .weex__buttonLink {
      font-size: 1em;
    }

    .weex__footer {
      margin: 0 35vw;
    }

    .weex__footer img {
      max-width: 100%;
    }
  }
  /* #endregion DESKTOP */
`;

import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Ears({ changeAvatar }) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.orelhas", "Orelhas")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.orelhas1",
            "Orelhas pequenas selecionadas",
          )}
          type="earSize"
          value="small"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.orelhas1", "Orelhas pequenas")}
          avatarPart={"/svgs-3.0/avatar-parts/ear_small.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.orelhas2",
            "Orelhas grandes selecionadas",
          )}
          type="earSize"
          value="big"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.orelhas2", "Orelhas grandes")}
          avatarPart={"/svgs-3.0/avatar-parts/ear_big.svg"}
        />
      </AvatarPartsContainerStyle>
    </>
  );
}

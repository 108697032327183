import React from "react";
import { AvisoStyle } from "./style";
import { useStore } from "react-redux";
import Lock from "../../svg/Lock";

export default function AvisoEnquete() {
  const i18n = useStore().getState().i18n;

  return (
    <AvisoStyle>
      <div className="avisoEnquete__cadeado">
        <Lock />
      </div>
      <h1 className="avisoEnquete__title">
        {i18n.message(
          "enqueteCampanha.naoComecou.aviso.title",
          "A enquete ainda não começou",
        )}
      </h1>
      <h3 className="avisoEnquete__title">
        {i18n.message("enqueteCampanha.naoComecou.aviso.subtitle", "Aguarde")}
      </h3>
    </AvisoStyle>
  );
}

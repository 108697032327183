import React from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Style } from "../style";
import PagesHeader from "../../pagesHeader/PagesHeader";
import BotaoPrimario from "../../button/BotaoPrimario";
import Divider from "../divider/Divider";
import AgendaGlobalService from "../../../services/AgendaGlobalService";
import ArrowRight from "../../svg/botoes/ArrowRight";

const agendaGlobalService = new AgendaGlobalService();

function isEmpty(globalSchedule) {
  let empty = true;
  for (let item of globalSchedule) {
    if (item.activities) {
      for (let atv of item.activities) {
        if (atv.type === "POLL_CAMPAIGN") {
          empty = false;
          break;
        }
      }
    }
  }
  return empty;
}

export default function PollCampaign() {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const globalSchedule =
    agendaGlobalService?.filterGlobalScheduleBy("POLL_CAMPAIGN");
  if (globalSchedule && globalSchedule[0] && globalSchedule[0].activities) {
    const executadas = globalSchedule[0]?.activities.filter((atv) => {
      return atv.executed;
    });
    const nao_executadas = globalSchedule[0]?.activities.filter((atv) => {
      return !atv.executed;
    });
    const todas = nao_executadas.concat(executadas);
    globalSchedule[0].activities = todas;
  }
  const irParaEnquete = (scheduleCode, atividade) => {
    let uri = `/poll/${scheduleCode}/${atividade?.uuid}`;
    navigate(uri, {
      state: {
        enqueteCampanha: true,
        codigoDinamica: atividade?.dynamicUuid,
      },
    });
  };

  const enquetes = globalSchedule?.map((item) => {
    return (
      <Style key={item?.scheduleCode}>
        {item.activities?.map((atividade) => {
          return (
            <>
              <BotaoPrimario
                disabledOnClick={false}
                funcao={() => {
                  irParaEnquete(item.scheduleCode, atividade);
                }}
                nome={atividade?.title || atividade?.name}
                size="small"
                iconRight={
                  <>
                    {atividade?.executed ? (
                      <i class="fa fa-check button_icon"></i>
                    ) : (
                      <ArrowRight />
                    )}
                  </>
                }
              />
            </>
          );
        })}
      </Style>
    );
  });
  isEmpty(globalSchedule);
  if (!isEmpty(globalSchedule)) {
    return (
      <>
        <PagesHeader
          title={i18n.message("enqueteCampanha.titulo", "Enquete")}
        />
        {enquetes}
        <Divider />
      </>
    );
  } else {
    return <></>;
  }
}

import styled from "styled-components";

export const AtividadesDoDiaStyle = styled.div`
  .atividadesDia__container {
    padding: 1.25rem;
    border-radius: 1.25rem;
    background-color: rgba(144, 144, 144, 0.1);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .atividadesDia__container:last-child {
    margin-bottom: 0px;
  }

  .atividadesDia__subheader {
    display: flex;
    align-items: center;
  }

  .atividadesDia__linhaContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.25rem; //4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .atividadesDia__linhaContainer {
      gap: 0.5rem; //8px;
    }
  }
  /* #endregion DESKTOP */
`;

export const MoreLessBtnStyle = styled.button`
  cursor: pointer;
  padding: 0.5rem; // 8px;
  margin-top: 1rem; //16px;
  background-color: transparent;
  border: none;
  color: #201d1d;

  display: flex;
  align-items: center;

  .moreLessBtn--iconContainer {
    margin-left: 0.25rem; // 4px;
    width: 0.875rem; // 14px;
    height: 0.875rem; // 14px;
    border-radius: 0.75rem; // 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    path {
      stroke: ${(props) => {
        return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
      }};
    }
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

import React from "react";
import { useStore } from "react-redux";
import WeexTextarea from "../WeexInputs/WeexTextarea";

function FeedbackVideo(props) {
  const i18n = useStore().getState().i18n;
  return (
    <div
      className="video-feedback-desktop"
      role="form"
      aria-label={i18n.message(
        "arial.label.video.comentario",
        "Formulário de feedback sobre o vídeo (aria-label)",
      )}
      style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px" }}
    >
      <div className="video-quiz-feedback-markup">
        <p className="video-pergunta" style={{ fontSize: "1.1rem" }}>
          {props.pergunta}
        </p>
        <div
          role="radiogroup"
          aria-label={i18n.message(
            "aria.label.opcoes.feedback",
            "Opções de feedback sobre o vídeo",
          )}
        ></div>
      </div>
      <div className="video-text-area-desktop">
        <WeexTextarea
          id="comentario-video"
          value={props.comentarioVideo}
          onChange={props.onChangeComentarioVideo}
          aria-label={i18n.message(
            "arial.label.video.comentario",
            "Digite aqui seu comentário sobre o vídeo: ",
          )}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}

export default FeedbackVideo;

import React from "react";
import styled from "styled-components";

export const SVG = styled.svg`
  path {
    fill: ${(props) => {
      return props.theme.fundoSecundario || `rgb(239, 231, 102)`;
    }};
  }
`;

const Plus = () => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      height="48px"
      viewBox="0 -960 960 960"
      width="48px"
      fill="#e8eaed"
    >
      <path d="M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z" />
    </SVG>
  );
};

export default Plus;

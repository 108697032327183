import React from "react";
import styled from "styled-components";
const Star = ({ filled }) => (
  <StarStyled $filled={filled}>
    <svg
      className="star-rating"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="star"
        d="M25.8239 10.9863C26.3309 10.4846 26.5099 9.74832 26.2911 9.06478C26.0723 8.38124 25.5012 7.89244 24.8005 7.78913L18.6739 6.88542C18.5536 6.86771 18.4497 6.79105 18.3958 6.68035L15.6559 1.04488C15.3426 0.400365 14.7082 0 14.0001 0C13.2921 0 12.6576 0.400365 12.3443 1.04488L9.60438 6.68041C9.55051 6.79105 9.44661 6.86771 9.32629 6.88548L3.19976 7.78913C2.4991 7.89244 1.928 8.38124 1.70914 9.06478C1.49028 9.74832 1.66933 10.4846 2.17639 10.9863L6.60964 15.3729C6.69664 15.4591 6.7364 15.5831 6.71584 15.7048L5.66928 21.8988C5.54963 22.6072 5.83105 23.3096 6.40385 23.732C6.97664 24.1546 7.72193 24.2092 8.34854 23.8748L13.8283 20.9504C13.9359 20.893 14.0644 20.893 14.1721 20.9504L19.6519 23.8748C19.9244 24.0203 20.2193 24.0922 20.5129 24.0921C20.894 24.0921 21.2728 23.9709 21.5964 23.732C22.1692 23.3095 22.4507 22.607 22.3309 21.8987L21.2844 15.7047C21.2639 15.5831 21.3036 15.459 21.3906 15.3729L25.8239 10.9863ZM19.8285 15.9583L20.8751 22.1524C20.9101 22.3597 20.784 22.4779 20.7282 22.519C20.6723 22.5601 20.5226 22.6454 20.3393 22.5475L14.8595 19.6231C14.5905 19.4796 14.2954 19.4078 14.0002 19.4078C13.7051 19.4078 13.4099 19.4796 13.141 19.623L7.66122 22.5474C7.4778 22.6453 7.32812 22.56 7.27229 22.5189C7.21645 22.4778 7.09034 22.3596 7.12539 22.1523L8.17195 15.9583C8.27471 15.3501 8.07625 14.7299 7.64088 14.2992L3.20763 9.91258C3.05927 9.76569 3.09284 9.59488 3.11423 9.52826C3.1355 9.46159 3.2073 9.30332 3.41233 9.27312L9.53881 8.36941C10.1403 8.2807 10.6601 7.89743 10.9292 7.34402L13.6691 1.70849C13.7608 1.51991 13.9312 1.49954 14.0003 1.49954C14.0692 1.49954 14.2396 1.51985 14.3314 1.70849V1.70855L17.0713 7.34407C17.3403 7.89749 17.8601 8.28076 18.4616 8.36947L24.5882 9.27317C24.7932 9.30337 24.8649 9.46164 24.8863 9.52832C24.9076 9.59499 24.9412 9.76586 24.7928 9.91264L20.3596 14.2993C19.9243 14.7299 19.7258 15.3501 19.8285 15.9583Z"
        fill={filled ? "#00E291" : "#8B919A"}
      />
      <path
        d="M22.6288 1.04449C22.2987 0.801118 21.8368 0.875396 21.5971 1.21037L20.813 2.30576C20.5733 2.64073 20.6464 3.10966 20.9764 3.35303C21.1076 3.44968 21.2595 3.49625 21.41 3.49625C21.6384 3.49625 21.8636 3.38906 22.0081 3.18721L22.7922 2.09181C23.0319 1.75679 22.9588 1.28786 22.6288 1.04449Z"
        fill={filled ? "#00E291" : "#8B919A"}
      />
      <path
        d="M7.18375 2.30132L6.39969 1.20592C6.16 0.871065 5.6981 0.796788 5.36801 1.04005C5.03802 1.28342 4.96485 1.75235 5.2046 2.08732L5.98866 3.18271C6.1332 3.38473 6.3584 3.49187 6.58689 3.49187C6.73744 3.49187 6.88936 3.44529 7.02039 3.34859C7.35038 3.10527 7.42355 2.63635 7.18375 2.30132Z"
        fill={filled ? "#00E291" : "#8B919A"}
      />
      <path
        d="M2.70987 16.8217C2.58381 16.4279 2.16715 16.2124 1.77914 16.3403L0.510552 16.7588C0.122544 16.8868 -0.0896986 17.3098 0.0363562 17.7036C0.137747 18.0205 0.427317 18.2219 0.738654 18.2219C0.814287 18.2219 0.891232 18.21 0.967084 18.185L2.23567 17.7665C2.62362 17.6385 2.83592 17.2156 2.70987 16.8217Z"
        fill={filled ? "#00E291" : "#8B919A"}
      />
      <path
        d="M14.0003 25.1464C13.5924 25.1464 13.2617 25.4821 13.2617 25.8961V27.2502C13.2617 27.6643 13.5924 28 14.0003 28C14.4082 28 14.7389 27.6643 14.7389 27.2502V25.8961C14.7389 25.482 14.4082 25.1464 14.0003 25.1464Z"
        fill={filled ? "#00E291" : "#8B919A"}
      />
      <path
        d="M27.4895 16.7599L26.2209 16.3414C25.833 16.2136 25.4162 16.4289 25.2901 16.8228C25.1641 17.2167 25.3763 17.6396 25.7643 17.7676L27.0329 18.1861C27.1088 18.211 27.1857 18.2229 27.2613 18.2229C27.5727 18.2229 27.8623 18.0215 27.9636 17.7046C28.0897 17.3108 27.8774 16.8878 27.4895 16.7599Z"
        fill={filled ? "#00E291" : "#8B919A"}
      />
    </svg>
  </StarStyled>
);

const StarStyled = styled.div`
  path {
    fill: ${(props) => {
      return props.$filled
        ? props.theme.fundoSecundario || `#533888`
        : "#8B919A";
    }};
  }
`;
export default Star;

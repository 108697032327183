import styled from "styled-components";

export const AtividadesStyle = styled.main`
  .atividades__container {
    display: flex;
    flex-direction: column;

    section {
      margin-bottom: 1.5rem; // 24px;
    }
  }

  .atividades__feedbackBtnContainer {
    text-align: center;
    margin-top: 1.5rem;
  }

  .atividades__geralInfos {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 1rem; // 16px;
    margin-bottom: 1rem; //16px
  }

  .atividades__geralInfos--filters {
    margin-top: 0.75rem; // 12px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .atividades__containerRanking {
      width: 40%;
    }

    .atividades__geralInfos {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .atividades__geralInfos--filters {
      margin-top: 0px;
    }

    .atividades__container {
      flex-direction: row;
      gap: 35px;

      section {
        min-width: 60%;
        margin-bottom: 0px;

        margin-left: ${(props) => {
          if (
            props?.$campaignType === "INDIVIDUAL" ||
            props?.$campaignType === "EQUIPE"
          ) {
            return "0px";
          } else {
            return "20rem";
          }
        }};
        margin-right: ${(props) => {
          if (
            props?.$campaignType === "INDIVIDUAL" ||
            props?.$campaignType === "EQUIPE"
          ) {
            return "0px";
          } else {
            return "20rem";
          }
        }};
      }
    }

    .atividades__feedbackBtnContainer {
      padding: 2em 0px;
      width: 28%;
      margin: auto;
    }
  }
  /* #endregion DESKTOP */
`;

import styled from "styled-components";

export const BotaoAtividadeStyle = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; //8px;
  cursor: pointer;
  border: none;
  padding: 0.375rem; // 6px;
  border-radius: 0.75rem; //12px;
  height: 5rem;
  background-color: ${(props) => {
    return props.$executado ? "#d2f4e0" : "rgba(255, 255, 255, 0.4)";
  }};
  border: 1px solid
    ${(props) => {
      return props.$executado ? "#91F7BA" : "#d6d6d6";
    }};

  min-width: 4.391rem; // 70.25px;
  min-height: 4.75rem; // 76px;

  &:disabled {
    cursor: not-allowed;
    filter: none;
  }

  .botaoAtividade__textColor {
    font-size: 11.4px;
    line-height: 0.875rem;
    text-align: center;
    color: ${(props) => {
      return props.$executado ? "#005925" : "#201d1d";
    }};
  }

  .botaoAtividade__icone--container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem; //6px;
    background-color: ${(props) => {
      return props.$executado ? "#91F7BA" : "rgba(255, 255, 255, 0.4)";
    }};

    width: 3.641rem; //58.25px;
    height: 2.188rem; //35px;
  }

  .botaoAtividade__icone--feito {
    width: 1.563rem; //25px;
    height: 1.563rem; //25px;
  }

  .botaoAtividade__icone {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .botaoAtividade__icone--habilitado {
    background-image: url(${({ $imagemHabilitado }) => $imagemHabilitado});
  }

  .botaoAtividade__icone--desabilitado {
    background-image: url(${({ $imagemDesabilitado }) => $imagemDesabilitado});
  }

  .botaoAtividade__icone--tamanho {
    width: 2.125rem; //34px;
    height: 2.125rem; //34px;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    height: auto;
    min-height: 2.5rem; // 40px;
    flex-direction: row;
    justify-content: start;

    .botaoAtividade__textColor {
      font-size: 0.813rem; //13px;
      line-height: 1.188rem; //19px;
      text-align: left;
    }

    .botaoAtividade__icone--container {
      padding: 0.25rem; //24px;
      width: 1.5rem; //24px;
      height: 1.75rem; //28px;
    }

    .botaoAtividade__icone--tamanho {
      width: 1.75rem; //28px;
      height: 1.75rem; //28px;
    }
    &:hover,
    &:active {
      filter: contrast(0.94);
    }
  }
  /* #endregion DESKTOP */
`;

import styled from "styled-components";

export const LeadershipStyle = styled.div`
  margin-top: 1.5rem; //24px;
  margin-bottom: 0.75rem; //12px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem; //12px;

  .podium {
    flex: 1;
    /* min-width: 15.625rem; // 250px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem; //8px;
    padding: 0.75rem; // 12px;
    border-radius: 1.25rem; // 20px;
  }

  .podium__place--0 {
    background-color: rgba(243, 207, 23, 0.1);
  }
  .podium__place--1 {
    background-color: rgba(243, 243, 243, 1);
  }
  .podium__place--2 {
    background-color: rgba(199, 109, 43, 0.1);
  }

  .podium__position {
    text-align: left;
  }

  .podium__score {
    margin-left: auto;
    text-align: center;
    min-width: 4.25rem; // 68px;
    padding: 0.75rem; //12px;
    border-radius: 0.75rem; //12px;
  }

  .podium__score--0 {
    background-color: #f3cf17;
  }

  .podium__score--1 {
    background-color: #7c7c7c;
  }

  .podium__score--2 {
    background-color: #c76d2b;
  }

  .podium__score--text {
    font-size: 1rem; // 16px;
    font-weight: 700;
    small {
      font-weight: 400;
    }
  }

  .podium__score--notFirstPlace {
    color: #ffffff;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    flex-direction: row;
    align-items: center;

    .podium {
      flex-direction: column;
      justify-content: center;
    }

    .podium__position {
      text-align: center;
    }

    .podium__score {
      margin-left: 0px;
    }
  }
  /* #endregion DESKTOP */
`;

import React from "react";
import { useStore, useSelector } from "react-redux";
import packageInfo from "../../../package.json";
import CampanhaService from "../../services/CampanhaService";
import Image from "../campanha/Image";
import styled from "styled-components";

function Footer() {
  const i18n = useStore().getState().i18n;
  const usuario = useSelector((state) => state.usuario);
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  let logoCentroFooter = null;
  let logoCentroFooterDesktop = null;
  if (campanha) {
    if (!campanha?.logoRight) {
      logoCentroFooter = "";
    } else {
      logoCentroFooter = campanha.logoRight;
    }
    // #region DESKTOP
    if (!campanha?.topRightDesktop) {
      logoCentroFooterDesktop = "";
    } else {
      logoCentroFooterDesktop = campanha.topRightDesktop;
    }
    // #endregion DESKTOP
  }
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  return (
    <>
      <Rodape className="weex__container">
        {logoCentroFooterDesktop && isDesktopMode && usuario === false ? (
          <Image
            className="campanha_logosTopo__centro__footer"
            src={logoCentroFooter}
            alt={i18n.message(
              "alt.logo.secundario.footer.mobile",
              "Logo secundário da empresa",
            )}
            classNameDesktop="campanha_logosTopo__centro__footer"
            srcDesktop={logoCentroFooterDesktop}
            altDesktop={i18n.message(
              "alt.logo.secundario.footer.desktop",
              "Logo secundário da empresa",
            )}
          />
        ) : logoCentroFooter && !isDesktopMode && usuario === false ? (
          <Image
            className="campanha_logosTopo__centro__footer"
            src={logoCentroFooter}
            alt={i18n.message(
              "alt.logo.secundario.footer.mobile",
              "Logo secundário da empresa",
            )}
            classNameDesktop="campanha_logosTopo__centro__footer"
            srcDesktop={logoCentroFooterDesktop}
            altDesktop={i18n.message(
              "alt.logo.secundario.footer.desktop",
              "Logo secundário da empresa",
            )}
          />
        ) : (
          <span>{`v. ${packageInfo?.version}`}</span>
        )}
      </Rodape>
    </>
  );
}
export const Rodape = styled.footer`
  padding-top: 0px;
  text-align: center;

  img {
    height: auto;
    max-width: 100%;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

export default Footer;

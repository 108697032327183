import React from "react";
import { WeexInput, Label, Select } from "./style";

/**
 * A Weex component for selecting an age of a person between 14 and 100.
 *
 * @param {string} name - The name of the number select.
 * @param {string} id - The id of the number select.
 * @param {string} placeholder - The placeholder of the number select.
 * @param {string} label - The label of the number select.
 * @param {boolean} required - Whether the number select is required.
 * @param {boolean} disabled - Whether the number select is disabled.
 * @param {function} onChange - The function to be called when the number select value changes.
 * @param {number} value - The current value of the number select.
 * @returns {JSX.Element} - A Weex component for selecting a number.
 */
function WeexNumbersSelect({
  key,
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  value,
  ariaLabel,
}) {
  const min = 14;
  const max = 100;
  let items = [];
  for (var i = min; i <= max; i++) {
    items.push(
      <option
        key={"value_option_" + i}
        value={i}
        selected={value && value === i}
      >
        {i}
      </option>,
    );
  }
  return (
    <WeexInput key={key}>
      <Label htmlFor={id} aria-label={ariaLabel}>
        {label}
        <Select
          id={id}
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
        >
          {items}
        </Select>
      </Label>
    </WeexInput>
  );
}

export default WeexNumbersSelect;

import React, { useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { WeexDialog } from "../../weexModais/WeexDialog";
import { useNavigate } from "react-router-dom";
import TeamService from "../../../services/TeamService";
import CampanhaService from "../../../services/CampanhaService";
import UsuarioService from "../../../services/UsuarioService";
import BotaoFechar from "../../BotaoFechar/BotaoFechar";
import PrimarioBtn from "../../buttonPWA2.0/PrimarioBtn";
import SecundarioBtn from "../../buttonPWA2.0/SecundarioBtn";

const teamService = new TeamService();
const campanha = new CampanhaService();
const usuarioService = new UsuarioService();

function EntrarEquipeModal() {
  const [step, setStep] = useState(1);
  const [chooseTeam, setChooseRandomTeam] = useState(null);
  const entrarEquipe = useSelector((state) => state.entrarEquipe);
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const navigate = useNavigate();

  const teamUuid = entrarEquipe?.teamUuid;
  const teamName = entrarEquipe?.teamName;
  let title = "";
  if (teamUuid && teamName) {
    title = `${entrarEquipe?.title} "${teamName}".`;
  } else {
    title = entrarEquipe?.title;
  }
  const message = entrarEquipe?.message;
  const callConfirmation = entrarEquipe?.callConfirmation;
  const btnCancelMessage = entrarEquipe?.btnCancelMessage;
  const btnConfirmMessage = entrarEquipe?.btnConfirmMessage;

  const ocultar = () => {
    if (chooseTeam !== null) {
      irParaAtividades();
      return;
    }
    dispatch({ type: "HIDE_MODAL", payload: "entrarEquipe" });
  };

  const irParaAtividades = () => {
    dispatch({ type: "userTeamUuid", payload: chooseTeam?.uuid });
    usuarioService.setUserTeamUuid(chooseTeam?.uuid);
    dispatch({ type: "HIDE_MODAL", payload: "entrarEquipe" });
    contadorCampanha();
    return;
  };

  const beLeader = () => {
    const request = {
      eventCode: campanha.campanhaCorrente()?.code,
      teamUuid: teamUuid,
    };
    teamService
      .beALeader(request)
      .then((response) => {
        if (response.status === 201) {
          setChooseRandomTeam(response.data);
          setStep(3);
          return;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({ type: "SHOW_MODAL", payload: "error" });
          dispatch({
            type: "error",
            payload: {
              title: i18n.message(
                "serLider.erro.titulo",
                "Erro ao se tornar líder",
              ),
              message: i18n.message(
                "serLider.erro.titulo",
                error?.response?.data?.message,
              ),
            },
          });
          return;
        }
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "serLider.erro.titulo",
              "Erro ao se tornar líder",
            ),
            message: i18n.message(
              "serLider.erro.message",
              "Erro ao se tornar líder. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            ),
          },
        });
        return;
      });
  };

  const joinATeam = () => {
    const request = {
      eventCode: campanha.campanhaCorrente()?.code,
      teamUuid: teamUuid,
    };
    teamService
      .joinATeam(request)
      .then((response) => {
        if (response.status === 201) {
          setChooseRandomTeam(response.data);
          if (response?.data?.leader === null) {
            setStep((prevStep) => prevStep + 1);
            return;
          }
          setStep(3);
          return;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({ type: "SHOW_MODAL", payload: "error" });
          dispatch({
            type: "error",
            payload: {
              title: i18n.message(
                "escolherEquipe.erro.titulo",
                "Erro ao entrar em equipe",
              ),
              message: i18n.message(
                "escolherEquipe.erro.message",
                error?.response?.data?.message,
              ),
            },
          });
          return;
        }
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "escolherEquipe.erro.titulo",
              "Erro ao entrar em equipe",
            ),
            message: i18n.message(
              "escolherEquipe.erro.message",
              "Erro ao entrar na equipe. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            ),
          },
        });
        return;
      });
  };

  const contadorCampanha = () => {
    campanha.contadorCampanha((erro, sucesso) => {
      if (erro) {
        console.error("Erro obter lista de dias habilitados");
        return;
      }
      if (sucesso) {
        if (sucesso !== null) {
          if (sucesso.length > 0) {
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
            return;
          }
          dispatch({ type: "campanhaComecou", payload: false });
          navigate("/home");
          return;
        }
      }
    });
  };

  const successMessage = () => {
    return (
      <>
        <h1 className="weexDialog__texts--alignment">
          {i18n.message("escolherEquipe.sucesso.title", "Parabéns!")}
        </h1>
        <section className="weexDialog__textsContainer"></section>
        <section className="weexDialog__buttonsContainer weexDialog__buttonsContainer--direction weexDialog__buttonsContainer--margins">
          <PrimarioBtn
            nome={i18n.message("escolherEquipe.btn.sucesso", "COMEÇAR")}
            funcao={irParaAtividades}
            id={"botao-equipe-ir-atividades"}
            disabledOnClick={false}
            ariaLabelMsg={i18n.message(
              "escolherEquipe.btn.sucesso",
              "Ir para atividades",
            )}
            ariaStatusMessage={i18n.message(
              "ariaStatus.escolherEquipe.btn.sucesso",
              "Ir para atividades clicado",
            )}
          />
        </section>
      </>
    );
  };

  const firstPageInfos = () => {
    return (
      <>
        <h1 className="weexDialog__texts--alignment">{title}</h1>
        <section className="weexDialog__textsContainer">
          <span className="weexDialog__texts--alignment weexDialog__texts--alignment--marginsSquish">
            {message}
          </span>
          <span className="weexDialog__texts--alignment">
            {callConfirmation}
          </span>
        </section>
        <section className="weexDialog__buttonsContainer weexDialog__buttonsContainer--direction weexDialog__buttonsContainer--margins">
          <SecundarioBtn
            id={"botao-equipe-cancel"}
            nome={btnCancelMessage}
            funcao={ocultar}
            disabledOnClick={false}
            ariaLabelMsg={btnCancelMessage}
            ariaStatusMessage={i18n.message(
              "ariaStatus.escolherEquipe.btn.cancel",
              btnCancelMessage + " clicado",
            )}
          />
          <PrimarioBtn
            nome={btnConfirmMessage}
            funcao={joinATeam}
            id={"botao-equipe-confirma"}
            disabledOnClick={false}
            ariaLabelMsg={btnConfirmMessage}
            ariaStatusMessage={i18n.message(
              "ariaStatus.escolherEquipe.btn.confirm",
              btnConfirmMessage + " clicado",
            )}
          />
        </section>
      </>
    );
  };

  const becameLeader = () => {
    const name = chooseTeam?.name;
    return (
      <>
        <h1 className="weexDialog__texts--alignment">
          {i18n.message(
            "escolherEquipe.leader.titulo",
            "Deseja ser líder da equipe",
          )}{" "}
          "{name}"?
        </h1>
        <section className="weexDialog__textsContainer">
          <span className="weexDialog__texts--alignment weexDialog__texts--alignment--marginsSquish">
            {i18n.message(
              "escolherEquipe.leader.message",
              "Você entrou em uma equipe que ainda não tem líder e, assim, poderá assumir a liderança dessa equipe.",
            )}
          </span>
          <span className="weexDialog__texts--alignment">
            {i18n.message(
              "escolherEquipe.leader.callConfirmation",
              "Deseja continuar?",
            )}
          </span>
        </section>
        <section className="weexDialog__buttonsContainer weexDialog__buttonsContainer--direction weexDialog__buttonsContainer--margins">
          <SecundarioBtn
            id={"botao-equipe-leader-cancel"}
            nome={i18n.message(
              "escolherEquipe.leader.btn.member",
              "Continuar como membro",
            )}
            funcao={ocultar}
            disabledOnClick={false}
            ariaLabelMsg={i18n.message(
              "escolherEquipe.leader.btn.member",
              "Continuar como membro",
            )}
            ariaStatusMessage={i18n.message(
              "ariaStatus.escolherEquipe.leader.btn.member",
              "Continuar como membro clicado",
            )}
          />
          <PrimarioBtn
            nome={i18n.message(
              "escolherEquipe.leader.btn.confirm",
              "Entrar como líder",
            )}
            funcao={beLeader}
            id={"botao-equipe-leader-confirma"}
            disabledOnClick={false}
            ariaLabelMsg={i18n.message(
              "escolherEquipe.leader.btn.confirm",
              "Entrar como líder",
            )}
            ariaStatusMessage={i18n.message(
              "ariaStatus.escolherEquipe.leader.btn.confirm",
              "Entrar como líder clicado",
            )}
          />
        </section>
      </>
    );
  };

  const steps = () => {
    switch (step) {
      case 1:
        return firstPageInfos();
      case 2:
        return becameLeader();
      case 3:
        return successMessage();
      default:
        return firstPageInfos();
    }
  };

  return (
    <WeexDialog dialogRef={dialogRef} onPressESC={ocultar}>
      <BotaoFechar funcao={ocultar} />
      <header className="weexDialog__header">
        <img
          className="weexDialog__header--ilustration"
          alt="temp"
          src="/entrar-equipe-ilustration.png"
        />
      </header>
      <main className="weexDialog__main">{steps()}</main>
    </WeexDialog>
  );
}

export default EntrarEquipeModal;

import styled from "styled-components";

export const CertificadoContainerStyle = styled.div`
  .certificado-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    text-align: center;
  }
  .btn-pdf {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 768px) {
    .btn-pdf {
      width: 30%;
    }
  }
`;

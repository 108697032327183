/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import TagPlacarAT from "./TagPlacarAT";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";
import { useNavigate, useParams } from "react-router-dom";
import { PlacarRespostasATStyle } from "./PlacarRespostasATStyle";

import TagTotalAT from "./TagTotalAT";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import AgendaService from "../../services/AgendaService";

function PlacarRespostasAT() {
  const {
    acerteTempo,
    TipoComponenteEnum,
    obtemPlacarFinal,
    mudarComponentePagina,
    revisaoMostrarTagPontos,
    campaignType,
    showProximaAtividade,
  } = useAcerteTempo();

  const scoreboard = obtemPlacarFinal;
  const presentationImage = acerteTempo?.image;
  let { codigo, codigoAtividade } = useParams();

  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const onClickRevisao = () => {
    mudarComponentePagina(TipoComponenteEnum.modoRevisao);
    revisaoMostrarTagPontos();
  };
  const [nextActivity, setNextActivity] = useState(null);

  useEffect(() => {
    const agendaService = new AgendaService();
    agendaService.atualizarAgenda(
      codigo,
      codigoAtividade,
      "acerteTempo",
      null,
      null,
      () => {
        agendaService.agendaCorrente((erro, agenda) => {
          if (agenda) {
            const dia = agenda.schedule.find(
              (item) => item.scheduleCode === codigo,
            );

            if (dia) {
              const acerteTempoComProximaAtividade = dia.activities.find(
                (item) => item.uuid === codigoAtividade,
              );
              setNextActivity({
                nextActivity: acerteTempoComProximaAtividade.nextActivity,
              });
            }
          }
        });
      },
    );
  }, []);

  const resultado = scoreboard?.scores.map((score, index) => {
    return (
      <li key={score.uuid} className="weex-l-acerteTempo-placar-pontuacoes">
        <span className="weex-l-acerteTempo-placar-pontuacoes--numeracao">
          {index + 1}
        </span>
        <TagPlacarAT
          campaignType={campaignType}
          correct={score?.correct}
          totalPoints={score?.totalPoints}
        />
      </li>
    );
  });

  const proximaAtividade = () => {
    navigate("/atividades", {
      state: {
        nextActivity: nextActivity.nextActivity,
        alreadyExecuted: nextActivity?.alreadyExecuted,
      },
    });
  };

  const botoes = () => {
    return (
      <div className="weex-l-acerteTempo-placar-btns-container">
        <div className="weex-l-acerteTempo-placar-btns-espacamentoSuperior">
          {showProximaAtividade && nextActivity ? (
            <PrimarioBtn
              nome={i18n.message(
                "missaoCumprida.proximaAtividade",
                "Próxima Atividade",
              )}
              disabled={""}
              funcao={proximaAtividade}
              ariaStatusMessage={""}
            />
          ) : (
            ""
          )}
          <PrimarioBtn
            nome={i18n.message(
              "dinamica.acerteTempo.btn.salaDeTrofeus",
              "Sala de Troféus",
            )}
            disabled={""}
            ariaDisabled={""}
            ariaHidden={""}
            tabIndex={""}
            ariaLabelMsg={""}
            classeComplementar={""}
            funcao={() => {
              navigate("/trophy");
            }}
            ariaStatusMessage={""}
          />
          <SecundarioBtn
            iconposition="right"
            nome={i18n.message(
              "dinamica.acerteTempo.btn.revisao",
              "Minhas Respostas",
            )}
            funcao={onClickRevisao}
          />
        </div>
      </div>
    );
  };

  return (
    <PlacarRespostasATStyle className="weex-l-acerteTempo-textos-container">
      <div className="weex-l-acerteTempo-container weex-l-acerteTempo-container-rowReversed">
        <div className="weex-l-acerteTempo-textos-container weex-l-acerteTempo-container--flexFilhos">
          <h2 className="weex-l-acerteTempo-placar--titulo">
            {i18n.message(
              "dinamica.acerteTempo.subitulo.placar",
              "Seu placar:",
            )}
          </h2>
          <ul className="weex-l-acerteTempo-botoesContainer">{resultado}</ul>
          {campaignType !== "REGULAR" ? (
            <TagTotalAT totalPoints={scoreboard?.totalPointsRightHabit} />
          ) : (
            ""
          )}
        </div>

        <WeexDesktopMode
          className={
            "weex-l-acerteTempo-container--flexFilhos weex-l-acerteTempo-posicionamento-imagens"
          }
        >
          {presentationImage ? (
            <>
              <div>
                <img
                  className="weex__image is-2by1"
                  src={presentationImage}
                  alt={i18n.message(
                    "alt.dinamica.acerteTempo.imagemApresentacao",
                    "Imagem de apresentação da dinâmica Hábito Certo",
                  )}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </WeexDesktopMode>
      </div>

      {botoes()}
    </PlacarRespostasATStyle>
  );
}

export default PlacarRespostasAT;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BotaoAsALink from "../../comps/botaoAsALink/BotaoAsALink";
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import AgendaService from "../../services/AgendaService";
import UsuarioService from "../../services/UsuarioService";
import { GameStyled } from "./styled";
import AtividadesService from "../../services/AtividadesService";

const Game = () => {
  const [state, setState] = useState({
    codigo: null,
    codigoAtividade: null,
    action: null,
    game: null,
    processando: false,
  });

  const { codigo, codigoAtividade } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const i18n = useSelector((state) => state.i18n);

  useEffect(() => {
    if (state.processando) return;

    setState((prevState) => ({ ...prevState, processando: true }));

    const service = new AtividadesService();
    const agendaService = new AgendaService();

    service.abreFechaAcao(codigo, codigoAtividade, (erro, action) => {
      if (erro) {
        alert(JSON.stringify(erro));
        return;
      }

      setState((prevState) => ({
        ...prevState,
        processando: false,
        codigo: codigo,
        codigoAtividade: codigoAtividade,
        action: action,
        game: action.game,
      }));

      agendaService.atualizarAgenda(
        codigo,
        codigoAtividade,
        "GAME",
        null,
        null,
        (errorAtualizarAgenda, sucesso) => {
          if (errorAtualizarAgenda) {
            alert(
              i18n.message(
                "dinamica.erro.jaexecutada.mensagem",
                "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
              ),
            );
          }
        },
      );

      const usuarioService = new UsuarioService();
      const url = `${action.game.url}?action=${
        action.actionId
      }&tkn=${usuarioService.token()}&agenda=${codigo}`;
      localStorage.setItem("ultimoGame", url);
      localStorage.setItem("actionGame", codigoAtividade);

      setTimeout(() => {
        window.location = url;
      }, 3000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const voltar = () => navigate("/atividades");

  const { game } = state;

  if (!game) {
    return <div>{i18n.message("dinamica.game.semjogo", "Carregando...")}</div>;
  }

  return (
    <GameStyled>
      <DinamicasHeader
        title={game.title || game.name}
        dinamicaNome={i18n.message("dinamica.game.nome", "Game")}
        dinamicaAlt={i18n.message(
          "alt.dinamica.game",
          "Ícone de um controle de videogame.",
        )}
        dinamicaImg={
          location.state ? location.state.icon || "/jogos.png" : "/jogos.png"
        }
      />
      <div className="card-content">
        <div
          role="region"
          className="info"
          aria-label={i18n.message(
            "aria.label.game.informacoes",
            "Informações do Jogo",
          )}
        >
          <p className="info-game">
            {i18n.message(
              "info.gamer",
              "Clique no botão concluir para que sua pontuação seja registrada ",
            )}{" "}
          </p>
          <p>{i18n.message("geral.aguarde", "Aguarde...")}</p>
        </div>
      </div>
      <BotaoAsALink
        nome={i18n.message("missaoCumprida.repetir", "Voltar")}
        ariaLabelMsg={i18n.message(
          "aria.label.geral.mensagem.voltar.atividades",
          "Volta para a página de atividades.",
        )}
        funcao={voltar}
      />
    </GameStyled>
  );
};

export default Game;

import React from "react";
import { useStore } from "react-redux";
import styled from "styled-components";

/**
 * Botão com imagem em X para fechar modal
 */
export default function BotaoFechar({ funcao }) {
  const i18n = useStore().getState().i18n;
  return (
    <BotaoFecharContainerStyle>
      <BotaoFecharStyle
        onClick={funcao}
        className="close-btn"
        aria-label={i18n.message(
          "aria.label.modal.generico.de.sucesso.erro.fechar.modal",
          "Fecha a janela e volta para a página anterior.",
        )}
      >
        <img
          src="/close-btn.png"
          alt={i18n.message(
            "alt.button.imagem.fechar",
            "ícone em formato de um X indicando o encerramento de uma ação.",
          )}
        />
      </BotaoFecharStyle>
    </BotaoFecharContainerStyle>
  );
}

export const BotaoFecharContainerStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const BotaoFecharStyle = styled.button`
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
  cursor: pointer;

  :active {
    filter: brightness(0.5);
  }
`;

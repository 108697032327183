import React, { useRef, useState } from "react";
import { useStore } from "react-redux";
import { WeexModalDialog } from "./WeexModalDialog";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";

/**
 * @deprecated - componente será reformulado para se adequar ao novo design do weex 3.0
 * @param {Object} props
 * @param {string} props.titulo
 * @param {string} props.conteudo
 * @param {function(event): void} props.confirmarCallback
 * @param {function(): void} props.cancelarCallback
 */
function InteressesConfirmacaoModal({
  titulo,
  conteudo,
  confirmarCallback,
  cancelarCallback,
}) {
  const [mostrarModal, setMostrarModal] = useState(true);
  const i18n = useStore().getState().i18n;
  const modalDialogRef = useRef(null);

  function cancelar() {
    cancelarCallback();
    setMostrarModal(false);
    modalDialogRef.current.close();
  }

  function confirmar(event) {
    confirmarCallback(event);
    setMostrarModal(false);
  }

  return mostrarModal ? (
    <WeexModalDialog dialogRef={modalDialogRef} onPressESC={cancelar}>
      <div className={"modal is-active"} style={{ zIndex: "102" }}>
        <div className="modal-background"></div>
        <div>
          <header className="modal-card-head weex-modal-card-header"></header>
          <header className="weex-modal-card-header wex-modal-card-header-title">
            <h1 className="nome-atividade">{titulo}</h1>
          </header>
          <section className="modal-card-body has-text-centered">
            <div className="weex-modal-card-body__texto">
              <div>{conteudo}</div>
            </div>
          </section>
          <footer
            className="modal-card-foot weex-modal-card-footer "
            style={{ textAlign: "center" }}
          >
            <div className="interesse-obrigatiorio-container">
              <span>
                {" "}
                <SecundarioBtn
                  nome={i18n.message("geral.cancelar", "Cancelar")}
                  funcao={cancelar}
                />
              </span>
              <span>
                <PrimarioBtn
                  nome={i18n.message("geral.confirmar", "Confirmar!")}
                  funcao={confirmar}
                  ariaStatusMessage={i18n.message(
                    "aria.status.interessesconfirmacaomodal.confirmar",
                    "Botão confirmar clicado.",
                  )}
                />
              </span>
            </div>
          </footer>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default InteressesConfirmacaoModal;

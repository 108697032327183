import styled from "styled-components";

export const CampaignInformationStyle = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4.5rem;
  border-radius: 1.5rem;
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  background-color: ${(props) => {
    return props.theme.headerSubBg;
  }};
  filter: contrast(0.92);

  .campaignInformation__textTitle {
    p {
      color: inherit;
      text-align: inherit;
      font-size: 0.938rem;
    }

    p .ql-size-large {
      font-size: 1.146rem;
      line-height: 1.492rem;
    }

    p .ql-size-huge {
      font-size: 1.668rem;
      line-height: 2.171rem;
    }
    span,
    u,
    em,
    strong,
    s {
      color: inherit;
    }
  }

  .campaignInformation__title {
    color: ${(props) => {
      return props.theme.headerSubBgText;
    }};
  }

  .campaignInformation__subtitle {
    color: ${(props) => {
      return props.theme.headerSubBgText || `#7c7c7c`;
    }};
  }
  @media (min-width: 75rem) {
    max-width: 70%;
  }
`;

/** @deprecated - retrocompatibilidade com o weex 2.0. login/register. Ainda não tem mockup para o 3.0  */
export const CampanhaLogosStyle = styled.div`
  h2 {
    margin-top: 2rem;
  }
  .campanha_logosTopo {
    margin: 0 1rem;
  }
  .foreground {
    color: ${(props) => {
      return props.theme.headerText;
    }};
  }

  .campanha_logosTopo_name__title {
    text-align: center;
    p {
      color: inherit;
      text-align: inherit;
      font-size: 2rem;
      line-height: 2rem;
    }

    p .ql-size-small {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    p .ql-size-large {
      font-size: 2.444rem;
      line-height: 3.321rem;
    }

    p .ql-size-huge {
      font-size: 3.556rem;
      line-height: 4.267rem;
    }

    span,
    em,
    strong,
    u,
    s {
      color: inherit;
    }
  }

  .campanha_logosTopo_name__subtitle {
    margin-top: 1rem;
    text-align: center;
    p .ql-size-small {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    p {
      color: inherit;
      text-align: inherit;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }

    p .ql-size-large {
      font-size: 1.333rem;
      line-height: 1.833rem;
    }

    p .ql-size-huge {
      font-size: 1.833rem;
      line-height: 2.334rem;
    }

    span,
    em,
    strong,
    u,
    s {
      color: inherit;
    }
  }

  .foreground_not_logged {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
    text-align: center;
  }

  .campanha.logosTopo {
    width: 100vw;
    min-width: 100vw;
  }

  .campanha_logosTopo {
    text-align: center;
  }

  .campanha_logosTopo_left {
    max-width: 25vw;
    float: left;
  }

  .campanha_logosTopo_right {
    max-width: 25vw;
    float: right;
  }

  .campanha_logosTopo__centro {
    width: 100%;
  }

  .campanha_logosTopo_name {
    margin-top: 3em;
  }

  /* #region LEGADO_DESKTOP */
  /*media query min-width 700px*/
  @media screen and (min-width: 700px) {
    display: flex;
    justify-content: center;
    .campanha_logosTopo {
      min-height: 10vh;
      margin: auto;
      width: 30%;
      text-align: center;
    }
    .campanha_logosTopo__centro img {
      height: auto;
      max-width: 12vw !important;
      max-height: 10vh !important;
      margin-right: 4em;
    }
    .campanha_logosTopo__centro__notLogged {
      width: 100%;
    }
    .foreground_not_logged {
      color: ${(props) => {
        return props.theme.textosCorFundoPrimario;
      }};
    }
  }
  /* #endregion DESKTOP-ANTIGO */
  /* #endregion LEGADO_DESKTOP */
`;

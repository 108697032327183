import React from "react";
import { WeexInput, Label, Select } from "./style";

/**
 * A Weex Select component.
 *
 * @param {string} name - The name of the input element.
 * @param {string} id - The id of the input element.
 * @param {string} placeholder - The placeholder text for the input element.
 * @param {string} label - The label text for the input element.
 * @param {boolean} [required=true] - Whether the input element is required.
 * @param {boolean} [disabled=false] - Whether the input element is disabled.
 * @param {function} onChange - A function that is called when the value of the input element changes.
 * @param {string} value - The value of the input element.
 * @param {string} ariaLabel - The aria-label attribute for the input element.
 * @param {node} children - The child elements of the select element.
 */
function WeexSelect({
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  onBlur,
  value = "",
  ariaLabel,
  children,
}) {
  return (
    <WeexInput>
      <Label htmlFor={id} aria-label={ariaLabel}>
        {label}
        <Select
          id={id}
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        >
          {children}
        </Select>
      </Label>
    </WeexInput>
  );
}

export default WeexSelect;

import React from "react";
import styled from "styled-components";
import { useStore } from "react-redux";
import packageInfo from "../../../package.json";

function Version() {
  const i18n = useStore().getState().i18n;

  return (
    <div>
      <div>
        <img
          src="../logo.png"
          alt={i18n.message(
            "alt.redirecionamento.campanha.weex.logo",
            "Logo da empresa fornecedora do serviço Weex",
          )}
        />
      </div>
      <Versao>
        versão <span className="version-label">{packageInfo?.version}</span>
      </Versao>
    </div>
  );
}

export const Versao = styled.div`
  margin-top: 2em;
  text-align: center;
  color: ${(props) => {
    return props.theme.textosCorFundoPrimario;
  }};

  .version-label {
    font-weight: 800;
  }
`;

export default Version;

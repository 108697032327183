import React from "react";

export default function Divider() {
  return (
    <>
      <div className="atividades__container">
        <section>
          <hr
            style={{
              marginBottom: "1.5rem",
              backgroundColor: "#e2e2e2",
              height: "1px",
              border: "0",
            }}
          />
        </section>
      </div>
    </>
  );
}

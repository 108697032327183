import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Glasses({ changeAvatar }) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.oculos", "Óculos")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.nenhum",
            "Nenhum selecionado",
          )}
          type="glassesStyle"
          value="none"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.nenhum", "Nenhum")}
          avatarPart={"/negativo_dois.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.oculos1",
            "Óculos redondo selecionado",
          )}
          type="glassesStyle"
          value="round"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.oculos1", "Óculos redondo")}
          avatarPart={"/svgs-3.0/avatar-parts/glasses_round.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.oculos2",
            "Óculos quadrado selecionado",
          )}
          type="glassesStyle"
          value="square"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.oculos2", "Óculos quadrado")}
          avatarPart={"/svgs-3.0/avatar-parts/glasses_square.svg"}
        />
      </AvatarPartsContainerStyle>
    </>
  );
}

import React from "react";
import { useStore } from "react-redux";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";

export default function CardRespostaFatoFake({
  proximoFatoFake,
  response,
  ultimo,
  finalizarAtividade,
  disabled,
}) {
  const i18n = useStore().getState().i18n;
  let fato = response?.participantAnsweredCorrectly === true;
  return (
    <div className="weex-l-dinamica-FatoFake-card animations-HideUntilLoaded">
      <div className="card-image">
        <div style={{ width: "100%" }} />
      </div>
      <div className="card-content" style={{ marginTop: "2em" }}>
        <div
          role="alert"
          className={`
            ${
              fato
                ? "weex-l-dinamica-card-ff-resposta weex-l-dinamica-card-ff-resposta__acertou"
                : "weex-l-dinamica-card-ff-resposta weex-l-dinamica-card-ff-resposta__errou"
            }
              weex-l-dinamica-card-ff-resposta
              weex-l-dinamica-FatoFake-card-content-respiro`}
          style={{ marginBottom: "4em" }}
        >
          <div
            className="weex-l-dinamica-card-ff-resposta--respiro-superior"
            style={{ textAlign: "center" }}
          >
            <span
              className={`${
                fato
                  ? "weex-l-dinamica-card-ff-resposta-tag--acertou"
                  : "weex-l-dinamica-card-ff-resposta-tag--errou"
              } weex-l-dinamica-card-ff-resposta-tag`}
            >
              {fato ? (
                <>{i18n.message("dinamica.fatofake.acertou", "ACERTOU!")}</>
              ) : (
                <>{i18n.message("dinamica.fatofake.errou", "OPS!")}</>
              )}
            </span>
          </div>
          <div className="weex-l-dinamica-card-ff-resposta-tag-markup">
            <div dangerouslySetInnerHTML={{ __html: response?.explanation }} />
          </div>
        </div>
        <div className="weex-l-dinamica-FatoFake-card-botao-respiro">
          {ultimo ? (
            <PrimarioBtn
              funcao={finalizarAtividade}
              disabled={disabled}
              nome={
                !disabled
                  ? i18n.message(
                      "dinamica.fatofake.concluir",
                      "Concluir atividade",
                    )
                  : i18n.message("geral.aguarde", "Aguarde...")
              }
              ariaStatusMessage={i18n.message(
                "aria.status.cardrespostafatofake.concluir",
                "Botão concluir atividade clicado",
              )}
            />
          ) : (
            <SecundarioBtn
              nome={i18n.message("dinamica.fatofake.proxima", "Avançar")}
              funcao={proximoFatoFake}
            />
          )}
        </div>
      </div>
    </div>
  );
}

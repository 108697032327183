import React from "react";
import { useStore, useSelector, useDispatch } from "react-redux";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import { WeexModalDialog } from "./WeexModalDialog";
import { useState } from "react";
import { useRef } from "react";

/**
 * @deprecated - componente será reformulado para se adequar ao novo design do weex 3.0
 * @param {Object} props
 * @param {string} props.titulo
 * @param {string|Array} props.conteudo
 * @param {function(): void} props.fecharModalCallback
 */
function WeexModal({ titulo, conteudo, fecharModalCallback = () => {} }) {
  const error = useSelector((state) => state.error);
  const i18n = useStore().getState().i18n;
  const [showModal, setShowModal] = useState(true);
  const dialogRef = useRef(null);
  const dispatch = useDispatch();

  let title = titulo ? titulo : error.title;
  let msg = conteudo ? conteudo : error.message;

  const ocultar = () => {
    setShowModal(false);
    fecharModalCallback();
    if (error) {
      dispatch({ type: "HIDE_MODAL", payload: "error" });
    }
  };

  const renderConteudo = () => {
    if (typeof msg === "string") {
      return <p>{msg}</p>;
    } else if (Array.isArray(msg)) {
      return (
        <ul>
          {msg.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return showModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={ocultar}>
      <div
        className={"modal is-active animations-popIn"}
        style={{ zIndex: "102" }}
      >
        <div className="modal-background"></div>
        <div>
          <header className="modal-card-head weex-modal-card-header">
            <button
              onClick={ocultar}
              className="close-btn"
              aria-label={i18n.message(
                "aria.label.modal.generico.de.sucesso.erro.fechar.modal",
                "Fecha a janela e volta para a página anterior.",
              )}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </header>
          <header className="weex-modal-card-header wex-modal-card-header-title">
            <h1 className="nome-atividade nome-atividade-font-weight-normal">
              {title}
            </h1>
          </header>
          <section className="modal-card-body has-text-centered">
            <div className="weex-modal-card-body__texto">
              {renderConteudo()}
            </div>
          </section>
          <footer
            className="modal-card-foot weex-modal-card-footer"
            style={{ textAlign: "center" }}
          >
            <PrimarioBtn
              disabledOnClick={false}
              nome="Ok"
              funcao={ocultar}
              ariaStatusMessage={i18n.message(
                "aria.status.weexmodal.botaook",
                "Botão ok clicado.",
              )}
            />
          </footer>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default WeexModal;

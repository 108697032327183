import instance from "./Api";
import CampanhaService from "./CampanhaService";
import AgendaGlobalService from "./AgendaGlobalService";

export default class PollService {
  async obtemImagemEnquete(pollUuid) {
    try {
      const response = await instance.get(
        this.host() + "/api/v1/pollImages/" + pollUuid,
        {
          responseType: "blob",
        },
      );
      if (response?.data?.size > 0) {
        const url = URL.createObjectURL(response.data);
        return url;
      }
    } catch (error) {
      console.error("Erro ao retornar imagem principal da enquete. ", error);
      return error;
    }
  }

  async obtemImagemAlternativas(optionUuid) {
    try {
      const response = await instance.get(
        this.host() + "/api/v1/pollImages/alternative/" + optionUuid,
        {
          responseType: "blob",
        },
      );

      if (response?.data?.size > 0) {
        const url = URL.createObjectURL(response.data);
        return url;
      }
    } catch (error) {
      console.error(
        "Erro ao retornar imagem da alternativa da enquete. ",
        error,
      );
      return error;
    }
  }

  async processaRespostaEnquente(response) {
    const { uuid, media, alternatives } = response;

    if (media && media.type === "IMAGEM") {
      response.media.imgUrl = await this.obtemImagemEnquete(uuid);
    }

    if (response.alternatives.length > 0 && alternatives.length > 0) {
      response.alternatives = await Promise.all(
        alternatives.map(async (alternative) => {
          alternative.checked = false;
          if (alternative.media && alternative.media.type === "IMAGEM") {
            alternative.media.imgUrl = await this.obtemImagemAlternativas(
              alternative.uuid,
            );
          }
          return alternative;
        }),
      );
    }

    return response;
  }

  obtemEnquete(pollUuid, callback) {
    const campanhaService = new CampanhaService();
    const campanhaCorrente = campanhaService?.campanhaCorrente();
    const eventUuid = campanhaCorrente?.uuid;
    if (!eventUuid) {
      throw new Error("Event UUID não encontrado");
    }
    instance
      .get(`${this.host()}/api/v1/event/${eventUuid}/polls/${pollUuid}`)
      .then((response) => {
        return this.processaRespostaEnquente(response.data);
      })
      .then((processado) => {
        callback(null, processado);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  votarEnquete(request) {
    const agendaGlobalService = new AgendaGlobalService();
    agendaGlobalService?.atualizarAtividadeExecutadaAgendaGlobal(
      request.poll,
      true,
    );
    return instance.post(this.host() + "/api/v1/polls/votes", request);
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}

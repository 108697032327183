import React from "react";
import { useStore } from "react-redux";
import AtividadeEntity from "../AtividadeEntity";
import GameSVG from "../../svg/GameSVG";
import { ButtonGameStyle } from "./style";
import GameTooltip from "./GameTooltip";

function BotaoGameAtividade({ games, atividades }) {
  const i18n = useStore().getState().i18n;

  const isEnable = () => {
    if (atividades.length > 0) {
      return atividades.reduce((isEnable, a) => {
        const atividade = new AtividadeEntity(a);
        return (
          (atividade.executed || atividade.type === "PRESENCIAL") && isEnable
        );
      }, true);
    }
    return false;
  };

  const GameButton = () => {
    return games.map((game) => {
      const gameRender = new AtividadeEntity(game);
      return (
        <GameTooltip
          text={i18n.message(
            "game.jogar.habilitado",
            "Que tal uma dose de diversão?",
          )}
          position="left"
        >
          <div
            className="weex-l-game"
            key={gameRender.uuid}
            aria-disabled="true"
            aria-hidden="true"
          >
            <ButtonGameStyle
              gameExecuted={game.executed}
              onClick={gameRender.navegar}
              tabIndex="-1"
              aria-hidden="true"
              aria-disabled="true"
            >
              <GameSVG />
            </ButtonGameStyle>
          </div>
        </GameTooltip>
      );
    });
  };

  const GameButtonBlocked = () => {
    return (
      <GameTooltip
        text={i18n.message(
          "game.jogar.desabilitado",
          "Faça todas as atividades para desbloquear",
        )}
        position="left"
      >
        <ButtonGameStyle disabled aria-disabled="true" aria-hidden="true">
          <img
            src="/svgs-3.0/lock.svg"
            alt={i18n.message("alt.game.locked", "Ícone de um cadeado")}
          />
        </ButtonGameStyle>
      </GameTooltip>
    );
  };

  const gameIsEnabled = () => {
    if (isEnable()) {
      return games.length > 0 ? GameButton() : "";
    }
    return games.length > 0 ? GameButtonBlocked() : "";
  };

  return <>{gameIsEnabled()}</>;
}

export default BotaoGameAtividade;

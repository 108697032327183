import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { InteracoesStyle } from "./style";
import Lock from "../../svg/Lock";

export default function TotalInteracoes({ totalVotes, executed, status }) {
  const i18n = useStore().getState().i18n;
  const wordVote =
    totalVotes === 1
      ? i18n.message("enqueteCampanha.votos.singular", "voto")
      : i18n.message("enqueteCampanha.votos.plural", "votos");
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <InteracoesStyle
      $hasvotes={
        (executed || status === "FINALIZADA") &&
        totalVotes !== null &&
        totalVotes > 0
      }
    >
      <div className="interacoesBar__textContainer">
        <span className="interacoesBar__text">
          {i18n.message(
            "enqueteCampanha.interacoes.title",
            "Total de interações",
          )}
        </span>
        {(executed || status === "FINALIZADA") &&
        totalVotes !== null &&
        totalVotes > 0 ? (
          <>
            <span
              className="interacoesBar__text--votes"
              style={{
                opacity: opacity,
                transition: "opacity 2s ease-in-out",
              }}
            >
              {totalVotes}
              <small>&#x2007;{wordVote}</small>
            </span>
          </>
        ) : (
          <>
            <div
              className="interacoesBar__bordaCadeado"
              style={{
                opacity: opacity,
                transition: "opacity 2s ease-in-out",
              }}
            >
              <Lock />
            </div>
          </>
        )}
      </div>
    </InteracoesStyle>
  );
}

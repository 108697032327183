import React, { useRef, useLayoutEffect } from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import { smoothScroll } from "./smoothScroll";

function ApresentacaoAT() {
  const { acerteTempo, TipoComponenteEnum, mudarComponentePagina } =
    useAcerteTempo();

  const i18n = useStore().getState().i18n;

  const scrollRef = useRef(null);

  const onClick = () => {
    mudarComponentePagina(TipoComponenteEnum.atividadeIniciada);
  };

  const textoBtn = i18n.message("dinamica.acerteTempo.btn.iniciar", "Iniciar");

  useLayoutEffect(() => {
    smoothScroll("volta-8993e98b", "up");
  }, [acerteTempo, TipoComponenteEnum, mudarComponentePagina]);

  const botaoComoFunciona = () => {
    return (
      <>
        <PrimarioBtn
          nome={textoBtn}
          disabled={""}
          ariaDisabled={""}
          ariaHidden={""}
          tabIndex={""}
          ariaLabelMsg={""}
          classeComplementar={""}
          funcao={onClick}
          ariaStatusMessage={""}
        />
      </>
    );
  };

  return (
    <>
      <div ref={scrollRef}></div>
      <section className="weex-l-acerteTempo-container weex-l-acerteTempo-container-rowReversed">
        <div className="weex-l-acerteTempo-textos-container weex-l-acerteTempo-container--flexFilhos">
          <div
            className="weex-l-acerteTempo-textos--formatoOriginal"
            dangerouslySetInnerHTML={{ __html: acerteTempo?.presentation }}
          ></div>
          <WeexDesktopMode
            className={
              "weex-l-acerteTempo-botoesContainer weex-l-acerteTempo-botaoRespiroSuperior"
            }
          >
            {botaoComoFunciona()}
          </WeexDesktopMode>
        </div>

        {acerteTempo?.image ? (
          <>
            <div className="weex-l-acerteTempo-container--flexFilhos">
              <div className="weex-l-acerteTempo-posicionamento-imagens weex-l-acerteTempo-apresentacaoMargin">
                <img
                  className="weex__image is-2by1 weex-l-acerteTempo-marginAuto"
                  src={acerteTempo?.image}
                  alt={i18n.message(
                    "alt.dinamica.acerteTempo.imagemApresentacao",
                    "Imagem de apresentação da dinâmica Hábito Certo",
                  )}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </section>
      <WeexMobileMode className={"weex-l-acerteTempo-botaoRespiroSuperior"}>
        {botaoComoFunciona()}
      </WeexMobileMode>
    </>
  );
}

export default ApresentacaoAT;

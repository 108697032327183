import React, { useEffect, useRef, useState } from "react";
import { PrimarioBtnStyle } from "./style";

/**
 * @param {Object} props
 * @param {string} props.nome
 * @param {boolean} props.disabled
 * @param {boolean} props.ariaDisabled
 * @param {boolean} props.ariaHidden
 * @param {boolean} props.tabIndex
 * @param {string} props.ariaStatusMessage
 * @param {string} props.ariaLabelMsg
 * @param {string} props.classeComplementar
 * @param {ReactNode} props.children
 * @param {function(event): void} props.funcao
 */
export default function PrimarioBtn({
  id = "botao-primario",
  nome,
  disabled,
  ariaDisabled,
  ariaHidden,
  tabIndex,
  ariaLabelMsg,
  classeComplementar,
  funcao,
  children,
  ariaStatusMessage,
  disabledOnClick = false,
}) {
  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] =
    useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      buttonRef.current.disabled = true;
      return;
    }
    buttonRef.current.disabled = false;
  }, [disabled]);

  function onClick(event) {
    if (disabled) return;
    if (disabledOnClick) {
      buttonRef.current.disabled = true;
    }
    setAriaAtivarMensagemDeClique(true);
    funcao && funcao(event);
  }

  return (
    <PrimarioBtnStyle
      id={id}
      className={
        disabled === true
          ? `${classeComplementar} 'button disabled'`
          : `${classeComplementar} `
      }
      onClick={onClick}
      aria-disabled={disabled || ariaDisabled ? "true" : "false"}
      aria-hidden={ariaHidden ? "true" : "false"}
      tabIndex={tabIndex ? tabIndex : "0"}
      aria-label={ariaLabelMsg}
      ref={buttonRef}
    >
      <span className="content-botao">
        {children}
        {nome}
      </span>
      <span className="weex__a11y--visuallyHidden" role="status">
        {ariaAtivarMensagemDeClique ? ariaStatusMessage : ""}
      </span>
    </PrimarioBtnStyle>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

function GlobalTheme({ children }) {
  const mainBackgroundColor = useSelector((state) => state.mainBackgroundColor);
  let { template } = useSelector((state) => {
    return {
      template: state.template,
    };
  });

  const combinedTheme = {
    ...template.finalTemplate,
    mainBackgroundColor,
  };
  return <ThemeProvider theme={combinedTheme}>{children}</ThemeProvider>;
}

export default GlobalTheme;

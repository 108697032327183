import React from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { AcerteTempoStyled } from "./style";
import ApresentacaoAT from "../../comps/acerteTempo/ApresentacaoAT";
import ComoFuncionaAT from "../../comps/acerteTempo/ComoFuncionaAT";
import QuestaoAT from "../../comps/acerteTempo/QuestaoAT";
import PlacarRespostasAT from "../../comps/acerteTempo/PlacarRespostasAT";
import CabecalhoAT from "../../comps/acerteTempo/CabecalhoAT";
import ModoRevisaoAT from "../../comps/acerteTempo/ModoRevisaoAT";

function AcerteTempo() {
  const { componentePagina, acerteTempo, TipoComponenteEnum } =
    useAcerteTempo();

  return (
    <AcerteTempoStyled>
      <CabecalhoAT />
      {componentePagina === TipoComponenteEnum.comoFunciona ? (
        <ComoFuncionaAT />
      ) : (
        ""
      )}
      {componentePagina === TipoComponenteEnum?.apresentacao &&
      acerteTempo?.presentation ? (
        <ApresentacaoAT />
      ) : (
        ""
      )}
      {componentePagina === TipoComponenteEnum.atividadeIniciada ? (
        <QuestaoAT />
      ) : (
        ""
      )}
      {componentePagina === TipoComponenteEnum.placar ? (
        <PlacarRespostasAT />
      ) : (
        ""
      )}
      {componentePagina === TipoComponenteEnum.modoRevisao ? (
        <ModoRevisaoAT />
      ) : (
        ""
      )}
    </AcerteTempoStyled>
  );
}

export default AcerteTempo;

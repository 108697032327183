import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Mouth({ changeAvatar }) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.boca", "Boca")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.boca1",
            "Boca rindo selecionada",
          )}
          type="mouthStyle"
          value="laugh"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.boca1", "Boca rindo")}
          avatarPart={"/svgs-3.0/avatar-parts/mouth_laugh.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.boca2",
            "Boca sorriso selecionada",
          )}
          type="mouthStyle"
          value="smile"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.boca2", "Boca sorrindo")}
          avatarPart={"/svgs-3.0/avatar-parts/mouth_smile.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.boca3",
            "Boca sorriso simpático selecionada",
          )}
          type="mouthStyle"
          value="peace"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.boca3", "Boca sorriso simpático")}
          avatarPart={"/svgs-3.0/avatar-parts/mouth_peace.svg"}
        />
      </AvatarPartsContainerStyle>
    </>
  );
}

import React from "react";

export default function Medalha({ color }) {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.21002 15L2.66002 7.14C2.46382 6.80087 2.37155 6.41159 2.39466 6.02048C2.41777 5.62937 2.55524 5.25366 2.79002 4.94L4.40002 2.8C4.58631 2.55161 4.82788 2.35 5.10559 2.21115C5.3833 2.07229 5.68953 2 6.00002 2H18C18.3105 2 18.6167 2.07229 18.8944 2.21115C19.1722 2.35 19.4137 2.55161 19.6 2.8L21.2 4.94C21.4363 5.25265 21.5756 5.62784 21.6005 6.01897C21.6254 6.4101 21.5348 6.79992 21.34 7.14L16.79 15M11 12L5.12002 2.2M13 12L18.88 2.2M8.00002 7H16M17 17C17 19.7614 14.7614 22 12 22C9.23859 22 7.00002 19.7614 7.00002 17C7.00002 14.2386 9.23859 12 12 12C14.7614 12 17 14.2386 17 17Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

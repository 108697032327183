import React from "react";
import { useDispatch, useStore, useSelector } from "react-redux";
import Avatar, { genConfig } from "react-nice-avatar";
import CampanhaService from "../../../services/CampanhaService";
import { ParticipantStyle } from "./style";

const campanhaService = new CampanhaService();

function Participant() {
  const usuarioAvatar = useSelector((state) => state.usuarioAvatar);
  const avatar = useSelector((state) => state.avatar);

  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;
  const naoMostrarInfoParticipante =
    campanhaService.naoMostrarInfoParticipante();
  const infoParticipante = campanhaService.getInformacaoParticipante();

  const showAvatarModal = () => {
    dispatch({ type: "SHOW_MODAL", payload: "avatar" });
  };

  const avatarConfigs = usuarioAvatar
    ? avatar
    : {
        sex: "man",
        faceColor: "black",
        earSize: "small",
        hairColor: "black",
        hairStyle: "normal",
        hairColorRandom: false,
        hatColor: "black",
        hatStyle: "none",
        eyeStyle: "circle",
        glassesStyle: "none",
        noseStyle: "round",
        mouthStyle: "peace",
        shirtStyle: "short",
        shirtColor: "black",
      };

  const config = genConfig(avatarConfigs);

  return (
    <ParticipantStyle $usuarioAvatar={usuarioAvatar}>
      <li>
        <button className="participant__avatarButton" onClick={showAvatarModal}>
          <Avatar className="participant__avatar" {...config} />
          {!usuarioAvatar && (
            <div className="participant__avatarCreate">
              {i18n.message("geral.menu.avatar.criar", "criar avatar")}
            </div>
          )}
        </button>
      </li>
      <li>
        <span className="participant__welcome">
          {i18n.message("geral.menu.boasvindas", "Olá! Boas vindas,")}
        </span>
        {naoMostrarInfoParticipante ? (
          ""
        ) : (
          <h2 className="participant__welcome">{infoParticipante}</h2>
        )}
      </li>
    </ParticipantStyle>
  );
}

export default Participant;

import React from "react";

const Trophy = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 5.08333H2.45833C2.07156 5.08333 1.70063 4.92969 1.42714 4.6562C1.15365 4.38271 1 4.01177 1 3.625C1 3.23823 1.15365 2.86729 1.42714 2.5938C1.70063 2.32031 2.07156 2.16667 2.45833 2.16667H3.33333M3.33333 5.08333V1H10.3333V5.08333M3.33333 5.08333C3.33333 6.01159 3.70208 6.90183 4.35846 7.55821C5.01484 8.21458 5.90508 8.58333 6.83333 8.58333C7.76159 8.58333 8.65183 8.21458 9.30821 7.55821C9.96458 6.90183 10.3333 6.01159 10.3333 5.08333M10.3333 5.08333H11.2083C11.5951 5.08333 11.966 4.92969 12.2395 4.6562C12.513 4.38271 12.6667 4.01177 12.6667 3.625C12.6667 3.23823 12.513 2.86729 12.2395 2.5938C11.966 2.32031 11.5951 2.16667 11.2083 2.16667H10.3333M2.16667 12.6667H11.5M5.66667 8.38499V9.74999C5.66667 10.0708 5.3925 10.3217 5.10083 10.4558C4.4125 10.7708 3.91667 11.64 3.91667 12.6667M8 8.38499V9.74999C8 10.0708 8.27417 10.3217 8.56583 10.4558C9.25417 10.7708 9.75 11.64 9.75 12.6667"
        stroke="#201D1D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trophy;

import styled from "styled-components";

export const DiaAgendaStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 2.375rem;
  max-width: 2.375rem;
  min-width: 2.375rem;
  height: 2.75rem;
  max-height: 2.75rem;
  min-height: 2.75rem;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.5rem;
  background-color: ${(props) => {
    if (props.$habilitado === "true") {
      return props.theme.fundoSecundario;
    } else {
      return "#b7b7b7";
    }
  }};

  box-shadow: ${(props) => {
    return props.theme.sombraPrimaria;
  }};

  .diaAgenda__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .diaAgenda__itemPadding {
    padding: 0.25rem;
  }

  .diaAgenda__month {
    color: ${(props) => {
      if (props.$habilitado === "true") {
        return props.theme.textosCorFundoSecundario;
      } else {
        return "#ffffff";
      }
    }};
    font-family: DM Sans;
    font-size: 1.125rem; //18px;
    font-weight: 900;
    line-height: 1.188rem; //19px;
    text-align: left;
  }

  .diaAgenda__day {
    color: ${(props) => {
      if (props.$habilitado === "true") {
        return props.theme.textosCorFundoSecundario;
      } else {
        return "#ffffff";
      }
    }};
    font-family: DM Sans;
    font-size: 1.125rem; //18px;
    font-weight: 900;
    line-height: 0.844rem; //13.5px;
    text-align: left;
  }

  path {
    padding: 0.25rem;
    stroke: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

import React from "react";
import { useStore } from "react-redux";

/**
 * @param {Object} props
 * @param {questao} props.questao
 * @param {resposta} props.resposta
 * @param {boolean} props.disableAlert
 */
export default function FeedBackResposta({ questao, resposta }) {
  const i18n = useStore().getState().i18n;
  const acertou = questao?.fact === resposta?.participantResponse;

  return (
    <div
      className={`
    ${
      acertou
        ? "weex-l-dinamica-card-ff-resposta weex-l-dinamica-card-ff-resposta__acertou"
        : "weex-l-dinamica-card-ff-resposta weex-l-dinamica-card-ff-resposta__errou"
    }
      weex-l-dinamica-card-ff-resposta
      weex-l-dinamica-FatoFake-card-content-respiro`}
      style={{ marginBottom: "4em" }}
    >
      <div
        className="weex-l-dinamica-card-ff-resposta--respiro-superior"
        style={{ textAlign: "center" }}
      >
        <span className="weex__a11y--visuallyHidden">
          {acertou
            ? i18n.message("dinamica.fatofake.acertou", "ACERTOU A QUESTÃO!")
            : i18n.message("dinamica.fatofake.errou", "OPS! ERROU A QUESTÃO")}
        </span>
        <span
          className={`
          ${
            acertou
              ? "weex-l-dinamica-card-ff-resposta-tag--acertou"
              : "weex-l-dinamica-card-ff-resposta-tag--errou"
          } weex-l-dinamica-card-ff-resposta-tag`}
          aria-disabled={true}
          aria-hidden={true}
        >
          {acertou
            ? i18n.message("dinamica.fatofake.acertou", "ACERTOU!")
            : i18n.message("dinamica.fatofake.errou", "OPS!")}
        </span>
      </div>
      <div className="weex-l-dinamica-card-ff-resposta-tag-markup">
        <div dangerouslySetInnerHTML={{ __html: questao.answer }} />
      </div>
    </div>
  );
}

import styled from "styled-components";

export const MissaoCumpridaFeedbackModalStyle = styled.section`
  .missaoCumprida__container {
    padding: 15px;
  }
  .missao-cumprida-container-confetes svg {
    width: 88% !important;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .missaoCumprida__voltar--tamanho {
    width: auto;
  }

  /*media query min-width 600px*/
  @media screen and (min-width: 600px) {
    .missao-cumprida-container-confetes svg {
      width: 75% !important;
      margin-left: calc(0vw - 208px);
      margin-right: calc(0vw - 208px);
      left: 17rem;
    }
  }

  .missao-cumprida__title {
    text-align: center;
    color: #201d1d;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .missao-cumprida__subtitle {
    text-align: center;
    font-weight: 400;
    color: rgb(0, 0, 0);
  }

  .missao-cumprida__subtitle__nameActivity {
    text-align: center;
    font-weight: 700;
    line-height: 1.3rem;
  }

  .pontuacao-container {
    margin-top: 3vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgba(0, 226, 145, 0.1);
    border-radius: 8px;
  }

  .pontuacao {
    color: #00e291;
    text-align: center;
    font-weight: 800;

    h1 {
      color: #00e291;
      text-align: center;
      font-weight: 800;
    }
    span {
      color: #00e291;
      font-weight: 800;
      font-size: 1.5rem;
      line-height: 1.953rem;
    }
    h4 {
      text-align: center;
      color: #00e291;
      font-weight: 600;
    }
  }

  @media screen and (min-width: 700px) {
    .modal-button-missaocomprida {
      width: 80%;
    }
    .missao-cumprida__title {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    .pontuacao-container {
      margin-top: 2rem;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: auto;
    }
  }
`;

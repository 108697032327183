import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AtividadeEntity from "../../comps/agenda/AtividadeEntity";
import RankingListaParticipantes from "../../comps/rankings/RankingListaParticipantes";
import LeadershipRanking from "../../comps/rankings/LeadershipRanking";
import PontuacaoUsuarioCorrente from "../../comps/rankings/PontuacaoUsuarioCorrente";
import RankingSelect from "../../comps/rankings/RankingSelect";
import CampanhaService from "../../services/CampanhaService";
import RankingGameService from "../../services/RankingGameService";
import TemaService from "../../services/TemaService";
import UsuarioService from "../../services/UsuarioService";
import { CampanhaRankingsStyled } from "../campanhaRanking/styled";

function GameRankings() {
  const i18n = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const gameUuid = useSelector((state) => state.gameUuid);
  const campanhaService = new CampanhaService();
  const rankingGameService = new RankingGameService();
  const usuarioService = new UsuarioService();
  const usuario = usuarioService?.usuarioCorrente();
  const [game, setGame] = useState({});
  const [participantePesquisadoNoRanking, setParticipantePesquisadoNoRanking] =
    useState([]);
  const [rankings, setRankings] = useState([]);
  const [rankingTop3, setRankingTop3] = useState([]);
  const [rankingWithFormat, setRankingWithFormat] = useState([]);
  const [pontuacaoUsuario, setPontuacaoUsuario] = useState({
    name: "",
    rating: null,
    unit: "",
    uuidParticipante: "",
  });

  useEffect(() => {
    let temaService = new TemaService(campanhaService?.campanhaCorrente());
    temaService.aplicarTema("HOME");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const games = rankingGameService.getGamesAvailable();
    if (games.length > 0) {
      const selectedGame = games.find(
        (g) => new AtividadeEntity(g).uuid === gameUuid,
      );
      const gameToSearch = selectedGame || games[0];
      setGame(gameToSearch);
      searchRankingGame(gameToSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pontuacaoUsuario?.uuidParticipante !== "") {
      formaterNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pontuacaoUsuario?.uuidParticipante]);

  const usuarioEstaNoRanking = (() => {
    let encontrei = false;
    let uuidParticipante = usuarioService?.getCodigoParticipante();
    if (!uuidParticipante) {
      console.error("Codigo do participante não encontrado em GameRankings");
    }
    rankings.forEach((pontuacaoUsuario) => {
      if (pontuacaoUsuario.uuidParticipante === uuidParticipante) {
        encontrei = true;
      }
    });

    return encontrei && rankings?.length > 0;
  })();

  const pesquisaPorParticipanteRankingGame = async (search) => {
    try {
      if (search !== "") {
        const response = await rankingGameService.buscarRanking(
          game?.uuid,
          game?.scoreType,
          0,
          search,
        );
        if (response?.data?.topRanking.length > 0) {
          setParticipantePesquisadoNoRanking(response?.data?.topRanking);
          formaterNumber();
        } else {
          setParticipantePesquisadoNoRanking([]);
        }
      } else {
        setParticipantePesquisadoNoRanking([]);
      }
    } catch (error) {
      if (error?.error?.message) {
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "ranking.erro.buscar.ranking.titulo",
              "Erro ao buscar ranking",
            ),
            message: error.error.message,
          },
        });
        return;
      }
      dispatch({ type: "SHOW_MODAL", payload: "error" });
      dispatch({
        type: "error",
        payload: {
          title: i18n.message(
            "ranking.erro.buscar.ranking.titulo",
            "Erro ao buscar ranking",
          ),
          message: i18n.message(
            "ranking.erro.buscar.ranking.mensagem",
            "Entre em contato com o suporte ou tente novamente mais tarde.",
          ),
        },
      });
      return;
    }
  };

  const searchRankingGame = (game) => {
    rankingGameService
      .buscarRanking(game?.uuid, game?.scoreType, 0)
      .then((ranking) => {
        setRankings(ranking?.data?.topRanking);
        setRankingTop3([...ranking?.data?.topRanking.slice(0, 3)]);
        formaterNumber();
        searchRankingUsuario(game, ranking?.data?.topRanking);
      })
      .catch((_error) => {
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message("ranking.erro", "Erro de Ranking"),
            message: i18n.message(
              "ranking.erro.msg",
              "Erro ao obter ranking, tente novamente mais tarde",
            ),
          },
        });
      });
  };

  const searchRankingUsuario = (game, ranking) => {
    let codePart = usuarioService?.getCodigoParticipante();
    if (!codePart) {
      console.error("Codigo do participante não encontrado em GameRankings");
    }
    rankingGameService
      .getPontuacaoAtividadeUsuario(game.uuid, game.scoreType)
      .then((pontuacao) => {
        if (pontuacao.data) {
          const { data: pontuacaoData } = pontuacao;
          const pontuacaoUsuario =
            ranking.find(
              ({ uuidParticipante }) =>
                uuidParticipante === pontuacaoData.uuidParticipante,
            ) || pontuacaoData;
          setPontuacaoUsuario(pontuacaoUsuario);
        } else {
          let unitName = campanhaService
            .campanhaCorrente()
            .client.units.find((unit) => unit.uuid === usuario?.unit)?.name;
          let rating = null;
          setPontuacaoUsuario({
            name: usuario?.name,
            rating: rating,
            unit: unitName,
            uuidParticipante: codePart,
          });
        }
        formaterNumber();
      })
      .catch((_error) => {
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message("ranking.erro", "Erro de Ranking"),
            message: i18n.message(
              "ranking.erro.msg.participante",
              "Erro ao obter pontuação do participante, tente novamente mais tarde",
            ),
          },
        });
      });
  };

  const formaterNumber = () => {
    let ranking = [...rankings];
    let rankingWithFormat = ranking.map((ranking) => {
      let position = parseInt(ranking.position);
      if (position < 10) {
        ranking.position = "0" + position;
      }
      return ranking;
    });
    setRankingWithFormat(rankingWithFormat);
  };

  return (
    <>
      <CampanhaRankingsStyled>
        <h1>{i18n.message("ranking.game", "Ranking games")}</h1>
        <LeadershipRanking topRanking={rankingTop3} />

        <PontuacaoUsuarioCorrente
          pontuacaoUsuario={pontuacaoUsuario}
          foraDoRanking={usuarioEstaNoRanking}
        />
        <RankingSelect searchRankingGame={searchRankingGame}></RankingSelect>
        <RankingListaParticipantes
          pontuacaoUsuario={pontuacaoUsuario}
          rankingFormat={rankingWithFormat}
          usuarioEstaNoRanking={usuarioEstaNoRanking}
          search={pesquisaPorParticipanteRankingGame}
          participantePesquisadoNoRanking={participantePesquisadoNoRanking}
        />
      </CampanhaRankingsStyled>
    </>
  );
}

export default GameRankings;

import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import RankingInputSearch from "../WeexInputs/RankingInputSearch";
import Spinner from "../publicacao/Spinner";
import PontuacaoUsuario from "./PontuacaoUsuario";
import { RankingsListStyled } from "./styled";

/**
 * Componente responsável por mostrar o ranking de participantes na campanha.
 * É usado tanto para o ranking dos games como para o da competição individual.
 */
function RankingListaParticipantes({
  pontuacaoUsuario,
  rankingFormat,
  search,
  participantePesquisadoNoRanking,
}) {
  const i18n = useSelector((state) => state.i18n);
  let ranking = rankingFormat;

  const [page, setPage] = useState(1);
  const [loading] = useState(false);
  const gerarPaginas = () => {
    if (ranking.length <= 0) {
      return (
        <>
          <p>
            {i18n.message(
              "ranking.campanha.no.participants",
              "Não há participantes",
            )}
          </p>
        </>
      );
    }
    if (
      participantePesquisadoNoRanking !== null &&
      participantePesquisadoNoRanking?.length > 0
    ) {
      return participantePesquisadoNoRanking.map((pontuacaoUsuario, index) => {
        if (index <= page * 10) {
          return (
            <div key={index}>
              <PontuacaoUsuario
                pontuacaoUsuario={pontuacaoUsuario}
                isParticipante={false}
              />
            </div>
          );
        }
        return null;
      });
    } else {
      return ranking.map((pontuacaoUsuario, index) => {
        if (index <= page * 10) {
          return (
            <div key={index}>
              <PontuacaoUsuario
                pontuacaoUsuario={pontuacaoUsuario}
                isParticipante={false}
              />
            </div>
          );
        }
        return null;
      });
    }
  };

  const loadMore = () => {
    if (page <= 5) {
      setPage(page + 1);
    }
  };

  const infiniteScroll = () => {
    return (
      <div className="ranking__scrollbar">
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={true}
          loader={
            <div>
              <Spinner isVisivel={loading} />
            </div>
          }
          useWindow={false}
        >
          {loading ? <Spinner isVisivel={loading} /> : gerarPaginas()}
        </InfiniteScroll>
      </div>
    );
  };
  return (
    <RankingsListStyled>
      <div className="rankings__searchContainer">
        <RankingInputSearch
          placeholder={i18n.message(
            "rankingsCampanha.ranking.search",
            "Encontrar colega",
          )}
          ariaLabel={i18n.message(
            "rankingsCampanha.aria.search",
            "campo para buscar participantes do ranking pelo nome",
          )}
          onChange={search}
        ></RankingInputSearch>
      </div>
      {infiniteScroll()}
    </RankingsListStyled>
  );
}

export default RankingListaParticipantes;

import React from "react";
import { WeexInput, Label, File } from "./style";

function WeexFile({
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  icon,
  iconposition = "left",
  accept,
  ariaLabel,
}) {
  const className =
    iconposition === "right"
      ? "weexInput__icon weexInput__icon--right"
      : "weexInput__icon";

  const classNameDisabled = disabled ? "weexInput__disabled" : "";

  return (
    <WeexInput>
      <span className={classNameDisabled}>
        {" "}
        <Label htmlFor={id} aria-label={ariaLabel}>
          <File>
            <span className="weexInput__label">{label}</span>

            <input
              className="weexInput__file"
              id={id}
              type="file"
              name={name}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              onChange={onChange}
              isright={iconposition === "right" ? "true" : "false"}
              accept={accept}
            />
          </File>
          {icon && (
            <div className={`${className}`}>
              <i className={icon}></i>
            </div>
          )}
        </Label>
      </span>
    </WeexInput>
  );
}

export default WeexFile;

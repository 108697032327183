import styled from "styled-components";

export const ButtonVideoStyle = styled.button`
  background-color: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  text-transform: uppercase;
  width: 100%;
  text-align: center !important;
  padding: 1.6em;
  padding-left: 2em;
  padding-right: 2em;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: 700;
  color: ${(props) => {
    return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
  }};
  box-shadow: ${(props) => {
    if (props.$disabled === true) {
      return props.theme.sombraSecundaria;
    } else {
      return props.theme.sombraPrimaria;
    }
  }};
  cursor: pointer;

  &:active {
    filter: brightness(0.5);
  }

  .disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  @media screen and (max-width: 600px) {
    padding-left: 0em;
    padding-right: 0em;
  }
`;

import instance from "./Api";
import CampanhaService from "./CampanhaService";

export default class TermosCondicoesService {
  campanhaService = new CampanhaService();
  campanhaCorrente = this.campanhaService.campanhaCorrente();

  aceiteTermoObrigatorio(callback) {
    instance
      .post(
        this.host() +
          "/api/v1/campaign/" +
          this.campanhaCorrente?.code +
          "/accept",
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((erro) => {
        callback(erro, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}

import styled from "styled-components";
export const PontuacaoUsuarioCorrenteStyle = styled.div`
  background-color: #d2f4e0;
  padding: 0.75rem; //12px;
  border-radius: 20px;

  margin-top: 1.5rem; //24px;
  margin-bottom: 1.5rem; //24px;
  display: flex;
  gap: 1.125rem; //18px;
  align-items: center;

  .participantCurrent__score {
    margin-left: auto;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem; // 12px 8px 12px 8px;
    gap: 0.25rem; //4px;
    border-radius: 0.75rem; // 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    h4,
    span {
      color: #005925;
    }
  }

  svg {
    width: 1.5rem; //24px;
    height: 1.5rem; //24px;
  }

  path {
    stroke: #7c7c7c;
  }
`;

export const RankingsListStyled = styled.div`
  .rankings__searchContainer {
    margin-top: 1.5rem; // 24px;
    margin-bottom: 1.5rem; //24px;
  }
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .ranking__scrollbar {
      padding-bottom: 8rem;
      overflow: auto;
      max-height: 65vh;
      &::-webkit-scrollbar {
        width: 0.313rem; //5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.313rem; //5px;
        border-radius: 0.625rem; //10px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => {
          return props.theme.fundoSecundario;
        }};
        border-radius: 0.625rem; //10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => {
          return props.theme.fundoSecundario || `#533888`;
        }};
      }
    }
  }
  /* #endregion DESKTOP */
`;

export const InformacaoSuaEquipeStyle = styled.div`
  background-color: #d2f4e0;
  padding: 0.75rem; //12px;
  border-radius: 20px;

  margin-top: 1.5rem; //24px;
  margin-bottom: 1.5rem; //24px;
  display: flex;
  gap: 1.125rem; //18px;
  align-items: center;

  .participantCurrent__score {
    margin-left: auto;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem; // 12px 8px 12px 8px;
    gap: 0.25rem; //4px;
    border-radius: 0.75rem; // 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    h4,
    span {
      color: #005925;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .logo__editable__container {
    position: relative;
    display: inline-block;

    .logo__button__icon {
      position: absolute;
      top: 90%;
      left: 95%;
      transform: translate(-50%, -50%);
      font-size: 25x;
      color: #005925;
    }

    .editable__card__imageContainer {
      border: 2px solid #bdd3c6;
    }
  }

  .cardTeam__imageContainer {
    width: 3.5rem; // 56px;
    height: 3.5rem; // 56px;
    background-color: #e7e7e7;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .cardTeam__imageContainer--logoDefault {
      width: 2.25rem; // 36px;
      height: 2.25rem; // 36px;
    }

    .cardTeam__imageContainer--logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  svg {
    width: 1.5rem; //24px;
    height: 1.5rem; //24px;
  }

  path {
    stroke: #7c7c7c;
  }
`;

import React, { useEffect, useRef, useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CardFatoFake from "../../comps/cardFatoFake/CardFatoFake";
import CardFatoFakeRevisao from "../../comps/cardFatoFake/revisao/CardFatoFakeRevisao";
import CardRespostaFatoFake from "../../comps/cardFatoFake/CardRespostaFatoFake";
import BotaoAsALink from "../../comps/botaoAsALink/BotaoAsALink";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import MarcadorProgresso from "../../comps/marcadorProgresso/MarcadorProgresso";
import WeexModal from "../../comps/weexModais/WeexModal";
import WeexModalRedirect from "../../comps/weexModais/WeexModalComRedirect";
import AgendaService from "../../services/AgendaService";
import AtividadesService from "../../services/AtividadesService";
import CampanhaService from "../../services/CampanhaService";
import LoaderService from "../../services/LoaderService";
import TemaService from "../../services/TemaService";
import FatoFakeService from "../../services/FatoFakeService";
import { FatoFakeStyle } from "./FatoFakerStyle";
import WeexMobileMode from "../../comps/WeexMode/WeexMobileMode";
import WeexDesktopMode from "../../comps/WeexMode/WeexDesktopMode";

function FatoFake() {
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const location = useLocation();
  let { codigo, codigoAtividade } = useParams();
  const atividadesService = new AtividadesService();
  const agendaService = new AgendaService();
  const loaderService = new LoaderService();
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();

  const fatoFakeService = new FatoFakeService();

  const [erro, setErro] = useState(null);
  const [fatoFake, setFatoFake] = useState();
  const [indiceFatoFake, setIndiceFatoFake] = useState(0);
  const [responseFatoFake, setResponseFatoFake] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [atividade, setAtividade] = useState();
  const [alcancouPontuacaoMinima, setAlcancouPontuacaoMinima] = useState(null);
  const [questoesRespondidasCorretamente, setQuestoesRespondidasCorretamente] =
    useState(0);
  const [earnedPoints, setEarnedPoints] = useState(null);
  const [approve, setApprove] = useState(true);

  const effectRan = useRef(false);

  useEffect(() => {
    let service = new TemaService(campanhaService.campanhaCorrente());
    service.aplicarTema("HOME");
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //TODO - No modo de desenvolvimento do React 18, os componentes podem ser renderizados duas vezes para detectar efeitos colaterais inseguros.
    if (effectRan.current === false) {
      effectRan.current = true;
      dispatch({ type: "mainBackgroundColor", payload: "rgb(243, 243, 248)" });
      iniciarAtividade();
    }

    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isAtividadeConcluida() {
    if (fatoFake?.uuid) {
      const response = window.localStorage.getItem(
        `conclusao-fatoFake-${codigoAtividade}`,
      );
      if (!response && location?.state?.alreadyExecuted) {
        return location?.state?.alreadyExecuted;
      }
      return response ? JSON.parse(response) : false;
    }
    return false;
  }

  const atividadeConcluida = isAtividadeConcluida();

  const iniciarAtividade = () => {
    atividadesService.iniciarAtividade(
      codigo,
      codigoAtividade,
      (error, atividade) => {
        if (atividade) {
          setFatoFake(atividade?.factOrFake);
          if (
            !atividadeConcluida &&
            atividade?.factOrFake?.remainingQuestions === 0 &&
            !atividade?.dateFinished
          ) {
            atividadesService.finalizarAtividade(
              codigo,
              codigoAtividade,
              "FACT_OR_FAKE",
              {},
              (erroFinalizarAtividade, sucesso) => {
                if (sucesso) {
                  atualizarAgenda();
                } else if (erroFinalizarAtividade) {
                  setErro({
                    titulo: i18n.message(
                      "dinamica.erro.finalizar.titulo",
                      "Erro ao finalizar atividade",
                    ),
                    mensagem: i18n.message(
                      "dinamica.erro.finalizar.mensagem",
                      "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
                    ),
                    voltarAgenda: false,
                  });
                }
              },
            );
            atualizarAgendaExecutada();
          }
          const indice = ondeParouFatoFakeIndice(
            atividade?.factOrFake?.remainingQuestions,
            atividade?.factOrFake,
          );
          setAtividade(atividade);

          if (atividade.dateFinished !== null) {
            atualizarAgenda();
          } else {
            responderFatoFake(
              null,
              atividade?.factOrFake?.questions[indice]?.uuid,
              true,
            );
          }

          if (atividade.factOrFake.questions.length > 1) {
            loaderService.load(atividade.factOrFake.questions[1].picture);
          }
        }
        if (error) {
          if (
            error?.response &&
            error?.response?.data &&
            error?.response?.data?.message
          ) {
            setErro({
              titulo: i18n.message(
                "dinamica.error.iniciar.titulo",
                "Erro ao iniciar atividade",
              ),
              mensagem: error.response.data.message,
              voltarAgenda: true,
            });
            return;
          }
          setErro({
            titulo: i18n.message(
              "dinamica.error.iniciar.titulo",
              "Erro ao iniciar atividade",
            ),
            mensagem: i18n.message(
              "dinamica.error.iniciar.mensagem",
              "Entre em contato com o suporte ou tente novamente mais tarde.",
            ),
            voltarAgenda: true,
          });
        }
      },
    );
  };

  const atualizarAgenda = () => {
    agendaService.atualizarAgenda(
      codigo,
      codigoAtividade,
      "FACT_OR_FAKE",
      fatoFake?.questions.length,
      questoesRespondidasCorretamente,
      (error, sucesso) => {
        if (error) {
          console.error("erro atualizar agenda");
          setErro({
            titulo: i18n.message(
              "dinamica.erro.jaexecutada.titulo",
              "Erro ao exibir atividade já executada",
            ),
            mensagem: i18n.message(
              "dinamica.erro.jaexecutada.mensagem",
              "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
            ),
            voltarAgenda: true,
          });
        }
      },
    );
  };

  const redirecionar = () => {
    setIndiceFatoFake(0);
    setApprove(true);
    setResponseFatoFake(null);
    setAlcancouPontuacaoMinima(null);

    setErro(null);
    setFatoFake();
    setDisabled(false);
    setAtividade();
    setQuestoesRespondidasCorretamente(0);
    setEarnedPoints(null);

    iniciarAtividade();
  };

  const proximoFatoFake = () => {
    let indice = indiceFatoFake + 1;
    if (indice === fatoFake.length) {
      return;
    }
    if (fatoFake.questions.length > indice + 1) {
      loaderService.load(fatoFake.questions[indice + 1].picture);
    }
    setIndiceFatoFake(indice);
    setResponseFatoFake(null);
    responderFatoFake(null, fatoFake?.questions[indice]?.uuid);
  };

  const responderFatoFake = (
    resposta,
    questionUuid,
    entrouNaPagina = false,
  ) => {
    if (!disabled) {
      if (!entrouNaPagina) {
        setDisabled(true);
      }
      let request = {
        activity: codigoAtividade,
        question: questionUuid,
        factOrFake: resposta,
      };

      fatoFakeService.responderQuestaoFF(request, (error, sucesso) => {
        if (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setDisabled(false);
            if (
              error.response.data.message ===
              "Esta questão já foi respondida. Por favor, responda a próxima questão."
            ) {
              if (error.response.data.question) {
                const indiceProximaQuestao = encontrarIndicePorUuid(
                  error.response.data.question,
                  atividade?.factOrFake?.questions,
                );
                if (indiceProximaQuestao !== -1) {
                  loaderService.load(
                    atividade?.factOrFake?.questions[indiceProximaQuestao]
                      .picture,
                  );
                  setIndiceFatoFake(indiceProximaQuestao);
                  setResponseFatoFake(null);
                  responderFatoFake(
                    null,
                    atividade?.factOrFake?.questions[indiceProximaQuestao]
                      ?.uuid,
                  );
                  return;
                } else {
                  console.error("Erro ao encontrar a questão pelo UUID");
                  setErro({
                    titulo: i18n.message(
                      "dinamica.erro.responder.titulo",
                      "Erro ao processar a resposta",
                    ),
                    mensagem: i18n.message(
                      "dinamica.erro.responder.quertion.null",
                      "Não foi possivel encontrar a proxima pergunta !",
                    ),
                    voltarAgenda: true,
                  });
                }
              }
              console.error("erro ao responder");
              setErro({
                titulo: i18n.message(
                  "dinamica.erro.responder.titulo",
                  "Erro ao processar a resposta",
                ),
                mensagem: i18n.message(
                  "dinamica.erro.responder.question.null",
                  "Não foi enviada a proxima questão!",
                ),
                voltarAgenda: true,
              });
            } else {
              setErro({
                titulo: i18n.message(
                  "dinamica.erro.responder.titulo",
                  "Erro ao processar a resposta",
                ),
                mensagem: i18n.message(
                  "dinamica.erro.responder.mensagem",
                  error.response.data.message,
                ),
                voltarAgenda: true,
              });
              setDisabled(false);
            }

            return;
          }
          console.log(error);
          console.error("erro linha 318");
          setErro({
            titulo: i18n.message(
              "dinamica.erro.iniciar.titulo",
              "Erro ao iniciar atividade",
            ),
            mensagem: i18n.message(
              "dinamica.erro.iniciar.mensagem",
              "Entre em contato com o suporte ou tente novamente mais tarde.",
            ),
            voltarAgenda: true,
          });
          setDisabled(false);
          return;
        }

        if (sucesso) {
          if (sucesso?.participantAnsweredCorrectly !== null) {
            // significa que o participante respondeu
            setResponseFatoFake(sucesso);
          }
          if (sucesso?.reachMinScore !== null) {
            // significa que o participante chegou ao final da tentativa
            setAlcancouPontuacaoMinima(sucesso?.reachMinScore);
            setQuestoesRespondidasCorretamente(sucesso?.correctAnswersCount);
            setEarnedPoints(sucesso?.earnedPoints);
          }
          atualizarAgenda();
          setDisabled(false);
        }
      });
    }
  };

  const ondeParouFatoFakeIndice = (qtFaltantes, ff) => {
    if (ff.questions.length !== qtFaltantes) {
      const indice = ff.questions.length - qtFaltantes;
      setIndiceFatoFake(indice);
      return indice;
    }
    return indiceFatoFake;
  };

  const encontrarIndicePorUuid = (uuid, questoes) => {
    return questoes.findIndex((q) => q.uuid === uuid);
  };

  const atualizarAgendaExecutada = () => {
    if (alcancouPontuacaoMinima !== null && !alcancouPontuacaoMinima) {
      setApprove(false);
      setIndiceFatoFake(0);
    } else {
      agendaService.atualizarAtividadeAgendaExecutada(codigoAtividade, true);

      navigate(`/missionFinished/${codigo}/FACT_OR_FAKE/${codigoAtividade}`, {
        state: {
          earnedPoints,
          questoesRespondidasCorretamente,
          atividade,
        },
      });
    }
  };

  const callbackModal = () => {
    setErro(null);
    navigate("/atividades");
  };

  return (
    <FatoFakeStyle>
      <div className="weex__desktopMargins">
        {atividadeConcluida ? (
          <WeexDesktopMode asHtmlTag="div">
            <BotaoAsALinkIconeEsquerda
              icon="fas fa-arrow-left "
              classeComplementar="header-como-funciona-desktop-botao"
              nome={i18n.message("geral.voltar", "Voltar")}
              ariaLabelMsg={i18n.message(
                "aria.label.geral.mensagem.voltar.atividades",
                "Volta para a página de atividades.",
              )}
              funcao={() => {
                navigate("/home");
              }}
            />
          </WeexDesktopMode>
        ) : (
          ""
        )}
        {erro != null && (
          <WeexModal
            fecharModalCallback={callbackModal}
            titulo={erro.titulo}
            conteudo={erro.mensagem}
          />
        )}
        {!approve ? (
          <WeexModalRedirect
            fecharCallbackModal={callbackModal}
            redirecionar={redirecionar}
            campanhaCorrente={campanhaCorrente}
            scoreTextual={`${questoesRespondidasCorretamente} ${i18n.message(
              "missaoCumprida.pontuacao.perguntas.de",
              "de",
            )} ${fatoFake?.questions?.length} (${parseInt(
              100 *
                (questoesRespondidasCorretamente / fatoFake?.questions?.length),
            )}%)`}
          />
        ) : (
          ""
        )}
        <div style={{ width: "100%" }}>
          {fatoFake != null && (
            <div className="weex-l-dinamica-FatoFake-content">
              <div className="weex-l-dinamica-FatoFake-content__titulo">
                <DinamicasHeader
                  title={fatoFake?.title || fatoFake?.name}
                  dinamicaNome={i18n.message(
                    "dinamica.fatofake.nome",
                    "Fato ou Fake?",
                  )}
                  dinamicaAlt={i18n.message(
                    "alt.dinamica.icone.fatofake",
                    "Ícone de uma mão escolhendo entre o certo e o errado.",
                  )}
                  dinamicaImg={
                    location && location.state && location.state.icon
                      ? location.state.icon
                      : "/fatooufake.png"
                  }
                />
              </div>

              {atividadeConcluida ? (
                <div className="weex-l-dinamica-FatoFake-content__card-container-tamanho">
                  <CardFatoFakeRevisao
                    questoes={fatoFake?.questions}
                    respostas={fatoFake?.factFakeAnswers}
                    onConcluir={() => navigate("/atividades")}
                  />
                </div>
              ) : (
                <div className="weex-l-dinamica-FatoFake-content__card-container-tamanho">
                  {responseFatoFake != null ? (
                    <CardRespostaFatoFake
                      proximoFatoFake={proximoFatoFake}
                      response={responseFatoFake}
                      ultimo={indiceFatoFake >= fatoFake?.questions?.length - 1}
                      finalizarAtividade={atualizarAgendaExecutada}
                      disabled={disabled}
                    />
                  ) : (
                    <CardFatoFake
                      questao={fatoFake.questions[indiceFatoFake]}
                      responderFatoFake={responderFatoFake}
                      disabled={disabled}
                    />
                  )}
                  <MarcadorProgresso
                    indiceAtual={indiceFatoFake}
                    totalDeItems={fatoFake.questions.length}
                  />
                  {atividadeConcluida ? (
                    <WeexMobileMode
                      asHtmlTag="div"
                      className="weex-l-dinamica-FatoFake-content__voltar-respiro"
                    >
                      <BotaoAsALink
                        nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                        funcao={() => navigate("/")}
                      />
                    </WeexMobileMode>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </FatoFakeStyle>
  );
}

export default FatoFake;

import React from "react";

function BurguerMobile({ alt }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={alt}
    >
      <path
        d="M5.14286 0H0.857143C0.383756 0 0 0.383756 0 0.857143V5.14286C0 5.61624 0.383756 6 0.857143 6H5.14286C5.61624 6 6 5.61624 6 5.14286V0.857143C6 0.383756 5.61624 0 5.14286 0Z"
        fill="white"
      />
      <path
        d="M13.1429 0H8.85714C8.38376 0 8 0.383756 8 0.857143V5.14286C8 5.61624 8.38376 6 8.85714 6H13.1429C13.6162 6 14 5.61624 14 5.14286V0.857143C14 0.383756 13.6162 0 13.1429 0Z"
        fill="white"
      />
      <path
        d="M5.14286 8H0.857143C0.383756 8 0 8.38376 0 8.85714V13.1429C0 13.6162 0.383756 14 0.857143 14H5.14286C5.61624 14 6 13.6162 6 13.1429V8.85714C6 8.38376 5.61624 8 5.14286 8Z"
        fill="white"
      />
      <path
        d="M13.1429 8H8.85714C8.38376 8 8 8.38376 8 8.85714V13.1429C8 13.6162 8.38376 14 8.85714 14H13.1429C13.6162 14 14 13.6162 14 13.1429V8.85714C14 8.38376 13.6162 8 13.1429 8Z"
        fill="white"
      />
    </svg>
  );
}

export default BurguerMobile;

import React from "react";
import { PagesHeaderStyle } from "./style";

/**
 * A reusable component for displaying a page header with title, subtitle, and an optional second subtitle.
 *
 * @function PagesHeader
 * @param {Object} props - The properties of the component.
 * @param {string} props.title - The main title of the page header.
 * @param {string} props.subtitle - The first subtitle of the page header.
 * @param {string} [props.subtitleSecondPart] - The optional second subtitle of the page header.
 * @returns {JSX.Element} - A JSX element representing the page header.
 */
function PagesHeader({ title, subtitle, subtitleSecondPart }) {
  return (
    <PagesHeaderStyle id="page-header">
      <h1 className="pageHeader__title">{title}</h1>
      <p className="pageHeader__subtitle">{subtitle}</p>
      {subtitleSecondPart && (
        <p className="pageHeader__subtitle">{subtitleSecondPart}</p>
      )}
    </PagesHeaderStyle>
  );
}

export default PagesHeader;

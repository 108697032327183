import instance from "./Api";
import UsuarioService from "./UsuarioService";

export default class ExtraService {
  /**
   * Obtém o conteúdo extra para um participante e atividade específicos.
   *
   * @param {string} participant - O UUID do participante.
   * @param {string} atividade - O UUID da atividade.
   * @returns {Promise} Uma Promise contendo o resultado da requisição HTTP.
   */
  getConteudoExtra(atividade) {
    let usuarioService = new UsuarioService();
    let participantCode = usuarioService?.getCodigoParticipante();
    if (!participantCode) {
      console.error("Codigo do participante não encontrado em ExtraService");
    }
    // eslint-disable-next-line max-len
    const url = `/api/v1/campaign/schedule/${participantCode}/extra/${atividade}`;
    return instance.get(`${this.host()}${url}`, { responseType: "blob" });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}

import React from "react";
import { PontuacaoEquipeStyle } from "./PontuacaoEquipeStyle";
import { useSelector } from "react-redux";
import TeamLogoListagem from "../team/LogoEquipeListagem";

function PontuacaoEquipe({
  name: nomeEqipe,
  score: pontuacao,
  position: colocacao,
  uuid,
}) {
  const i18n = useSelector((state) => state.i18n);
  const pontuacaoFormatada = pontuacao.toLocaleString("pt-BR");
  return (
    <PontuacaoEquipeStyle>
      <div className="team_on_ranking">
        <div className="ranking__position__name">
          <div className="team__position">{colocacao}°</div>
          <div className="team__name">
            <div className="team__imageContainer">
              <TeamLogoListagem uuid={uuid} />
            </div>

            <span className="team_nomeEquipe">{nomeEqipe}</span>
          </div>
        </div>
        <div className="ranking__score">
          <h4>{pontuacaoFormatada}</h4>{" "}
          <span> {i18n.message("geral.pts", "pts")} </span>{" "}
        </div>
      </div>
    </PontuacaoEquipeStyle>
  );
}

export default PontuacaoEquipe;

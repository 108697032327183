import React from "react";
import { WeexInput, Label, Input } from "./style";

/**
 * Creates a new Weex input component.
 * @param {string} type - The type of input, such as "text", "number", "password", etc.
 * @param {string} name - The name of the input.
 * @param {string} id - The unique ID of the input.
 * @param {string} placeholder - The placeholder text for the input.
 * @param {string} label - The label text for the input.
 * @param {boolean} [required=true] - Whether the input is required or not.
 * @param {boolean} [disabled=false] - Whether the input is disabled or not.
 * @param {function} onChange - A function that is called when the input value changes.
 * @param {string} icon - The icon to display next to the input.
 * @param {string} iconposition - The position of the icon relative to the input. Can be "left" or "right".
 * @returns {JSX.Element} The Weex input component.
 */
function WeexSimplesInput({
  key,
  type,
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  icon,
  iconposition = "left",
  pattern,
  onInvalid,
  ariaLabel,
  onKeyPress,
}) {
  const className =
    iconposition === "right"
      ? "weexInput__icon weexInput__icon--right"
      : "weexInput__icon";

  return (
    <WeexInput key={key}>
      <Label htmlFor={id} aria-label={ariaLabel}>
        {label}
        <Input
          id={id}
          type={type}
          name={name}
          pattern={pattern}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
          $isright={iconposition === "right" ? "true" : "false"}
          onInvalid={onInvalid}
          onKeyPress={onKeyPress}
        />
        {icon && (
          <div className={`${className}`}>
            <i className={icon}></i>
          </div>
        )}
      </Label>
    </WeexInput>
  );
}

export default WeexSimplesInput;

import styled from "styled-components";

/**
 * Barra de força do componente senha
 */
export const PasswordStrengthBar = styled.div`
  font-size: 0.625rem;
  color: ${(props) => {
    return props.theme.textosCorFundoSecundario;
  }};
  width: 100% !important;
  height: 0.313rem;
  background-color: #e0e0de;
  position: absolute;
  top: 100%;
  border-radius: 0px 0px 0.75rem 0.75rem;

  .passwordStrengthBar__fill {
    width: 100%;
    height: 100%;
    color: #000;
    border-radius: 0px 0px 0.75rem 0.75rem;
  }

  .passwordStrengthBar__icon:hover {
    box-shadow: inset 0 0 0.313rem rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
`;

/**
 * Conteiner do componente Input
 */
export const WeexInput = styled.div`
  width: 100%;
  font-family: DM Sans;
  position: relative;

  margin-bottom: ${(props) => {
    if (props.$strong) {
      return "2.5rem";
    }
  }};

  .weexInput__disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  .weexInput__icon {
    padding-left: 0.625rem;
    position: absolute;
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    display: flex;
    color: ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};
    flex-direction: ${(props) => {
      if (props.$isright === "true") {
        return "row-reverse";
      } else {
        return "row";
      }
    }};
  }

  .weexInput__icon--right {
    padding-left: 0px;
    right: 1%;
    padding-right: 0.625rem;
  }

  .weexInput__checkbox {
    appearance: auto;
  }

  .weexInput__checkbox--label {
    cursor: pointer;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2rem;

    font-size: 0.875rem;
  }

  .weexInput__file {
    display: none;
  }

  .weexInput__maxText {
    font-size: 0.938rem;
    margin-top: 0.2rem;
    text-align: right;
    color: #7c7c7c;
    font-weight: 400;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin-bottom: ${(props) => {
      if (props.$strong) {
        return "1rem";
      }
    }};
  }
  /* #endregion DESKTOP */
`;

/**
 * Label dos componentes Inputs
 */
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: left;
`;

/**
 * Input dos componentes
 */
export const Input = styled.input`
  background-color: #f8f8f8;
  border-radius: 0.75rem;
  border: 0.125rem solid #f3f3f3;
  padding: 1rem;
  padding-left: ${(props) => {
    if (props.$isright === "true") {
      return "1rem";
    } else {
      return "2rem";
    }
  }};

  ::placeholder {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.225rem;
    text-align: left;
    color: #7c7c7c;
  }

  &:disabled {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &:disabled&::placeholder {
    color: #efefef;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    &:focus,
    &:hover,
    &:active {
      border: 0.125rem solid
        ${(props) => {
          if (props.$backgroundispresent) {
            return props.theme.fundoTres;
          } else {
            return props.theme.fundoSecundario || `#533888`;
          }
        }};
    }
  }
  /* #endregion DESKTOP */
`;

/**
 * Select dos componentes
 */
export const Select = styled.select`
  appearance: auto;
  height: 3.5rem;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 0.75rem;
  border: 0.125rem solid #f3f3f3;
  padding: 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.225rem;
  text-align: left;
  color: #7c7c7c;

  &:focus,
  &:hover,
  &:active {
    border: 0.125rem solid
      ${(props) => {
        if (props.$backgroundispresent) {
          return props.theme.fundoTres;
        } else {
          return props.theme.fundoSecundario || `#533888`;
        }
      }};
  }

  &:disabled {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &:disabled&::placeholder {
    color: #efefef;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

/**
 * Textarea dos componentes
 */
export const Textarea = styled.textarea`
  resize: none;
  background-color: #f8f8f8;
  border-radius: 0.75rem;
  border: 0.125rem solid #f3f3f3;
  padding: 1rem;

  &::placeholder {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.225rem;
    text-align: left;
    color: #7c7c7c;
  }

  &:disabled {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &:disabled&::placeholder {
    color: #efefef;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    &:focus,
    &:hover,
    &:active {
      border: 0.125rem solid
        ${(props) => {
          if (props.$backgroundispresent) {
            return props.theme.fundoTres;
          } else {
            return props.theme.fundoSecundario || `#533888`;
          }
        }};
    }
  }
  /* #endregion DESKTOP */
`;

/**
 * Input File
 */
export const File = styled.div`
  background-color: #f8f8f8;
  border-radius: 0.75rem;
  border: 0.125rem solid #f3f3f3;
  padding: 1rem;
  padding-left: ${(props) => {
    if (props.$isright === "true") {
      return "1rem";
    } else {
      return "2rem";
    }
  }};

  &::placeholder {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.225rem;
    text-align: left;
    color: #7c7c7c;
  }

  &:disabled {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &:disabled&::placeholder {
    color: #efefef;
  }

  .weexInput__label {
    padding-left: 1.4em;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    &:focus,
    &:hover,
    &:active {
      cursor: pointer;
      border: 0.125rem solid
        ${(props) => {
          if (props.$backgroundispresent) {
            return props.theme.fundoTres;
          } else {
            return props.theme.fundoSecundario || `#533888`;
          }
        }};
    }
  }
  /* #endregion DESKTOP */
`;

export const RankingInputSearchStyle = styled(Input)`
  background-color: #f3f3f3;
`;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UsuarioService from "../../services/UsuarioService";
import Navbar from "./navbar/Navbar";
import { useLocation } from "react-router-dom";
import { WeexHeaderStyle } from "./HeaderStyle";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import CampaignInformations from "../campanha/CampaignInformations";
import CampanhaService from "../../services/CampanhaService";

function Header() {
  const usuario = useSelector((state) => state.usuario);
  const usuariologado = useSelector((state) => state.usuariologado);
  const isLogged = usuariologado && usuario === true;
  const campanhaService = new CampanhaService();
  let campanhaCorrente = campanhaService.campanhaCorrente();
  let location = useLocation();
  const dispatch = useDispatch();
  let now = new Date().getTime();
  let sair = () => {
    dispatch({ type: "usuario", payload: false });
    new UsuarioService().logout(() => {
      window.location = "/";
    });
  };

  if (location.pathname === "/accept") {
    return null;
  }

  return (
    <>
      {isLogged ? (
        <>
          <WeexHeaderStyle id="header__weex" className="weex__container">
            <Navbar now={now} sair={sair} />
          </WeexHeaderStyle>
          <WeexMobileMode>
            {campanhaCorrente?.titleVisible && <CampaignInformations />}
          </WeexMobileMode>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Header;

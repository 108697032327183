import styled from "styled-components";

export const PollOptionStyle = styled.div`
  position: relative;
  flex-grow: 2;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  //margin-left: 0.313rem; //5px;

  width: 100%;
  max-height: 4.688rem;

  label {
    padding: 0px;
    margin: 0px;
  }

  .pollOption {
    cursor: pointer;
    flex-grow: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.063rem solid #d9d9d9;
    border-radius: 0.25rem; // 4px;
    padding: 0.688rem 0.313rem 1rem 0.625rem; //11px 5px 16px 10px

    position: relative;
    z-index: 2;
  }

  .pollOption__percentageBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    /* width: ${(props) => {
      if (props.$percent) {
        return props.$percent;
      } else {
        return "0%";
      }
    }}; */
    height: 100%;
    border-radius: 0.25rem; // 4px;
    background-color: #c5dff8;
    transition: width 1s ease-in-out;
    z-index: 1;
  }

  @keyframes grow {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .pollOption__checkbox {
    position: relative;
    display: inline-block;
  }

  .pollOption__checkbox input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .pollOption__container {
    display: flex;
    gap: 1.5rem; // 24px
    align-items: center;
  }

  .pollOption__checkbox--statement {
    margin-top: 0.313rem; //5px;
    margin-left: ${(props) => {
      if (!props.$executed && props.$status === "ABERTA") {
        return "1rem";
      } else {
        return "0px";
      }
    }};
  }

  .pollOption__badge {
    width: 1.75rem; // 28px;
    padding-left: 0.188rem; // 3px;
    padding-right: 0.188rem; // 3px;
    border-radius: 0.25rem; //4px;
    background-color: #d2f4e0;
  }

  .pollOption__badge--participant {
    font-size: 0.688rem; //11px;
    font-weight: 700;
    text-align: center;
    color: #005925;
  }

  .pollOption__checkbox--icon {
    width: 1rem;
    height: auto;
  }

  .pollOption__checkbox--container {
    width: 1.5rem; // 24px;
    height: 1.5rem; // 24px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 0.188rem solid #d9d9d9;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pollOption__checkbox--container .pollOption__checkbox--icon {
    opacity: 0;
    transition: opacity 0.4s;
  }

  input:checked + .pollOption__checkbox--container {
    background-color: #005925;
    border-color: #005925;
  }

  input:checked + .pollOption__checkbox--container .pollOption__checkbox--icon {
    opacity: 1;
  }

  input:focus + .pollOption__checkbox--container {
    box-shadow: 0 0 0.063rem #005925;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .pollOption:hover {
      background-color: #ededed;
    }

    .pollOption,
    .pollOption__percentageBG,
    .pollOption__badge {
      border-radius: 0.375rem; //6px;
    }

    .pollOption__badge {
      padding-left: 0.375rem; // 6px;
      padding-right: 0.375rem; // 6px;
    }

    .pollOption__badge--participant {
      font-size: 0.875rem; // 14px;
    }
  }
  /* #endregion DESKTOP */
`;

export const PollVotesStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;

  .pollVotes__percentage {
    color: #201d1d;
    font-size: 1.5rem; // 24px;
    font-weight: 900;
    line-height: 1.379rem; //22.07px;
    letter-spacing: -0.04em;
    text-align: center;
    small {
      font-size: 0.75rem; // 12px;
      font-weight: 900;
      line-height: 0.648rem; // 10.37px;
      letter-spacing: -0.04em;
      text-align: center;
    }
  }

  .pollVotes__count {
    color: #201d1d;
    font-size: 0.75rem; //12px;
    line-height: 1.379rem; //22.07px;
    letter-spacing: -0.04em;
    text-align: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .pollVotes__percentage {
      color: #201d1d;
      font-size: 1.5rem; // 24px;
      font-weight: 900;
      line-height: 1.379rem; //22.07px;
      letter-spacing: -0.04em;
      text-align: center;
      small {
        font-size: 0.875rem; // 14px;
        line-height: 0.972rem; // 15.55px;
      }
    }

    .pollVotes__count {
      font-size: 0.875rem; // 14px;
      line-height: 0.972rem; // 15.55px;
    }
  }
  /* #endregion DESKTOP */
`;

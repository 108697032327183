import styled from "styled-components";

export const WeexCheckExecutadoStyle = styled.div`
  opacity: ${(props) => {
    return props.$executado ? 1 : 0;
  }};
  position: relative;
  top: ${(props) => {
    return props.$top ? props.$top : 0;
  }};
  right: ${(props) => {
    return props.$right ? props.$right : 0;
  }};
  bottom: ${(props) => {
    return props.$bottom ? props.$bottom : 0;
  }};
  left: ${(props) => {
    return props.$left ? props.$left : 0;
  }};
`;

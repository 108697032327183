import React from "react";
import CampanhaService from "../../services/CampanhaService";
import { CampaignInformationStyle } from "./style";
const campanhaService = new CampanhaService();

function CampaignInformations() {
  let campanhaCorrente = campanhaService.campanhaCorrente();
  let title = "";
  let campanhaName = "";
  let titleVisible;
  if (campanhaCorrente) {
    titleVisible = campanhaCorrente.titleVisible;
    // #region DESKTOP
    title = campanhaCorrente.title;
    campanhaName = campanhaCorrente.subtitle || campanhaCorrente.name;
    // #endregion DESKTOP
  }

  return (
    (campanhaName || title) &&
    titleVisible && (
      <CampaignInformationStyle>
        {campanhaName && titleVisible ? (
          <h4
            dangerouslySetInnerHTML={{ __html: campanhaName }}
            className="campaignInformation__title campaignInformation__textTitle"
          ></h4>
        ) : (
          ""
        )}
        {title ? (
          <span
            dangerouslySetInnerHTML={{ __html: title }}
            className="campaignInformation__subtitle campaignInformation__textTitle"
          ></span>
        ) : (
          ""
        )}
      </CampaignInformationStyle>
    )
  );
}

export default CampaignInformations;

import React, { useEffect, useMemo } from "react";
import { useSelector, useStore, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BotaoAsALink from "../../comps/botaoAsALink/BotaoAsALink";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import { ComoFuncionaStyle } from "./style";

function ComoFunciona() {
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const campanhaService = useMemo(() => new CampanhaService(), []);
  const campanhaCorrente = campanhaService.campanhaCorrente();
  const isDesktopMode = useSelector((state) => state.isDesktopMode);

  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
    const sincronizarCampanha = async () => {
      try {
        await new CampanhaService().useSincronizarCampanha();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanha();
    let temaService = new TemaService(new CampanhaService().campanhaCorrente());
    temaService.aplicarTema("HOME");
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let now;
  let beginDate;
  let logoCentro = "";
  if (campanhaCorrente) {
    if (campanhaCorrente.startDate) {
      let dtStart = campanhaCorrente.startDate.split(" ");
      let dateStart = dtStart[0].split("/");
      let hourStart = dtStart[1].split(":");
      beginDate = new Date(
        dateStart[2],
        dateStart[1] - 1,
        dateStart[0],
        hourStart[0],
        hourStart[1],
        hourStart[2],
      );
      now = new Date();
    }

    if (!campanhaCorrente.topLeftDesktop) {
      logoCentro = "";
    } else {
      logoCentro = campanhaCorrente.topLeftDesktop;
    }
  }

  let i18nmsg = "";
  if (beginDate != null && now != null && now >= beginDate) {
    i18nmsg = i18n.message(
      "aria.label.geral.mensagem.voltar.atividades",
      "Volta para a página de atividades.",
    );
  } else {
    i18nmsg = i18n.message(
      "aria.label.geral.mensagem.voltar.espera",
      "Volta para a página de espera da campanha.",
    );
  }

  return (
    <ComoFuncionaStyle logoCentro={logoCentro}>
      {isDesktopMode ? (
        <div className="header-como-funciona">
          <div className="header-como-funciona-voltar">
            <div>
              <BotaoAsALinkIconeEsquerda
                icon="fas fa-arrow-left "
                classeComplementar="header-como-funciona-desktop-botao"
                nome={i18n.message("geral.voltar", "Voltar")}
                ariaLabelMsg={i18nmsg}
                funcao={() => {
                  if (beginDate != null && now != null && now >= beginDate) {
                    navigate("/atividades");
                  } else {
                    navigate("/home");
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="weex-como-funciona">
        <div
          dangerouslySetInnerHTML={{
            __html: campanhaCorrente.comoFunciona.helpMessage,
          }}
        ></div>
      </div>
      {!isDesktopMode ? (
        <div style={{ marginTop: "3em", marginBottom: "2em" }}>
          <BotaoAsALink
            nome={i18n.message("geral.voltar", "Voltar")}
            ariaLabelMsg={i18nmsg}
            funcao={() => {
              if (beginDate != null && now != null && now >= beginDate) {
                navigate("/atividades");
              } else {
                navigate("/home");
              }
            }}
          />
        </div>
      ) : (
        ""
      )}
    </ComoFuncionaStyle>
  );
}

export default ComoFunciona;

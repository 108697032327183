import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import UsuarioService from "../../services/UsuarioService";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 *
 * @returns Seleciona uma campanha pelo params ou pelo subdominio da URL fornecida pelo participante.
 * Caso não encontre a campanha, redireciona para a página de seleção de campanha manual.
 */
function SelecaoDeCampanhaAutomatica() {
  const service = new CampanhaService();
  const usuarioService = new UsuarioService();
  const [processando, setProcessando] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { code } = useParams();

  // Caso seja convite de campanha
  const query = useQuery();
  const weexInvite = query.get("weexInvite");
  const dashboardStyles = query.get("dashboardStyles");

  useEffect(() => {
    usuarioService.removeUsuarioCorrente();
    dispatch({ type: "usuario", payload: false });
  });

  useEffect(() => {
    if (!processando) {
      setProcessando(true);
      let codeCampaign =
        service.verificaCodigoCampanhaPresenteParamsOuSubdominio(code);
      service.removeLocalStorageSeCampanhasForemDiferentes(code);
      service.definirCampanha(
        codeCampaign,
        { dashboardStyles },
        (erro, sucesso) => {
          if (erro) {
            navigate("/select");
            return;
          }
          if (sucesso) {
            dispatch({
              type: "campanha/selecionar",
              payload: sucesso.messages,
            });
            dispatch({ type: "campanha", payload: sucesso });
            dispatch({ type: "template", payload: sucesso.template });
            dispatch({ type: "icone", payload: sucesso.icons });
            dispatch({ type: "usuario", payload: false });
            if (weexInvite) {
              usuarioService.setConvite(weexInvite);
            }
            const dashboardStylesParams =
              query.get("dashboardStyles") === "true"
                ? "dashboardStyles=true"
                : "";
            navigate("/?" + dashboardStylesParams);
          }
        },
      );
    }
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return <></>;
}

export default SelecaoDeCampanhaAutomatica;

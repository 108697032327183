import React, { useEffect, useState } from "react";
import { useSelector, useStore, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import WeexModal from "../../comps/weexModais/WeexModal";
import CampanhaService from "../../services/CampanhaService";
import MensagemVideoService from "../../services/MensagemVideoService";
import TemaService from "../../services/TemaService";
import { VideoStyled } from "./videoStyled";
import BotaoVideo from "../../comps/botaoVideo/BotaoVideo";
import { VimeoVideo } from "../../comps/video/VimeoVideo";
import { Mp4Video } from "../../comps/video/MP4Video";
import { IframeVideo } from "../../comps/video/IframeVideo";

function MensagemVideo() {
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;

  let location = useLocation();

  const navigate = useNavigate();
  const mensagemVideoService = new MensagemVideoService();
  const [video, setVideo] = useState();
  const [mensagemDeVideoUuid, setMensagemDeVideoUuid] = useState();
  const [bloquearBotao, setBloquearBotao] = useState(true);
  const [processando, setProcessando] = useState(false);
  const [erro, setErro] = useState(null);
  const [vimeoCarregado, setVimeoCarregado] = useState(false);
  const [sizeScreen, setSizeScreen] = useState(window.innerWidth);
  const campanhaService = new CampanhaService();
  const isDesktopMode = useSelector((state) => state.isDesktopMode);

  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "rgb(243, 243, 248)" });
    if (!processando) {
      setProcessando(true);
      mensagemVideoService.obterMensagemDeVideo(
        (erroMensagemVideo, atividade) => {
          if (erroMensagemVideo) {
            if (
              erroMensagemVideo.response &&
              erroMensagemVideo.response.data &&
              erroMensagemVideo.response.data.message
            ) {
              setErro({
                titulo: i18n.message(
                  "mensagemvideo.erro.iniciar.titulo",
                  "Erro ao iniciar mensagem de vídeo",
                ),
                mensagem: erroMensagemVideo.response.data.message,
                voltarAgenda: true,
              });
            } else {
              setErro({
                titulo: i18n.message(
                  "mensagemvideo.erro.iniciar.titulo",
                  "Erro ao iniciar mensagem de vídeo",
                ),
                mensagem: i18n.message(
                  "mensagemvideo.erro.iniciar.mensagem",
                  "Entre em contato com o suporte ou tente novamente mais tarde.",
                ),
                voltarAgenda: true,
              });
            }
          }
          if (atividade) {
            if (
              atividade[0].video.url.indexOf("youtube") !== -1 ||
              atividade[0].video.url.indexOf("youtu.be") !== -1
            ) {
              let regex =
                /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
              let resultadoRegex = atividade[0].video.url.match(regex);
              if (resultadoRegex && resultadoRegex.length > 1) {
                atividade[0].video.urlParseada =
                  "https://www.youtube.com/embed/" +
                  resultadoRegex[1] +
                  "?mute=0&controls=2&origin=" +
                  window.location.origin +
                  "&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=0&fs=1&autohide=1&widget_referrer=" +
                  window.location.origin;
              }
            }
            setBloquearBotao(isVimeo(atividade[0].video));
            setMensagemDeVideoUuid(atividade[0].uuid);
            setVideo(atividade[0].video);
          }
        },
      );
    }
    let service = new TemaService(campanhaService.campanhaCorrente());
    service.aplicarTema("HOME");
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sizeOfThings = () => {
    return window.screen.width;
  };
  window.addEventListener("resize", function () {
    setSizeScreen(sizeOfThings());
  });

  const callbackModal = () => {
    setErro(null);
    if (erro.voltarAgenda) {
      navigate("/atividades");
    }
  };

  const videoCarregado = () => {
    setVimeoCarregado(true);
  };

  const timeUpdate = (event) => {
    if (event) {
      if (event.percent > 0.8) {
        setBloquearBotao(false);
      }
    }
  };

  const getVideo = () => {
    if (isVimeo(video)) {
      return (
        <VimeoVideo
          video={video}
          onReady={videoCarregado}
          onTimeUpdate={timeUpdate}
          sizeScreen={sizeScreen}
        />
      );
    } else if (video.url.indexOf("videos.weex.digital") !== -1) {
      return <Mp4Video video={video} />;
    } else {
      return <IframeVideo video={video} onLoad={videoCarregado} />;
    }
  };

  const isVideoCarregado = () => {
    return (
      vimeoCarregado ||
      (video && video.url.indexOf("videos.weex.digital") !== -1)
    );
  };

  const botaoBloqueado = () => {
    if (isVimeo()) {
      return bloquearBotao && isVimeo();
    } else {
      return bloquearBotao;
    }
  };

  const isVimeo = (videoParam) => {
    if (videoParam) {
      return (
        videoParam && videoParam.url && videoParam.url.indexOf("vimeo") !== -1
      );
    }
    return video && video.url && video.url.indexOf("vimeo") !== -1;
  };

  const videoClass = () => {
    if (isDesktopMode) {
      return "column is-offset-2";
    } else {
      return "mobile-video";
    }
  };

  const marcarComoVistoMensagem = () => {
    mensagemVideoService.marcarComoVisto(
      mensagemDeVideoUuid,
      (erroMensagemVideo, sucesso) => {
        if (erroMensagemVideo) {
          if (
            erroMensagemVideo.response &&
            erroMensagemVideo.response.data &&
            erroMensagemVideo.response.data.message
          ) {
            setErro({
              titulo: i18n.message(
                "mensagemvideo.erro.marcar.titulo",
                "Erro ao marcar mensagem de vídeo como visualizado",
              ),
              mensagem: erroMensagemVideo.response.data.message,
              voltarAgenda: false,
            });
          } else {
            setErro({
              titulo: i18n.message(
                "mensagemvideo.erro.marcar.titulo",
                "Erro ao marcar mensagem de vídeo como visualizado",
              ),
              mensagem: i18n.message(
                "mensagemvideo.erro.marcar.mensagem",
                "Entre em contato com o suporte ou tente novamente mais tarde.",
              ),
              voltarAgenda: false,
            });
          }
        }
        if (sucesso) {
          navigate("/home");
        }
      },
    );
  };

  let msgVideoInformacoesGerais = i18n.message(
    "aria.label.video.informacoes.gerais",
    "Para desbloquear o botão de concluir atividade é necessário assistir o vídeo até o final.",
  );

  const getButtonCompletar = () => {
    return (
      <div style={{ marginBottom: "2rem", marginTop: "2.5rem" }}>
        <BotaoVideo
          disabled={botaoBloqueado()}
          funcao={marcarComoVistoMensagem}
          nome={i18n.message("video.entendi", "Entendi")}
          classeComplementar={
            botaoBloqueado() ? "botao-video-desabilitado" : ""
          }
        />
      </div>
    );
  };

  return (
    <VideoStyled $sizeScreen={sizeScreen}>
      <div className="dinamica-content-desktop">
        {isDesktopMode && (
          <div className="desktop-video-voltar">
            <BotaoAsALinkIconeEsquerda
              icon="fas fa-arrow-left "
              classeComplementar="header-como-funciona-desktop-botao"
              nome={i18n.message("geral.voltar", "Voltar")}
              ariaLabelMsg={i18n.message(
                "aria.label.geral.mensagem.voltar.atividades",
                "Volta para a página de atividades.",
              )}
              funcao={() => {
                navigate("/home");
              }}
            />
          </div>
        )}
        {erro != null && (
          <WeexModal
            fecharModalCallback={callbackModal}
            titulo={erro.titulo}
            conteudo={erro.mensagem}
          />
        )}
        <div
          className={`${
            isDesktopMode
              ? "dinamica-container columns is-desktop is-centered"
              : ""
          }`}
        >
          <div
            id="informacoes-gerais"
            className="video-desktop-content-left column is-one-third"
            style={!isDesktopMode ? { paddingBottom: "0.75rem" } : {}}
          >
            <DinamicasHeader
              title={video?.title || video?.name}
              dinamicaNome={i18n.message("dinamica.video.nome", "Vídeo")}
              dinamicaAlt={i18n.message(
                "alt.dinamica.video",
                "Ícone de um player de vídeo.",
              )}
              dinamicaImg={
                location && location.state && location.state.icon
                  ? location.state.icon
                  : "/videos.png"
              }
              type="video"
            />
            <span className="weex__a11y--visuallyHidden">
              {msgVideoInformacoesGerais}
            </span>
            {isDesktopMode && isVideoCarregado() && getButtonCompletar()}
          </div>
          {video && video.url && (
            <div id="player-de-video" className="column is-desktop_video">
              <div className="columns is-centered">
                <div className={videoClass()}>{getVideo()}</div>
              </div>
            </div>
          )}
        </div>
        {!isDesktopMode && isVideoCarregado() && getButtonCompletar()}
      </div>
    </VideoStyled>
  );
}

export default MensagemVideo;

import React, { useEffect, useState, useRef } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import PollService from "../../services/PollService";
import { PollStyle } from "./style";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import MidiaEnquete from "../../comps/enquete/MidiaEnquete/MidiaEnquete";
import TotalInteracoes from "../../comps/enquete/totalInteracoes/TotalInteracoes";
import PollOption from "../../comps/enquete/pollCard/PollOption";
import AvisoEnquete from "../../comps/enquete/aviso/AvisoEnquete";
import WeexMobileMode from "../../comps/WeexMode/WeexMobileMode";
import WeexDesktopMode from "../../comps/WeexMode/WeexDesktopMode";

const pollService = new PollService();

/*

  ABERTA("ABERTA"),
  FINALIZADA("FINALIZADA"),
  NAO_INICIADA("NÃO INICIADA");

*/

export default function Poll() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const i18n = useStore().getState().i18n;
  let location = useLocation();

  const effectRan = useRef(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [codigoDinamica, setCodigoDinamica] = useState("");
  const [enquete, setEnquete] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [executed, setExecuted] = useState(false);

  useEffect(() => {
    //TODO - No modo de desenvolvimento do React 18, os componentes podem ser renderizados duas vezes para detectar efeitos colaterais inseguros.
    if (effectRan.current === false) {
      effectRan.current = true;
      if (location?.state?.codigoDinamica) {
        const codeDynamic = location?.state?.codigoDinamica;
        setCodigoDinamica(codeDynamic);
        getEnquete(codeDynamic);
      }
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa #18901 --> By Larissa
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEnquete = (codeDynamic) => {
    pollService.obtemEnquete(codeDynamic, (error, success) => {
      if (error) {
        if (error?.response?.data?.message) {
          setLoading(false);
          dispatch({ type: "SHOW_MODAL", payload: "error" });
          dispatch({
            type: "error",
            payload: {
              title: i18n.message(
                "enqueteCampanha.erro.title",
                "Erro ao obter enquete",
              ),
              message: error?.response?.data?.message,
            },
          });
          return;
        }
        setLoading(false);
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "enqueteCampanha.erro.title",
              "Erro ao obter enquete",
            ),
            message: i18n.message(
              "enqueteCampanha.erro.message",
              "Erro ao obter enquete. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            ),
          },
        });
        return;
      }
      setLoading(false);
      setEnquete(success);
      if (
        success?.pollAnsweredOptions !== null &&
        success?.pollAnsweredOptions.length > 0
      ) {
        setAnswers(success?.pollAnsweredOptions);
      }
      if (success?.executed !== null && success?.executed) {
        setExecuted(success?.executed);
      }
    });
  };

  const handleAlternativesChange = (alternative) => {
    setSelectedAnswers((prevSelected) => {
      if (prevSelected.includes(alternative.uuid)) {
        // Remove alternative if unselected
        alternative.checked = false;
        return prevSelected.filter((uuid) => uuid !== alternative.uuid);
      } else {
        alternative.checked = true;
        // Add alternative if selected
        if (enquete?.isMoreThanOneVote) {
          return [...prevSelected, alternative.uuid];
        }
        enquete.alternatives.forEach((other) => {
          other.checked = false;
        });
        alternative.checked = true;

        return [alternative.uuid];
      }
    });
  };
  const infoText = () => {
    if (enquete?.isMoreThanOneVote) {
      return i18n.message(
        "enquete.texto.selecioneMaisDeUma",
        "Selecione uma ou mais opções",
      );
    } else {
      return i18n.message("enquete.texto.selecioneUma", "Selecione uma opção");
    }
  };

  const submitVotes = () => {
    setDisable(true);
    setLoading(true);
    let request = {
      poll: codigoDinamica,
      alternative: selectedAnswers,
    };
    pollService
      .votarEnquete(request)
      .then((response) => {
        getEnquete(codigoDinamica);
        setLoading(false);
      })
      .catch((error) => {
        setSelectedAnswers([]);
        setAnswers([]);
        getEnquete(codigoDinamica);
        if (error?.response?.data?.message) {
          setLoading(false);
          setDisable(false);
          dispatch({ type: "SHOW_MODAL", payload: "error" });
          dispatch({
            type: "error",
            payload: {
              title: i18n.message(
                "enqueteCampanha.votar.erro.title",
                "Erro ao votar na enquete",
              ),
              message: error?.response?.data?.message,
            },
          });
          return;
        }
        setLoading(false);
        setDisable(false);
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "enqueteCampanha.votar.erro.title",
              "Erro ao votar na enquete",
            ),
            message: i18n.message(
              "enqueteCampanha.votar.erro.message",
              "Erro ao votar na enquete. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            ),
          },
        });
        return;
      });
  };

  const renderPollCards = () => {
    if (enquete?.alternatives !== null && enquete?.alternatives?.length > 0) {
      return (
        <div className="poll_card--container">
          {enquete?.alternatives?.map((alternative) => {
            let chooseByParticipant = false;
            if (answers?.length > 0) {
              chooseByParticipant = answers?.some(
                (item) => item === alternative?.uuid,
              );
            }
            return (
              <div className="poll__card" key={alternative?.uuid}>
                <MidiaEnquete media={alternative?.media} />
                <PollOption
                  status={enquete?.status}
                  chooseByParticipant={chooseByParticipant}
                  alternative={alternative}
                  handleAlternatives={handleAlternativesChange}
                  executed={executed}
                />
              </div>
            );
          })}
        </div>
      );
    }
  };

  if (loading) {
    return <WeexSpinner />;
  } else if (enquete?.status === "NÃO INICIADA") {
    return (
      <>
        <BotaoAsALinkIconeEsquerda
          icon="fas fa-arrow-left"
          nome={i18n.message("geral.voltar", "Voltar")}
          ariaLabelMsg={i18n.message(
            "aria.label.geral.mensagem.voltar.atividades",
            "Volta para a página de atividades.",
          )}
          funcao={() => {
            navigate("/atividades");
          }}
        />
        <AvisoEnquete />
      </>
    );
  } else {
    return (
      <>
        <BotaoAsALinkIconeEsquerda
          icon="fas fa-arrow-left"
          nome={i18n.message("geral.voltar", "Voltar")}
          ariaLabelMsg={i18n.message(
            "aria.label.geral.mensagem.voltar.atividades",
            "Volta para a página de atividades.",
          )}
          funcao={() => {
            navigate("/atividades");
          }}
        />
        <PollStyle>
          <WeexMobileMode>
            <h1 className="poll__title">{enquete?.title || enquete?.name}</h1>
            <div className="poll__description">
              <div dangerouslySetInnerHTML={{ __html: enquete?.description }} />
            </div>
          </WeexMobileMode>
          <WeexDesktopMode className={"poll__header--desktop"}>
            <div>
              <h1 className="poll__title">{enquete?.title || enquete?.name}</h1>
              <div className="poll__description">
                <div
                  dangerouslySetInnerHTML={{ __html: enquete?.description }}
                />
              </div>
            </div>
            <TotalInteracoes
              totalVotes={enquete?.totalVotes}
              executed={executed}
              status={enquete?.status}
            />
          </WeexDesktopMode>
          <MidiaEnquete media={enquete?.media} principalMedia={true} />
          <WeexMobileMode>
            <TotalInteracoes
              totalVotes={enquete?.totalVotes}
              executed={executed}
              status={enquete?.status}
            />
          </WeexMobileMode>

          {!executed && <p>{infoText()}</p>}

          {renderPollCards()}

          {!executed && enquete?.status === "ABERTA" ? (
            <div className="poll__btn--container">
              <PrimarioBtn
                id={"botao-votar-enquete"}
                nome={i18n.message("enqueteCampanha.btn.votar", "VOTAR!")}
                funcao={submitVotes}
                disabled={disable}
                disabledOnClick={disable}
                ariaDisabled={disable}
                ariaHidden={false}
                ariaLabelMsg={i18n.message(
                  "enqueteCampanha.btn.votar",
                  "VOTAR !",
                )}
                ariaStatusMessage={i18n.message(
                  "aria.enqueteCampanha.btn.votar",
                  "Botão votar clicado",
                )}
              />
            </div>
          ) : (
            ""
          )}
        </PollStyle>
      </>
    );
  }
}

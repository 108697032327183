import React from "react";
import { useStore } from "react-redux";
import { BotaoAtividadeStyle } from "./style";

/**
 * @param {Object} props
 * @param {number | string} props.chave
 * @param {boolean} props.habilitado
 * @param {string} props.imagemHabilitado
 * @param {string} props.imagemDesabilitado
 * @param {boolean} props.executado
 * @param {string} props.type
 * @param {React.CSSProperties} props.size
 * @param {function(event): void} props.navegar
 */
export default function BotaoAtividadeDia({
  chave,
  navegar,
  habilitado,
  imagemHabilitado,
  imagemDesabilitado,
  executado,
  size,
  type,
}) {
  return (
    <BotaoAtividadeStyle
      style={size}
      onClick={navegar}
      disabled={!habilitado}
      key={"button" + chave}
      $habilitado={habilitado}
      $executado={executado}
      $imagemHabilitado={imagemHabilitado}
      $imagemDesabilitado={imagemDesabilitado}
    >
      <Icone executado={executado} habilitado={habilitado} />
      <p className="botaoAtividade__textColor">{type}</p>
    </BotaoAtividadeStyle>
  );
}

function Icone({ executado, habilitado }) {
  const i18n = useStore().getState().i18n;

  const altImageAtividade = executado
    ? i18n.message("alt.atividade.completou", "completou")
    : i18n.message("alt.atividade.nao.completou", "Não completou");
  return (
    <div className="botaoAtividade__icone--container">
      {executado ? (
        <img
          className="botaoAtividade__icone--feito"
          src="/svgs-3.0/check_with_borders.svg"
          alt={altImageAtividade}
        />
      ) : (
        <>
          {habilitado ? (
            <div className="botaoAtividade__icone botaoAtividade__icone--habilitado botaoAtividade__icone--tamanho"></div>
          ) : (
            <div className="botaoAtividade__icone botaoAtividade__icone--desabilitado botaoAtividade__icone--tamanho"></div>
          )}
        </>
      )}
    </div>
  );
}

import React from "react";
import { useStore } from "react-redux";
import WeexTextarea from "../../comps/WeexInputs/WeexTextarea";

/**
 * @param {Object} props
 * @param {string} props.mensagem valor do formulário
 * @param {boolean} props.isDesktopMode se aplicativo esta no modo desktop ou não
 * @param {function(React.ChangeEventHandler<HTMLTextAreaElement): void} props.callback onChange onde será setada o valor da mensagem
 */
function Mensagem({ mensagem, callback, isDesktopMode }) {
  const i18n = useStore().getState().i18n;

  return (
    <div>
      <WeexTextarea
        id={"feedback-campanha"}
        value={mensagem}
        onChange={callback}
        rows="3"
        maxLength="250"
        ariaLabel={i18n.message(
          "aria.label.feedback.comentario",
          "campo para escrever um comentário (opcional).",
        )}
      />
    </div>
  );
}

export default Mensagem;

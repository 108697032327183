import React from "react";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/**
 * Componente de paginação para a lista de rankings.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {number} props.page - A página atual.
 * @param {number} props.numberPages - O número total de páginas.
 * @param {Function} props.paginaAnterior - Função para navegar para a página anterior.
 * @param {Function} props.proximaPagina - Função para navegar para a próxima página.
 * @param {Object} props.i18n - Objeto de internacionalização para mensagens de texto.
 * @returns {JSX.Element} O componente de paginação renderizado.
 */
const RankingPaginacao = ({
  page,
  numberPages,
  paginaAnterior,
  proximaPagina,
}) => {
  const i18n = useSelector((state) => state.i18n);
  return (
    <div className="buttom-page">
      <div className="buttom-page__buttom">
        <SecundarioBtn
          nome={
            <span className="flex justify-content-center align-content-center">
              <span>
                <i className="fas fa-chevron-left" />
              </span>
              <span>{i18n.message("btn.geral.anterior", "Anterior")}</span>
            </span>
          }
          ariaLabelMsg={i18n.message(
            "aria.label.geral.mensagem.pagina.anterior",
            "Página anterior.",
          )}
          disabledOnClick={false}
          funcao={paginaAnterior}
          disabled={page === 0}
        />
      </div>
      <div className="flex">
        <p>
          {numberPages === 0 ? 0 : page + 1}/{numberPages}
        </p>
      </div>
      <div className="buttom-page__buttom">
        <SecundarioBtn
          iconposition="right"
          nome={
            <span className="flex justify-content-center align-content-center">
              <span>{i18n.message("btn.geral.proximo", "Próxima")}</span>
              <span>
                <i className="fas fa-chevron-right" />
              </span>
            </span>
          }
          ariaLabelMsg={i18n.message("btn.geral.proximo", "Próxima página.")}
          disabledOnClick={false}
          funcao={proximaPagina}
          disabled={page === numberPages - 1 || numberPages === 0}
        />
      </div>
    </div>
  );
};

// Adicione propTypes para validação
RankingPaginacao.propTypes = {
  page: PropTypes.number.isRequired,
  numberPages: PropTypes.number.isRequired,
  paginaAnterior: PropTypes.func.isRequired,
  proximaPagina: PropTypes.func.isRequired,
};

export default RankingPaginacao;

import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Hair({ changeAvatar, currentValue }) {
  const i18n = useStore().getState().i18n;
  const unavailableColor =
    currentValue === "thick" || currentValue === "mohawk";
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.cabelo", "Cabelo")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          type="hairStyle"
          value="normal"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cabelo1",
            "Mullet selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.cabelo1", "Mullet")}
          avatarPart={"/svgs-3.0/avatar-parts/hair_normal.svg"}
        />
        <AvatarPartsButton
          type="hairStyle"
          value="womanLong"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cabelo4",
            "Longo selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.cabelo4", "Longo")}
          avatarPart={"/svgs-3.0/avatar-parts/hair_womanLong.svg"}
        />
        <AvatarPartsButton
          type="hairStyle"
          value="womanShort"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cabelo5",
            "Médio selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.cabelo5", "Médio")}
          avatarPart={"/svgs-3.0/avatar-parts/hair_womanShort.svg"}
        />
        <AvatarPartsButton
          type="hairStyle"
          value="thick"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cabelo2",
            "Curto selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.cabelo2", "Curto")}
          avatarPart={"/svgs-3.0/avatar-parts/hair_thick.svg"}
        />
        <AvatarPartsButton
          type="hairStyle"
          value="mohawk"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cabelo3",
            "Moicano selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.cabelo3", "Moicano")}
          avatarPart={"/svgs-3.0/avatar-parts/hair_mohawk.svg"}
        />
      </AvatarPartsContainerStyle>

      <h2>{i18n.message("aria.msg.avatar.cores", "Cores")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#F9C9B6"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor1",
            "Cor de pele rosa suave",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor1",
            "Cor de pele rosa suave selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#AC6651"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor2",
            "Cor de pele marrom suave",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor2",
            "Cor de pele marrom suave selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#77311D"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor3",
            "Cor de pele marrom",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor3",
            "Cor de pele marrom selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#9287FF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor4", "Lilás")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor4",
            "Lilás selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#6BD9E9"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor5", "Azul aqua")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor5",
            "Azul aqua selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#FC909F"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor6", "Rosa")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor6",
            "Rosa selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#F4D150"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor7", "Amarelo")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor7",
            "Amarelo selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#E0DDFF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor8", "Lilás suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor8",
            "Lilás suave selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#D2EFF3"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor9", "Azul aqua suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor9",
            "Azul aqua suave selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#FFEDEF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor10", "Rosa suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor10",
            "Rosa suave selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#FFEBA4"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor11", "Amarelo suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor11",
            "Amarelo suave selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#201d1d"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor12", "Preto")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor12",
            "Preto selecionado",
          )}
        />
        <AvatarPartsButton
          disabled={unavailableColor}
          type="hairColor"
          color="#ffffff"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor13", "Branco")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor13",
            "Branco selecionado",
          )}
        />
      </AvatarPartsContainerStyle>
      {unavailableColor && (
        <p>
          <small>
            {i18n.message(
              "aria.msg.avatar.corIndisponivel",
              "Não é possível mudar as cores desses cabelos.",
            )}
          </small>
        </p>
      )}
    </>
  );
}

import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
`;

export const ParticipantStyle = styled.ul`
  display: flex;
  align-items: center;
  /* flex-direction: row-reverse; */
  /* justify-content: space-between; */
  flex-direction: row;
  gap: 0.75rem; //12px

  .participant__avatarButton {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
  }
  .participant__avatar {
    width: 3.813rem;
    height: 3.813rem;
    border: ${(props) => {
      if (!props.$usuarioAvatar) {
        return "0.063rem solid #fe001aff";
      }
      return "none";
    }};
    background-color: ${(props) => {
      return props.theme.cardLogadoFundo + "!important";
    }};
  }

  .participant__avatarCreate {
    text-transform: uppercase;
    font-size: 0.625rem;
    position: absolute;
    background-color: #fe001aff;
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    color: white;
    font-weight: 700;
    letter-spacing: 0.013rem;
    border-radius: 0.25rem;
    animation: ${({ usuarioAvatar }) => (usuarioAvatar ? "" : pulseAnimation)}
      2s infinite ease-in-out;
  }

  .participant__welcome {
    color: ${(props) => {
      return props.theme.headerText || `rgb(239, 231, 102)`;
    }};
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    /* .participant__avatarCreate {
      right: auto;
      left: 1.75rem;
    } */
  }
  /* #endregion DESKTOP */
`;

import styled from "styled-components";

export const ConviteStyle = styled.div`
  display: flex;
  flex-direction: column;

  .weex-l-convite__descricao {
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.8rem;
  }

  .weex-l-convite__linkContainer {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 0.5rem;
    padding-top: 1rem;
    padding-right: 0.5rem;
    padding-bottom: 1rem;
    background-color: #ffffff;
    border: 1px solid
      ${(props) => {
        return props.theme.fundoSecundario || `#533888`;
      }};
    border-radius: 0.4rem;
    text-align: center;
    font-size: 0.7rem;
    letter-spacing: 0.01rem;

    p {
      color: ${(props) => {
        return props.theme.fundoSecundario || `#533888`;
      }};
      word-break: break-word;
    }
  }

  .weex-l-convite__listagem {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .weex-l-convite__listagem--subtitulo {
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
    font-size: 0.9rem;
  }

  .weex-l-convite__listagem--semConvites {
    font-size: 1.1rem;
    line-height: 2rem;
  }

  .weex-l-convite__botaoContainer {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .weex-l-tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 2rem;
    background-color: #00e291;
    color: #4a4a4a;
    font-weight: 700;
    font-size: 1rem;
  }

  .weex-l-tag__icone {
    font-size: 2rem;
    color: #ffffff;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin: 1vw 5vw; /** <--- @deprecated - será removido */
    flex-direction: row;
    justify-content: space-around;

    .weex-l-convite__informacoes {
      min-width: 25%;
    }

    .weex-l-convite__listagem {
      width: 46%;
    }

    .weex-l-convite__linkContainer {
      font-size: 0.9rem;
      margin-left: 5rem;
      margin-right: 5rem;
    }

    .weex-l-convite__botaoContainer {
      margin-left: 10rem;
      margin-right: 10rem;
    }

    .weex-l-convite__descricao {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    .weex-l-convite__listagem--subtitulo {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

    .weex-l-tag {
      margin-left: 5rem;
      margin-right: 5rem;
      font-size: 1.5rem;
    }
  }
  /* #endregion DESKTOP */
`;

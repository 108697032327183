import React from "react";
import { useStore } from "react-redux";
import CampanhaService from "../../services/CampanhaService";

function TermosCondicoes() {
  const i18n = useStore().getState().i18n;
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  const { code, privacyPolicy } = campanhaCorrente;
  let link = "";
  if (code === "rededor") {
    link = "https://weex.digital/termos-rede-dor/";
  } else if (code === "lorenzetti" || code === "lorenzettiteste") {
    link =
      "https://weex.digital/wp-content/uploads/2023/06/02-2023_06_01_Regulamento-Sem-Meio-Amb-Loren-2023_SP-rev-02-SP-e-MG.pdf";
  } else {
    if (privacyPolicy) {
      link = privacyPolicy;
    } else {
      link = "https://weex.digital/politica-de-privacidade-e-uso/";
    }
  }
  return (
    <>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <span aria-hidden="true">
          {i18n.message("registro.legal.termos", " Termos e Condições ")}
        </span>
        <span className="weex__a11y--visuallyHidden">
          {`
            ${i18n.message(
              "aceiteTermos.condicao",
              "Para acessar a campanha, é necessário ler e aceitar os",
            )}
            ${i18n.message("registro.legal.termos", " Termos e Condições ")}
            ${i18n.message("registro.legal.daweex", "da Weex.")}
            `}
        </span>
      </a>
    </>
  );
}

export default TermosCondicoes;

import styled from "styled-components";

export const FatoFakeStyle = styled.div`
  .weex-l-dinamica-FatoFake-content {
    display: flex;
    flex-direction: column;
  }

  .weex-l-dinamica-FatoFake-content__voltar-respiro {
    margin-bottom: 2rem;
  }

  .card,
  .card-content {
    background: transparent;
    box-shadow: none;
  }

  /* #region=============== CARDS FF =============== */

  .weex-l-dinamica-FatoFake-card {
    background: transparent;
    border-radius: 0.875rem;
    position: relative;
  }

  .weex-l-dinamica-FatoFake-card__imagem-container {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
    img {
      max-width: 90%;
    }
  }
  .weex-l-dinamica-FatoFake-card__imagem {
    max-width: 100%;
  }

  .weex-l-dinamica-FatoFake-card-content {
    padding: 1.5rem;
  }

  .weex-l-dinamica-FatoFake-card-content-respiro {
    min-height: 5vh;
    padding-bottom: 3vh;
    margin-top: 1rem;
    margin-bottom: 3vh;
  }

  .weex-l-dinamica-FatoFake-card-content__markup {
    p {
      text-align: center;
      color: #201d1d;
      font-weight: 400;
      font-size: 1.188rem;
      line-height: 1.5rem;
    }
    span {
      font-size: 1.188rem;
      line-height: 1.5rem;
    }
  }

  .weex-l-dinamica-FatoFake-card-botoes-container {
    display: flex;
    justify-content: space-around;
  }

  .weex-l-dinamica-FatoFake-card-botoes__container-icone {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
  }

  .weex-l-dinamica-FatoFake-card-botoes__container-icone-image {
    margin-right: 0.313rem;
    margin-left: 1rem;
  }

  .weex-l-dinamica-card-ff-resposta {
    border-radius: 0.875rem;
    position: relative;
  }

  .weex-l-dinamica-card-ff-resposta--respiro-superior {
    margin-top: -0.938rem;
  }

  .weex-l-dinamica-card-ff-resposta__acertou {
    background: rgb(0 226 145 / 10%);
    border: 0.125rem solid #00e291;
  }

  .weex-l-dinamica-card-ff-resposta__errou {
    background: #fff7f7;
    border: 0.125rem solid #fe001a;
  }

  .weex-l-dinamica-card-ff-resposta-tag {
    padding: 0.938rem 1.875rem;
    border-radius: 0.5rem;
    color: #ffffff;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    box-shadow: 0px 1.25rem 2.5rem -0.625rem rgb(119 119 119 / 23%);
  }

  .weex-l-dinamica-card-ff-resposta-tag--acertou {
    background: #00e291;
  }

  .weex-l-dinamica-card-ff-resposta-tag--errou {
    background: #fe001a;
  }

  .weex-l-dinamica-card-ff-resposta-tag-markup {
    padding: 1.875rem 1.875rem 0 1.875rem;

    p {
      margin-bottom: 1em;
      text-align: center;
      color: #201d1d;
      font-size: 1.188rem;
      line-height: 1.5rem;
    }
    span {
      font-size: 1.188rem;
      line-height: 1.5rem;
    }
  }

  .weex-l-FatoFakeRevisao-BotoesColumn {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  .weex-l-FatoFakeRevisao-BotoesRow {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
  }

  .weex-g-CardFatoFakeRevisao-BotaoContainer {
    width: 45%;
    margin-top: 1rem;
  }

  .weex-g-FatoFakeRevisao-botaoSecundarioSpanContainer {
    display: flex;
    justify-content: center;
    gap: 0.3rem;
  }

  .weex-l-FatoFakeRevisao-respiroBotaoVoltar {
    text-align: center;
    margin-top: 1rem;

    button {
      width: auto;
    }
  }

  /* #endregion=============== CARDS FF =============== */

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-dinamica-FatoFake-content__titulo {
      margin-top: 2vw;
      min-width: 25%;
    }
    //padding: 1.5rem;
    .weex-l-dinamica-FatoFake-content__titulo--respiro-superior-maior {
      margin-top: 20vh;
    }

    .weex-l-dinamica-FatoFake-content {
      flex-direction: row;
      gap: 10vw;
    }

    .weex-l-dinamica-FatoFake-content-respiro-superior {
      margin-top: 4em;
    }

    /* #region=============== CARDS FF =============== */
    .weex-l-dinamica-FatoFake-content__card-container-tamanho {
      width: 62.75%;
    }

    .weex-l-dinamica-FatoFake-card {
      padding: 1rem;
    }

    .weex-l-dinamica-FatoFake-card--sem-respiro-superior {
      padding-top: 0px;
    }

    .weex-l-dinamica-FatoFake-card--border {
      border: 0.063rem solid #cccccc;
    }

    .weex-l-dinamica-FatoFake-card--limitado {
      max-height: 75vh;
      overflow: auto;
    }

    .weex-l-dinamica-FatoFake-card__imagem {
      display: block;
      height: auto;
    }

    .weex-l-dinamica-FatoFake-card-botao-respiro {
      margin: 0 10vw;
    }
    /* #endregion=============== CARDS FF =============== */
  }
  /* #endregion DESKTOP */
`;

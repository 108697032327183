import React, { useEffect, useState } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import LeadershipRanking from "../../comps/rankings/LeadershipRanking";
import PontuacaoUsuarioCorrente from "../../comps/rankings/PontuacaoUsuarioCorrente";
import RankingListaParticipantes from "../../comps/rankings/RankingListaParticipantes";
import SelectTreeDropdow from "../../comps/selectTreeDropdow/SelectTreeDropdow";
import CampanhaService from "../../services/CampanhaService";
import RankingIndividualService from "../../services/RankingIndividualService";
import UsuarioService from "../../services/UsuarioService";
import { CampanhaRankingsStyled } from "./styled";

export default function CampanhaRankings({ isRenderedInSchedulePage = false }) {
  const i18n = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const campanhaService = new CampanhaService();
  const rankingIndividualService = new RankingIndividualService();
  const usuarioService = new UsuarioService();
  const usuario = usuarioService?.usuarioCorrente();
  const [participantFetched, setParticipantFetched] = useState(false);
  const unidades = campanhaService.getUnidades();
  const [loading, setLoading] = useState(false);
  const [rankings, setRankings] = useState([]);
  const [rankingEvent, setRankingEvent] = useState([]);
  const [rankingTop3, setRankingTop3] = useState([]);
  const [rankingWithFormat, setRankingWithFormat] = useState([]);
  const [pontuacaoUsuario, setPontuacaoUsuario] = useState({
    name: "",
    rating: null,
    unit: "",
    unitUuid: "",
    subUnitUuid: "",
    nivel3Uuid: "",
    uuidParticipante: "",
  });
  const [participant, setParticipant] = useState({
    uuid: "",
    name: "",
    score: 0,
    position: 0,
  });
  const [filtrosParaBuscar, setFiltrosParaBuscar] = useState({
    units: [],
  });

  useEffect(() => {
    getParticipant().finally(() => setParticipantFetched(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (participantFetched) {
      onComponenteMonte();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosParaBuscar, participantFetched]);

  async function onComponenteMonte() {
    try {
      if (!loading) {
        setLoading(true);
      }

      const topRanking = await getRankingParticipantes();
      setRankingEvent(topRanking.data.topRanking);
      init(topRanking.data.topRanking);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const getRankingParticipantes = async () => {
    return rankingIndividualService.getRankingParticipanteCampanha(
      filtrosParaBuscar,
    );
  };

  const usuarioEstaNoRanking = (() => {
    let encontrei = false;
    let uuidParticipante = usuarioService?.getCodigoParticipante();
    if (!uuidParticipante) {
      console.error("Codigo do participante não encontrado em CampanhaRanking");
    }
    rankingEvent.forEach((pontuacaoUsuario) => {
      if (pontuacaoUsuario.uuidParticipante === uuidParticipante) {
        encontrei = true;
      }
    });

    return encontrei && rankings?.length > 0;
  })();

  const init = (rank) => {
    let ranking = rank?.map((participant) => {
      return {
        rating: participant.score,
        name: participant.name,
        unit: participant.unit,
        uuidParticipante: participant.uuidParticipante,
        position: participant.position,
      };
    });
    setRankings(ranking);
    setRankingTop3(ranking.slice(0, 3));
    positionRankingParticipant(rank);
    formaterNumber(rank);
  };

  const positionRankingParticipant = (rank) => {
    let position = 0;
    rank.forEach((participante, index) => {
      if (participant.uuid === participante.uuidParticipante) {
        position = parseInt(index) + 1;
      }
    });
    setParticipant({ ...participant, position: position });
  };

  const formaterNumber = (rank) => {
    let ranking = [];
    rank != null &&
      rank.forEach((partipante) => {
        let participant = {
          rating: partipante.score,
          name: partipante.name,
          unit: partipante.unit,
          uuidParticipante: partipante.uuidParticipante,
          isCompind: true,
          position: partipante.position,
        };
        ranking.push(participant);
      });

    let rankingWithFormat = ranking.map((ranking) => {
      let position = parseInt(ranking.position);
      if (position < 10) {
        ranking.position = "0" + position;
      } else {
        ranking.position = position.toString();
      }

      return ranking;
    });

    setRankingWithFormat(rankingWithFormat);
  };

  const getParticipant = () => {
    setLoading(true);
    return rankingIndividualService
      .getPontuacaoParticipanteCampanha()
      .then((participante) => {
        let unitParticipante = unidades.find(
          (unit) => unit.uuid === usuario.unit,
        );
        let subUnidadeParticipante = buscarElementosNasUnidades(
          unidades,
          usuario.subUnit,
        );
        let nivel3 = buscarElementosNasUnidades(unidades, usuario.nivel3);
        let unitName = unitParticipante ? unitParticipante.name : "";
        let unitUuid = unitParticipante ? unitParticipante.uuid : "";
        setParticipant({
          uuid: participante.data.uuid,
          score: participante.data.pontuacao,
          name: usuario.name,
          unit: unitName,
          isCompind: true,
        });
        setPontuacaoUsuario({
          name: usuario.name,
          rating: participante.data.pontuacao,
          uuidParticipante: participante.data.uuid,
          unit: unitName,
          unitUuid: unitUuid,
          subUnitUuid: subUnidadeParticipante
            ? subUnidadeParticipante.uuid
            : "",
          nivel3Uuid: nivel3 ? nivel3.uuid : "",
          isCompind: true,
        });
      })
      .catch((error) => {
        setPontuacaoUsuario({
          isCompind: true,
        });
      });
  };

  const buscarElementosNasUnidades = (unidades, target) => {
    let unidadeEscolhida = null;
    unidades.forEach((unidade) => {
      const unidadeTarget = searchInTree(unidade, target);
      if (unidadeTarget) {
        unidadeEscolhida = unidadeTarget;
      }
    });
    return unidadeEscolhida;
  };

  /**
   *
   * @param  {import('../../services/CampanhaService').Unit} node - nó raiz
   * @param {string} target - uuid do elemento a ser buscado
   * @returns {import('../../services/CampanhaService').Unit} retorna os nós raizes selecionados
   */
  const searchInTree = (node, target) => {
    // Verifica se o nó atual corresponde ao elemento desejado
    if (node.uuid === target) {
      return node;
    }

    // Percorre os nós filhos (se houver)
    if (node.subUnits && node.subUnits.length > 0) {
      for (const element of node.subUnits) {
        // Realiza a busca recursivamente em cada nó filho
        const result = searchInTree(element, target);
        if (result) {
          return result; // Retorna o resultado se encontrado em algum nó filho
        }
      }
    }

    return null; // Retorna null se o elemento não for encontrado
  };

  /**
   *
   * @param {import("../../services/CampanhaService").Unit[] } unidades
   * @param action
   */
  const setOnChangeSelect = (unidades, action) => {
    if (unidades && unidades.length > 0) {
      const unidadesUuids = unidades?.map((unidade) => unidade.uuid);
      setFiltrosParaBuscar({ units: unidadesUuids });
    } else {
      if (action === "SUA_UNIDADE") {
        setFiltrosParaBuscar({ units: getSuaUnidadeBusca() });
      } else if (action === "ALL") {
        setFiltrosParaBuscar({});
      } else if (action === "none") {
        setFiltrosParaBuscar({ units: [] });
      }
    }
  };

  const getSuaUnidadeBusca = () => {
    const levelUnit = getNivelUnit();
    switch (levelUnit) {
      case 1:
        return [pontuacaoUsuario.unitUuid];
      case 2:
        return [pontuacaoUsuario.subUnitUuid];
      case 3:
        return [pontuacaoUsuario.nivel3Uuid];
      default:
        return [];
    }
  };

  const getNivelUnit = () => {
    return campanhaService.campanhaCorrente().levelUnit;
  };

  const buscarParticipanteCampanha = async (search) => {
    try {
      const updatedFiltro = { ...filtrosParaBuscar, participantName: search };
      const response =
        await rankingIndividualService.getRankingParticipanteCampanha(
          updatedFiltro,
        );

      let ranking = [...response.data.topRanking];
      ranking.forEach((participante) => {
        participante.rating = participante.score;
        participante.isCompind = true;
      });

      formaterNumber(ranking);
    } catch (error) {
      if (error?.error?.message) {
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "ranking.erro.buscar.participante.titulo",
              "Erro ao buscar participante",
            ),
            message: error.error.message,
          },
        });
        return;
      }
      dispatch({ type: "SHOW_MODAL", payload: "error" });
      dispatch({
        type: "error",
        payload: {
          title: i18n.message(
            "ranking.erro.buscar.participante.titulo",
            "Erro ao buscar participante",
          ),
          message: i18n.message(
            "ranking.erro.buscar.participante.mensagem",
            "Entre em contato com o suporte ou tente novamente mais tarde.",
          ),
        },
      });
      return;
    }
  };

  return (
    <>
      <CampanhaRankingsStyled
        $isRenderedInSchedulePage={isRenderedInSchedulePage}
      >
        {getNivelUnit() > 0 && (
          <>
            <h1>{i18n.message("rankingsCampanha.ranking.title", "Ranking")}</h1>
          </>
        )}

        <LeadershipRanking topRanking={rankingTop3} />

        <PontuacaoUsuarioCorrente
          pontuacaoUsuario={pontuacaoUsuario}
          foraDoRanking={usuarioEstaNoRanking}
        />
        <h5>
          {i18n.message(
            "rankingCampanha.selecione.filtroRanking.label",
            "Ranking na sua unidade",
          )}
        </h5>
        <SelectTreeDropdow
          unidades={unidades}
          onChangeSelect={setOnChangeSelect}
        />
        <RankingListaParticipantes
          pontuacaoUsuario={pontuacaoUsuario}
          rankingFormat={rankingWithFormat}
          loading={loading}
          search={buscarParticipanteCampanha}
        />
      </CampanhaRankingsStyled>
    </>
  );
}

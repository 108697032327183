import React, { useEffect, useRef, useState } from "react";
import { BaseButton } from "../button/style";
import styled from "styled-components";
import ArrowEnter from "../svg/botoes/ArrowEnter";

/**
 * A reusable primary button component with customizable features.
 *
 * @param {Object} props - The properties of the button component.
 * @param {Function} props.funcao - The function to be called when the button is clicked.
 * @param {string} props.nome - The text displayed on the button.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled or not.
 * @param {boolean} [props.disabledOnClick=true] - Whether to disable the button after it is clicked.
 * @param {("normal"|"small")} [props.size="normal"] - The size of the button.
 * @param {("button"|"submit")} [props.type="button"] - The type of the button.
 * @param {string} [props.ariaLabelMsg] - The aria-label attribute for the button.
 * @param {string} [props.ariaStatusMessage=""] - The aria-status message to be displayed.
 * @param {string} [props.tabIndex="0"] - The tab index of the button.
 * @param {("true"|"false")} [props.ariaHidden="false"] - Whether the button is hidden from screen readers.
 * @param {("left"|"right"|"both")} [props.iconposition="right"] - The position of the icons on the button.
 * @returns {JSX.Element} - The primary button component.
 */
export default function BotaoEntrarTeam({
  id = "botao-entrar-time",
  funcao,
  nome,
  disabled = false,
  disabledOnClick = true,
  size = "normal",
  type = "button",
  ariaLabelMsg,
  ariaStatusMessage = "",
  tabIndex = "0",
  ariaHidden = "false",
  iconposition = "right",
}) {
  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] =
    useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      buttonRef.current.disabled = true;
      return;
    }
    buttonRef.current.disabled = false;
  }, [disabled]);

  function onClick(event) {
    if (disabled) return;
    if ((funcao !== null || funcao !== undefined) && type !== "submit") {
      if (disabledOnClick) {
        buttonRef.current.disabled = true;
      }
      setAriaAtivarMensagemDeClique(true);
      funcao && funcao(event);
    }
  }

  const a11yStatus = () => {
    if (ariaAtivarMensagemDeClique) {
      return (
        <span className="weex__a11y--visuallyHidden" role="status">
          {ariaStatusMessage}
        </span>
      );
    }
  };

  const left = () => {
    if (iconposition === "left" || iconposition === "both") {
      return (
        <div className="baseButton__iconContainer">
          <ArrowEnter />
        </div>
      );
    }
  };

  const right = () => {
    if (iconposition === "right" || iconposition === "both") {
      return (
        <div className="baseButton__iconContainer">
          <ArrowEnter />
        </div>
      );
    }
  };

  return (
    <ButtonEntrarTeamStyle
      id={id}
      $iconposition={iconposition}
      $size={size}
      $hasname={nome ? "true" : "false"}
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      aria-hidden={ariaHidden}
      tabIndex={tabIndex}
      aria-label={ariaLabelMsg ? ariaLabelMsg : nome}
      aria-disabled={disabled ? "true" : "false"}
      type={type}
    >
      <span className="baseButton__content">
        {left()}
        {nome && <span className="baseButton__text">{nome}</span>}
        {right()}
      </span>
      {a11yStatus()}
    </ButtonEntrarTeamStyle>
  );
}

export const ButtonEntrarTeamStyle = styled(BaseButton)`
  border: none;
  .baseButton__iconContainer {
    background-color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  path {
    stroke: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }
`;

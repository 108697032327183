import React, { useRef, useState } from "react";
import { useStore } from "react-redux";
import UsuarioService from "../../services/UsuarioService";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import { WeexModalDialog } from "./WeexModalDialog";

/**
 * @deprecated - componente será reformulado para se adequar ao novo design do weex 3.0
 * @param {Object} props
 * @param {string} props.rota
 * @param {string} props.conteudo_dangerouslySetInnerHTML
 * @param {string} props.imagemSrc
 * @param {string} props.atvName
 * @param {string} props.atvTitle
 * @param {boolean} props.isAtividadeCompleta
 * @param {function(): void} props.fecharModalCallback
 * @param {function(): void} props.irParaDinamica
 */
function WeexModalAtividades({
  fecharModalCallback,
  irParaDinamica,
  rota,
  conteudo_dangerouslySetInnerHTML,
  imagemSrc,
  atvName,
  atvTitle,
  isAtividadeCompleta,
  codigoAtividade,
  atvExecuted,
}) {
  const [mostrarModal, setMostrarModal] = useState(true);
  const dialogRef = useRef(null);
  const i18n = useStore().getState().i18n;
  const usuarioService = new UsuarioService();

  let acaoDoBotao;
  switch (rota) {
    case "video":
      if (atvExecuted) {
        acaoDoBotao = i18n.message(
          "atividades.video.revisar.acao.btn",
          "REVISAR!",
        );
      } else {
        acaoDoBotao = i18n.message("atividades.video.acao.btn", "ASSISTIR!");
      }
      break;
    case "fatoFake":
      if (isAtividadeCompleta) {
        acaoDoBotao = i18n.message(
          "atividades.fatoFake.revisar.acao.btn",
          "REVISAR!",
        );
        usuarioService.salvarConcluidoLocalStorage(codigoAtividade, true);
      } else {
        acaoDoBotao = i18n.message("atividades.fatoFale.acao.btn", "COMEÇAR!");
        usuarioService.salvarConcluidoLocalStorage(codigoAtividade, false);
      }

      break;
    case "newHabit":
      if (atvExecuted) {
        acaoDoBotao = i18n.message(
          "atividades.newHabit.revisar.acao.btn",
          "REVISAR!",
        );
      } else {
        acaoDoBotao = i18n.message("atividades.newHabit.acao.btn", "COMEÇAR!");
      }
      break;
    case "game":
      acaoDoBotao = i18n.message("atividades.game.acao.btn", "JOGAR!");
      break;
    case "extra":
      acaoDoBotao = i18n.message("atividades.extra.acao.btn", "ACESSAR!");
      break;
    case "mural":
      acaoDoBotao = i18n.message("atividades.mural.acao.btn", "ACESSAR!");
      break;
    default:
      acaoDoBotao = i18n.message(
        "atividades.atividadeDefault.acao.btn",
        "CONTINUAR!",
      );
  }

  function ocultar() {
    fecharModalCallback();
    dialogRef.current.close();
    setMostrarModal(false);
  }

  return mostrarModal ? (
    <WeexModalDialog onPressESC={ocultar} dialogRef={dialogRef}>
      <div
        className={"modal is-active animations-popIn"}
        style={{ zIndex: "102" }}
      >
        <div className="modal-background"></div>
        <div>
          <header className="modal-card-head weex-modal-card-header">
            <button
              onClick={ocultar}
              className="close-btn"
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fecha a janela e volta para a página atividades.",
              )}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </header>
          <header className="weex-modal-card-header wex-modal-card-header-title">
            <p className="nome-atividade">{atvTitle ? atvTitle : atvName}</p>
          </header>
          <section className="modal-card-body has-text-centered">
            {imagemSrc ? (
              <div className="weex-modal-card-body__texto">
                <p className="modal-card-title wex-modal-card-img">
                  <img
                    src={imagemSrc}
                    alt={i18n.message(
                      "alt.imagem.apresentacao.atividade",
                      "Imagem apresentação da atividade",
                    )}
                    className="weex__image is-2by1"
                  />
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="weex-modal-card-body__texto">
              <div
                dangerouslySetInnerHTML={{
                  __html: conteudo_dangerouslySetInnerHTML,
                }}
              ></div>
            </div>
          </section>
          <footer
            className="modal-card-foot weex-modal-card-footer"
            style={{ textAlign: "center" }}
          >
            <div className="modal-card-foot-button-mobile">
              <PrimarioBtn
                disabledOnClick={true}
                nome={acaoDoBotao}
                funcao={irParaDinamica}
                ariaStatusMessage={i18n.message(
                  "aria.status.weexmodalatividades.botaoiniciarativida",
                  "Botão para iniciar atividade clicado.",
                )}
              />
            </div>
            <div className="modal-card-foot-button-desktop">
              <div className="modal-button">
                <PrimarioBtn
                  disabledOnClick={true}
                  nome={acaoDoBotao}
                  funcao={irParaDinamica}
                  ariaStatusMessage={i18n.message(
                    "aria.status.weexmodalatividades.botaoiniciarativida",
                    "Botão para iniciar atividade clicado.",
                  )}
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default WeexModalAtividades;

import React from "react";
import InputsCustomizadosService from "../../../services/InputsCustomizadosService";
import WeexFactory from "../../WeexInputs/WeexFactory";

const CustomWeexInput = ({
  tipoInscricao,
  onChange,
  elegibilidade,
  i18n,
  nomeMatricula,
}) => {
  let icon, type, placeholder;
  let passwordIcon, passwordType, passwordPlaceholder;

  const inputsCustomizadosService = new InputsCustomizadosService();

  if (tipoInscricao !== "CUSTOMIZADO") {
    icon = "fas fa-user";
    type = "text";
    placeholder = nomeMatricula;
  } else {
    icon = "fas fa-user";
    type = "text";
    placeholder = inputsCustomizadosService.rotuloDoCampo("MATRICULA");
  }

  if (
    !elegibilidade &&
    (tipoInscricao !== "CUSTOMIZADO" ||
      inputsCustomizadosService.possuiCampo("SENHA"))
  ) {
    passwordIcon = "fas fa-lock";
    passwordType = "password";
    passwordPlaceholder =
      tipoInscricao !== "CUSTOMIZADO"
        ? i18n.message("registro.campo.senha", "Senha")
        : inputsCustomizadosService.rotuloDoCampo("SENHA");
  }

  return (
    <>
      <WeexFactory
        icon={icon}
        type={type}
        name="username"
        id="username"
        iconposition="right"
        placeholder={placeholder}
        onChange={onChange}
      />
      {passwordIcon && (
        <WeexFactory
          icon={passwordIcon}
          type={passwordType}
          name="password"
          id="password"
          iconposition="right"
          placeholder={passwordPlaceholder}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default CustomWeexInput;

import styled from "styled-components";

export const SelectTeamStyle = styled.div`
  .team__iconContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem; // 8px;
  }

  .team__icon {
    width: 1.75rem; // 28px;
    height: 1.75rem; // 28px;
    padding: 0.5rem; // 8px;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};
    path {
      stroke: ${(props) => {
        return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
      }};
    }
  }

  .team__cardContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .team__controlsContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.688rem; // 27px;
    margin-bottom: 1.5rem; // 24px;
  }

  .team__searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem; // 12px;
  }

  .team__chosseRandomTeamContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.25rem 0.75rem 1.25rem; // 12px 20px 12px 20px;
    border-radius: 0.75rem; // 12px;
    background-color: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
  }

  .team__chosseRandomTeamContainer--text {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem; // 12px;

    span {
      color: ${(props) => {
        return props.theme.textosCorFundoSecundario || `rgb(255, 255, 255)`;
      }};
      text-align: center;
    }
  }

  .team__showMore {
    margin-top: 1.688rem; // 27px;
    text-align: center;
  }
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .team__controlsContainer {
      flex-direction: row;
    }

    .team__desktopContainer {
      width: 30%;
    }
    .team__searchContainer {
      width: auto;
      flex-direction: row;
    }
    .team__chosseRandomTeamContainer {
      flex-direction: row;
    }
    .team__chosseRandomTeamContainer--text {
      margin-right: 2.25rem; //36px;
      margin-bottom: 0px;

      span {
        color: ${(props) => {
          return props.theme.textosCorFundoSecundario || `rgb(255, 255, 255)`;
        }};
        text-align: left;
      }
    }
    .team__cardContainer {
      flex-direction: row;
    }

    .team__card {
      min-width: 32%;
      max-width: 32.7%;
    }
  }
  /* #endregion DESKTOP */
`;

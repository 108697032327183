import React from "react";

export default function Lock() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 7.71429V5.83429C13.9985 4.81783 13.594 3.84345 12.8753 3.12471C12.1565 2.40597 11.1822 2.00151 10.1657 2H9.83428C8.81783 2.00151 7.84344 2.40597 7.1247 3.12471C6.40596 3.84345 6.0015 4.81783 5.99999 5.83429V7.71429C5.54533 7.71429 5.1093 7.8949 4.78781 8.21639C4.46632 8.53788 4.28571 8.97392 4.28571 9.42857V14.7429C4.28722 15.6062 4.63086 16.4338 5.24137 17.0443C5.85187 17.6548 6.67946 17.9985 7.54285 18H12.4571C13.3205 17.9985 14.1481 17.6548 14.7586 17.0443C15.3691 16.4338 15.7128 15.6062 15.7143 14.7429V9.42857C15.7143 8.97392 15.5337 8.53788 15.2122 8.21639C14.8907 7.8949 14.4546 7.71429 14 7.71429ZM7.14285 5.83429C7.14285 5.12047 7.42641 4.4359 7.93115 3.93116C8.43589 3.42642 9.12047 3.14286 9.83428 3.14286H10.1657C10.8795 3.14286 11.5641 3.42642 12.0688 3.93116C12.5736 4.4359 12.8571 5.12047 12.8571 5.83429V7.71429H7.14285V5.83429ZM14.5714 14.7429C14.5714 15.3036 14.3487 15.8414 13.9522 16.2379C13.5557 16.6344 13.0179 16.8571 12.4571 16.8571H7.54285C6.98211 16.8571 6.44433 16.6344 6.04782 16.2379C5.65132 15.8414 5.42856 15.3036 5.42856 14.7429V9.42857C5.42856 9.27702 5.48877 9.13167 5.59593 9.02451C5.70309 8.91735 5.84844 8.85714 5.99999 8.85714H14C14.1515 8.85714 14.2969 8.91735 14.4041 9.02451C14.5112 9.13167 14.5714 9.27702 14.5714 9.42857V14.7429Z"
          fill="#7C7C7C"
        />
        <path
          d="M10.5714 12.6971V14.5714C10.5714 14.723 10.5112 14.8683 10.4041 14.9755C10.2969 15.0827 10.1515 15.1429 9.99999 15.1429C9.84844 15.1429 9.70309 15.0827 9.59593 14.9755C9.48877 14.8683 9.42856 14.723 9.42856 14.5714V12.6971C9.21069 12.5714 9.04041 12.3772 8.94413 12.1448C8.84785 11.9123 8.83096 11.6546 8.89608 11.4116C8.96119 11.1686 9.10467 10.9539 9.30426 10.8007C9.50386 10.6476 9.74841 10.5645 9.99999 10.5645C10.2516 10.5645 10.4961 10.6476 10.6957 10.8007C10.8953 10.9539 11.0388 11.1686 11.1039 11.4116C11.169 11.6546 11.1521 11.9123 11.0559 12.1448C10.9596 12.3772 10.7893 12.5714 10.5714 12.6971Z"
          fill="#7C7C7C"
        />
      </svg>
    </>
  );
}

import React from "react";

export const Mp4Video = ({ video }) => (
  <div className="video-with-desktop">
    <video controls className="video-container">
      <track kind="captions" />
      <source src={video.url} type="video/mp4"></source>
    </video>
  </div>
);

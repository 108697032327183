import styled from "styled-components";

export const ProgressoCircularStyle = styled.div`
  width: 3.125rem; // 50px;
  height: 3.125rem; // 50px;

  .CircularProgressbar-path {
    stroke: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    fill-opacity: 1;
    fill: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
  }
  .CircularProgressbar-trail {
    stroke: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
  }
  .progress__text {
    font-size: 1.059rem; // 16.95px;
    font-weight: 900;
    line-height: 1.379rem; // 22.07px;
    letter-spacing: -0.04em;
    text-align: center;
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }};
  }
  .progress__percentage {
    font-size: 0.498rem; // 7.97px;
    font-weight: 900;
    line-height: 0.648rem; // 10.37px;
    letter-spacing: -0.04em;
    text-align: center;
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }};
  }
`;

export const FlatProgressBarStyle = styled.div`
  /* width: 100%; */
  padding: 1rem; // 16px;
  border-radius: 0.75rem; // 12px;
  background-color: #f8f8f8;

  .flatProgressBar__textContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem; //16px
  }

  .flatProgressBar__text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.225rem; // 19.6px;
    text-align: left;
    color: #7c7c7c;
  }

  .flatProgressBar__text--porcentage {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1.379rem; //22.07px;
    letter-spacing: -0.04em;
    text-align: center;
    small {
      font-size: 0.75rem; // 12px;
      font-weight: 900;
      line-height: 0.648rem; // 10.37px;
      letter-spacing: -0.04em;
      text-align: center;
    }
  }

  .flatProgressBar__filler {
    background-color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    width: 15rem; // 240px;
  }
  /* #endregion DESKTOP */
`;

import styled from "styled-components";

export const WeexSpinnerStyle = styled.div`
  /* height: 100vh; */

  .weexLoader,
  .weexLoader&:after {
    border-radius: 50%;
    width: 6em;
    height: 6em;
  }
  .weexLoader {
    margin: 45px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1px solid rgba(207, 213, 212, 0.2);
    border-right: 1px solid rgba(207, 213, 212, 0.2);
    border-bottom: 1px solid rgba(207, 213, 212, 0.2);
    border-left: 3px solid
      ${(props) => {
        return props.theme.fundoSecundario;
      }};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  p {
    text-align: center;
  }
`;

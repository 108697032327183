import React, { useRef, useState } from "react";
import { useStore, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MensagemVideoService from "../../services/MensagemVideoService";
import { WeexModalDialog } from "./WeexModalDialog";
import ModalConclusao from "./ModalConclusao";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";

const mensagemVideoService = new MensagemVideoService();

/**
 * @deprecated - componente será reformulado para se adequar ao novo design do weex 3.0
 */
function MensagemVideoModal() {
  const messageVideo = useSelector((state) => state.messageVideo);
  const i18n = useStore().getState().i18n;
  const videoModalRef = useRef();
  const [concluiu, setConcluiu] = useState(false);
  const [pontos, setPontos] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const marcarComoVistoMensagem = () => {
    mensagemVideoService.marcarComoVisto(
      messageVideo[0]?.uuid,
      (erroMensagemVideo, sucesso) => {
        if (sucesso) {
          setConcluiu(true);
          setPontos(sucesso.earnedPoints);
        }
        fecharModal();
      },
    );
  };

  function assistirVideo() {
    if (messageVideo && messageVideo[0]?.video?.code) {
      navigate("/video-message/" + messageVideo[0]?.video?.code);
      fecharModal();
    }
  }

  function fecharModal() {
    dispatch({ type: "HIDE_MODAL", payload: "welcomeVideo" });
  }

  const text = () => {
    return (
      <div className="titulo-container">
        <h1 className="missao-cumprida__title">
          {i18n.message("missaoCumprida.fim", "PARABÉNS!")}
        </h1>
        <h2 className="missao-cumprida__subtitle">
          {i18n.message(
            "missaoCumprida.parabens",
            "Você concluiu a atividade:",
          )}
        </h2>
      </div>
    );
  };

  if (concluiu) {
    return <ModalConclusao pontos={pontos} text={text} />;
  }

  return (
    <WeexModalDialog dialogRef={videoModalRef} onPressESC={fecharModal}>
      <div className={"modal is-active"}>
        <div className="modal-background"></div>
        <div>
          <nav className="modal-card-head weex-modal-card-header">
            <button
              onClick={fecharModal}
              className="close-btn"
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fecha a janela e volta para a página atividades.",
              )}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </nav>
          <header className="weex-modal-card-header wex-modal-card-header-title">
            <h1
              id="tilteMensagemVideo"
              className="nome-atividade title-mensagem-video"
            >
              {messageVideo[0]?.title}
            </h1>
          </header>
          <section
            aria-labelledby="tilteMensagemVideo"
            className="modal-card-body has-text-centered"
          >
            {messageVideo[0]?.image !== null ? (
              <div className="weex-modal-card-body__texto">
                <div className="modal-card-title wex-modal-card-img espacamento-fim">
                  <img
                    src={messageVideo[0]?.image}
                    alt={i18n.message(
                      "alt.modal.apresentacao.thumbnail",
                      "thumbnail da vídeo mensagem",
                    )}
                    className="weex__image is-2by1"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="weex-modal-card-body__texto">
              <div>
                {messageVideo[0]?.message !== null
                  ? messageVideo[0]?.message
                  : ""}
              </div>
            </div>
          </section>
          <footer
            className="modal-card-foot weex-modal-card-footer"
            style={{ textAlign: "center" }}
          >
            <PrimarioBtn
              nome={
                messageVideo[0]?.type === "MENSAGEM"
                  ? i18n.message("mensagemvideo.campanha", "Concluir!")
                  : i18n.message("mensagemvideo.assistir", "Assistir!")
              }
              funcao={
                messageVideo[0]?.type === "MENSAGEM"
                  ? marcarComoVistoMensagem
                  : assistirVideo
              }
              ariaStatusMessage={i18n.message(
                "aria.status.mensagemvideomodal.botaoassistir",
                "Botão assistir clicado.",
              )}
            />
          </footer>
        </div>
      </div>
    </WeexModalDialog>
  );
}

export default MensagemVideoModal;

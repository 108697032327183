import styled from "styled-components";

export const ButtonGameStyle = styled.button`
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  width: 3.125rem; //50px;
  height: 3.125rem; //50px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: ${(props) => {
    return "0.125rem solid " + props.theme.fundoSecundario;
  }};
  background-color: ${(props) => {
    return props.theme.cardLogadoTextoPrimeiro;
  }};

  path {
    stroke: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }

  &:disabled {
    cursor: not-allowed;
    filter: none;
    background-color: rgba(255, 255, 255, 0.4);
    border: 0.063rem solid #7c7c7c;
  }
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    &:hover,
    &:active {
      filter: contrast(0.94);
    }
  }
  /* #endregion DESKTOP */
`;

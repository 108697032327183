import styled from "styled-components";

export const WeexDialogStyle = styled.dialog`
  width: 85vw;
  display: flex;
  flex-direction: column;
  border-radius: 2rem !important;
  margin-top: 1rem;
  gap: 1rem;
  padding: 1rem;

  background-color: ${(props) => {
    return props.theme.fundoSecundario;
  }};
  color: ${(props) => {
    return props.theme.textosCorFundoSecundario;
  }};
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 1.25rem 2.5rem rgba(0, 0, 0, 0.17);

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .weexDialog__title {
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  .weexDialog__texts {
    font-size: 1.188rem;
    line-height: 1.5rem;
  }

  .weexDialog__texts--alignment {
    text-align: center;
  }

  .weexDialog__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .weexDialog__header--ilustration {
    width: 65%;
  }

  .weexDialog__main {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1.5rem;
    gap: 1rem;
    border-radius: 1.5rem;
  }

  .weexDialog__textsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .weexDialog__container--alignment {
    margin-top: 1.5rem;
  }

  .weexDialog__buttonsContainer,
  .weexDialog__stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  .weexDialog__buttonsContainer--direction {
    flex-direction: column;
    gap: 2rem;
  }

  .weexDialog__buttonsContainer--margins {
    margin-top: 1.5rem; //24px;
    margin-bottom: 1.5rem; //24px;
  }
  .weexDialog__buttonsContainer {
    flex-direction: column-reverse;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    width: 35.5rem;
    min-height: 41.125rem;
    padding: 2rem;
    gap: 2rem;

    /*     .weexDialog__buttonsContainer--direction {
      flex-direction: row;
    } */

    .weexDialog__texts--alignment--marginsSquish {
      margin-left: 7rem;
      margin-right: 7rem;
    }
  }
  /* #endregion DESKTOP */
`;

/** @deprecated - retrocompatibilidade com o weex 2.0. Ao decorrer do desenvolvimento, esta const irá desaparecer.  */
export const WeexModalStyle = styled.dialog`
  border: none;
  width: 85vw;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.17);
  text-align: center;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .text-area-feedback-video {
    max-height: 3rem;
    margin-top: 1rem;
  }
  .text-area-feedback-video&::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
    color: #847d92;
  }

  .weex-botao-confirmacao__texto-comprido__missao-cumprida {
    z-index: 100;
    position: relative;
  }

  .not-padding-left {
    padding-left: 0;
  }

  .textarea-mural {
    line-height: initial;
  }

  .preview {
    margin-top: 1em;
    width: 25%;
    height: 25%;
    object-fit: cover;
  }

  .count-text-area {
    float: right;
  }

  .overflow {
    overflow: scroll;
  }

  .display-icon {
    display: flex;
    padding: 2rem;
  }

  .imagem-publicacao {
    padding: 0.5em;
    border-radius: 8px;
    .weexInput__icon {
      font-size: 1.4rem !important;
    }
    button {
      background-color: #f8f8f8;
      border-radius: 0.75rem;
      border: 0.125rem solid #f3f3f3;
      padding: 1rem 1rem 1rem 0.5rem;
    }
    span {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-align: left;
    }
  }

  .title-mensagem-video {
    font-weight: 400;
  }

  .imagem-publicacao {
    padding: 0.5em;
    border-radius: 8px;
    font-size: 12px;
  }

  .modal-card-head {
    display: flex;
    justify-content: flex-end;
  }

  .close-btn {
    border: none;
    background: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    width: 16px;

    &:active {
      filter: brightness(0.5);
    }
  }

  .weex-modal-card-header {
    text-align: right;
    border: none;
  }

  .weex-modal-card-header-publicacao {
    padding: 10px;
  }

  .titulo-icone {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
  }

  .color-fundo-secundaria {
    color: ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};
  }

  .pointer {
    cursor: pointer;
  }

  .spinner {
    display: "flex";
    justify-content: "center";
    margin-bottom: "2em";
    margin: auto;
  }

  .weex-modal-interests-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .wex-modal-card-header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .data {
      color: ${(props) => {
        return props.theme.cardLogadoTextoTerceiro;
      }};
      font-weight: 600px;
      font-size: 11px;
      line-height: 17px;
    }

    .nome-atividade {
      color: ${(props) => {
        return props.theme.fundoSecundario;
      }};
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      text-align: center;
    }

    .nome-atividade-font-weight-normal {
      font-weight: normal;
    }
  }

  .wex-modal-card-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .espacamento-fim {
    margin-bottom: 1.5rem;
  }

  .weex-modal-card-body__texto {
    margin: 1rem 1rem 2.5rem 1rem;

    span {
      font-size: 1.188rem;
      line-height: 1.5rem;
    }

    p {
      color: #201d1d;
      font-weight: 400px;
      font-size: 1.188rem;
      /* line-height: 1.5rem; */
      text-align: center;
    }

    .quase-la-texto {
      line-height: 1.563rem;
      text-align: center;
      font-weight: 400px;
    }
  }

  .weex-modal__fato_fake_text {
    margin: auto;
    background-color: #fee2e2;
    width: 100%;
    min-height: 5rem;
    margin-top: 3.125rem;
    border-radius: 0.625rem;
    color: #fe2a40 !important;
    font-weight: 700;
    line-height: 17px !important;
    font-size: 1rem;
    padding-top: 1.25rem;
  }

  .weex-modal-card-body__publicacao {
    margin: 1rem 1rem 0.5rem 1rem;
  }

  .weex-modal-card-footer {
    border: none;
  }

  .espaço {
    margin-right: 10px;
    box-shadow: 0px 1px 40px -5px rgb(0 0 0 / 25%);
  }

  .modal-card-foot-button-desktop {
    display: none;
  }

  .missao-cumprida {
    display: block;
  }

  .modal-card-foot-button-mobile {
    display: block;
    width: 100%;
  }

  .weex-mural-button-disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .modal-card-foot-actions {
    width: 100%;
  }

  .modal-card-foot-actions-link {
    margin-top: 1em;
  }

  .modal-title-anexar-video__texto {
    margin-bottom: 2em;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 14px;
  }

  .video-mural {
    width: 100%;
    margin: auto;
  }

  .video-mural > iframe {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .youtube {
    margin-top: 1em;
  }

  .modal-card-body-publicacao {
    padding: 10px 10px 0px 10px;
  }

  .video-publicacao {
    pointer-events: none;
    margin-left: 3.5rem;
    margin-top: 1rem;
  }

  .video-publicacao-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #input-file &:disabled + label {
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  .weex-modal-card-img__almost {
    margin: auto;
    max-width: 6em;
    max-height: 6em;
  }

  .weex-modal-card-img__text {
    font-weight: bold;
    margin-top: 1.188em;
    margin-bottom: 1.125em;
  }

  .modal-mensagemfinal-pontuacao {
    margin-left: 2em;
    margin-right: 2em;

    .modal-mensagemfinal-pontuacao__texto {
      color: #fe2a40;
      font-weight: 700;
    }
  }

  .pontos-obtidos {
    font-size: 1.25rem;
  }

  .titulo-mural-modal {
    margin-right: auto;
  }

  .modal-card {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
  }
  .modal-card-body {
    overflow-x: hidden;
  }

  /*media query min-width 700px*/
  @media screen and (min-width: 700px) {
    width: 28.125rem;
    margin-left: calc(-13rem + 50vw);
    margin-right: calc(-13rem + 50vw);

    .modal-card-publicacao {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 5px);
    }
    .weex-modal-card-body__texto {
      p {
        font-weight: 400px;
      }
    }

    .modal-card-foot-button-desktop {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 2em;
    }

    .modal-button {
      width: 60%;
    }

    .modal-card-foot-button-mobile {
      display: none;
    }

    .wex-modal-card-header-title {
      margin-left: 3vw;
      margin-right: 3vw;
    }

    .header-atividades {
      margin-right: 1vw;
      margin-top: 1vh;
      padding: 1px;
      background: transparent;
      z-index: 99999;
    }

    .titulo-mural-modal {
      width: 100%;
      margin-left: 1em;
      font-size: 1.6em;
      font-weight: bold;
      margin-right: auto;
    }

    .icon-mural-modal {
      margin-right: 0.4em;
      font-weight: 500;
    }

    .weex-modal-card-body__texto {
      margin: 1rem 2rem 2.5rem 2rem;
    }

    .modal-card-foot weex-modal-card-footer {
      min-height: 20vh;
    }

    .modal-card-body {
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => {
          return props.theme.fundoSecundario;
        }};
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => {
          return props.theme.fundoSecundario || `#533888`;
        }};
      }
    }
    .modal-card {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 40px);
      overflow: hidden;
    }
    .titulo-icone {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }

    .titulo-modal {
      margin-top: 0.5em;
      h3 {
        padding: 0 1em;
      }
    }

    .atv-card-desktop {
      margin-left: 0 !important;
    }

    .dia-container > div {
      min-height: auto;
      width: 100%;
      max-height: 100%;
      border: none;
      margin-bottom: 0;
    }

    .final-atividades {
      max-height: 100% !important;
    }

    .atividade-section {
      padding: 0;
      margin-top: -1.7em;
      .weex-modal-card-body__publicacao {
        margin: 1rem 1rem 0.5rem 1rem;
      }
    }

    .weex-modal-card-img__almost {
      max-width: 6em;
      max-height: 6em;
    }

    .weex-modal-card-img__text {
      font-size: 20px !important;
      font-weight: bold;
      margin-top: 1.188em;
      margin-bottom: 1.125em;
    }

    .weex-modal__fato_fake_text {
      background-color: #fee2e2;
      width: 100%;
      min-height: 5rem;
      margin-top: 1.125rem;
      border-radius: 0.625rem;
      color: #fe2a40 !important;
      font-weight: 700;
      line-height: 17px;
      padding-top: 1.25rem;
      font-size: 1rem;
    }
  }
  .interesse-obrigatiorio-container {
    display: flex;
    justify-content: space-evenly;

    .content-botao {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .preview-imagem {
    width: 40%;
    object-fit: cover;
  }
`;

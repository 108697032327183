import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./styles/reset.css";
import "material-icons/iconfont/material-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import GlobalTheme from "./styles/GlobalTheme";

import { Provider } from "react-redux";
import store from "./store";
import axe from "@axe-core/react";

import * as Sentry from "@sentry/browser";

const monitorsActive = process.env.REACT_APP_MONITORS_ACTIVE;

if (monitorsActive === "true") {
  Sentry.init({
    dsn: "https://a7823800bf264a6cb3800de0bd0fee44@glitch.weex.digital/2",
  });
}
if (process.env.NODE_ENV !== "production") {
  axe(React, ReactDOM, 1000);
}

// Encontre o elemento do DOM onde você quer montar seu app
const container = document.getElementById("root");
// Crie uma raiz com esse elemento
const root = createRoot(container);

// Renderize seu app dentro do React.StrictMode para um desenvolvimento mais seguro
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalTheme>
        <App />
      </GlobalTheme>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "homol") {
//   serviceWorker.register();
// } else {
//   serviceWorker.unregister()
// }

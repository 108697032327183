import React from "react";

const InviteFriends = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 11.5V10.3333C9.16667 9.71449 8.92083 9.121 8.48325 8.68342C8.04566 8.24583 7.45217 8 6.83333 8H3.33333C2.71449 8 2.121 8.24583 1.68342 8.68342C1.24583 9.121 1 9.71449 1 10.3333V11.5M10.9167 3.91667V7.41667M12.6667 5.66667H9.16667M7.41667 3.33333C7.41667 4.622 6.372 5.66667 5.08333 5.66667C3.79467 5.66667 2.75 4.622 2.75 3.33333C2.75 2.04467 3.79467 1 5.08333 1C6.372 1 7.41667 2.04467 7.41667 3.33333Z"
        stroke="#201D1D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InviteFriends;

import React from "react";
import CampanhaService from "../../../services/CampanhaService";
import CustomRegisterCampaign from "../../../comps/authInscricao/inscricao/CustomRegisterCampaign";

function RegisterSelector({ modoLogin }) {
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();

  return (
    <div>
      <div>
        {campanhaCorrente.tipoInscricao === "CUSTOMIZADO" ? (
          <CustomRegisterCampaign modoLogin={modoLogin} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default RegisterSelector;

import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Nose({ changeAvatar }) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.nariz", "Nariz")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.nariz1",
            "Nariz pequeno selecionado",
          )}
          type="noseStyle"
          value="short"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.nariz1", "Nariz pequeno")}
          avatarPart={"/svgs-3.0/avatar-parts/nose_short.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.nariz2",
            "Nariz longo selecionado",
          )}
          type="noseStyle"
          value="long"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.nariz2", "Nariz longo")}
          avatarPart={"/svgs-3.0/avatar-parts/nose_long.svg"}
        />
        <AvatarPartsButton
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.nariz3",
            "Nariz redondo selecionado",
          )}
          type="noseStyle"
          value="round"
          funcao={changeAvatar}
          alt={i18n.message("aria.msg.avatar.nariz3", "Nariz redondo")}
          avatarPart={"/svgs-3.0/avatar-parts/nose_round.svg"}
        />
      </AvatarPartsContainerStyle>
    </>
  );
}

import React from "react";
import styled from "styled-components";

/**
 * @param {Object} props
 * @param {number} props.indiceAtual
 * @param {number} props.totalDeItems
 */
export default function WeexMarcadorProgresso({ indiceAtual, totalDeItems }) {
  const bolinhas = [];

  for (let indiceQuestao = 0; indiceQuestao < totalDeItems; indiceQuestao++) {
    bolinhas.push(
      <MarcadorBolinha
        isConcluido={indiceQuestao <= indiceAtual}
        key={indiceQuestao}
      />,
    );
  }

  return <MarcadorStyle>{bolinhas}</MarcadorStyle>;
}

/**
 * @param {Object} props
 * @param {boolean} props.isColorida
 */
function MarcadorBolinha({ isConcluido }) {
  if (isConcluido) {
    return <BolinhaConcluidaStyle />;
  } else {
    return <BolinhaDefaultStyle />;
  }
}

export const MarcadorStyle = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

export const BolinhaStyle = styled.div`
  border-radius: 100%;
  width: 0.375rem; //6px
  height: 0.375rem;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    width: 0.563rem; //9px
    height: 0.563rem;
  }
  /* #endregion DESKTOP */
`;

export const BolinhaDefaultStyle = styled(BolinhaStyle)`
  background-color: #dfdde3;
  border: 0.188rem solid #f3f3f3;
`;

export const BolinhaConcluidaStyle = styled(BolinhaStyle)`
  background-color: ${(props) => {
    return props.theme.fundoSecundario;
  }};
  border: ${(props) => {
    return "0.188rem solid " + props.theme.cardLogadoFundo; //3px
  }};
`;

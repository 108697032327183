import React from "react";
import { useStore } from "react-redux";
import AgendaHeader from "../AgendaHeader/AgendaHeader";
import AtividadesDoDia from "../AtividadesDoDia/AtividadesDoDia";
import { CardStyle } from "./style";

function DiaSemanaAgenda({ agendaDoDia }) {
  const i18n = useStore().getState().i18n;

  const numeroItensPorLinha = 4;
  const nomeInteresse =
    agendaDoDia?.interest != null ? agendaDoDia?.interest?.name : "";

  let dataTextual = agendaDoDia?.day?.replace("00:00:00", "").replace(" ", "");
  const componentesData = dataTextual?.split("-");
  dataTextual = componentesData[2];
  const dayOfWeek = agendaDoDia?.dayOfWeek;
  const dayOfWeekComplete = i18n.getNomeDiaSemana(dayOfWeek);
  const mesTextualVisivel = i18n.getNomeMesAbreviado(componentesData[1]);
  const mesTextualLeitorTela = i18n.getNomeMesInteiro(componentesData[1]);

  return (
    <CardStyle>
      <AgendaHeader
        habilitado={agendaDoDia?.enabled}
        nome={agendaDoDia?.nome}
        nomeInteresse={nomeInteresse}
        dayOfWeekComplete={dayOfWeekComplete}
        diaTextual={dataTextual}
        mesTextualVisivel={mesTextualVisivel}
        mesTextualLeitorTela={mesTextualLeitorTela}
        isAgendaProlongada={agendaDoDia?.extendedSchedule}
        concluidasPorcentagem={
          isNaN(agendaDoDia?.completionPercentage?.toFixed(0))
            ? 0
            : agendaDoDia?.completionPercentage?.toFixed(0)
        }
        activitiesWithSubtitlesNoGames={
          agendaDoDia?.activitiesWithSubtitlesNoGames
        }
        atividadesSemGameFinal={agendaDoDia?.activitiesWithoutGames}
        gamesFinal={agendaDoDia?.games}
      />
      <AtividadesDoDia
        numeroItensPorLinha={numeroItensPorLinha}
        hasSubtitles={agendaDoDia?.hasSubtitles}
        activitiesWithSubtitlesNoGames={
          agendaDoDia?.activitiesWithSubtitlesNoGames
        }
        atividadesSemGameFinal={agendaDoDia?.activitiesWithoutGames}
      />
    </CardStyle>
  );
}

export default DiaSemanaAgenda;

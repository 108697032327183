import React from "react";
import { useStore, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import UsuarioService from "../../services/UsuarioService";
import Image from "./Image";
import InfosTextuais from "./InfosTextuais";
import { CampanhaLogosStyle } from "./style";

const usuarioService = new UsuarioService();
const campanhaService = new CampanhaService();

const fontSizeForTitlesDefautl = "weex-l-font-size-title__default";
const fontSizeForTitlesMedium = "weex-l-font-size-title__medium";
const fontSizeForTitlesLarge = "weex-l-font-size-title__large";

/** @deprecated - retrocompatibilidade com o weex 2.0. login/register. Ainda não tem mockup para o 3.0  */
function LogosCampanha() {
  const i18n = useStore().getState().i18n;
  const usuario = useSelector((state) => state.usuario);
  let location = useLocation();
  let fontSizeForTitles = fontSizeForTitlesDefautl;
  let usuariologado = !!(
    usuarioService.usuarioCorrente() &&
    usuarioService.usuarioCorrente().token != null
  );
  const exporCampaignInformation =
    (location?.pathname === "/atividades" ||
      location?.pathname === "/" ||
      location?.pathname === "/trophy" ||
      location?.pathname === "/interests" ||
      location?.pathname === "/home" ||
      location?.pathname === "/resetPassword" ||
      location?.pathname === "/passwordReset") &&
    !usuariologado &&
    usuario === false;
  let campanha = campanhaService.campanhaCorrente();
  let logoCentro = "";
  let logoCentroDesktop = "";
  let campanhaName = "";
  let title = "";
  let titleVisible = true;
  if (campanha) {
    // #region DESKTOP
    if (campanha.topLeftDesktop) {
      logoCentroDesktop = campanha.topLeftDesktop;
    }
    // #endregion DESKTOP
    if (campanha.logoLeft) {
      logoCentro = campanha.logoLeft;
    }
    if (campanha.title) {
      title = campanha.title;
    }
    if (campanha.name) {
      campanhaName = campanha.name;
    }

    titleVisible = campanha.titleVisible;
  }

  const exporLogo =
    (logoCentro || logoCentroDesktop) && !usuariologado && usuario === false;

  if (campanha?.styles) {
    let styleByPageEntrada = campanha?.styles?.find(
      (style) => style.page === "ENTRADA",
    );
    if (styleByPageEntrada) {
      switch (styleByPageEntrada.titleFontSize) {
        case "GRANDE":
          fontSizeForTitles = fontSizeForTitlesLarge;
          break;
        case "MEDIO":
          fontSizeForTitles = fontSizeForTitlesMedium;
          break;
        default:
          fontSizeForTitles = fontSizeForTitlesDefautl;
          break;
      }
    }
  }

  const logoCampaign = () => {
    if (exporLogo) {
      return (
        <div style={{ marginTop: "100px" }}>
          <Image
            className="campanha_logosTopo__centro"
            src={logoCentro}
            alt={i18n.message(
              "alt.logo.principal.mobile",
              "Logo principal da empresa",
            )}
            classNameDesktop={
              usuariologado
                ? "campanha_logosTopo__centro"
                : "campanha_logosTopo__centro__notLogged"
            }
            srcDesktop={logoCentroDesktop}
            altDesktop={i18n.message(
              "alt.logo.principal.desktop",
              "Logo principal da empresa",
            )}
          />
        </div>
      );
    }
  };
  const shouldRenderHeader =
    location.pathname === "/" &&
    !usuariologado &&
    usuario === false &&
    !logoCentro &&
    !logoCentroDesktop &&
    !titleVisible;

  if (shouldRenderHeader) {
    return null;
  }

  return (
    <>
      {exporCampaignInformation ? (
        <header className="weex__container">
          <CampanhaLogosStyle>
            <div className={`campanha_logosTopo ${fontSizeForTitles}`}>
              {logoCampaign()}
              <InfosTextuais
                campanhaName={campanhaName}
                title={title}
                titleVisible={titleVisible}
              />
            </div>
          </CampanhaLogosStyle>
        </header>
      ) : (
        ""
      )}
    </>
  );
}

export default LogosCampanha;

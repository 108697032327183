/* eslint-disable max-len */
import styled from "styled-components";

export const CardStyle = styled.div`
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
  border: 0.125rem solid
    ${(props) => {
      if (props.$achieved) {
        return `#00E291`;
      } else {
        return `#CECECE`;
      }
    }};
  border-radius: 0.875rem;
  background: ${(props) =>
    props.$achieved ? `rgba(0, 226, 145, 0.1)` : `#FFFFFF`};
  padding-bottom: 0.75rem;

  .weex-l-card-trofeus-atividades-header-container {
    max-height: 6vh;
  }

  .weex-l-card-trofeus-header-container__apenasIcone {
    padding-top: 1rem !important;
  }

  .weex-l-card-trofeus-atividades-header-container__trofeu-dia {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -2.5rem;
    margin-left: 1.25rem;
    padding: 0.625rem 1.875rem;
    border-radius: 0.5rem;
    color: #ffffff;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    background: ${(props) => (props.$achieved ? `#00E291` : `#CECECE`)};
    box-shadow: ${(props) => {
      return props.theme.sombraPrimaria;
    }};
    max-width: 2.4rem;
    width: 2.1rem;
    height: 3.4rem;

    p {
      color: #ffffff;
      font-weight: bolder;
    }
  }

  .weex-l-card-trofeus-atividades-body-container__link {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 0 auto;
    gap: 0.625rem; //10px;
  }

  .weex-l-card-trofeus-atividades-body-container {
    width: 82%;
    display: inline;
    text-align: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    min-width: 45%;
    max-width: 45%;
    margin-left: 1em;
    margin-right: 1em;
    min-height: 38vh;

    .weex-l-card-trofeus-atividades-header-container {
      max-height: 4vh;
    }

    .weex-l-card-trofeus-atividades-body-container__link {
      display: flex;
      align-items: baseline;
      justify-content: space-evenly;
      margin: 0 auto;
    }
  }
  /* #endregion DESKTOP */
`;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { WeexDialog } from "../../weexModais/WeexDialog";
import PrimarioBtn from "../../buttonPWA2.0/PrimarioBtn";
import BotaoAsALink from "../../botaoAsALink/BotaoAsALink";
import WeexMarcadorProgresso from "../../WeexMarcadorProgresso/WeexMarcadorProgresso";
import Avatar, { genConfig } from "react-nice-avatar";
import { AvatarModalStyle } from "./style";
import UsuarioService from "../../../services/UsuarioService";
import ParticipanteService from "../../../services/ParticipanteService";
import Face from "./partes/Face";
import Hair from "./partes/Hair";
import Hat from "./partes/Hat";
import Eyes from "./partes/Eyes";
import Glasses from "./partes/Glasses";
import Ears from "./partes/Ears";
import Nose from "./partes/Nose";
import Mouth from "./partes/Mouth";
import Shirts from "./partes/Shirts";
import BotaoFechar from "../../BotaoFechar/BotaoFechar";

const usuarioService = new UsuarioService();
const participanteService = new ParticipanteService();

export function AvatarModal() {
  const avatarLocal = useSelector((state) => state.avatar);
  const i18n = useStore().getState().i18n;
  const [step, setStep] = useState(0);
  const [avatar, setAvatar] = useState({});

  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const svgRef = useRef(null);

  useEffect(() => {
    if (avatarLocal) {
      return setAvatar(avatarLocal);
    }

    setAvatar({
      sex: "man",
      faceColor: "black",
      earSize: "small",
      hairColor: "black",
      hairStyle: "normal",
      hairColorRandom: false,
      hatColor: "black",
      hatStyle: "none",
      eyeStyle: "circle",
      glassesStyle: "none",
      noseStyle: "round",
      mouthStyle: "peace",
      shirtStyle: "short",
      shirtColor: "black",
      eyeBrowStyle: "up",
    });
  }, [avatarLocal]);

  const ocultar = () => {
    dispatch({ type: "HIDE_MODAL", payload: "avatar" });
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    } else {
      setStep(0);
    }
  };

  const changeAvatar = (type, value) => {
    const newAvatar = { ...avatar };
    newAvatar[type] = value;
    setAvatar(newAvatar);
    setAvatar(genConfig(newAvatar));
  };

  const saveAvatar = () => {
    const svgElement = svgRef.current;
    if (avatarLocal?.uuid) {
      avatar.uuid = avatarLocal.uuid;
    }
    participanteService.saveParticipantAvatar(
      avatar,
      svgElement,
      (err, sucesso) => {
        if (err) {
          dispatch({ type: "SHOW_MODAL", payload: "error" });
          dispatch({
            type: "error",
            payload: {
              title: i18n.message(
                "avatarModal.erro.title",
                "Erro ao salvar avatar",
              ),
              message: i18n.message(
                "avatarModal.erro.message",
                "Erro ao salvar avatar. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
              ),
            },
          });
          return;
        }
        if (sucesso) {
          usuarioService.setUsuarioAvatar(sucesso);
          dispatch({ type: "usuarioAvatar", payload: true });
          dispatch({ type: "avatar", payload: sucesso });
          ocultar();
        }
      },
    );
  };

  const presentation = () => {
    return (
      <span className="weexDialog__texts--alignment weexDialog__texts">
        {i18n.message(
          "avatarModal.apresentacao",
          "Escolha entre uma variedade de opções para criar uma representação online única!",
        )}
      </span>
    );
  };

  const conclusion = () => {
    return (
      <span className="weexDialog__texts--alignment weexDialog__texts">
        {i18n.message(
          "avatarModal.conclusao",
          "Pronto! Caso queira modificar o seu avatar basta clicar sobre ele na tela.",
        )}
      </span>
    );
  };

  const steps = () => {
    switch (step) {
      case 1:
        return <Face changeAvatar={changeAvatar} />;
      case 2:
        return (
          <Hair changeAvatar={changeAvatar} currentValue={avatar?.hairStyle} />
        );
      case 3:
        return <Hat changeAvatar={changeAvatar} />;
      case 4:
        return <Ears changeAvatar={changeAvatar} />;
      case 5:
        return <Eyes changeAvatar={changeAvatar} />;
      case 6:
        return <Glasses changeAvatar={changeAvatar} />;
      case 7:
        return <Nose changeAvatar={changeAvatar} />;
      case 8:
        return <Mouth changeAvatar={changeAvatar} />;
      case 9:
        return <Shirts changeAvatar={changeAvatar} />;
      case 10:
        return conclusion();
      default:
        return presentation();
    }
  };

  const buttons = () => {
    let i18nMessage = i18n.message("avatarModal.continuar", "Bora lá!");
    let i18nAriaStatus = i18n.message(
      "aria.status.avatarModal.continuar",
      "Botão bora lá clicado.",
    );

    if (step === 10) {
      i18nMessage = i18n.message("avatarModal.salvar", "Salvar");
      i18nAriaStatus = i18n.message(
        "aria.status.avatarModal.salvar",
        "Botão salvar avatar clicado",
      );
    } else if (step >= 1 && step < 10) {
      i18nMessage = i18n.message("avatarModal.próximo", "Próximo");
      i18nAriaStatus = i18n.message(
        "aria.status.avatarModal.próximo",
        "Botão próximo clicado.",
      );
    }
    return (
      <>
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          <BotaoAsALink
            nome={
              step === 0
                ? i18n.message("avatarModal.fechar", "Mais tarde")
                : i18n.message("geral.voltar", "Voltar")
            }
            funcao={step === 0 ? ocultar : prevStep}
            ariaStatusMessage={
              step === 0
                ? i18n.message(
                    "aria.status.avatarModal.fechar",
                    "Botão mais tarde clicado.",
                  )
                : i18n.message(
                    "aria.status.geral.voltar",
                    "Botão voltar clicado",
                  )
            }
            disabledOnClick={false}
          />
          <PrimarioBtn
            nome={i18nMessage}
            funcao={step === 10 ? saveAvatar : nextStep}
            ariaStatusMessage={i18nAriaStatus}
            disabledOnClick={false}
          />
        </section>
        <section className="weexDialog__stepsContainer weexDialog__container--alignment">
          <WeexMarcadorProgresso indiceAtual={step - 1} totalDeItems={10} />
        </section>
      </>
    );
  };

  return (
    <>
      <WeexDialog dialogRef={dialogRef} onPressESC={ocultar}>
        <BotaoFechar funcao={ocultar} />
        <header className="weexDialog__header">
          <AvatarModalStyle ref={svgRef}>
            <Avatar className="avatarModal__size" {...avatar} />
          </AvatarModalStyle>
        </header>
        <main className="weexDialog__main">
          <h1 className="weexDialog__texts--alignment weexDialog__title">
            {i18n.message("avatarModal.titulo", "Customize seu avatar!")}
          </h1>
          <section>{steps()}</section>
          {buttons()}
        </main>
      </WeexDialog>
    </>
  );
}

import styled from "styled-components";

export const TrofeusStyle = styled.div`
  width: 100%;

  a {
    text-decoration: none;
  }
  .weex-l-trofeu-card-container {
    margin-top: 5em;
  }

  .weex-l-trofeu-agenda-container {
    margin-top: 2.5rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-trofeu-agenda-container {
      display: flex;
    }

    .weex-l-trofeu-card-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: auto 0px auto auto;
      width: 63%;
    }
  }
  /* #endregion DESKTOP */
`;

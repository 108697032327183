import styled from "styled-components";
export const PrimarioBtnStyle = styled.button`
  box-sizing: border-box;
  background-color: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  text-transform: uppercase;
  width: 100%;
  padding: 1.6em;
  padding-left: 2em;
  padding-right: 2em;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: 700;
  color: ${(props) => {
    return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraPrimaria;
  }};
  cursor: pointer;

  .content-botao {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7em;
    flex-direction: row;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
    font-weight: 700;
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }

  path {
    fill: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }
  &:disabled {
    cursor: not-allowed;
    filter: contrast(0.8);
  }

  .disabled {
    cursor: not-allowed;
    filter: contrast(0.8);
  }

  .baseButton__content--link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 0.5rem;
    font-weight: 700;
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }

  @media screen and (max-width: 600px) {
    padding-left: 0em;
    padding-right: 0em;
  }
  /* para desktop */
  @media screen and (min-width: 1024px) {
    &:hover {
      filter: contrast(0.8);
    }

    &:active {
      filter: contrast(0.8);
    }
  }
`;

/**
 */
export const SecundarioBtnStyle = styled.button`
  box-sizing: border-box;
  text-transform: uppercase;
  width: 100%;
  padding: 1.6em;
  padding-left: 2em;
  padding-right: 2em;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: 700;
  background-color: ${(props) => {
    return props.theme.fundoTres;
  }};
  color: ${(props) => {
    return props.theme.fundoSecundario;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  cursor: pointer;

  .content-botao {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
    gap: 0.7em;
    flex-direction: row;
    font-weight: 700;
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  span {
    font-weight: 700;
  }

  &:disabled {
    cursor: not-allowed;
    filter: contrast(0.9);
  }

  .disabled {
    cursor: not-allowed;
    filter: contrast(0.9);
  }

  /* para desktop */
  @media screen and (min-width: 1024px) {
    &:hover {
      filter: contrast(0.8);
    }

    &:active {
      filter: contrast(0.8);
    }
  }

  @media screen and (max-width: 600px) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;

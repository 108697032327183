import React from "react";
import { useStore } from "react-redux";
import AvatarPartsButton from "../AvatarPartsButton";
import { AvatarPartsContainerStyle } from "../style";

export default function Hat({ changeAvatar }) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <h2>{i18n.message("aria.msg.avatar.chapeus", "Chapéus")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          type="hatStyle"
          value="none"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.nenhum",
            "Nenhum selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.nenhum", "Nenhum")}
          avatarPart={"/negativo_dois.svg"}
        />
        <AvatarPartsButton
          type="hatStyle"
          value="beanie"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.chapeu1",
            "Gorro selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.chapeu1", "Gorro")}
          avatarPart={"/svgs-3.0/avatar-parts/beanie.svg"}
        />
        <AvatarPartsButton
          type="hatStyle"
          value="turban"
          funcao={changeAvatar}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.chapeu2",
            "Turbante selecionado",
          )}
          alt={i18n.message("aria.msg.avatar.chapeu2", "Turbante")}
          avatarPart={"/svgs-3.0/avatar-parts/turban.svg"}
        />
      </AvatarPartsContainerStyle>
      <h2>{i18n.message("aria.msg.avatar.cores", "Cores")}</h2>
      <AvatarPartsContainerStyle>
        <AvatarPartsButton
          type="hatColor"
          color="#F9C9B6"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor1",
            "Cor de pele rosa suave",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor1",
            "Cor de pele rosa suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#AC6651"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor2",
            "Cor de pele marrom suave",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor2",
            "Cor de pele marrom suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#77311D"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message(
            "aria.msg.avatar.cor3",
            "Cor de pele marrom",
          )}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor3",
            "Cor de pele marrom selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#9287FF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor4", "Lilás")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor4",
            "Lilás selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#6BD9E9"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor5", "Azul aqua")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor5",
            "Azul aqua selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#FC909F"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor6", "Rosa")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor6",
            "Rosa selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#F4D150"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor7", "Amarelo")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor7",
            "Amarelo selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#E0DDFF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor8", "Lilás suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor8",
            "Lilás suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#D2EFF3"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor9", "Azul aqua suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor9",
            "Azul aqua suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#FFEDEF"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor10", "Rosa suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor10",
            "Rosa suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#FFEBA4"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor11", "Amarelo suave")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor11",
            "Amarelo suave selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#201d1d"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor12", "Preto")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor12",
            "Preto selecionado",
          )}
        />
        <AvatarPartsButton
          type="hatColor"
          color="#ffffff"
          funcao={changeAvatar}
          ariaLabelMsg={i18n.message("aria.msg.avatar.cor13", "Branco")}
          ariaStatusMessage={i18n.message(
            "aria.status.avatar.cor13",
            "Branco selecionado",
          )}
        />
      </AvatarPartsContainerStyle>
    </>
  );
}

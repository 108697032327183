import React from "react";
import { useStore } from "react-redux";
import { WeexInput } from "./style";
import TermosCondicoes from "../AcceptLegalTerm/TermosCondicoes";

function WeexTermsConditions({
  name,
  id,
  required = true,
  disabled = false,
  onChange,
  ariaLabel,
}) {
  const i18n = useStore().getState().i18n;
  return (
    <WeexInput>
      <label
        htmlFor={id}
        aria-label={ariaLabel}
        className="weexInput__checkbox--label"
      >
        <input
          className="weexInput__checkbox"
          type="checkbox"
          id={id}
          name={name}
          required={required}
          disabled={disabled}
          onChange={onChange}
        />
        <div>
          <span aria-hidden="true">
            &nbsp;&nbsp;
            {i18n.message("registro.legal.liaceito", "Li e aceito os")}
            &nbsp;
          </span>
          <TermosCondicoes />
          <span aria-hidden="true">
            &nbsp;
            {i18n.message("registro.legal.daweex", "da Weex")}
          </span>
        </div>
        <span className="weex__a11y--visuallyHidden">
          {`
            ${i18n.message("registro.legal.liaceito", "Li e aceito os")}
            ${i18n.message("registro.legal.termos", " Termos e Condições ")}
            ${i18n.message("registro.legal.daweex", "da Weex.")}
            `}
        </span>
      </label>
    </WeexInput>
  );
}

export default WeexTermsConditions;

import styled from "styled-components";

export const AcerteTempoStyled = styled.div`
  padding-bottom: 1.5rem;

  .custom-color-icon {
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .weex-l-acerteTempo-textos--formatoOriginal {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol,
    ul,
    span {
      /* font-size: initial; */
      color: initial;
      line-height: 29px;

      font-size: inherit;
      line-height: inherit;
      font-style: inherit;
      font-weight: inherit;
    }
    ul {
      list-style: inside;
    }
  }

  .weex-l-acerteTempo-marginAuto {
    margin: auto;
  }

  .weex-l-acerteTempo-container {
    display: flex;
    flex-direction: column;
  }

  .weex-l-acerteTempo-botaoRespiroSuperior {
    margin-top: 1.5rem;
    justify-content: center;
  }

  .weex-l-acerteTempo-imagemRespiroSuperior {
    margin-top: 1.5rem;
  }

  .weex-l-acerteTempo-apresentacaoMargin {
    margin-top: 1.5rem;
  }

  .weex-l-acerteTempo-imagens {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .weex-l-acerteTempo-revisao-btnContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .weex-l-acerteTempo-revisao-btnContainer--container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .weex-l-acerteTempo-revisao-btnTexto {
    display: flex;
    justify-content: space-evenly;
    span {
      color: ${(props) => {
        return props.theme.fundoSecundario;
      }};
    }
  }

  // setando tamanho dos textos que vem do backend
  .weex-l-acerteTempo-textos-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    word-break: break-word;
    p {
      font-size: 1.188rem;
      line-height: 1.5rem;
    }
    ol {
      font-size: 1.188rem !important;
    }
    ul {
      font-size: 1.188rem !important;
    }
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-acerteTempo-imagemRespiroSuperior {
      margin-top: 0;
    }

    .weex-l-acerteTempo-apresentacaoMargin {
      margin-top: 0px;
    }

    .weex-l-acerteTempo-container-row {
      margin-left: 5rem;
      margin-right: 5rem;
      flex-direction: row;
    }

    .weex-l-acerteTempo-container-rowReversed {
      margin-left: 5rem;
      margin-right: 5rem;
      flex-direction: row-reverse;
    }

    .weex-l-acerteTempo-container--flexFilhos {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      flex: 1;
    }

    .weex-l-acerteTempo-posicionamento-imagens {
      display: flex;
      align-items: baseline;
      justify-content: center;
    }

    .weex-l-acerteTempo-botaoRespiroSuperiorMenor {
      margin-top: 3rem;
    }

    .weex-l-acerteTempo-botaoRespiroSuperior {
      margin-top: 5rem;
    }

    .weex-l-acerteTempo-botoesContainer {
      margin-left: 20%;
      margin-right: 20%;
    }
    .weex-l-acerteTempo-botaoParaResponder {
      width: fit-content;
    }

    // setando tamanho dos textos que vem do backend
    .weex-l-acerteTempo-textos-container {
      padding: 0;
    }
  }
  /* #endregion DESKTOP */
`;

import React from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import ContadorRegressivo from "./ContadorRegressivo";
import AlternativaAT from "./AlternativaAT";
import TagRespostaAT from "./TagRespostaAT";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";

import Spinner from "../WeexSpinner/Spinner";

function QuestaoAT() {
  const {
    esconderProntoResponderBtn,
    respondeAcerteTempoEndpoint,
    acerteTempo,
    mostraAlternativas,
    setaAcerteTempoIndice,
    indiceAcerteTempo,
    totalDeQuestoes,
    proximoAcerteTempo,
    mostrarAlternativas,
    iniciarContador,
    resetarContador,
    isContando,
    responseAcerteRequest,
    setResponseAcerteRequestContext,
  } = useAcerteTempo();

  const i18n = useStore().getState().i18n;

  const onClick = () => {
    resetarContador(false);
    // abro a próxima questão
    respondeAcerteTempoEndpoint(
      [],
      acerteTempo?.questions[indiceAcerteTempo]?.uuid,
      false,
    )
      .then(() => {
        iniciarContador();
        mostraAlternativas();
        setaAcerteTempoIndice(indiceAcerteTempo, "QUESTAO_ABERTA");
      })
      .finally(() => {
        setResponseAcerteRequestContext(false);
      });
  };

  const proximaQuestaoOnClick = () => {
    resetarContador(true);
    proximoAcerteTempo();
  };

  const responderBtn = () => {
    if (!esconderProntoResponderBtn) {
      return (
        <>
          <PrimarioBtn
            nome={i18n.message(
              "dinamica.acerteTempo.btn.prontoResponder",
              "Pronto para Responder",
            )}
            disabled={""}
            ariaDisabled={""}
            ariaHidden={""}
            tabIndex={""}
            ariaLabelMsg={""}
            classeComplementar={""}
            funcao={onClick}
            ariaStatusMessage={""}
          />
        </>
      );
    }
  };

  const imagemQuestao = () => {
    if (acerteTempo?.questions[indiceAcerteTempo]?.image) {
      return (
        <>
          <img
            className="weex__image is-2by1 weex-l-acerteTempo-marginAuto"
            src={acerteTempo?.questions[indiceAcerteTempo]?.image}
            alt={i18n.message(
              "alt.dinamica.acerteTempo.imagemQuestao",
              "Imagem da questão da dinâmica Hábito Certo",
            )}
          />
        </>
      );
    }
  };

  const enunciadoQuestao = () => {
    return (
      <>
        <div
          className="weex-l-acerteTempo-textos-container weex-l-acerteTempo-textos--formatoOriginal"
          dangerouslySetInnerHTML={{
            __html: acerteTempo?.questions[indiceAcerteTempo]?.statement,
          }}
        ></div>
      </>
    );
  };

  return (
    <>
      <section className="weex-l-acerteTempo-container weex-l-acerteTempo-container-row">
        <WeexDesktopMode className={"weex-l-acerteTempo-container--flexFilhos"}>
          <div className="weex-l-acerteTempo-posicionamento-imagens">
            {imagemQuestao()}
          </div>
          <ContadorRegressivo />
          <TagRespostaAT />
          {mostrarAlternativas && !isContando && !responseAcerteRequest ? (
            <div className="weex-l-acerteTempo-botoesContainer">
              <PrimarioBtn
                nome={i18n.message(
                  indiceAcerteTempo === totalDeQuestoes - 1
                    ? "dinamica.acerteTempo.btn.concluir"
                    : "dinamica.acerteTempo.btn.proximo",
                  indiceAcerteTempo === totalDeQuestoes - 1
                    ? "Concluir"
                    : "Próximo",
                )}
                funcao={proximaQuestaoOnClick}
              />
            </div>
          ) : (
            ""
          )}
        </WeexDesktopMode>
        <div className="weex-l-acerteTempo-container--flexFilhos">
          {enunciadoQuestao()}

          <WeexDesktopMode>
            {responseAcerteRequest ? (
              <span className="mt-4">
                <Spinner isVisivel={responseAcerteRequest} />
              </span>
            ) : (
              <AlternativaAT />
            )}
          </WeexDesktopMode>

          <WeexMobileMode
            className={"weex-l-acerteTempo-imagemRespiroSuperior"}
          >
            {!esconderProntoResponderBtn ? imagemQuestao() : ""}
          </WeexMobileMode>

          <div className="weex-l-acerteTempo-botaoParaResponder weex-l-acerteTempo-botaoRespiroSuperior">
            {responderBtn()}
          </div>
        </div>
      </section>

      <WeexMobileMode>
        <ContadorRegressivo />

        {responseAcerteRequest ? (
          <span className="mt-4">
            <Spinner isVisivel={responseAcerteRequest} />
          </span>
        ) : (
          <AlternativaAT />
        )}
      </WeexMobileMode>
    </>
  );
}

export default QuestaoAT;

import React from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import { AlternativaContainer, AlternativaStyle } from "./alternativaStyle";
import TagRespostaAT from "./TagRespostaAT";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import ExplanacaoAT from "./explanacaoAT";

const AlternativasEnum = Object.freeze({
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
});

function AlternativaRevisaoAT() {
  const {
    acerteTempo,
    indiceAcerteTempo,
    componentePagina,
    TipoComponenteEnum,
    obtemPlacarFinal,
  } = useAcerteTempo();

  const alternativas = acerteTempo?.questions[indiceAcerteTempo]?.alternatives;
  const alternativaEscolhida =
    obtemPlacarFinal !== null
      ? obtemPlacarFinal?.scores[indiceAcerteTempo]
      : null;

  const i18n = useStore().getState().i18n;

  let render = null;
  if (alternativaEscolhida != null) {
    render = alternativas.map((alternativa, index) => {
      let estiloDiv = "weex-l-acerteTempo-alternativa";
      let estiloLabel = "weex-l-acerteTempo-alternativa--label";
      let estiloSeparadorLetra = "weex-l-acerteTempo-verticalLine";
      if (
        alternativa.uuid === alternativaEscolhida.alternativeUuid &&
        !alternativa.correct
      ) {
        estiloDiv =
          "weex-l-acerteTempo-alternativa--errou weex-l-acerteTempo-alternativa";
        estiloLabel =
          "weex-l-acerteTempo-alternativa--label--errou weex-l-acerteTempo-alternativa--label";
        estiloSeparadorLetra =
          "weex-l-acerteTempo-verticalLine--errou weex-l-acerteTempo-verticalLine";
      } else if (
        alternativa.uuid === alternativaEscolhida.alternativeUuid &&
        alternativa.correct
      ) {
        estiloDiv =
          "weex-l-acerteTempo-alternativa--acertou weex-l-acerteTempo-alternativa";
        estiloLabel =
          "weex-l-acerteTempo-alternativa--label--acertou weex-l-acerteTempo-alternativa--label";
        estiloSeparadorLetra =
          "weex-l-acerteTempo-verticalLine--acertou weex-l-acerteTempo-verticalLine";
      } else if (
        alternativa.uuid !== alternativaEscolhida.alternativeUuid &&
        alternativa.correct
      ) {
        estiloDiv =
          "weex-l-acerteTempo-alternativa--correta weex-l-acerteTempo-alternativa";
        estiloLabel =
          "weex-l-acerteTempo-alternativa--label--correta weex-l-acerteTempo-alternativa--label";
        estiloSeparadorLetra =
          "weex-l-acerteTempo-verticalLine--correta weex-l-acerteTempo-verticalLine";
      }

      return (
        <AlternativaStyle key={alternativa?.uuid}>
          <div className={`${estiloDiv}`}>
            <input
              id={alternativa?.uuid}
              type="radio"
              name="alternativa"
              value={alternativa?.uuid}
              disabled={true}
              readOnly={true}
            />
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              className={`${estiloLabel}`}
              htmlFor={alternativa?.uuid}
              aria-label={i18n.message(
                "alt.dinamica.acerteTempo.alternativa",
                "alternativa:",
              )}
            >
              <span className="weex-l-acerteTempo-alternativa--alfabeto">
                {AlternativasEnum[index]}
              </span>
              <div className={`${estiloSeparadorLetra}`}></div>
              <span
                className="weex-l-acerteTempo-alternativa--enunciado weex-l-acerteTempo-textos--formatoOriginal"
                dangerouslySetInnerHTML={{ __html: alternativa?.content }}
              ></span>
            </label>
          </div>
        </AlternativaStyle>
      );
    });
  }

  if (componentePagina === TipoComponenteEnum.modoRevisao) {
    return (
      <>
        <ExplanacaoAT />
        <AlternativaContainer>{render}</AlternativaContainer>
        <WeexMobileMode>
          <TagRespostaAT />
        </WeexMobileMode>
      </>
    );
  } else {
    return <></>;
  }
}

export default AlternativaRevisaoAT;

import React from "react";

const ArrowRight = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3450_2262)">
        <path
          d="M7.5 3L11 6.5L7.5 10"
          stroke="#201D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 6.5L11 6.5"
          stroke="#201D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 6.5C1 6.77614 1.22386 7 1.5 7C1.77614 7 2 6.77614 2 6.5C2 6.22386 1.77614 6 1.5 6C1.22386 6 1 6.22386 1 6.5Z"
          stroke="#201D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3450_2262">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(12 0.5) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRight;

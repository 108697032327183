import React from "react";
import { useStore } from "react-redux";
import {
  ConvitesContainerStyle,
  CardConviteStyle,
  PaginacaoContainerStyle,
} from "./style";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";

export default function AceiteConvite({
  convitesAceitos,
  handlePrevPage,
  handleNextPage,
  numeroTotalItens,
  page,
}) {
  const i18n = useStore().getState().i18n;

  const convites = convitesAceitos[0]?.guestEngagement?.map((convidado) => {
    return (
      <>
        <CardConviteStyle>
          <p className="weex-l-cardConvite__matricula">
            <strong className="weex-l-cardConvite__matricula--respiroLateral">
              {i18n.message("convite.afiliados.card.matricula", "Matrícula")}
            </strong>
            {convidado?.enrollment}
          </p>
          <div className="weex-l-cardConvite__confirmado">
            <span className="weex-l-cardConvite__confirmado--icone">
              <i className="fas fa-check"></i>
            </span>
            <p className="weex-l-cardConvite__confirmado--texto">
              {i18n.message(
                "convite.afiliados.card.cadastroConfirmado",
                "Cadastro confirmado",
              )}
            </p>

            {convidado?.pointsToInviter && convidado?.pointsToInviter > 0 ? (
              <p>
                +{convidado?.pointsToInviter} {i18n.message("geral.pts", "PTS")}
              </p>
            ) : (
              ""
            )}
          </div>
        </CardConviteStyle>
      </>
    );
  });

  return (
    <>
      <ConvitesContainerStyle>{convites}</ConvitesContainerStyle>
      <PaginacaoContainerStyle>
        <SecundarioBtn
          nome={i18n.message("convites.listagem.anterior", "Anterior")}
          funcao={handlePrevPage}
          disabled={page === 0}
          ariaLabelMsg={i18n.message(
            "aria.label.geral.mensagem.pagina.anterior",
            "Página anterior.",
          )}
          disabledOnClick={false}
        />
        <p className="weex-l-convite__paginacao--paginas">
          {page + 1}/{numeroTotalItens}
        </p>
        <SecundarioBtn
          iconposition="right"
          nome={i18n.message("convites.listagem.proximo", "Próxima")}
          funcao={handleNextPage}
          disabled={page + 1 === numeroTotalItens}
          ariaLabelMsg={i18n.message(
            "aria.label.geral.mensagem.proxima.pagina",
            "Próxima página.",
          )}
          disabledOnClick={false}
        />
      </PaginacaoContainerStyle>
    </>
  );
}

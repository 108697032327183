import styled from "styled-components";

export const NovosHabitosStyled = styled.div`
  .card,
  .card-content {
    background: transparent;
    box-shadow: none;
    padding: 1.5rem;
  }
  .dinamica-container {
    overflow: hidden;
  }
  .marcador-progresso {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .marcador {
    border-radius: 100%;
    background: #dfdde3;
    float: left;
  }

  .marcador-concluido {
    background: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }
  .botao-desabilitado {
    background-color: #dfdde3;
    color: #ffffff;
    cursor: not-allowed;
  }
  .weex-card-container-imagem {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
  }
  .weex-card-interno__content {
    min-height: 30vh;
    margin-bottom: 3vh;
  }
  .weex-card-interno__content__fato-fake__novos-habitos {
    min-height: 5vh;
    padding-bottom: 3vh;

    .markup-novos-habitos {
      p {
        text-align: center;
        color: #201d1d;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .botao-voltar-mobile {
    text-align: center;
    button {
      width: auto;
    }
  }
  @media screen and (min-width: 700px) {
    .desktop-content-left {
      margin-right: 10vw;
      margin-top: 20vh;
      min-width: 25%;
    }
    .desktop-content {
      display: flex;
    }
    .card-novos-habitos {
    }
    .botao-voltar-desktop {
      display: flex;
      min-width: 80vw;
      justify-content: flex-start;

      button {
        width: auto;
      }
    }
    .desktop-content-right-CardNovosHabitos {
      max-height: 75vh;
      overflow: auto;
      padding-left: 1em;
      padding-right: 1em;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => {
          return props.theme.fundoSecundario;
        }};
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => {
          return props.theme.fundoSecundario || `#533888`;
        }};
      }
    }
    .desktop-content-right-marcador {
      position: fixed;
      right: 30vw;
      top: 100%;
      width: 8em;
      margin-top: -2.5em;
    }

    .desktop-novos-habitos {
      margin: 1vw 6vw;
      margin-bottom: 0;
    }

    .marcador-progresso {
      padding-top: 1em;
      justify-content: center;
    }

    .marcador {
      margin: 0.3em;
    }
    .botao-card-novos-habitos {
      width: 100%;
      text-align: center;
    }
    .box-custom {
      background: transparent;
      border: 1px solid #ccc;
      border-radius: 14px;
      position: relative;
    }
  }
`;

import React, { useRef } from "react";
import { useStore } from "react-redux";
import { MidiaStyle } from "./style";

export default function MidiaEnquete({ media, principalMedia = false }) {
  const thumbnailVideoRef = useRef(null);

  const i18n = useStore().getState().i18n;
  const mediaClassName = principalMedia
    ? "midia__maxWidth"
    : "midia__alternatives";

  const handleThumbnailClick = (url) => {
    const videoElement = thumbnailVideoRef.current;

    if (videoElement) {
      videoElement.src = url;
      videoElement.play();

      // Tenta entrar em modo tela cheia
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen(); // Para Firefox
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen(); // Para Chrome e Safari
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen(); // Para IE/Edge
      }
    }
  };

  const img = () => {
    if (media?.imgUrl) {
      return (
        <img
          className={`${mediaClassName}`}
          alt={i18n.message("alt.enqueteCampanha", "Imagem da enquete.")}
          src={media?.imgUrl}
        />
      );
    }
  };
  const video = (auxClass = "") => {
    if (media?.video?.signedThumb) {
      return (
        <video
          ref={thumbnailVideoRef}
          className={`${mediaClassName} ${auxClass}`}
          preload="auto"
          controls
          poster={media?.video?.signedThumb}
        >
          <track kind="captions" />
          <source src={media?.video?.signedVideo} type="video/mp4" />
          <source src={media?.video?.signedVideo} type="video/ogg" />
          <source src={media?.video?.signedVideo} type="video/webm" />
          <source src={media?.video?.signedVideo} type="video/x-msvideo" />
          <source src={media?.video?.signedVideo} type="video/quicktime" />
          {i18n.message(
            "enqueteCampanha.video.format.warning",
            "Seu navegador não suporta a tag de vídeo.",
          )}
        </video>
      );
    }
  };

  const thumbnail = () => {
    if (media?.video?.signedThumb) {
      return (
        <button
          className="midia__thumbnail--btn"
          onClick={() => handleThumbnailClick(media?.video?.signedVideo)}
        >
          <img
            className={`${mediaClassName} midia__thumbnail`}
            alt={i18n.message(
              "alt.enqueteCampanha.thumbnail",
              "Thumbnail do vídeo da enquete.",
            )}
            src={media?.video?.signedThumb}
          />
          <span className="midia__thumbnail--icon">
            <i className="fas fa-play-circle"></i>
          </span>
        </button>
      );
    }
  };

  if (media) {
    if (media?.type === "IMAGEM") {
      return <MidiaStyle>{img()}</MidiaStyle>;
    }
    if (media?.type === "VIDEO") {
      if (media?.video?.signedThumb && !principalMedia) {
        return (
          <MidiaStyle>
            <div className="midia__thumbnail--container">
              {thumbnail()}
              {video("midia__thumbnail--video")}
            </div>
          </MidiaStyle>
        );
      } else {
        return <MidiaStyle>{video()}</MidiaStyle>;
      }
    }
  } else {
    return <></>;
  }
}

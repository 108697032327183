import React from "react";
import styled from "styled-components";
/**
 * @deprecated - retrocompatibilidade com o weex 2.0. Deixará de existir com o andamento da weex 3.0.
 * Para novos desenvolvimentos, favor usar o WeexMarcadorProgresso.
 *
 * @param {Object} props
 * @param {number} props.indiceAtual
 * @param {number} props.totalDeItems
 */
export default function MarcadorProgresso({ indiceAtual, totalDeItems }) {
  const bolinhas = [];

  for (let indiceQuestao = 0; indiceQuestao < totalDeItems; indiceQuestao++) {
    bolinhas.push(
      <MarcadorBolinha
        isColorida={indiceQuestao <= indiceAtual}
        key={indiceQuestao}
      />,
    );
  }

  return <MarcadorProgressoStyle>{bolinhas}</MarcadorProgressoStyle>;
}

/**
 * @param {Object} props
 * @param {boolean} props.isColorida
 */
function MarcadorBolinha({ isColorida }) {
  return (
    <MarcadorStyle
      className={`
        marcador
        ${isColorida && "marcador-concluido"}
      `}
      style={{ width: "1vw", height: "1vw" }}
    />
  );
}

export const MarcadorProgressoStyle = styled.div`
  /* .marcador-progresso { */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .marcador {
    border-radius: 100%;
    background: #dfdde3;
    float: left;
  }

  .marcador-concluido {
    background: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  /* #region LEGADO_DESKTOP */
  /*media query min-width 700px*/
  @media screen and (min-width: 700px) {
    /* .marcador-progresso { */
    padding-top: 1em;
    justify-content: center;

    .marcador {
      margin: 0.3em;
    }
  }
  /* #endregion DESKTOP-ANTIGO */
  /* #endregion LEGADO_DESKTOP */
`;

export const MarcadorStyle = styled.div`
  .marcador {
    border-radius: 100%;
    background: #dfdde3;
    float: left;
  }

  .marcador-concluido {
    background: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }
`;

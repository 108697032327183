import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Weex } from "./styles/AppStyle";
import Footer from "./comps/footer/Footer";
import LogosCampanha from "./comps/campanha/LogosCampanha";
import Header from "./comps/header/Header";
import WeexRoutes from "./routes/WeexRoutes";
import { WeexModalFactory } from "./comps/weexModais/WeexModalFactory";

function App() {
  const usuario = useSelector((state) => state.usuario);
  const usuariologado = useSelector((state) => state.usuariologado);
  const dispatch = useDispatch();
  const backgroundispresent = useSelector((state) => state.backgroundispresent);

  const containerRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const currentContainerRef = containerRef.current;
    const checkSize = (entries) => {
      if (currentContainerRef) {
        const width = currentContainerRef.offsetWidth;
        const newIsDesktop = width > 700;
        if (newIsDesktop !== isDesktop) {
          setIsDesktop(newIsDesktop);
          dispatch({ type: "isDesktopMode", payload: newIsDesktop });
        }
      }
    };

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => checkSize(entries));
    });

    if (currentContainerRef) {
      resizeObserver.observe(currentContainerRef);
      checkSize(); // Check size initially
    }

    return () => {
      if (currentContainerRef) {
        resizeObserver.unobserve(currentContainerRef);
      }
    };
  }, [dispatch, isDesktop]);

  return (
    <Weex
      $usuariologado={usuariologado}
      $usuario={usuario ? "true" : "false"}
      $backgroundispresent={backgroundispresent}
      className="weex"
      id="weex"
    >
      <Router>
        <WeexModalFactory />
        <Header />
        <LogosCampanha />
        <main className="weex__container" ref={containerRef}>
          <WeexRoutes />
        </main>
        <Footer />
      </Router>
    </Weex>
  );
}

export default App;

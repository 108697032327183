import React from "react";
const ArrowLeft = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3450_2187)">
        <path
          d="M4.5 3L1 6.5L4.5 10"
          stroke="#201D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 6.5L1 6.5"
          stroke="#201D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 6.5C11 6.77614 10.7761 7 10.5 7C10.2239 7 10 6.77614 10 6.5C10 6.22386 10.2239 6 10.5 6C10.7761 6 11 6.22386 11 6.5Z"
          stroke="#201D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3450_2187">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowLeft;

import styled from "styled-components";

export const CardStyle = styled.div`
  background-color: #e3e4e340;
  border: 0.063rem solid #9090901a;
  border-radius: 0.75rem;
  padding: 1rem; //16px;

  .cardTeam__header,
  .cardTeam__main {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem; //24px;
  }

  .cardTeam__main {
    width: 9.688rem; // 155px;
    gap: 0.75rem; // 12px;
    background-color: #ffffff;
    border-radius: 0.5rem; // 8px;
    padding: 0.5rem; // 8px;
  }

  .cardTeam__imageContainer {
    width: 3.5rem; // 56px;
    height: 3.5rem; // 56px;
    background-color: #e7e7e7;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .cardTeam__imageContainer--logoDefault {
      width: 2.25rem; // 36px;
      height: 2.25rem; // 36px;
    }

    .cardTeam__imageContainer--logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cardTeam__pointsContainer {
    padding: 0.375rem; // 6px;
    border-radius: 0.5rem; // 8px;
    background-color: #d2f4e0;
    margin-left: auto;
  }

  .cardTeam__pointsContainer--text {
    color: #005925;
  }

  .cardTeam__imgLeaderContainer {
    width: 1.875rem; // 30px;
    height: 1.875rem; // 30px;
    background-color: #e7e7e7;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.125rem; // 18px;
      height: 1.125rem; // 18px;
    }
  }

  .cardTeam__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    /* width: 30%; */
    /* width: 110%; */
  }
  /* #endregion DESKTOP */
`;

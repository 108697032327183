import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import WeexModal from "../../comps/weexModais/WeexModal";
import CampanhaService from "../../services/CampanhaService";
import UsuarioService from "../../services/UsuarioService";
import styled from "styled-components";
import WeexSelect from "../../comps/WeexInputs/WeexSelect";

export const SelectStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
    max-width: 100%;
  }

  span {
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }

  path {
    stroke: ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};
  }

  .selectCampaign__form {
    max-width: 100%;
  }

  .selectCampaign__form--sendButton {
    margin-top: 1rem;
    text-align: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

/**
 *
 * @returns Página na qual o participante pode selecionar uma campanha manualmente
 * com base no nome da empresa na qual trabalha e nome da campanha caso a mesma seja pública.
 */
function SeletorCampanha() {
  const service = new CampanhaService();
  const usuarioService = new UsuarioService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const i18n = useStore().getState().i18n;

  const [codigo, setCodigo] = useState("");
  const [clienteEscolhido, setClienteEscolhido] = useState("");
  const [campanhaEscolhida, setCampanhaEscolhida] = useState("");
  const [campanhasPublicas, setCampanhas] = useState([]);
  const [clienteCampanha, setCliente] = useState("");
  const [erroMsg, setErroMsg] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });

  useEffect(() => {
    usuarioService.removeUsuarioCorrente();
    dispatch({ type: "usuario", payload: false });
  });

  /**
   * Obtem todas as campanhas públicas da Weex
   */
  useEffect(() => {
    new CampanhaService().obterCampanhas((erro, sucesso) => {
      if (erro) {
        console.log(erro);
      }
      if (sucesso) {
        setCampanhas(sucesso);
      }
    });
  }, []);

  const onChangeCliente = (e) => {
    const { value } = e.target;
    if (value === "") {
      setCliente("");
      setClienteEscolhido("");
      setCodigo("");
      return;
    }

    const dadosCliente = campanhasPublicas[value].cliente.campanhas;
    if (dadosCliente.length > 1) {
      setCliente(dadosCliente);
      setClienteEscolhido(value);
      setCodigo("");
    } else {
      setCliente("");
      setClienteEscolhido(value);
      setCodigo(dadosCliente[0].code);
    }
  };

  const onChangeCampanha = (e) => {
    const { value } = e.target;
    if (value === "") {
      setCampanhaEscolhida("");
      setCodigo("");
      return;
    }
    setCodigo(value);
    setCampanhaEscolhida(value);
  };

  const submitRedirectEvent = (event) => {
    event.preventDefault();
    service.removeLocalStorageSeCampanhasForemDiferentes(codigo);
    service.definirCampanha(codigo, {}, (erro, sucesso) => {
      if (erro) {
        setErroMsg({
          isWrong: true,
          titulo: i18n.message(
            "redirecionamento.erro.aoEncontrarCampanha.titulo",
            "Campanha não encontrada",
          ),
          mensagem: i18n.message(
            "redirecionamento.erro.aoEncontrarCampanha.mensagem",
            "Escolha uma campanha antes de prosseguir.",
          ),
          voltar: true,
        });
      }
      if (sucesso) {
        dispatch({ type: "campanha/selecionar", payload: sucesso.messages });
        dispatch({ type: "template", payload: sucesso.template });
        dispatch({ type: "icone", payload: sucesso.icons });
        dispatch({ type: "usuario", payload: false });
        setErroMsg({ isWrong: false });
        navigate("/");
      }
    });
  };

  const callbackModal = () => {
    if (erroMsg.isWrong === true) {
      setErroMsg({ isWrong: false });
    }
  };

  return (
    <>
      {erroMsg.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erroMsg.titulo}
          conteudo={erroMsg.mensagem}
        />
      ) : null}
      <SelectStyled>
        <div>
          <img
            src="../logo.png"
            alt={i18n.message(
              "alt.redirecionamento.campanha.weex.logo",
              "Logo da empresa fornecedora do serviço Weex",
            )}
          />
        </div>
        <form className="selectCampaign__form">
          <WeexSelect
            label={i18n.message(
              "redirecionamento.empresa",
              "Em qual empresa você trabalha?",
            )}
            id="select-empresa"
            value={clienteEscolhido}
            onChange={onChangeCliente}
          >
            <option value="">
              {i18n.message(
                "redirecionamento.empresaSelecao",
                "Selecione a sua empresa",
              )}
            </option>
            {campanhasPublicas?.map((clientes, index) => {
              return (
                <option key={clientes.cliente.uuid} value={index}>
                  {clientes.cliente.name}
                </option>
              );
            })}
          </WeexSelect>

          {clienteCampanha ? (
            <WeexSelect
              label={i18n.message(
                "redirecionamento.campanha",
                "De qual campanha está participando?",
              )}
              id="select-campanha"
              value={campanhaEscolhida}
              onChange={onChangeCampanha}
            >
              <option value="">
                {i18n.message(
                  "redirecionamento.campanhaSelecao",
                  "Selecione uma campanha",
                )}
              </option>
              {clienteCampanha.map((campanha, index) => {
                return (
                  <option
                    dangerouslySetInnerHTML={{ __html: campanha.name }}
                    key={campanha.uuid}
                    value={campanha.code}
                  ></option>
                );
              })}
            </WeexSelect>
          ) : (
            ""
          )}
          <div className="selectCampaign__form--sendButton">
            {codigo && (
              <PrimarioBtn
                nome={i18n.message(
                  "redirecionamento.redirecionar.botao",
                  "Entrar",
                )}
                disabled={codigo.length === 0}
                funcao={submitRedirectEvent}
                ariaStatusMessage={i18n.message(
                  "aria.status.seletorcampanha.botaoredirecionar",
                  "Botão redirecionar clicado.",
                )}
              />
            )}
          </div>
        </form>
      </SelectStyled>
    </>
  );
}

export default SeletorCampanha;

import instance from "./Api";
import UsuarioService from "./UsuarioService";

export default class ParticipanteService {
  host() {
    return process.env.REACT_APP_HOST_API;
  }

  ativarParticipante(codigo, callback) {
    instance
      .get(this.host() + "/api/v1/activateSubscription/" + codigo)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  saveParticipantAvatar(request, svgElement, callback) {
    let usuarioService = new UsuarioService();
    let campaignCode = usuarioService?.usuarioCorrente()?.campaignCode;
    let codeParticipante = usuarioService?.getCodigoParticipante();
    const formData = new FormData();
    if (svgElement) {
      const svgString = new XMLSerializer().serializeToString(svgElement);
      const file = new Blob([svgString], { type: "image/svg+xml" });
      formData.append("file", file, "avatar_" + codeParticipante + ".svg");
    }
    formData.append("avatar", JSON.stringify(request));

    instance
      .post(
        this.host() + `/api/v1/campaign/${campaignCode}/participant/avatar`,
        formData,
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }
}

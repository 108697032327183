import styled from "styled-components";
import { BaseButton } from "../../button/style";

export const AvatarModalStyle = styled.span`
  .avatarModal__size {
    width: 11.25rem; //180px
    height: 11.25rem; //180px
    background-color: ${(props) => {
      return props.theme.cardLogadoFundo + "!important";
    }};
  }
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .avatarModal__size {
      width: 17.5rem; //280px
      height: 17.5rem; //280px
    }
  }
  /* #endregion DESKTOP */
`;

export const AvatarPartsContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const AvatarPartsButtonStyle = styled(BaseButton)`
  padding: 0.125rem; // 2px;
  background-color: #ffffff;
  border: 0.125rem solid
    ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};

  .baseButton__iconContainer {
    min-width: ${(props) => {
      if (props.$img) {
        return "1.563rem";
      }
    }};
    min-height: ${(props) => {
      if (props.$img) {
        return "1.563rem";
      }
    }};
    background-image: url(${(props) => props.$img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    background-color: ${(props) => {
      if (props.$disabled) {
        return "#efefef";
      }
      return props.$color || "#ffffff";
    }};
  }
`;

import React from "react";
import { useStore } from "react-redux";
import { TagPlacarStyle } from "./TagRespostaStyle";
import TagPontuacao from "./TagPontuacao";

function TagPlacarAT({ correct, totalPoints, campaignType }) {
  const i18n = useStore().getState().i18n;

  const cssColumn = campaignType !== "REGULAR" ? "column" : "";

  const icons = () => {
    if (correct) {
      return (
        <>
          <span
            className={`
          ${cssColumn} 
          weex-l-acerteTempo-placar-desktopIconeSize 
          has-text-left 
          icon-text 
          weex-l-acerteTempo-resposta-tag--textos 
          weex-l-acerteTempo-icone
        `}
          >
            <i className="fas fa-check"></i>
          </span>
        </>
      );
    } else {
      return (
        <span
          className={`
          ${cssColumn} 
          weex-l-acerteTempo-placar-desktopIconeSize 
          has-text-left icon-text 
          weex-l-acerteTempo-resposta-tag--textos 
          weex-l-acerteTempo-icone`}
        >
          <i className="fas fa-times"></i>
        </span>
      );
    }
  };
  return (
    <>
      {campaignType !== "REGULAR" ? (
        <>
          <TagPlacarStyle $correto={correct}>
            <div className="weex-l-acerteTempo-resposta-tag--bulmaColumn">
              {icons()}
              <TagPontuacao totalPoints={totalPoints} />
              <div className="acerteTempo__tagResposta--ghostDiv" />
            </div>
          </TagPlacarStyle>
          <span className="weex__a11y--visuallyHidden">{`${totalPoints} ${i18n.message(
            "alt.dinamica.acerteTempo.tag.pontosExtenso",
            "pontos",
          )}`}</span>
        </>
      ) : (
        <>
          <TagPlacarStyle $correto={correct}>
            <div className="weex-l-acerteTempo-resposta-tag--bulmaColumn justify-center">
              {icons()}
            </div>
          </TagPlacarStyle>
        </>
      )}
    </>
  );
}

export default TagPlacarAT;

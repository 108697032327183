import styled from "styled-components";

export const AvaliacaoDinamicaPresencialStyled = styled.div`
  .botao-invisivel + .botao-invisivel {
    border-left: solid 2px #ffffff;
  }

  .botao-invisivel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .botao-invisivel {
    background: transparent;
    border: none;
    padding-right: 0.5em;
    margin: 0px;
  }

  .icon-star-avaliacao {
    width: 2rem;
    height: 2rem;
  }
  .presencial__feedback {
    text-align: left;
    font-weight: 800;
    font-size: 18px;
    line-height: 18px;
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .presencial__starRating {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .presencial__voltar,
  .presencial__enviarComentario {
    margin-top: 1.5rem;
  }
  .container-avaliacao {
    border-left: none !important;
  }
`;

export const PresencialStyled = styled.div`
  .container-contador {
    margin: 2rem;
  }

  .container-footer {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .presencial__aviso {
    text-align: center;
    color: #4a4a4a;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
  }
`;

import styled from "styled-components";

export const BotaoFatoFakeStyle = styled.button`
  padding-top: 5px;
  padding-bottom: 5px;
  width: 45%;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  padding-left: 2em;
  padding-right: 2em;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 800;
  background-color: ${(props) => {
    return props.theme.fundoTres;
  }};
  color: ${(props) => {
    return props.theme.fundoSecundario;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  cursor: pointer;

  .weex-l-botaoFatoFake-conteudo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: 800;
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  .weex-l-botaoFatoFake-conteudo__icone {
    margin-right: 0.313rem;
    margin-left: 1rem;
  }

  @media screen and (max-width: 600px) {
    padding-left: 0em;
    padding-right: 0em;
  }

  /* para desktop */
  @media screen and (min-width: 1024px) {
    &:hover {
      filter: contrast(0.8);
    }

    &:active {
      filter: contrast(0.8);
    }
  }
`;

import styled from "styled-components";

export const ComoFuncionaStyle = styled.div`
  .weex-como-funciona {
    margin-top: 40px;
    color: #201d1d;

    button {
      margin-top: 50vh;
    }

    ul {
      list-style: unset;
      padding-left: 40px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  @media screen and (min-width: 700px) {
    .header-como-funciona {
      display: flex;
      align-items: flex-start;
      background-size: 30% 70%;
      background-repeat: no-repeat;
      background-position: center bottom;
      padding: 3vw 0;
    }
  }
`;

import React, { useEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import { BotaoFatoFakeStyle } from "./style";

/**
 * Componente botão fato do fato ou fake.
 * @param {Object} props - Propriedades do componente:
 * @param {boolean} props.disabled - Indica se o botão está desabilitado.
 * @param {function} props.funcao - Função de clique do botão.
 * @param {string} props.ariaLabelMsg - Texto exibido para leitores de tela.
 * @returns {JSX.Element} - Elemento JSX representando o botão secundário.
 */
export default function BotaoFato({
  disabled,
  funcao,
  ariaLabelMsg,
  ariaStatusMessage,
}) {
  const i18n = useStore().getState().i18n;
  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] =
    useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      buttonRef.current.disabled = true;
      return;
    }
    buttonRef.current.disabled = false;
  }, [disabled]);

  function onClick(event) {
    if (disabled) return;
    setAriaAtivarMensagemDeClique(true);
    funcao && funcao(event);
  }

  return (
    <BotaoFatoFakeStyle
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabelMsg}
      aria-disabled={disabled ? "true" : "false"}
    >
      <span className="weex-l-botaoFatoFake-conteudo">
        {i18n.message("dinamica.fatofake.fato", "Fato")}
        <img
          alt={i18n.message(
            "alt.dinamica.fatofake.fatoButtonImage",
            "Imagem do botão fato",
          )}
          src="/positivo_dois.svg"
          className="weex__image is-24x24 weex-l-botaoFatoFake-conteudo__icone"
        />
      </span>
      <span className="weex__a11y--visuallyHidden" role="status">
        {ariaAtivarMensagemDeClique ? ariaStatusMessage : ""}
      </span>
    </BotaoFatoFakeStyle>
  );
}

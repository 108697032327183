import Vimeo from "@u-wave/react-vimeo";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BotaoAsALink from "../../comps/botaoAsALink/BotaoAsALink";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import SecundarioBtn from "../../comps/buttonPWA2.0/SecundarioBtn";
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import AgendaService from "../../services/AgendaService";
import AtividadesService from "../../services/AtividadesService";
import ExtraService from "../../services/ExtraService";
import { ExtraStyle } from "./style";
// eslint-disable-next-line max-len
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
/**
 * @typedef {Object} ExtraObject
 * @property {string} uuid - O UUID do objeto extra.
 * @property {string} title - O título do objeto extra.
 * @property {string} code - O código do objeto extra.
 * @property {string} name - O nome do objeto extra.
 * @property {string} description - A descrição do objeto extra.
 * @property {string} presentation - A apresentação do objeto extra.
 * @property {boolean} active - Indica se o objeto extra está ativo.
 * @property {Object} theme - O tema do objeto extra.
 * @property {string} theme.uuid - O UUID do tema.
 * @property {string} theme.name - O nome do tema.
 * @property {string|null} picture - A imagem relacionada ao objeto extra (pode ser nula).
 * @property {string} typeApresentation - O tipo de apresentação do objeto extra.
 * @property {boolean} iframe - Indica se o objeto extra usa iframe.
 * @property {string} mimeType - O tipo MIME do objeto extra.
 * @property {string} url - A URL relacionada ao objeto extra.
 * @property {string|null} arquivoExtra - Arquivo extra relacionado ao objeto extra (pode ser nulo).
 */

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};
const Extra = () => {
  const dispatch = useDispatch();
  const { i18n, isDesktopMode } = useSelector((state) => ({
    i18n: state?.i18n,
    isDesktopMode: state?.isDesktopMode,
  }));
  const navigate = useNavigate();
  let location = useLocation();
  const codigo = useParams().codigo;
  const codigoAtividade = useParams().codigoAtividade;

  const largura = isDesktopMode
    ? window.screen.width - window.screen.width * 0.06
    : window.screen.width - window.screen.width * 0.0;

  const alturaVideo = isDesktopMode
    ? window.screen.height - window.screen.height * 0.38
    : ((window.screen.width * 0.8) / 16) * 9;
  const larguraVideo = isDesktopMode
    ? window.screen.width - window.screen.width * 0.06
    : window.screen.width * 0.8;
  const service = useMemo(() => new ExtraService(), []);
  const agendaService = useMemo(() => new AgendaService(), []);
  const atividadeService = useMemo(() => new AtividadesService(), []);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [carregouExtra, setCarregouExtra] = useState(false);
  const [state, setState] = useState({
    codigo: null,
    codigoAtividade: null,
    action: null,
    extra: {
      uuid: "",
      title: "",
      code: "",
      name: "",
      description: "",
      presentation: "",
      active: false,
      picture: null,
      typeApresentation: "",
      iframe: false,
      mimeType: "",
      url: "",
      arquivoExtra: null,
    },
    processando: false,
    redirecionando: false,
    tipoApresentacao: null,
    sizeScreen: window.innerWidth,
    erro: {
      isWrong: false,
      titulo: null,
      mensagem: null,
    },
    fileUrl: "",
    numPages: null,
    pageNumber: 1,
    erroPdf: true,
  });

  const effectRan = useRef(false);

  useEffect(() => {
    if (state?.processando) {
      return;
    }

    if (effectRan.current === false) {
      effectRan.current = true;
      dispatch({ type: "mainBackgroundColor", payload: "rgb(243, 243, 248)" });
      setState((prevState) => ({ ...prevState, processando: true }));

      atividadeService.abreFechaAcao(
        codigo,
        codigoAtividade,
        (erro, action) => {
          setState((prevState) => ({ ...prevState, processando: false }));
          if (action) {
            agendaService.atualizarAgenda(
              codigo,
              codigoAtividade,
              "EXTRA",
              null,
              null,
              (error, sucesso) => {
                if (error) {
                  alert(
                    i18n.message(
                      "dinamica.erro.jaexecutada.mensagem",
                      "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
                    ),
                  );
                }
              },
            );
          }
          if (erro) {
            alert(JSON.stringify(erro));
            return;
          }
          setState((prevState) => ({
            ...prevState,
            codigo: codigo,
            codigoAtividade: codigoAtividade,
            action: action,
            tipoApresentacao: action.extra.typeApresentation,
            extra: action.extra,
          }));

          if (action.extra.typeApresentation === "REDIRECT") {
            window.location = action.extra.url;
          }
          setState((prevState) => ({ ...prevState, redirecionando: false }));
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Executa o tipo de apresentação.
   *
   * @param {ExtraObject} extra - O objeto extra a ser processado.
   */
  const getExtraArquivo = useCallback(
    (extra) => {
      if (
        extra.typeApresentation !== "REDIRECT" &&
        (!extra || extra.url === "" || extra.url === undefined)
      ) {
        service.getConteudoExtra(state.codigoAtividade).then((fileRequest) => {
          const file = new Blob([fileRequest.data], { type: extra.mimeType });
          const fileUrl = URL.createObjectURL(file);
          setState((prevState) => ({
            ...prevState,
            extra: {
              ...prevState.extra,
            },
            fileUrl: fileUrl,
          }));
        });
      } else {
        setState((prevState) => ({
          ...prevState,
          extra: {
            ...prevState.extra,
          },
          url: extra.url,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [service, state.codigoAtividade],
  );
  //use efect para buscar o conteudo extra
  useEffect(() => {
    if (state.extra && carregouExtra === false && state.codigoAtividade) {
      setCarregouExtra(true);
      getExtraArquivo(state.extra);
    }
  }, [
    state.extra,
    state.codigoAtividade,
    carregouExtra,
    state,
    getExtraArquivo,
  ]);

  const createYouTubeEmbedLink = (link) => {
    let videoId = null;
    let queryParamsIndex = null;
    let embedUrl = null;
    if (link.includes("?v")) {
      videoId = link.split("https://www.youtube.com/watch?v=")[1];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
      queryParamsIndex = videoId.indexOf("&");
    } else if (link.includes("youtu.be")) {
      videoId = link.split("https://youtu.be/")[1];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
      queryParamsIndex = videoId.indexOf("&");
    }

    if (queryParamsIndex !== -1) {
      // A URL já possui parâmetros adicionais.
      const queryParams = videoId.slice(queryParamsIndex + 1);
      return `${embedUrl}?${queryParams}&autoplay=1`;
    } else {
      // A URL não possui parâmetros adicionais.
      return `${embedUrl}?autoplay=1`;
    }
  };

  const createGoogleDocEmbedLink = (link) => {
    return link.replace("view?usp=sharing", "preview");
  };

  const createVimeoIframe = (link) => {
    return (
      <div className="columns iframe-extra">
        <div className="column is-full text-align">
          <Vimeo
            video={link}
            width={larguraVideo}
            height={alturaVideo}
            autoplay
          >
            {" "}
          </Vimeo>
        </div>
      </div>
    );
  };

  const verificarseURLContemYoutube = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };
  const verificarSeUrlGoogleDrive = (url) => {
    return url.includes("drive.google.com");
  };

  const verificarSeUrlIsIframe = (url) => {
    return url.includes("<iframe");
  };

  const verificarSeUrlVimeo = (url) => {
    return url.includes("vimeo.com");
  };

  const gerarHtmliframe = (url) => {
    return (
      <div
        className="columns iframe-extra"
        dangerouslySetInnerHTML={{ __html: url }}
      ></div>
    );
  };

  const gerarHtmlLink = (url) => {
    return (
      <div className="">
        <div className="is-full">
          <iframe
            id="iframe-extra"
            src={url}
            allowFullScreen
            title="Atividade extra"
            allow="autoplay; picture-in-picture"
            webkitallowfullscreen="webkitallowfullscreen"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
          />
        </div>
      </div>
    );
  };

  const getExtra = (isIframe, url) => {
    if (verificarSeUrlVimeo(url)) {
      return createVimeoIframe(url);
    } else if (isIframe) {
      return gerarHtmliframe(url);
    } else {
      return gerarHtmlLink(url);
    }
  };

  const iframeRender = () => {
    const { extra } = state;
    if (state && state.extra.typeApresentation === "REDIRECT") {
      window.open(this.state.extra.url, "_blank");
    } else {
      return iframeHandler(extra);
    }
  };

  /**
   * Executa o tipo de apresentação.
   *
   * @param {ExtraObject} extra - O objeto extra a ser processado.
   */
  const iframeHandler = (extra) => {
    let isIframe;
    let url;
    if (extra.url) {
      url = extra.url;
      isIframe = verificarSeUrlIsIframe(url);
      if (verificarseURLContemYoutube(url)) {
        url = createYouTubeEmbedLink(url);
      }
      if (verificarSeUrlGoogleDrive(url)) {
        url = createGoogleDocEmbedLink(url);
      }
      if (verificarSeUrlVimeo(url)) {
        isIframe = true;
      }
      if (extra.mimeType !== "application/pdf") {
        return (
          <div className="columns">
            <div className="is-full">
              <div className="desktop-voltar">
                <BotaoAsALinkIconeEsquerda
                  icon="fas fa-arrow-left "
                  classeComplementar="header-como-funciona-desktop-botao"
                  nome={i18n.message("geral.voltar", "Voltar")}
                  ariaLabelMsg={i18n.message(
                    "aria.label.geral.mensagem.voltar.atividades",
                    "Volta para a página de atividades.",
                  )}
                  funcao={() => {
                    navigate("/home");
                  }}
                />
              </div>

              <div className="extra-iframe">{getExtra(isIframe, url)}</div>
            </div>
          </div>
        );
      } else if (
        extra.mimeType === "image/png" ||
        extra.mimeType === "image/jpeg"
      ) {
        // Renderiza imagens PNG ou JPEG
        return (
          <div className="columns">
            <div className="is-full">
              <div className="desktop-voltar">
                <BotaoAsALinkIconeEsquerda
                  icon="fas fa-arrow-left"
                  classeComplementar="header-como-funciona-desktop-botao"
                  nome={i18n.message("geral.voltar", "Voltar")}
                  ariaLabelMsg={i18n.message(
                    "aria.label.geral.mensagem.voltar.atividades",
                    "Volta para a página de atividades.",
                  )}
                  funcao={() => {
                    navigate("/home");
                  }}
                />
              </div>

              <div className="extra-image">
                <img src={url} alt={extra.title} style={{ maxWidth: "100%" }} />
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const voltar = () => {
    window.location = "/";
  };

  const renderIframePdf = () => {
    if (state.fileUrl) {
      return (
        <div className="has-text-centered mt-5">
          <Document
            options={options}
            error={errorPdf()}
            file={state.fileUrl}
            loading={i18n.message("extra.pdf.carregando", "Carregando PDF...")}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setState({ erroPdf: true })}
            externalLinkTarget="_blank"
          >
            <Page
              renderAnnotationLayer={false}
              width={isDesktopMode ? "" : 360}
              scale={1}
              pageNumber={state.pageNumber}
              renderTextLayer={false}
              //canvasBackground={"transparent"}
            />
          </Document>
          <div className="has-text-centered mt-5">
            <p className="extra__pdf--page">
              {i18n.message("extra.pdf.pagina", "Pagina")}{" "}
              {state.pageNumber || (state.pageNumber ? 1 : "--")}{" "}
              {i18n.message("extra.pdf.pagina.de", "de")}{" "}
              {state.numPages || "--"}
            </p>

            <div className="extra__pdf--buttons">
              <div className="m-3">
                <SecundarioBtn
                  nome={i18n.message("extra.pagina.anterior", "Anterior")}
                  funcao={previousPage}
                  disabled={state.pageNumber <= 1}
                  disabledOnClick={false}
                />
              </div>
              <div className="m-3">
                <SecundarioBtn
                  iconposition="right"
                  nome={i18n.message("extra.pagina.proxima", "Próxima")}
                  funcao={nextPage}
                  disabledOnClick={false}
                  disabled={state.pageNumber >= state.numPages}
                />
              </div>
            </div>
          </div>
          <div>{pdfDownload()}</div>
        </div>
      );
    }
  };
  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const changePage = (offset) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber: prevState.pageNumber + offset,
    }));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState((prevState) => ({
      ...prevState,
      numPages: numPages,
    }));
  };

  const errorPdf = () => {
    return (
      <div className="is-flex is-justify-content-center">
        <p>
          {" "}
          {i18n.message(
            "extra.pdf.erro.carregar",
            "Não foi possível carregar o PDF",
          )}{" "}
        </p>
      </div>
    );
  };

  const pdfDownload = () => {
    return (
      <div className="extra__pdf--buttons">
        <div style={{ width: "20rem" }}>
          <PrimarioBtn
            nome={i18n.message("extra.pdf.botao.download", "Baixar arquivo")}
            funcao={baixarArquivo}
            classeComplementar="px-4"
          />
        </div>
      </div>
    );
  };

  const baixarArquivo = () => {
    const link = document.createElement("a");
    link.href = state.fileUrl;
    link.setAttribute("download", `${state.extra.title}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  const renderExtraArquivo = () => {
    if (state.extra.mimeType === "application/pdf") {
      return (
        <div className="">
          <div className="">
            <div className="desktop-voltar mb-3">
              <BotaoAsALinkIconeEsquerda
                icon="fas fa-arrow-left "
                classeComplementar="header-como-funciona-desktop-botao"
                nome={i18n.message("geral.voltar", "Voltar")}
                ariaLabelMsg={i18n.message(
                  "aria.label.geral.mensagem.voltar.atividades",
                  "Volta para a página de atividades.",
                )}
                funcao={() => {
                  navigate("/home");
                }}
              />
            </div>
            <div className="">{renderIframePdf()}</div>
          </div>
        </div>
      );
    } else if (
      state.extra.mimeType === "image/png" ||
      state.extra.mimeType === "image/jpeg"
    ) {
      return (
        <div className="">
          <div className="">
            <div className="desktop-voltar mb-3">
              <BotaoAsALinkIconeEsquerda
                icon="fas fa-arrow-left"
                classeComplementar="header-como-funciona-desktop-botao"
                nome={i18n.message("geral.voltar", "Voltar")}
                ariaLabelMsg={i18n.message(
                  "aria.label.geral.mensagem.voltar.atividades",
                  "Volta para a página de atividades.",
                )}
                funcao={() => {
                  navigate("/home");
                }}
              />
            </div>
            <div className="extra-image ml-5">
              {!imageLoaded && (
                <div className="loading-text">
                  {i18n.message("dinamica.game.semjogo", "Carregando...")}
                </div>
              )}
              <img
                className="ml5"
                src={state.fileUrl}
                alt={state.extra.title}
                style={{
                  maxWidth: "100%",
                  display: imageLoaded ? "block" : "none",
                }}
                onLoad={() => setImageLoaded(true)}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const extra = state.extra;
  if (!extra) {
    setTimeout(() => {
      return (
        <p>{i18n.message("dinamica.extra.semConteudo", "Sem conteúdo...")}</p>
      );
    }, 2000);
  }

  return (
    <ExtraStyle $tamanho={largura}>
      <div>
        <div className="card weex-card-interno">
          <div>
            <div className="card-content">
              {state.tipoApresentacao === null ? (
                <>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <p>{i18n.message("geral.redirecionando", "Aguarde...")}</p>
                  </div>
                  <BotaoAsALink
                    nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                    ariaLabelMsg={i18n.message(
                      "aria.label.geral.mensagem.voltar.atividades",
                      "Volta para a página de atividades.",
                    )}
                    funcao={voltar}
                  />
                </>
              ) : (
                ""
              )}
              {state.tipoApresentacao === "REDIRECT" ? (
                <>
                  <DinamicasHeader
                    title={extra?.title || extra?.name}
                    dinamicaNome={i18n.message("dinamica.extra.nome", "Extra")}
                    dinamicaAlt={i18n.message(
                      "alt.dinamica.extra",
                      "Ícone de uma mão clicando em um ponto especifíco da página.",
                    )}
                    dinamicaImg={location.state?.icon || "/mural.png"}
                  />
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <p>{i18n.message("geral.redirecionando", "Aguarde...")}</p>
                  </div>
                  <a
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "50px",
                      color: "blue",
                    }}
                    className="fa-solid  fa-link"
                    href={state.extra.url}
                  >
                    {i18n.message("geral.link", "Link")}
                  </a>
                  <BotaoAsALink
                    nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                    ariaLabelMsg={i18n.message(
                      "aria.label.geral.mensagem.voltar.atividades",
                      "Volta para a página de atividades.",
                    )}
                    funcao={voltar}
                  />
                </>
              ) : (
                <>{iframeRender()}</>
              )}
              {renderExtraArquivo()}
            </div>
          </div>
        </div>
      </div>
    </ExtraStyle>
  );
};

export default Extra;

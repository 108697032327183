import axios from "axios";

export default class UsuarioService {
  /**
   * @typedef {Object} usuario
   * @property {string} username - O nome de usuário do usuário corrente.
   * @property {string} name - O nome do usuário corrente.
   * @property {string} token - O token de autenticação do usuário corrente.
   * @property {number} dateCreated - A data de criação do usuário corrente em milissegundos.
   * @property {number} timeToLive - O tempo de vida do token de autenticação em milissegundos.
   * @property {string} participantCode - O código do participante do usuário corrente.
   * @property {string} campaignCode - O código da campanha do usuário corrente.
   * @property {string} campaignId - O ID da campanha do usuário corrente.
   * @property {string[]} roles - Os papéis atribuídos ao usuário corrente.
   * @property {string|null} legalTerm - O termo legal associado ao usuário corrente (pode ser nulo).
   * @property {string} enrollment - A inscrição do usuário corrente.
   * @property {string|null} unit - A unidade relacionada ao usuário corrente (pode ser nula).
   * @property {string} userUuid - O UUID do usuário corrente.
   */

  /**
   * Returna a informação de usuário corrente.
   * @returns {usuario|null}
   */
  usuarioCorrente() {
    let conteudo = localStorage.getItem("usuario");
    if (conteudo) {
      return JSON.parse(conteudo);
    } else {
      return null;
    }
  }

  getCodigoParticipante() {
    let currentUser = this.usuarioCorrente();
    if (currentUser && currentUser.participantCode) {
      return currentUser.participantCode;
    } else {
      console.error("Erro ao obter o código do participante");
      return null;
    }
  }

  usuarioPossuiEquipe() {
    let currentUser = this.usuarioCorrente();
    if (currentUser && currentUser.team) {
      return currentUser.team ? true : false;
    } else {
      return false;
    }
  }

  /**
   * Retorna se o usario é um administrador
   */
  isAdmin() {
    let corrente = this.usuarioCorrente();
    if (corrente && corrente.roles && corrente.roles.length > 0) {
      return corrente.roles.includes("ROLE_ADMIN");
    } else {
      return false;
    }
  }

  /**
   * Retorna se o usario é um administrador
   */
  isGestor() {
    let corrente = this.usuarioCorrente();
    if (corrente && corrente.roles && corrente.roles.length > 0) {
      return corrente.roles.includes("ROLE_GESTOR");
    } else {
      return false;
    }
  }

  setConvite(codidoConvite) {
    if (codidoConvite) {
      localStorage.setItem("convite", codidoConvite);
    }
  }

  removeConvite() {
    localStorage.removeItem("convite");
  }

  getConvite() {
    return localStorage.getItem("convite");
  }

  setUsuarioCorrente(usuario) {
    localStorage.setItem("usuario", JSON.stringify(usuario));
  }

  removeUsuarioCorrente() {
    localStorage.removeItem("usuario");
  }

  setUsuarioAvatar(avatar) {
    if (avatar) {
      localStorage.setItem("usuario_avatar", JSON.stringify(avatar));
    }
  }

  getUsuarioAvatar() {
    const avatar = localStorage.getItem("usuario_avatar");
    if (avatar) {
      return JSON.parse(avatar);
    }
  }

  setUserTeamUuid(teamUuid) {
    if (teamUuid) {
      localStorage.setItem("user_team_uuid", JSON.stringify(teamUuid));
    }
  }

  getUserTeamUuid() {
    const teamUuid = localStorage.getItem("user_team_uuid");
    if (teamUuid) {
      return JSON.parse(teamUuid);
    } else {
      return null;
    }
  }

  token() {
    let corrente = this.usuarioCorrente();
    if (corrente) {
      return corrente.token;
    } else {
      return null;
    }
  }

  /**
   * Realiza o logout do usuário na aplicação
   *
   * O callback é executado após o logout ser bem sucedido
   * @param {*} callback
   */
  logout(callback) {
    this.removeUsuarioCorrente();
    localStorage.removeItem("agenda");
    localStorage.removeItem("agenda-global");
    localStorage.removeItem("validade-cache-agenda");
    localStorage.removeItem("check-start-campanha");
    localStorage.removeItem("time-to-next-request");
    localStorage.removeItem("date-request-time");
    localStorage.removeItem("validade-cache-aviso");
    localStorage.removeItem("aviso");
    localStorage.removeItem("usuario_avatar");
    localStorage.removeItem("validade-cache-aviso");
    localStorage.removeItem("last-campanha-check");
    localStorage.removeItem("user_team_uuid");
    this.removeItemsWithPrefix("conclusao-fatoFake-");
    this.removeItemsWithPrefix("acerteTempo");
    callback();
  }

  removeItemsWithPrefix(prefix) {
    for (const key in localStorage) {
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
      }
    }
  }

  /**
   * Realiza a autenticação no sistema.
   *
   * Credenciais consiste em um objeto com três campos: username, password, campaign - o terceiro é o código da campanha
   * @param {*} credenciais
   * @param {*} callback
   */
  auth(credenciais, callback) {
    axios
      .post(this.host() + "/api/v1/auth", credenciais)
      .then((response) => {
        if (response?.data?.code !== "202") {
          this.setUsuarioCorrente(response.data);
          this.setUsuarioAvatar(response?.data?.avatarDTO);
          this.setUserTeamUuid(response?.data?.team);
        }
        callback(null, response.data);
      })
      .catch((error) => {
        this.removeUsuarioCorrente();
        callback(error, null);
      });
  }

  salvarConcluidoLocalStorage(atividadeUuid, concluido) {
    window.localStorage.setItem(
      `conclusao-fatoFake-${atividadeUuid}`,
      JSON.stringify(concluido),
    );
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}

import React from "react";
import { AgendaHeaderStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";
import ProgressoCircular from "../barraProgresso/ProgressoCircular";
import BotaoGameAtividade from "../BotaoGameAtividade/BotaoGameAtividade";
import DataAgenda from "../CardzinhoDataDaAgenda/DataAgenda";

function AgendaHeader({
  habilitado,
  concluidasPorcentagem,
  nome,
  nomeInteresse,
  dayOfWeekComplete,
  diaTextual,
  mesTextualVisivel,
  mesTextualLeitorTela,
  isAgendaProlongada,
  possuiSubtitulos,
  atividadesSemGameFinal,
  gamesFinal,
}) {
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const shouldShowScheduleDate =
    campanha?.personalizationEvent?.SHOW_DATE_SCHEDULE === "true";

  let title;

  if (nome) {
    title = nome;
  } else if (nomeInteresse) {
    title = nomeInteresse;
  } else {
    if (!isAgendaProlongada && shouldShowScheduleDate) {
      title = dayOfWeekComplete;
    }
  }

  const atividadesGame = () => {
    return (
      <>
        <BotaoGameAtividade
          games={
            possuiSubtitulos
              ? gamesFinal.filter((a) => a?.subtitle?.uuid)
              : gamesFinal
          }
          atividades={
            possuiSubtitulos
              ? atividadesSemGameFinal.filter((a) => a?.subtitle?.uuid)
              : atividadesSemGameFinal
          }
        />
      </>
    );
  };

  return (
    <AgendaHeaderStyle>
      <div className="agendaHeader__alignedSubelements">
        <DataAgenda
          habilitado={habilitado}
          diaTextual={diaTextual}
          mesTextualVisivel={mesTextualVisivel}
          mesTextualLeitorTela={mesTextualLeitorTela}
          isAgendaProlongada={isAgendaProlongada}
        />
        <h5>{title}</h5>
      </div>
      <div className="agendaHeader__alignedSubelements">
        {atividadesGame()}
        <ProgressoCircular porcentagemFinal={concluidasPorcentagem} />
      </div>
    </AgendaHeaderStyle>
  );
}

export default AgendaHeader;

import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useStore } from "react-redux";
import ChangingProgressProvider from "../../WeexMarcadorProgresso/ChangingProgressProvider";
import { ProgressoCircularStyle } from "./style";

/**
 * A functional component that renders a circular progress bar.
 *
 * @function ProgressoCircular
 * @param {number} porcentagemFinal - The final percentage value to be displayed in the progress bar.
 * @returns {JSX.Element} - A JSX element representing the circular progress bar.
 */
export default function ProgressoCircular({ porcentagemFinal }) {
  const i18n = useStore().getState().i18n;
  return (
    <ProgressoCircularStyle>
      <ChangingProgressProvider
        values={[0, porcentagemFinal !== "NaN" ? porcentagemFinal : 0]}
        repeat={false}
      >
        {(porcentagemFinal) => (
          <CircularProgressbarWithChildren
            value={porcentagemFinal}
            strokeWidth={2.9}
            styles={{
              path: {
                strokeLinecap: "round",
                transition: "stroke-dashoffset 1.0s ease 0s",
              },
            }}
          >
            <>
              <div aria-hidden="true">
                <p className="progress__text">
                  {porcentagemFinal}
                  <small className="progress__percentage">%</small>
                </p>
              </div>
              <span className="weex__a11y--visuallyHidden">{`${porcentagemFinal}% ${i18n.message(
                "geral.porcentagem.concluido",
                "Concluído",
              )}`}</span>
            </>
          </CircularProgressbarWithChildren>
        )}
      </ChangingProgressProvider>
    </ProgressoCircularStyle>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { PollOptionStyle } from "./style";
import PollVotes from "./PollVotes";

export default function PollOption({
  status,
  chooseByParticipant,
  alternative,
  handleAlternatives,
  executed,
}) {
  const i18n = useStore().getState().i18n;
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(alternative?.percent);
    }, 100);
    return () => clearTimeout(timer);
  }, [alternative?.percent]);

  const checkbox = () => {
    if (!executed && status === "ABERTA") {
      return (
        <>
          <div className="pollOption__checkbox">
            <input
              id={alternative?.uuid}
              type="checkbox"
              name={alternative.statement}
              value={alternative?.uuid}
              checked={alternative?.checked}
              onChange={() => {
                handleAlternatives(alternative);
              }}
            />
            <span className="pollOption__checkbox--container">
              <img
                className="pollOption__checkbox--icon"
                src="/concluido.svg"
                alt={i18n.message("alt.geral.checked", "Ícone de um checked")}
              />
            </span>
          </div>
        </>
      );
    }
  };

  const badge = () => {
    if (chooseByParticipant) {
      return (
        <span className="pollOption__badge">
          <span className="pollOption__badge--participant">
            {i18n.message("enqueteCampanha.badge", "Você")}
          </span>
        </span>
      );
    }
  };

  return (
    <>
      <PollOptionStyle
        $percent={`${alternative?.percent}%`}
        $executed={executed}
        $status={status}
      >
        <label className="pollOption" htmlFor={alternative?.uuid}>
          <div className="pollOption__container">
            {checkbox()}
            <div className="pollOption__checkbox--statement">
              {alternative?.statement}&#x2007;
              {badge()}
            </div>
          </div>
          {executed || status === "FINALIZADA" ? (
            <PollVotes alternative={alternative} />
          ) : (
            ""
          )}
        </label>
        {executed || status === "FINALIZADA" ? (
          <div
            className="pollOption__percentageBG"
            style={{ width: `${width}%` }}
          />
        ) : (
          ""
        )}
      </PollOptionStyle>
    </>
  );
}

import styled from "styled-components";

export const PollStyle = styled.div`
  .poll__title {
    margin-top: 0.875rem; //14px;
    margin-bottom: 1.5rem; // 24px;
  }

  .poll__description {
    margin-top: 1.5rem; // 24px;
    margin-bottom: 1.5rem; // 24px;

    /* se ql-size for normal */
    p,
    span {
      color: inherit;
      text-align: inherit;
    }

    p .ql-size-small {
      font-size: 0.75em;
    }

    p .ql-size-large {
      font-size: 1.5em;
    }

    p .ql-size-huge {
      font-size: 2.5em;
    }

    span,
    u,
    em,
    strong,
    s {
      color: inherit;
      text-align: inherit;
    }
  }

  .poll__card {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    margin-bottom: 1.5rem; // 24px;
    max-height: 4.688rem; // 75px;
  }

  .poll__btn--container {
    margin-top: 0.25rem; //4px;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin-left: 20%;
    margin-right: 20%;

    .poll__title {
      font-size: 2rem;
    }

    .poll__header--desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .poll__description {
      margin-bottom: 1.75rem; //28px;
    }

    .poll_card--container {
      margin-top: 1.75rem;
    }
  }
  /* #endregion DESKTOP */
`;

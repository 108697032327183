import React, { useState, useEffect } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ConviteStyle } from "./style";
import CabecalhoPaginas from "../../comps/dinamicasHeader/CabecalhoPaginas";
import ConvidarAfiliadosSVG from "../../comps/svg/convite/ConvidarAfiliadosSVG";
import ConviteService from "../../services/ConviteService";
import UsuarioService from "../../services/UsuarioService";
import BotaoCopiarLink from "../../comps/convite/BotaoCopiarLink";
import WeexModal from "../../comps/weexModais/WeexModal";
import AceiteConvite from "../../comps/convite/AceiteConvite";

function Convite() {
  const dispatch = useDispatch();
  const conviteService = new ConviteService();
  const usuarioService = new UsuarioService();
  const [erro, setErro] = useState(null);
  const [urlConvite, setUrlConvite] = useState("");
  const [pontosAReceber, setPontosAReceber] = useState(null);
  const [copiarLinkFeedback, setCopiarLinkFeedback] = useState(null);
  const [convitesAceitos, setConvitesAceitos] = useState([]);
  const [totalPontos, setTotalPontos] = useState();
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [numeroTotalItens, setNumeroTotalItens] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  let { codigo } = useParams();
  const participante = usuarioService.usuarioCorrente();
  const i18n = useStore().getState().i18n;

  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
    const request = {
      campaign: codigo,
    };
    conviteService.criaConvite(request, (erro, sucesso) => {
      if (erro) {
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo: i18n.message(
              "convite.afiliados.criar.erro.titulo",
              "Erro ao criar convite",
            ),
            mensagem: erro.response.data.message,
          });
          return;
        }
        setErro({
          titulo: i18n.message(
            "convite.afiliados.criar.erro.titulo",
            "Erro ao criar convite",
          ),
          mensagem: i18n.message(
            "convite.afiliados.criar.erro.mensagem",
            "Entre em contato com o suporte ou tente novamente mais tarde.",
          ),
        });
        return;
      }

      const hostConvite = process.env.REACT_APP_HOST_CONVITE.replace(
        "{campaign}",
        codigo,
      );

      const conviteParams = `/campaign/${codigo}?weexInvite=${sucesso?.code}`;

      const urlPWA = `${hostConvite}${conviteParams}`;
      setUrlConvite(urlPWA);
      setPontosAReceber(sucesso?.accountsReceivable);
      return;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = {
      codigo: codigo,
      matricula: participante?.enrollment,
      pageSize: pageSize,
      page: page,
    };
    conviteService.listarConvite(params, (erro, sucesso) => {
      if (erro) {
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo: i18n.message(
              "convite.afiliados.listar.erro.titulo",
              "Erro ao listar convites aceitos",
            ),
            mensagem: erro.response.data.message,
          });
          return;
        }
        console.log(erro);
        setErro({
          titulo: i18n.message(
            "convite.afiliados.listar.erro.titulo",
            "Erro ao listar convites aceitos",
          ),
          mensagem: i18n.message(
            "convite.afiliados.criar.erro.mensagem",
            "Entre em contato com o suporte ou tente novamente mais tarde.",
          ),
        });
        return;
      }
      setConvitesAceitos(sucesso?.inviters);
      setTotalPontos(sucesso.inviters[0]?.totalPoints);
      setNumberOfElements(sucesso?.numberElements);
      setNumeroTotalItens(Math.ceil(sucesso?.numberElements / pageSize));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, numeroTotalItens]);

  const handleNextPage = () => {
    setNumeroTotalItens(Math.ceil(numberOfElements / pageSize));
    if (page < numeroTotalItens - 1) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const linkCopiadoComSucesso = () => {
    setCopiarLinkFeedback({
      titulo: i18n.message(
        "convite.afiliados.copiar.sucesso.titulo",
        "COPIADO!",
      ),
      mensagem: i18n.message(
        "convite.afiliados.copiar.sucesso.mensagem",
        "Link copiado com sucesso. Agora é só compartilhar com os amigos.",
      ),
    });
    return;
  };

  const erroCopiarLink = () => {
    setCopiarLinkFeedback({
      titulo: i18n.message(
        "convite.afiliados.copiar.erro.titulo",
        "Erro ao copiar link",
      ),
      mensagem: i18n.message(
        "convite.afiliados.copiar.erro.mensagem",
        "Entre em contato com o suporte ou tente novamente mais tarde.",
      ),
    });
  };

  const voltar = () => {
    setErro(null);
    navigate("/atividades");
  };

  const voltarCopiarLinkFeedback = () => {
    setCopiarLinkFeedback(null);
  };

  return (
    <>
      {erro != null && (
        <WeexModal
          fecharModalCallback={voltar}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      {copiarLinkFeedback != null && (
        <WeexModal
          fecharModalCallback={voltarCopiarLinkFeedback}
          titulo={copiarLinkFeedback.titulo}
          conteudo={copiarLinkFeedback.mensagem}
        />
      )}
      <ConviteStyle className="dinamica-content-desktop">
        <section className="weex-l-convite__informacoes">
          <CabecalhoPaginas
            voltar={voltar}
            titulo={i18n.message(
              "convite.afiliados.titulo",
              "Convide seus colegas para participarem!",
            )}
          >
            <ConvidarAfiliadosSVG />
          </CabecalhoPaginas>

          {pontosAReceber !== null && pontosAReceber ? (
            <>
              <p className="weex-l-convite__descricao">
                {i18n.message(
                  "convite.afiliados.descricao.parteUm",
                  "Convide uma pessoa para participar e ganhe ",
                )}
              </p>
              <p className="weex-l-convite__descricao">
                <strong>
                  + {pontosAReceber} {i18n.message("geral.pts", "PTS")}
                </strong>
                {i18n.message(
                  "convite.afiliados.descricao.parteTres",
                  " quando ela se cadastrar.",
                )}
              </p>
            </>
          ) : (
            <>
              <p className="weex-l-convite__descricao">
                {i18n.message(
                  "convite.afiliados.descricao.semPontos",
                  "Convide uma pessoa para participar!",
                )}
              </p>
            </>
          )}

          <div className="weex-l-convite__linkContainer">
            <p>{urlConvite}</p>
          </div>

          <div className="weex-l-convite__botaoContainer">
            <BotaoCopiarLink
              link={urlConvite}
              texto={i18n.message(
                "convite.afiliados.btn.copiarLink",
                "Copiar convite",
              )}
              erroCopiarLink={erroCopiarLink}
              linkCopiadoComSucesso={linkCopiadoComSucesso}
            />
          </div>
          {totalPontos && totalPontos > 0 ? (
            <div className="weex-l-tag">
              <span className="weex-l-tag__icone">
                <i className="fas fa-check"></i>
              </span>
              <p>
                +{totalPontos} {i18n.message("geral.pts", "PTS")}
              </p>
              <span />
            </div>
          ) : (
            ""
          )}
        </section>

        <section className="weex-l-convite__listagem">
          <h2 className="weex-l-convite__listagem--subtitulo">
            {i18n.message(
              "convite.afiliados.listagemDeConvites",
              "Seus convites:",
            )}
          </h2>
          {convitesAceitos.length > 0 ? (
            <AceiteConvite
              convitesAceitos={convitesAceitos}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              numeroTotalItens={numeroTotalItens}
              page={page}
            />
          ) : (
            <p className="weex-l-convite__listagem--semConvites">
              {i18n.message(
                "convite.afiliados.listagemDeConvites",
                "Sem convites aceitos.",
              )}
            </p>
          )}
        </section>
      </ConviteStyle>
    </>
  );
}

export default Convite;

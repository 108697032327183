import React, { useState } from "react";
import { WeexInput, Label, Input } from "./style";

/**
 * Creates a custom Birthdate input component.
 * @param {string} name - The name of the input.
 * @param {string} id - The unique identifier of the input.
 * @param {string} placeholder - The placeholder text for the input.
 * @param {string} label - The label text for the input.
 * @param {boolean} [required=true] - Whether the input is required or not. Defaults to true.
 * @param {boolean} [disabled=false] - Whether the input is disabled or not. Defaults to false.
 * @param {function} onChange - The function to be executed when the input changes.
 * @param {"left"|"right"} [iconposition="right"] - The position of the icon. Defaults to right.
 * @param {string} icon - The icon to be displayed next to the input.
 */
function WeexBirthdateInput({
  key,
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  iconposition = "right",
  icon,
  ariaLabel,
}) {
  const [dob, setDob] = useState("");

  const className =
    iconposition === "right"
      ? "weexInput__icon weexInput__icon--right"
      : "weexInput__icon";

  const handleInputChange = (e) => {
    const inputDate = e.target.value;
    let formattedDate = inputDate.replace(/\D/g, "").slice(0, 8); // Remove caracteres não numéricos e limita a 8 dígitos

    if (formattedDate.length > 2) {
      formattedDate = `${formattedDate.slice(0, 2)}/${formattedDate.slice(2)}`;
    }
    if (formattedDate.length > 5) {
      formattedDate = `${formattedDate.slice(0, 5)}/${formattedDate.slice(5)}`;
    }

    setDob(formattedDate);
    onChange(formattedDate);
  };

  return (
    <WeexInput key={key}>
      <Label htmlFor={id} aria-label={ariaLabel}>
        {label}
        <Input
          id={id}
          type="text"
          name={name}
          value={dob}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={handleInputChange}
          $isright={iconposition === "right" ? "true" : "false"}
        />
        {icon && (
          <div className={`${className}`}>
            <i className={icon}></i>
          </div>
        )}
      </Label>
    </WeexInput>
  );
}

export default WeexBirthdateInput;

import styled from "styled-components";

export const AvisoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem; // 16px;
  margin-top: 1.75rem; // 28px;
  margin-bottom: 1.5rem; // 24px;
  border-radius: 0.75rem; // 12px;
  background-color: ${(props) => {
    return props.theme.headerBg;
  }};

  .avisoEnquete__title {
    text-align: center;
    color: ${(props) => {
      return props.theme.headerText;
    }};
  }

  .avisoEnquete__cadeado {
    padding: 0px;
    margin: 0px;
    width: 5rem; // 80px;
    height: 5rem; //80px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    border: ${(props) => {
      return "0.125rem solid " + props.theme.headerSubBgText;
    }};
    background-color: ${(props) => {
      return props.theme.headerSubBg;
    }};

    color: ${(props) => {
      return props.theme.headerSubBgText;
    }};

    svg {
      width: 50%;
      height: 50%;
    }

    path {
      fill: ${(props) => {
        return props.theme.headerSubBgText || `rgb(239, 231, 102)`;
      }};
    }
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
    padding: 4rem; // 64px;
  }
  /* #endregion DESKTOP */
`;

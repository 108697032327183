import React, { useRef, useState } from "react";
import { useStore } from "react-redux";
import { WeexModalDialog } from "./WeexModalDialog";
import WeexFactory from "../WeexInputs/WeexFactory";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";

function WeexModalAnexarVideo({ cancela, confirma, visivel }) {
  const dialogRef = useRef(null);

  const i18n = useStore().getState().i18n;

  const [url, setUrl] = useState("");

  const refForm = useRef(null);

  const ocultar = () => {
    dialogRef.current.close();
    cancela();
  };

  const onChangeInputUrl = (e) => {
    setUrl(e.target.value);
  };

  const submit = (e) => {
    e.preventDefault();
    confirma(url);
  };

  const onInvalid = (e) => {
    e.target.setCustomValidity(
      i18n.message(
        "dinamica.mural.publicar.video.linkInvalido",
        "Somente links do YouTube ou Vimeo são aceitos na plataforma",
      ),
    );
    e.target.focus();
  };

  const buttonClick = () => {
    //se o form for valido ele vai chamar o submit mesmo que o botao esteja desabilitado
    if (refForm.current.checkValidity()) {
      refForm.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  };

  return (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={ocultar}>
      <div
        className={
          visivel === true
            ? "modal is-active animations-popIn"
            : "modal animations-popIn"
        }
        style={{ zIndex: "102" }}
      >
        <div className="modal-background"></div>
        <div>
          <form ref={refForm} onSubmit={submit}>
            <header className="modal-card-head weex-modal-card-header">
              <div className="titulo-icone titulo-mural-modal">
                <div className="icon-mural-modal">
                  <span className="icon color-fundo-secundaria">
                    <i className="fa fa-comment-dots"></i>
                  </span>
                </div>
                <h3 className="titulo-modal ml-3">
                  {i18n.message(
                    "dinamica.mural.publicar.titulo",
                    "ESCREVER PUBLICAÇÃO",
                  )}
                </h3>
              </div>
              <button
                onClick={ocultar}
                className="close-btn"
                aria-label={i18n.message(
                  "aria.label.mural.anexo.video.fechar.modal",
                  "Fecha a janela de publicar vídeo e volta para escrever publicação.",
                )}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message(
                    "alt.button.imagem.fechar",
                    "ícone em formato de um X indicando o encerramento de uma ação.",
                  )}
                />
              </button>
            </header>
            <section className="modal-card-body has-text-centered">
              <div className="weex-modal-card-body__texto">
                <WeexFactory
                  ariaLabel={i18n.message(
                    "dinamica.mural.publicar.video.link",
                    "Cole aqui o link para o vídeo (YouTube/Vimeo)",
                  )}
                  id="url"
                  label={i18n.message(
                    "dinamica.mural.publicar.video.link",
                    "Cole aqui o link para o vídeo (YouTube/Vimeo)",
                  )}
                  type="text"
                  name="url"
                  onChange={onChangeInputUrl}
                  required={true}
                  pattern="^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com).*$"
                  onInvalid={onInvalid}
                />
              </div>
            </section>
            <footer
              className="modal-card-foot weex-modal-card-footer"
              style={{ textAlign: "center" }}
            >
              <PrimarioBtn
                disabled={!visivel}
                funcao={buttonClick}
                nome={i18n.message(
                  "dinamica.mural.publicar.video.anexar",
                  "Anexar vídeo",
                )}
              />
            </footer>
          </form>
        </div>
      </div>
    </WeexModalDialog>
  );
}

export default WeexModalAnexarVideo;

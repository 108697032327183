import styled from "styled-components";

export const HomeStyle = styled.div`
  display: flex;
  flex-direction: column;

  .home-btn-padding-como-funciona {
    margin-bottom: 1rem;
  }
  .home-btn-padding-interesses {
    margin-bottom: 3.5rem;
  }
  h3 {
    color: #201d1d;
  }
  .contador-campanha-container__span {
    font-weight: 600;
  }

  .info-campanha-container {
    height: 65vh;
    width: 100vw;
    margin-left: -1.5em;
    margin-right: -1.5em;
    padding-top: 10vh;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 2px;
  }

  .info-campanha-container-interesse {
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-bottom: 20px;
    text-align: center;

    h1 {
      color: inherit;
      text-align: inherit;
    }
    h3 {
      color: inherit;
      text-align: inherit;
      margin-top: 1.25rem;
    }
  }

  .info-campanha-container__span {
    font-weight: 700;
    font-size: 1.1rem;
    color: #201d1d;
  }

  .info-campanha-container__span__data {
    font-size: 1.55rem;
  }

  .contador-campanha-container {
    width: 80vw;
    height: 30vh;
    padding-top: 5vh;
    margin-left: 10vw;
    margin-right: 10vw;
    left: 0px;
    position: absolute;
    top: 65vh;
  }

  .contador-campanha-container-interesse {
    margin-top: 20px;
    padding-left: 1.1em;
    padding-right: 1.1em;
    position: static;
    overflow-x: hidden;
  }

  .content-botoes {
    padding-bottom: 3rem;
  }

  @media screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: center;
    .weex__content-home {
      width: 70%;
      margin-top: 3rem;
    }

    .atividades__containerRanking {
      display: flex;
      justify-content: flex-end;
      width: 30%;
    }

    .atividades__containerRanking--size {
      width: 100%;
      margin-right: 2rem;
    }

    .weex-l-contador-botoes {
      min-height: 2vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .content-botoes {
      margin-top: 3vh;
      padding-bottom: 3rem;
    }
    .info-campanha-container-interesse {
      margin-bottom: 0;
      color: ${(props) => {
        return props.theme.fundoSecundario;
      }};
      h1 {
        color: inherit;
        font-size: 3rem;
        text-align: center;
        font-weight: 700;
        line-height: 3rem;
        text-transform: uppercase;
        margin-bottom: 3rem;
      }
      h3 {
        color: inherit;
        font-size: 3em;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 3em;
        margin-top: 4rem;
      }
    }
  }

  /* media query min-width 280px
@media screen and (max-width: 280px) {
	.interesse-mostrar {
		min-width: 220px;
	}
} */
`;

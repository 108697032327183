import React from "react";
import { useStore } from "react-redux";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useNavigate } from "react-router-dom";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import ContadorRegressivoRevisaoAT from "./ContadorRegressivoRevisaoAT";
import TagRespostaRevisaoAT from "./TagRespostaRevisaoAT";
import AlternativaRevisaoAT from "./AlternativaRevisaoAT";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";

const ModoRevisaoAT = () => {
  const {
    acerteTempo,
    proximoAcerteTempoRevisao,
    totalDeQuestoes,
    anteriorAcerteTempoRevisao,
    indiceAcerteTempo,
    obtemPlacarFinal,
  } = useAcerteTempo();

  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();

  const imagemQuestao = () => {
    if (acerteTempo?.questions[indiceAcerteTempo]?.image) {
      return (
        <>
          <img
            className="weex__image is-2by1"
            src={acerteTempo?.questions[indiceAcerteTempo]?.image}
            alt={i18n.message(
              "alt.dinamica.acerteTempo.imagemQuestao",
              "Imagem da questão da dinâmica Hábito Certo",
            )}
          />
        </>
      );
    }
  };

  const enunciadoQuestao = () => {
    return (
      <>
        <div
          className="weex-l-acerteTempo-textos-container weex-l-acerteTempo-textos--formatoOriginal"
          dangerouslySetInnerHTML={{
            __html: acerteTempo?.questions[indiceAcerteTempo]?.statement,
          }}
        ></div>
      </>
    );
  };

  const revisaoBtnVoltarProximo = () => {
    return (
      <>
        <div className="weex-l-acerteTempo-revisao-btnContainer">
          <div className="weex-l-acerteTempo-revisao-btnContainer--container">
            <div style={{ width: "45%" }}>
              <SecundarioBtn
                nome={
                  <span className="weex-l-acerteTempo-revisao-btnTexto">
                    <span>
                      <i className="fas fa-chevron-left" />
                    </span>
                    <span>
                      {i18n.message(
                        "dinamica.acerteTempo.btn.voltar",
                        "Voltar",
                      )}
                    </span>
                  </span>
                }
                disabled={indiceAcerteTempo === 0}
                funcao={anteriorAcerteTempoRevisao}
                disabledOnClick={false}
              />
            </div>
            <div style={{ width: "45%" }}>
              {indiceAcerteTempo === totalDeQuestoes - 1 ? (
                <>
                  <PrimarioBtn
                    nome={i18n.message(
                      "dinamica.acerteTempo.btn.revisao.concluir",
                      "CONCLUIR",
                    )}
                    ariaDisabled={false}
                    ariaHidden={false}
                    ariaLabelMsg={i18n.message(
                      "dinamica.acerteTempo.btn.revisao.concluir",
                      "CONCLUIR",
                    )}
                    ariaStatusMessage={i18n.message(
                      "dinamica.acerteTempo.btn.revisao.concluir.ariaLabel",
                      "botão CONCLUIR clicado",
                    )}
                    disabled={false}
                    funcao={() => navigate("/atividades")}
                  />
                </>
              ) : (
                <>
                  <SecundarioBtn
                    nome={
                      <span className="weex-l-acerteTempo-revisao-btnTexto">
                        <span>
                          {i18n.message(
                            "dinamica.acerteTempo.btn.proximo",
                            "Próximo",
                          )}
                        </span>
                        <span>
                          <i className="fas fa-chevron-right" />
                        </span>
                      </span>
                    }
                    disabled={indiceAcerteTempo === totalDeQuestoes - 1}
                    funcao={proximoAcerteTempoRevisao}
                    disabledOnClick={false}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <section className="weex-l-acerteTempo-container weex-l-acerteTempo-container-row">
        <WeexDesktopMode className={"weex-l-acerteTempo-container--flexFilhos"}>
          <div className="weex-l-acerteTempo-posicionamento-imagens">
            {imagemQuestao()}
          </div>
          <ContadorRegressivoRevisaoAT
            tempoEmSegundos={acerteTempo?.questions[indiceAcerteTempo]?.time}
            tempoParticipanteRespondeu={
              obtemPlacarFinal?.scores[indiceAcerteTempo]?.answeredTimeInSeconds
            }
          />
          <TagRespostaRevisaoAT />
          <div className="weex-l-acerteTempo-botoesContainer weex-l-acerteTempo-botaoRespiroSuperiorMenor">
            {revisaoBtnVoltarProximo()}
          </div>
        </WeexDesktopMode>
        <div className="weex-l-acerteTempo-container--flexFilhos">
          {enunciadoQuestao()}

          <WeexDesktopMode>
            <AlternativaRevisaoAT />
          </WeexDesktopMode>
        </div>
      </section>

      <WeexMobileMode className={"weex-l-acerteTempo-botaoRespiroSuperior"}>
        <ContadorRegressivoRevisaoAT
          tempoEmSegundos={acerteTempo?.questions[indiceAcerteTempo]?.time}
          tempoParticipanteRespondeu={
            obtemPlacarFinal?.scores[indiceAcerteTempo]?.answeredTimeInSeconds
          }
        />
        <AlternativaRevisaoAT />
        <TagRespostaRevisaoAT />
        <div className="weex-l-acerteTempo-botoesContainer weex-l-acerteTempo-botaoRespiroSuperior">
          {revisaoBtnVoltarProximo()}
        </div>
      </WeexMobileMode>
    </>
  );
};

export default ModoRevisaoAT;

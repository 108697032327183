import React from "react";
import { Link } from "react-router-dom";
import {
  WeexMobileModeStyle,
  WeexDesktopModeStyle,
} from "../../WeexMode/style";
import styled from "styled-components";

export const Style = styled.span`
  .logo__loggedInLogo {
    height: auto;
    max-width: 18rem; //158.016px
    max-height: 5rem; //40.48px
  }
`;

function Logo({
  to = "/",
  className,
  srcMobile,
  altMobile,
  srcDesktop,
  altDesktop,
}) {
  const desktop = () => {
    if (srcDesktop) {
      return (
        <>
          <WeexDesktopModeStyle
            className="logo__loggedInLogo"
            as="img"
            src={srcDesktop}
            alt={altDesktop}
          />
        </>
      );
    }
  };

  const mobile = () => {
    if (srcMobile) {
      return (
        <>
          <WeexMobileModeStyle
            className="logo__loggedInLogo"
            as="img"
            src={srcMobile}
            alt={altMobile}
          />
        </>
      );
    }
  };

  return (
    <Style>
      <Link to={to} className={className}>
        {desktop()}
        {mobile()}
      </Link>
    </Style>
  );
}

export default Logo;

import React from "react";
import Interesse from "./InteresseMobile";
import PagesHeader from "../../pagesHeader/PagesHeader";

export default function InteressesMobile(props) {
  return (
    <>
      <div className="weex__container">
        <div className="interesse-content">
          <PagesHeader
            title={props.titleMessage}
            subtitle={props.selectMessage}
          />
          <div style={{ marginTop: "3rem" }}>
            <Interesse
              campanha={props.campanha.interests}
              beginDate={props.beginDate}
              now={props.now}
              daysOfEvent={props.daysOfEvent}
              canSelectInterets={props.canSelectInterets}
              requiredInterests={props.requiredInterests}
            />
          </div>
        </div>
      </div>
    </>
  );
}

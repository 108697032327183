import React from "react";
import { useStore } from "react-redux";
import { TagTotalStyle } from "./TagRespostaStyle";
import TagPontuacao from "./TagPontuacao";

const TagTotalAT = ({ totalPoints }) => {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <TagTotalStyle>
        <TagPontuacao totalPoints={totalPoints} />
      </TagTotalStyle>
      <span className="weex__a11y--visuallyHidden">{`${totalPoints} ${i18n.message(
        "alt.dinamica.acerteTempo.tag.pontosExtenso",
        "pontos",
      )}`}</span>
    </>
  );
};

export default TagTotalAT;

import CampanhaService from "./CampanhaService";

const campanhaService = new CampanhaService();
export default class TemaService {
  constructor(campanha) {
    if (campanha && campanha.styles) {
      this.styles = campanha.styles;
    } else {
      this.styles = [];
    }
    this.isDesktopModel = campanhaService.isDesktopModel();
    this.imagemDeFundo = null;
  }

  getStyle(pagina) {
    if (!pagina) {
      return null;
    }
    if (this.styles && this.styles.length > 0) {
      for (var i = 0; i < this.styles.length; i++) {
        if (this.styles[i].page === pagina) {
          return this.styles[i];
        }
      }
    }
    return null;
  }

  temBackGround() {
    return this.imagemDeFundo;
  }

  aplicarTema(pagina) {
    let tema = this.getStyle(pagina);
    let element;
    let dropdownmenu;
    if (pagina === "ENTRADA") {
      element = document.querySelector(".weex");
    } else {
      element = document.getElementById("header__weex");
      dropdownmenu = document.getElementById("dropdown-menu");
    }

    const isDesktop = campanhaService.isDesktopModelByElement(element);
    if (tema) {
      if (!isDesktop) {
        if (tema.backgroundImage) {
          if (element && element.style) {
            // Configuração da imagem de fundo para o elemento principal
            element.style.backgroundImage = "url(" + tema.backgroundImage + ")";
            element.style.backgroundSize = "100% auto";
            element.style.backgroundRepeat = "no-repeat";

            // Configuração da imagem de fundo para o dropdownmenu
            if (dropdownmenu && dropdownmenu.style) {
              dropdownmenu.style.backgroundImage =
                "url(" + tema.backgroundImage + ")";
              dropdownmenu.style.backgroundSize = "100% auto";
              dropdownmenu.style.backgroundRepeat = "no-repeat";
            }
          }
          this.imagemDeFundo = true;
        }
      } else if (tema.backgroundImageDesktop) {
        if (element && element.style) {
          element.style.backgroundImage =
            "url(" + tema.backgroundImageDesktop + ")";
          element.style.backgroundSize = "100% auto";
          element.style.backgroundRepeat = "no-repeat";
        }
        this.imagemDeFundo = true;
      }
    } else if (element && element.style) {
      element.style.removeProperty("background-image");
    }
  }

  removerTema() {
    var element = document.querySelector(".weex");
    element.style.removeProperty("background-image");
  }
}

import styled from "styled-components";

export const CardStyle = styled.div`
  border-radius: 1.25rem;
  background-color: rgba(227, 228, 227, 0.25);
  margin-top: 1rem;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    padding: 0.75rem;
  }
  /* #endregion DESKTOP */
`;

import React, { useEffect, useRef, useState } from "react";
import { ButtonPrimarioStyle } from "./style";
import ArrowRight from "../svg/botoes/ArrowRight";
import ArrowLeft from "../svg/botoes/ArrowLeft";

/**
 * A reusable primary button component with customizable features.
 *
 * @param {Object} props - The properties of the button component.
 * @param {Function} props.funcao - The function to be called when the button is clicked.
 * @param {string} props.nome - The text displayed on the button.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled or not.
 * @param {boolean} [props.disabledOnClick=true] - Whether to disable the button after it is clicked.
 * @param {("normal"|"small")} [props.size="normal"] - The size of the button.
 * @param {("button"|"submit")} [props.type="button"] - The type of the button.
 * @param {string} [props.ariaLabelMsg] - The aria-label attribute for the button.
 * @param {string} [props.ariaStatusMessage=""] - The aria-status message to be displayed.
 * @param {string} [props.tabIndex="0"] - The tab index of the button.
 * @param {("true"|"false")} [props.ariaHidden="false"] - Whether the button is hidden from screen readers.
 * @param {("left"|"right"|"both"|"none")} [props.iconposition="right"] - The position of the icons on the button.
 * @param {React.ReactNode} [props.iconLeft] - The icon to be displayed on the left side.
 * @param {React.ReactNode} [props.iconRight] - The icon to be displayed on the right side.
 * @returns {JSX.Element} - The primary button component.
 */
export default function BotaoPrimario({
  id = "botao-primario",
  funcao,
  nome,
  disabled = false,
  disabledOnClick = true,
  size = "normal",
  type = "button",
  ariaLabelMsg,
  ariaStatusMessage = "",
  tabIndex = "0",
  ariaHidden = "false",
  iconposition = "right",
  iconLeft,
  iconRight,
}) {
  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] =
    useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      buttonRef.current.disabled = true;
      return;
    }
    buttonRef.current.disabled = false;
  }, [disabled]);

  function onClick(event) {
    if (disabled) return;
    if ((funcao !== null || funcao !== undefined) && type !== "submit") {
      if (disabledOnClick) {
        buttonRef.current.disabled = true;
      }
      setAriaAtivarMensagemDeClique(true);
      funcao && funcao(event);
    }
  }

  const a11yStatus = () => {
    if (ariaAtivarMensagemDeClique) {
      return (
        <span className="weex__a11y--visuallyHidden" role="status">
          {ariaStatusMessage}
        </span>
      );
    }
  };

  const left = () => {
    if (iconposition === "left" || iconposition === "both") {
      return (
        <div className="baseButton__iconContainer">
          {iconLeft ? iconLeft : <ArrowLeft />}
        </div>
      );
    }
  };

  const right = () => {
    if (iconposition === "right" || iconposition === "both") {
      return (
        <div className="baseButton__iconContainer">
          {iconRight ? iconRight : <ArrowRight />}
        </div>
      );
    }
  };

  return (
    <ButtonPrimarioStyle
      id={id}
      $iconposition={iconposition}
      $size={size}
      $hasname={nome ? "true" : "false"}
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      aria-hidden={ariaHidden}
      tabIndex={tabIndex}
      aria-label={ariaLabelMsg ? ariaLabelMsg : nome}
      aria-disabled={disabled ? "true" : "false"}
      type={type}
    >
      <span className="baseButton__content">
        {left()}
        {nome && <span className="baseButton__text">{nome}</span>}
        {right()}
      </span>
      {a11yStatus()}
    </ButtonPrimarioStyle>
  );
}

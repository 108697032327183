import React from "react";
import { TooltipContainer, TooltipText } from "./tooltipStyle";

const GameTooltip = ({ text, position, children }) => (
  <TooltipContainer>
    {children}
    <TooltipText $position={position}>{text}</TooltipText>
  </TooltipContainer>
);

export default GameTooltip;

import React, { useRef, useState } from "react";
import { useStore } from "react-redux";
import BotaoAsALink from "../botaoAsALink/BotaoAsALink";
import QuaseLa from "../svg/quaseLa";
import { WeexModalDialog } from "./WeexModalDialog";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";

/**
 * @deprecated - componente será reformulado para se adequar ao novo design do weex 3.0
 * @param {Object} props
 * @param {string} props.scoreTextual
 * @param {string} props.campanhaCorrente
 * @param {function(): void} props.fecharCallbackModal
 * @param {function(): void} props.redirecionar
 */
function WeexModalRedirect({
  fecharCallbackModal,
  redirecionar,
  campanhaCorrente,
  scoreTextual,
}) {
  const i18n = useStore().getState().i18n;
  const [showModal, setShowModal] = useState(true);
  const dialogRef = useRef(null);

  const ocultar = () => {
    fecharCallbackModal();
    setShowModal(false);
  };

  const mensagemFinal = () => {
    return (
      <>
        <p className="quase-la-texto">
          {` ${i18n.message(
            "dinamica.fatofake.mensagemfinal.parte.um",
            "Para concluir a atividade é necessário alcançar a ",
          )}`}
          <strong>
            {` ${i18n.message(
              "dinamica.fatofake.mensagemfinal.parte.dois",
              "pontuação mínima de ",
            )}`}
            <span>{campanhaCorrente?.minimumFakeFactScore + "%."}</span>
          </strong>
        </p>
      </>
    );
  };

  const mensagemFinalPontuacaoParticipante = () => {
    return (
      <>
        <div className="modal-mensagemfinal-pontuacao">
          <p className="modal-mensagemfinal-pontuacao__texto">
            {i18n.message(
              "dinamica.fatofake.mensagemfinal.pontuacao.um",
              "Você acertou",
            )}
            <span> {scoreTextual} </span>
            <span>
              {i18n.message(
                "dinamica.fatofake.mensagemfinal.pontuacao.dois",
                " das respostas!",
              )}
            </span>
          </p>
        </div>
      </>
    );
  };

  return showModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={ocultar}>
      <div
        className={"modal is-active animations-popIn"}
        style={{ zIndex: "102" }}
      >
        <div className="modal-background"></div>
        <div>
          <header className="modal-card-head weex-modal-card-header">
            <button
              onClick={ocultar}
              className="close-btn"
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fechar modal",
              )}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "Botão fechar modal",
                )}
              />
            </button>
          </header>
          <section className="modal-card-body has-text-centered">
            <div className="weex-modal-card-body__texto">
              <div className="weex-modal-card-img__almost">
                <QuaseLa />
              </div>
              <div className="weex-modal-card-img__text">
                {" "}
                {i18n.message(
                  "dinamica.fatofake.mensagemfinal.quase.la",
                  "QUASE LÁ!",
                )}{" "}
              </div>
              <div className="weex-modal-card-body__texto__redirect">
                {mensagemFinal()}
              </div>
              <div className="weex-modal__fato_fake_text">
                {mensagemFinalPontuacaoParticipante()}
              </div>
            </div>
          </section>
          <footer
            className="modal-card-foot weex-modal-card-footer"
            style={{ textAlign: "center" }}
          >
            <div className="modal-card-foot-actions">
              <PrimarioBtn
                classeComplementar="mb-4"
                nome={i18n.message(
                  "fatoFake.tentarNovamente",
                  "Tentar novamente",
                )}
                funcao={() => redirecionar()}
                ariaStatusMessage={i18n.message(
                  "aria.status.weexmodalComRedirect.botao",
                  "Botão tentar novamente clicado.",
                )}
              />
              <div className="modal-card-foot-actions-link">
                <BotaoAsALink
                  nome={i18n.message("fatoFake.voltar", "Voltar")}
                  funcao={ocultar}
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default WeexModalRedirect;

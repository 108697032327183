import CampanhaService from "./CampanhaService";
let campanhaService = new CampanhaService();

export default class InputsCustomizadosService {
  constructor() {
    this.campanhaCorrente = campanhaService.campanhaCorrente();
  }

  possuiCampo(campo) {
    let possui = false;
    for (let i = 0; i < this.campanhaCorrente.fields.length; i++) {
      if (this.campanhaCorrente.fields[i].field === campo) {
        possui = true;
        break;
      }
    }
    return possui;
  }

  rotuloDoCampo = (campo) => {
    let rotulo = "";
    for (const element of this.campanhaCorrente.fields) {
      if (element.field === campo) {
        if (this.campanhaCorrente.nomeMatricula) {
          rotulo = this.campanhaCorrente.nomeMatricula;
        } else {
          rotulo = element.label;
        }
        break;
      }
    }
    return rotulo;
  };
}

import React, { useState } from "react";
import { AvatarPartsButtonStyle } from "./style";

export default function AvatarPartsButton({
  disabled,
  funcao,
  color,
  value,
  type,
  avatarPart,
  size = "normal",
  ariaLabelMsg,
  ariaStatusMessage = "",
  alt = "",
  tabIndex = "0",
  ariaHidden = "false",
}) {
  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] =
    useState(false);

  const onClick = () => {
    setAriaAtivarMensagemDeClique(true);
    let response = color ? color : value;
    funcao && funcao(type, response);
  };

  const a11yStatus = () => {
    if (alt) {
      return (
        <span className="weex__a11y--visuallyHidden" role="status">
          {alt}
        </span>
      );
    } else if (ariaAtivarMensagemDeClique) {
      return (
        <span className="weex__a11y--visuallyHidden" role="status">
          {ariaStatusMessage}
        </span>
      );
    }
  };

  return (
    <AvatarPartsButtonStyle
      $disabled={disabled}
      $color={color}
      $img={avatarPart}
      size={size}
      onClick={onClick}
      aria-hidden={ariaHidden}
      tabIndex={tabIndex}
      aria-label={ariaLabelMsg}
      type="button"
    >
      <span className="baseButton__content">
        <div className="baseButton__iconContainer"></div>
      </span>
      {a11yStatus()}
    </AvatarPartsButtonStyle>
  );
}

import React from "react";
import { DetalhesDinamicaPresencialStyle } from "./style";
import CircleCheck from "../svg/circleCheck";
import { useStore } from "react-redux";
import Localizacao from "../svg/Localizacao";

export default function DetalhesDinamicaPresencial({
  voltar,
  dinamicaPresencial,
  titulo,
  page,
}) {
  const i18n = useStore().getState().i18n;

  return (
    <DetalhesDinamicaPresencialStyle>
      <header className="presencial__header">
        <button
          onClick={voltar}
          className="custom-color-icon weex__buttonLink weex-l-cabecalho-btn-voltar"
          aria-label={i18n.message(
            "aria.label.geral.mensagem.voltar.atividades",
            "Volta para a página de atividades.",
          )}
        >
          <span className="custom-color-icon icon weex-l-cabecalho-custom-color-icon">
            <span className="custom-color-icon fa-stack fa-sm">
              <i className="custom-color-icon far fa-circle fa-stack-2x "></i>
              <i className="custom-color-icon fas fa-chevron-left fa-stack-1x "></i>
            </span>
          </span>
        </button>

        <div style={{ marginRight: "4rem" }}>
          {page === "feedback" && (
            <span className="icon is-large">
              <CircleCheck></CircleCheck>
            </span>
          )}
          {page !== "feedback" && <Localizacao></Localizacao>}
        </div>
        <div />
      </header>

      <main className="presencial__main">
        <h1 className="presencial__titulo">{titulo}</h1>
        <div
          className="presencial__paragrafos"
          dangerouslySetInnerHTML={{
            __html: dinamicaPresencial?.description,
          }}
        ></div>
        <p className="presencial__informacoes">
          <b>{i18n.message("dinamica.presencial.local", "Local:")}</b>{" "}
          {dinamicaPresencial?.mainAddress}
          <br />
        </p>
        <p className="presencial__informacoes">
          <b>{i18n.message("dinamica.presencial.horario", "Horário:")}</b>{" "}
          {dinamicaPresencial?.timeDescription}
          <br />
        </p>
      </main>
    </DetalhesDinamicaPresencialStyle>
  );
}

import styled from "styled-components";

export const PagesHeaderStyle = styled.div`
  .pageHeader__title {
    margin-bottom: 0.5rem; //8px;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

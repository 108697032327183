export default class AgendaGlobalService {
  getAgendaGlobal() {
    let valorStorage = window.localStorage.getItem("agenda-global");
    if (valorStorage) {
      return JSON.parse(valorStorage);
    } else {
      return null;
    }
  }

  setAgendaGlobal(agendaGlobal) {
    if (agendaGlobal) {
      window.localStorage.setItem(
        "agenda-global",
        JSON.stringify(agendaGlobal),
      );
    }
  }

  removeAgendaGlobal() {
    window.localStorage.removeItem("agenda-global");
  }

  atualizarAtividadeExecutadaAgendaGlobal(codigoAtividade, executada) {
    let globalSchedule = this.getAgendaGlobal();
    let atividadeEncontrada = globalSchedule
      .flatMap((item) => item.activities)
      .find((atividade) => atividade.dynamicUuid === codigoAtividade);
    if (atividadeEncontrada) {
      atividadeEncontrada.executed = executada;
    }
    this.setAgendaGlobal(globalSchedule);
  }

  filterGlobalScheduleBy(type) {
    const globalSchedule = this.getAgendaGlobal();
    if (globalSchedule !== null) {
      const result = globalSchedule
        ?.map((item) => {
          return {
            ...item,
            activities: item?.activities?.filter(
              (activity) => activity?.type === type,
            ),
          };
        })
        .filter((item) => item?.activities?.length > 0);
      return result;
    }
    return [];
  }
}

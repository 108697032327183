import React, { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import SecundarioBtn from "../../buttonPWA2.0/SecundarioBtn";
import CampanhaService from "../../../services/CampanhaService";
import UsuarioService from "../../../services/UsuarioService";
import Auth2FATokenService from "../../../services/2FAToken";
import WeexModal from "../../weexModais/WeexModal";
import { LoginStyle } from "../../../pages/authInscricao/style";
import CustomWeexInput from "../input/CustomWeexInput";
import TemaService from "../../../services/TemaService";
import WeexFactory from "../../WeexInputs/WeexFactory";

function Login2FA() {
  const usuarioService = new UsuarioService();
  const campanhaService = new CampanhaService();
  const eventoCorrente = campanhaService.campanhaCorrente();
  const tipoInscricao = eventoCorrente.tipoInscricao;
  const elegibilidade = eventoCorrente.restrictEligibility;
  const nomeMatricula = eventoCorrente.nomeMatricula;
  const obrigatoriedadeDoTermoCampanha = eventoCorrente.legalTerm;
  const campaignType = eventoCorrente?.campaignType;
  const isCampaignTypeEquipe = campaignType === "EQUIPE";
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showInputsCodigoValidacao, setShowInputsCodigoValidacao] =
    useState(false);
  const [validateCodigo, setValidateCodigo] = useState(false);
  const [auth2FABloqueado, setAuth2FABloqueado] = useState(false);

  const [username, setUsername] = useState(""); // poder ser e-mail ou matrícula
  const [codigo, setCodigo] = useState("");
  const [processando, setProcessando] = useState(false);
  const [erro, setErro] = useState(null);
  const [update, setUpdate] = useState(0);
  const auth2FATokenService = new Auth2FATokenService();

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleCodigo = (e) => {
    setCodigo(e.target.value);
    setValidateCodigo(false);
  };

  const submit = (event) => {
    event.preventDefault();
    if (processando) {
      return;
    }
    setProcessando(true);

    if (codigo === "" && showInputsCodigoValidacao) {
      setValidateCodigo(true);
      setUpdate((update) => update + 1);
      return;
    }

    let credenciais = {
      username: username,
      campaign: eventoCorrente.code,
      password: null,
      extraInfo: [{ code: "EMAIL_2FA", value: codigo }],
    };
    usuarioService.auth(credenciais, (erro, sucesso) => {
      if (erro) {
        verificaErrosSubmit(erro);
        return;
      }
      if (sucesso) {
        removerTema();
        setProcessando(false);
        if (sucesso?.code === "202") {
          setShowInputsCodigoValidacao(true);
          return;
        }
        if (obrigatoriedadeDoTermoCampanha && !sucesso.legalTerm) {
          navigate("/accept");
          return;
        }
        contadorCampanha();
        dispatch({ type: "usuario", payload: true });
        dispatch({ type: "usuariologado", payload: sucesso });
        dispatch({ type: "userTeamUuid", payload: sucesso.team });
        dispatch({
          type: "usuarioAvatar",
          payload: sucesso?.avatarDTO ? true : false,
        });
        dispatch({ type: "avatar", payload: sucesso?.avatarDTO });
      }
    });
  };

  const removerTema = () => {
    const temaService = new TemaService();
    temaService.removerTema();
  };

  const contadorCampanha = () => {
    campanhaService.contadorCampanha((erro, sucesso) => {
      if (erro) {
        setErro({
          titulo: i18n.message(
            "atividades.erro.dias.habilitados",
            "Erro obter lista de dias habilitados",
          ),
        });
        return;
      }
      if (sucesso) {
        if (sucesso !== null) {
          if (sucesso.length > 0) {
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
          } else {
            dispatch({ type: "campanhaComecou", payload: false });
            const adminOrGestor =
              usuarioService.isAdmin() || usuarioService.isGestor();
            if (
              elegibilidade &&
              tipoInscricao === "ELEGIBILIDADE" &&
              isCampaignTypeEquipe &&
              !usuarioService.usuarioPossuiEquipe() &&
              !adminOrGestor
            ) {
              navigate("/selectTeam");
              return;
            }
            navigate("/home");
          }
        }
      }
    });
  };

  const verificaErrosSubmit = (erro) => {
    if (erro?.response?.data?.message) {
      const message = erro.response.data.message;
      if (message === "Código inválido") {
        setErro({
          titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
          mensagem: i18n.message("login.erro.denied.code", "Código inválido"),
        });
        setProcessando(false);
        return;
      }
      setErro({
        titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
        mensagem: i18n.message(
          "login.erro.denied.mensagem",
          "Participante, informe todas as credenciais, elas são obrigatórias.",
        ),
      });
      setProcessando(false);
      return;
    }

    setErro({
      titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
      mensagem: i18n.message(
        "login.erro.denied.mensagem",
        "Verifique sua matrícula ou e-mail e tente novamente.",
      ),
    });
    setProcessando(false);
  };

  const request2FAToken = (event) => {
    event.preventDefault();
    if (processando) {
      return;
    }

    if (auth2FABloqueado) {
      return;
    }

    setAuth2FABloqueado(true);
    setProcessando(true);

    if (!username) {
      setProcessando(false);
      setUpdate((update) => update + 1);
      return;
    }

    const request = {
      principal: username,
      campaign: eventoCorrente?.code,
      type: "EMAIL_2FA",
    };

    auth2FATokenService.fAToken(request, (erro, sucesso) => {
      if (erro) {
        setErro({
          titulo: i18n.message(
            "login.erro.2fa.token.titulo",
            "Error ao gerar código",
          ),
          mensagem: i18n.message(
            "login.erro.2fa.token.mensagem",
            "Não foi possível gerar o código, tente novamente mais tarde",
          ),
        });
        setProcessando(false);
        return;
      }
      //criar time de 5 segundos
      const timer = setTimeout(() => {
        setAuth2FABloqueado(false);
        setProcessando(false);
        clearTimeout(timer);
      }, 5000);
    });
  };

  const mensagemCodigo2F = () => {
    return (
      <fieldset>
        <p>
          {i18n.message(
            "2fa.mesagem.codigo.enviado",
            "Um código foi enviado ao seu email, favor digitá-lo no campo abaixo",
          )}
        </p>
      </fieldset>
    );
  };

  const authCodigoBloqueado = () => {
    if (auth2FABloqueado) {
      return (
        <fieldset>
          <p>
            {i18n.message(
              "2fa.mensagem.reenvio.aviso",
              "Foi reenviado o código para o seu e-mail. Caso não o encontre na caixa de entrada, verifique no spam.",
            )}
          </p>
        </fieldset>
      );
    }
  };

  const mensagemValidarCodigo2F = () => {
    if (validateCodigo) {
      return (
        <fieldset>
          <p className="login__2fa--error">
            {i18n.message(
              "2fa.campo.codigo.validacao",
              "Por favor, preechar o código",
            )}
          </p>
        </fieldset>
      );
    }
  };

  return (
    <LoginStyle>
      {erro != null ? (
        <WeexModal
          fecharModalCallback={() => setErro(null)}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}
      <form className="loginStyle__form loginStyle__2fa" onSubmit={submit}>
        <CustomWeexInput
          tipoInscricao={tipoInscricao}
          elegibilidade={elegibilidade}
          nomeMatricula={nomeMatricula}
          onChange={onChangeUsername}
        />
        {showInputsCodigoValidacao && (
          <>
            {mensagemCodigo2F()}
            <fieldset>
              <WeexFactory
                id="enrollment"
                type="text"
                name="enrollment"
                placeholder={i18n.message(
                  "2fa.campo.codigo.placeholder",
                  "Código",
                )}
                onChange={handleCodigo}
                required={true}
                ariaLabel={i18n.message(
                  "aria.label.campo.codigo2fa",
                  "Insira ó código",
                )}
              />

              <button
                type="button"
                onClick={request2FAToken}
                className="weex__buttonLink weex__buttonLink--color"
                disabled={auth2FABloqueado}
              >
                {i18n.message("2fa.renviar", "Reenviar código")}
              </button>
            </fieldset>
            {authCodigoBloqueado()}
            {mensagemValidarCodigo2F()}
          </>
        )}
        <fieldset key={update} className="loginStyle__enterButton">
          <SecundarioBtn
            disabledOnClick={false}
            disabled={processando}
            type="submit"
            nome={
              processando
                ? i18n.message("geral.aguarde", "AGUARDE...")
                : i18n.message("login.entrar", "Entrar")
            }
          />
        </fieldset>
      </form>
    </LoginStyle>
  );
}

export default Login2FA;

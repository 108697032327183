import React, { useLayoutEffect, useRef, useState } from "react";
import { useStore, useSelector } from "react-redux";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import ModalConclusao from "../../comps/weexModais/ModalConclusao";
import FeedbackService from "../../services/FeedbackService";
import Estrelas from "./Estrelas";
import SimNao from "./SimNao";
import { FeedbackStyle } from "./style";
import Mensagem from "./Mensagem";
import PagesHeader from "../../comps/pagesHeader/PagesHeader";

function Feedback() {
  const i18n = useStore().getState().i18n;
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const [processando, setProcessando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [pontos, setPontos] = useState(null);
  const [feedback, setFeedback] = useState({
    conteudoRelevante: 0,
    recomendaria: 0,
    participariaNovamente: null,
    mensagem: "",
  });
  const scrollRef = useRef(null);

  useLayoutEffect(() => {
    window.scrollTo({ top: 200, behavior: "smooth" });
  }, []);

  const onChangeConteudoRelevante = (estrelas) => {
    setFeedback({ ...feedback, conteudoRelevante: estrelas });
  };

  const onChangeRecomendaria = (estrelas) => {
    setFeedback({ ...feedback, recomendaria: estrelas });
  };

  const onChangeParticiparia = (valor) => {
    setFeedback({ ...feedback, participariaNovamente: valor });
  };

  const onChangeFeedback = (valor) => {
    let { value } = valor.target;
    setFeedback({ ...feedback, mensagem: value });
  };

  const enviar = (event) => {
    event.preventDefault();
    if (
      feedback.participariaNovamente === null ||
      feedback.recomendaria === 0 ||
      feedback.conteudoRelevante === 0
    ) {
      return;
    }
    setProcessando(true);
    let service = new FeedbackService();
    service.enviarFeedback(feedback, (erro, sucesso) => {
      setProcessando(false);
      if (sucesso) {
        setPontos(sucesso.earnedPoints);
        setEnviado(true);
      }
    });
  };

  const text = () => {
    return (
      <div className="titulo-container">
        <h3 className="missao-cumprida__title">
          {i18n.message("feedback.obrigado", "OBRIGADO!")}
        </h3>
        <h4 className="missao-cumprida__subtitle">
          {i18n.message(
            "feedback.opiniaoEnviada",
            "Sua opinião foi enviada com sucesso.",
          )}
        </h4>
      </div>
    );
  };

  if (enviado) {
    return <ModalConclusao pontos={pontos} text={text} />;
  }

  return (
    <FeedbackStyle>
      <PagesHeader
        ref={scrollRef}
        title={i18n.message("feedback.titulo", "Feedback")}
        subtitle={i18n.message(
          "feedback.titulo1",
          "Conte-nos sobre sua experiência aqui.",
        )}
      />

      <div className="conteudo-card-atividades">
        <form>
          <div className="questao_feedback">
            <div>
              <span className="weex__a11y--visuallyHidden">{`1. ${i18n.message(
                "aria.label.feedback.escala",
                "Em uma escala de 1 a 5.",
              )} ${i18n.message(
                "feedback.questao1",
                "O conteúdo foi relevante?",
              )}`}</span>
              <p className="questao_feedback_enunciado" aria-hidden="true">
                <strong>01. </strong>
                {i18n.message("feedback.questao1", "O conteúdo foi relevante?")}
              </p>
              <Estrelas
                valor={feedback.conteudoRelevante}
                callback={onChangeConteudoRelevante}
              />
            </div>
          </div>
          <div className="questao_feedback">
            <div>
              <span className="weex__a11y--visuallyHidden">{`2. ${i18n.message(
                "aria.label.feedback.escala",
                "Em uma escala de 1 a 5.",
              )} ${i18n.message(
                "feedback.questao2",
                "Você recomendaria para algum colega?",
              )}`}</span>
              <p className="questao_feedback_enunciado" aria-hidden="true">
                <strong>02. </strong>
                {i18n.message(
                  "feedback.questao2",
                  "Você recomendaria para algum colega?",
                )}
              </p>
              <Estrelas
                valor={feedback.recomendaria}
                callback={onChangeRecomendaria}
              />
            </div>
          </div>
          <div className="questao_feedback">
            <div>
              <span className="weex__a11y--visuallyHidden">{`3. ${i18n.message(
                "feedback.questao3",
                "Você participaria novamente?",
              )} ${i18n.message(
                "aria.label.feedback.simNao",
                "Clique em sim ou não.",
              )}`}</span>
              <p className="questao_feedback_enunciado" aria-hidden="true">
                <strong>03. </strong>
                {i18n.message(
                  "feedback.questao3",
                  "Você participaria novamente?",
                )}
              </p>
              <SimNao
                valor={feedback.participariaNovamente}
                callback={onChangeParticiparia}
              />
            </div>
          </div>
          <div className="questao_feedback--comentario ">
            <div className="mensagem-op">
              <span className="weex__a11y--visuallyHidden">{`4. ${i18n.message(
                "feedback.comentarios",
                "Escreva seus comentários (opcional)",
              )}`}</span>
              <p className="questao_feedback_enunciado" aria-hidden="true">
                <strong>04. </strong>
                {i18n.message(
                  "feedback.comentarios",
                  "Escreva seus comentários (opcional)",
                )}
              </p>
              <Mensagem
                mensagem={feedback.mensagem}
                callback={onChangeFeedback}
                isDesktopMode={isDesktopMode}
              />
            </div>
          </div>
          <div
            className="feedback__enviarContainer"
            style={{ marginTop: "2.0em" }}
          >
            <div className="button-enviar-feedback">
              <PrimarioBtn
                nome={
                  processando
                    ? i18n.message("geral.aguarde", "Aguarde...")
                    : i18n.message("geral.enviar", "Enviar")
                }
                funcao={enviar}
                ariaStatusMessage={i18n.message(
                  "aria.status.feedback.botaoenviar",
                  "Botão enviar clicado.",
                )}
              />
            </div>
          </div>
        </form>
      </div>
    </FeedbackStyle>
  );
}

export default Feedback;

import styled from "styled-components";

export const TermsConditionsStyled = styled.div`
  margin-top: 5vh;

  .termsConditions__container {
    width: 100%;
  }

  .termsConditions__title--marginBottom {
    margin-bottom: 1.875rem; // 30px;
  }

  .termsConditions__texts {
    margin-bottom: 3.125rem; // 50px;
    text-align: center;
  }

  .termsConditions__buttonContainer {
    text-align: center;
    margin-top: 1.25rem; // 20px;
    margin-bottom: 0.625rem; // 10px;
    width: fit-content;
    margin: auto;
  }
`;
